import React from 'react'

const JJLurgiRecepi403 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width='100%'
      height='100%'
      viewBox="-0.5 -0.5 991 736"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <rect
        x={20}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"STEP"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"STEP"}
        </text>
      </switch>
      <rect
        x={20}
        y={158}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step === "1.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 173,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Standby"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={177}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Standby"}
        </text>
      </switch>
      <rect
        x={20}
        y={198}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step === "2.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 213,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Filling"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={217}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Filling"}
        </text>
      </switch>
      <rect
        x={20}
        y={238}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step === "3.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 253,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Heating"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={257}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Heating"}
        </text>
      </switch>
      <rect
        x={20}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "1.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 1"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={337}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 1"}
        </text>
      </switch>
      <rect
        x={20}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "2.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 2"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={377}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 2"}
        </text>
      </switch>
      <rect
        x={20}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "3.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 3"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={417}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 3"}
        </text>
      </switch>
      <rect
        x={20}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "4.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 4"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={457}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 4"}
        </text>
      </switch>
      <rect
        x={20}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "5.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 5"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={497}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 5"}
        </text>
      </switch>
      <rect
        x={20}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "6.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 6"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={537}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 6"}
        </text>
      </switch>
      <rect
        x={20}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "7.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 7"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={577}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 7"}
        </text>
      </switch>
      <rect
        x={20}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "8.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 8"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={617}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 8"}
        </text>
      </switch>
      <rect
        x={20}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "9.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 9"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={657}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 9"}
        </text>
      </switch>
      <rect
        x={20}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step2 === "10.0" ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Cooling 10"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={697}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Cooling 10"}
        </text>
      </switch>
      <rect
        x={140}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Control"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Control"}
        </text>
      </switch>
      <rect
        x={140}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"1"}
        </text>
      </switch>
      <rect
        x={140}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"2"}
        </text>
      </switch>
      <rect
        x={140}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"3"}
        </text>
      </switch>
      <rect
        x={140}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"4"}
        </text>
      </switch>
      <rect
        x={140}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"5"}
        </text>
      </switch>
      <rect
        x={140}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"6"}
        </text>
      </switch>
      <rect
        x={140}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"7"}
        </text>
      </switch>
      <rect
        x={140}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"8"}
        </text>
      </switch>
      <rect
        x={140}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"9"}
        </text>
      </switch>
      <rect
        x={140}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 141,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.stepmode10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={195}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"10"}
        </text>
      </switch>
      <rect
        x={260}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Delta"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Delta"}
        </text>
      </switch>
      <rect
        x={260}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"11"}
        </text>
      </switch>
      <rect
        x={260}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"12"}
        </text>
      </switch>
      <rect
        x={260}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"13"}
        </text>
      </switch>
      <rect
        x={260}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"14"}
        </text>
      </switch>
      <rect
        x={260}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"15"}
        </text>
      </switch>
      <rect
        x={260}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"16"}
        </text>
      </switch>
      <rect
        x={260}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"17"}
        </text>
      </switch>
      <rect
        x={260}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"18"}
        </text>
      </switch>
      <rect
        x={260}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"19"}
        </text>
      </switch>
      <rect
        x={260}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 261,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Delta_Tsv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={315}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"31"}
        </text>
      </switch>
      <rect
        x={380}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"T Water"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"T Water"}
        </text>
      </switch>
      <rect
        x={380}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"22"}
        </text>
      </switch>
      <rect
        x={380}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"23"}
        </text>
      </switch>
      <rect
        x={380}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"24"}
        </text>
      </switch>
      <rect
        x={380}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"25"}
        </text>
      </switch>
      <rect
        x={380}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"26"}
        </text>
      </switch>
      <rect
        x={380}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"27"}
        </text>
      </switch>
      <rect
        x={380}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"28"}
        </text>
      </switch>
      <rect
        x={380}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {props.R403.WaterTempsv8}
        </text>
      </switch>
      <rect
        x={380}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"30"}
        </text>
      </switch>
      <rect
        x={380}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.WaterTempsv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"32"}
        </text>
      </switch>
      <rect
        x={500}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Oil"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Oil"}
        </text>
      </switch>
      <rect
        x={500}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"34"}
        </text>
      </switch>
      <rect
        x={500}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"35"}
        </text>
      </switch>
      <rect
        x={500}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"36"}
        </text>
      </switch>
      <rect
        x={500}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"37"}
        </text>
      </switch>
      <rect
        x={500}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"38"}
        </text>
      </switch>
      <rect
        x={500}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"39"}
        </text>
      </switch>
      <rect
        x={500}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"40"}
        </text>
      </switch>
      <rect
        x={500}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"41"}
        </text>
      </switch>
      <rect
        x={500}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"42"}
        </text>
      </switch>
      <rect
        x={500}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.OilTempsv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"43"}
        </text>
      </switch>
      <rect
        x={620}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"SV (min)"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"SV (min)"}
        </text>
      </switch>
      <rect
        x={620}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"46"}
        </text>
      </switch>
      <rect
        x={620}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"47"}
        </text>
      </switch>
      <rect
        x={620}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"48"}
        </text>
      </switch>
      <rect
        x={620}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"49"}
        </text>
      </switch>
      <rect
        x={620}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"50"}
        </text>
      </switch>
      <rect
        x={620}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"51"}
        </text>
      </switch>
      <rect
        x={620}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"52"}
        </text>
      </switch>
      <rect
        x={620}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"53"}
        </text>
      </switch>
      <rect
        x={620}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"54"}
        </text>
      </switch>
      <rect
        x={620}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timesv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"55"}
        </text>
      </switch>
      <rect
        x={740}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"PV (min)"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"PV (min)"}
        </text>
      </switch>
      <rect
        x={740}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"60"}
        </text>
      </switch>
      <rect
        x={740}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"61"}
        </text>
      </switch>
      <rect
        x={740}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"62"}
        </text>
      </switch>
      <rect
        x={740}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"63"}
        </text>
      </switch>
      <rect
        x={740}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"64"}
        </text>
      </switch>
      <rect
        x={740}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"65"}
        </text>
      </switch>
      <rect
        x={740}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"66"}
        </text>
      </switch>
      <rect
        x={740}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"67"}
        </text>
      </switch>
      <rect
        x={740}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"68"}
        </text>
      </switch>
      <rect
        x={740}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Timepv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"69"}
        </text>
      </switch>
      <rect
        x={860}
        y={118}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#fff2cc"
        stroke="#d6b656"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 133,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"RPM"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={137}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"RPM"}
        </text>
      </switch>
      <rect
        x={860}
        y={318}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 333,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={337}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"73"}
        </text>
      </switch>
      <rect
        x={860}
        y={358}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 373,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={377}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"74"}
        </text>
      </switch>
      <rect
        x={860}
        y={398}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 413,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={417}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"75"}
        </text>
      </switch>
      <rect
        x={860}
        y={438}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 453,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={457}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"76"}
        </text>
      </switch>
      <rect
        x={860}
        y={478}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 493,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv5}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={497}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"77"}
        </text>
      </switch>
      <rect
        x={860}
        y={518}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 533,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv6}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={537}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"78"}
        </text>
      </switch>
      <rect
        x={860}
        y={558}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 573,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv7}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={577}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"79"}
        </text>
      </switch>
      <rect
        x={860}
        y={598}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 613,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv8}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={617}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"80"}
        </text>
      </switch>
      <rect
        x={860}
        y={638}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 653,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv9}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={657}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"81"}
        </text>
      </switch>
      <rect
        x={860}
        y={678}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 693,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedSv10}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={697}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"82"}
        </text>
      </switch>
      <rect
        x={380}
        y={278}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 293,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.step4water}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={297}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"21"}
        </text>
      </switch>
      <rect
        x={380}
        y={198}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 213,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.step2Filling}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={435}
          y={217}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"20"}
        </text>
      </switch>
      <rect
        x={500}
        y={238}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 253,
              marginLeft: 501,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.step3Heating}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={555}
          y={257}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"33"}
        </text>
      </switch>
      <rect
        x={620}
        y={158}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 173,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.TimeSteppv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={177}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"44"}
        </text>
      </switch>
      <rect
        x={620}
        y={278}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 293,
              marginLeft: 621,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.TimeSteppv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={675}
          y={297}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"45"}
        </text>
      </switch>
      <rect
        x={740}
        y={198}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 213,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Time2pv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={217}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"57"}
        </text>
      </switch>
      <rect
        x={740}
        y={238}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 253,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Time2pv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={257}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"58"}
        </text>
      </switch>
      <rect
        x={740}
        y={278}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 293,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Time2pv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={297}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"59"}
        </text>
      </switch>
      <rect
        x={860}
        y={198}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 213,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedStepSv2}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={217}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"70"}
        </text>
      </switch>
      <rect
        x={860}
        y={238}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 253,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedStepSv3}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={257}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"71"}
        </text>
      </switch>
      <rect
        x={860}
        y={278}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 293,
              marginLeft: 861,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.SpeedStepSv4}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={915}
          y={297}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"72"}
        </text>
      </switch>
      <rect
        x={740}
        y={158}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill="#f5f5f5"
        stroke="#666"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 173,
              marginLeft: 741,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: #333333;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#333"
                pointerEvents="all"
              >
                {props.R403.Time2pv1}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={795}
          y={177}
          fill="#333"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"56"}
        </text>
      </switch>
      <rect
        x={20}
        y={278}
        width={110}
        height={30}
        rx={4.5}
        ry={4.5}
        fill={props.R403.step === '4.0' ? '#eb4034':'#dae8fc'}
        stroke="#6c8ebf"
        pointerEvents="all"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 108,
              height: 1,
              paddingTop: 293,
              marginLeft: 21,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1);"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                <h3>{"Homogenize"}</h3>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={75}
          y={297}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"Homogenize"}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M380 28h230v50H380z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            textAlign: "left",
          }}
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 228,
              height: 1,
              paddingTop: 53,
              marginLeft: 381,
            }}
            display="flex"
          >
            <div
              data-drawio-colors="color: rgba(0, 0, 0, 1); background-color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                textAlign: "center",
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 0.1,
                  backgroundColor: "#fff",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
                display="inline-block"
                fontSize={12}
                fontFamily="Verdana"
                color="#000"
                pointerEvents="all"
              >
                <h1>
                  <font fontSize={29}>{"RECEPI D403"}</font>
                </h1>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={495}
          y={57}
          fill="rgba(0, 0, 0, 1)"
          fontFamily="Verdana"
          fontSize={12}
          textAnchor="middle"
        >
          {"RECEPI D403"}
        </text>
      </switch>
    </svg>
  )
}

export default JJLurgiRecepi403
