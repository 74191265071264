import { Button, DatePicker,  Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { ConvertMonentToDateTimeOne, SetDateRange } from '../../DataRequest/LogDataRequestWrap';
import './ReportTable.css';
import AddRowModel from './AddRowModel';
import { PlusOutlined} from '@ant-design/icons';

const ExportTable = (props) => {    

    if (props.section == 4 || props.section == 5 || props.section == 2 || props.section == 3 || props.section == 6 || props.section == 7) {
        return (
            <div style={{ paddingTop: '20px' }}>

                <div style={{ paddingTop: '20px' }}>
                    <Table columns={props.Columb} dataSource={props.LogValue}
                        bordered scroll={{ x: 50, y: 500 }}
                        pagination={false} size="small" />
                </div>

            </div >
        )
    }
    else{
        return (
            <div style={{ paddingTop: '20px' }}>

                <div style={{ paddingTop: '20px' }}>
                    <Table columns={props.Columb} dataSource={props.LogValue}
                        bordered scroll={{ x: 2000, y: 500 }}
                        pagination={false} size="small" />
                </div>

            </div >
        )
    }
}

export default ExportTable
