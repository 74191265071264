import React, { useContext, createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import Home from '../../pages/Home/Home';
// import TestApi from '../../pages/TestPage/TestApi';
import OiltekReportPG2 from '../Oiltek/OiltekReportPG2';
import OiltekReportPG1 from '../Oiltek/OiltekReportPG1';
import OiltekReportPG3 from '../Oiltek/OiltekReportPG3';
import OiltekRT from '../Oiltek/OiltekRT';
import OiltekNotification from '../Oiltek/OiltekNotification';
import OiltekDataLog from '../Oiltek/OiltekDataLog';
import AlfaReportPG1 from '../AlfaLava/AlfaReportPG1';
import AlfaReportPG2 from '../AlfaLava/AlfaReportPG2';
import AlfaReportPG3 from '../AlfaLava/AlfaReportPG3';
import AlfaDataLog from '../AlfaLava/AlfaDataLog';
import AlfaNotification from '../AlfaLava/AlfaNotification';
import AlfaRT from '../AlfaLava/AlfaRT';
import TirtiauxReportPG1 from '../Tirtiaux/TirtiauxReportPG1';
import TirtiauxReportPG2 from '../Tirtiaux/TirtiauxReportPG2';
import TirtiauxReportPG3 from '../Tirtiaux/TirtiauxReportPG3';
import TirtiauxRT from '../Tirtiaux/TirtiauxRT'
import TirtiauxDataLog from '../Tirtiaux/TirtiauxDataLog'
import TirtiauxNotification from '../Tirtiaux/TirtiauxNotification'
import NotAuthorised from '../../pages/Notfound/NotAuthorised';
import NetworkError from '../../pages/Notfound/NetworkError';
import memoryUtils from "../../utils/memoryUtils";
import OiltekReportExport from "../Oiltek/OiltekReportExport";
import AlfaReportExport from "../AlfaLava/AlfaReportExport";
import TirtiauxReportExport from "../Tirtiaux/TirtiauxReportExport";
import MSRT from "../MarShor/MSRT";
import MSDataLog from "../MarShor/MSDataLog";
import MSNotification from "../MarShor/MSNotification";

import JJLurgiReportPG1 from '../JJ Lurgi/TirtiauxReportPG1';
import JJLurgiReportPG2 from '../JJ Lurgi/TirtiauxReportPG2';
import JJLurgiReportPG3 from '../JJ Lurgi/TirtiauxReportPG3';
import JJLurgiRT from '../JJ Lurgi/TirtiauxRT'
import JJLurgiDataLog from '../JJ Lurgi/TirtiauxDataLog'
import JJLurgiNotification from '../JJ Lurgi/TirtiauxNotification'
import JJLurgiReportExport from "../JJ Lurgi/TirtiauxReportExport";

import OiltekNReportPG1 from '../OiltekN/TirtiauxReportPG1';
import OiltekNReportPG2 from '../OiltekN/TirtiauxReportPG2';
import OiltekNReportPG3 from '../OiltekN/TirtiauxReportPG3';
import OiltekNRT from '../OiltekN/TirtiauxRT'
import OiltekNDataLog from '../OiltekN/TirtiauxDataLog'
import OiltekNNotification from '../OiltekN/TirtiauxNotification'
import OiltekNReportExport from "../OiltekN/TirtiauxReportExport";

import { userinfo } from '../constants'

export function PrivateRoute(props) {
  console.log(props);
  let Line_section

  try {
    Line_section = props.line.split('-')

  } catch (error) {
    // Line_section[0] = ''
    // Line_section[1] = ''

    console.log(Line_section + 'error');

  }


  return (
    <Route
      {...props.rest}
      render={({ location }) =>
        (Line_section[0] === props.UserType || Line_section[0] === 'All') ?
          (props.Level >= props.AuthorizeType && (props.LineName === Line_section[1] || Line_section[1] === 'All' || Line_section[0] === 'All')) ?
            (props.children)
            :
            <Redirect
              to={{
                pathname: "/NotAuthorised",
                state: { from: location }
              }}
            />
          :
          (
            <Redirect
              to={{
                pathname: "/NotAuthorised",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

export function RouteWrapper(props) {
  const [state, setState] = useState({ count: 0 });




  useEffect(() => {

  }, [state]);

  // console.log(userinfo);

  // let history = useHistory();
  // const location = useLocation();

  // useEffect(() => {
  //   history.replace(`/${memoryUtils.user.site}${location.pathname}`)
  //   console.log('asda');
  //   console.log(location.pathname);
  //   return () => {}
  // }, [])

  // console.log(location.pathname);

  return (
    <div>
      {/* {console.log(userinfo)} */}

      {props.Error ?
        <div>
          <NetworkError />
        </div>
        :

        userinfo['username'] != '' ?

          <Switch>
            <Redirect from='/' exact to='/Home' />
            <Route path='/Home' component={Home} />

            <PrivateRoute path="/OiltekReportPG1" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekReportPG1 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekReportPG2" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekReportPG2 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekReportPG3" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekReportPG3 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekDataVisualize" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekDataLog />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNotification" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekNotification />
            </PrivateRoute>

            <PrivateRoute path="/OiltekRealTime" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekRT />
            </PrivateRoute>

            <PrivateRoute path="/OiltekReportExport" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekReportExport />
            </PrivateRoute>

            <PrivateRoute path="/AlfaReportPG1" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <AlfaReportPG1 />
            </PrivateRoute>

            <PrivateRoute path="/AlfaReportPG2" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <AlfaReportPG2 />
            </PrivateRoute>

            <PrivateRoute path="/AlfaReportPG3" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <AlfaReportPG3 />
            </PrivateRoute>

            <PrivateRoute path="/AlfaDataVisualize" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <AlfaDataLog />
            </PrivateRoute>

            <PrivateRoute path="/AlfaNotification" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <AlfaNotification />
            </PrivateRoute>

            <PrivateRoute path="/AlfaRealTime" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <AlfaRT />
            </PrivateRoute>

            <PrivateRoute path="/AlfaReportExport" LineName={'ALR'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <AlfaReportExport />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxReportPG1" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <TirtiauxReportPG1 />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxReportPG2" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <TirtiauxReportPG2 />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxReportPG3" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <TirtiauxReportPG3 />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxDataVisualize" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <TirtiauxDataLog />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxNotification" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <TirtiauxNotification />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxRealTime" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <TirtiauxRT />
            </PrivateRoute>

            <PrivateRoute path="/TirtiauxReportExport" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <TirtiauxReportExport />
            </PrivateRoute>


            <PrivateRoute path="/OiltekNReportPG1" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekNReportPG1 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNReportPG2" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekNReportPG2 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNReportPG3" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <OiltekNReportPG3 />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNDataVisualize" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekNDataLog />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNNotification" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekNNotification />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNRealTime" LineName={'OTRFrac'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekNRT />
            </PrivateRoute>

            <PrivateRoute path="/OiltekNReportExport" LineName={userinfo['OTRFrac']} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <OiltekNReportExport />
            </PrivateRoute>


            <PrivateRoute path="/JJLurgiReportPG1" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <JJLurgiReportPG1 />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiReportPG2" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <JJLurgiReportPG2 />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiReportPG3" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={1} Level={userinfo['authorization_level']}>
              <JJLurgiReportPG3 />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiDataVisualize" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <JJLurgiDataLog />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiNotification" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <JJLurgiNotification />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiRealTime" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <JJLurgiRT />
            </PrivateRoute>

            <PrivateRoute path="/JJLurgiReportExport" LineName={'TXF'} line={userinfo['Line']} UserType='F&R' AuthorizeType={5} Level={userinfo['authorization_level']}>
              <JJLurgiReportExport />
            </PrivateRoute>
            {/* 
          <PrivateRoute path="/TestApi" line={userinfo['Site']} UserType='All' AuthorizeType={1} Level={userinfo['authorization_level']}>
            <TestApi />
          </PrivateRoute> */}

            {/* <Route path='/TestApi' component={TestApi} /> */}
            <PrivateRoute path="/MSRT" LineName={'MARSHOR'} line={userinfo['Line']} UserType='FATS' AuthorizeType={2} Level={userinfo['authorization_level']}>
              <MSRT />
            </PrivateRoute>

            <PrivateRoute path="/MSDataLog" LineName={'MARSHOR'} line={userinfo['Line']} UserType='FATS' AuthorizeType={2} Level={userinfo['authorization_level']}>
              <MSDataLog />
            </PrivateRoute>

            <PrivateRoute path="/MSNotification" LineName={'MARSHOR'} line={userinfo['Line']} UserType='FATS' AuthorizeType={2} Level={userinfo['authorization_level']}>
              <MSNotification />
            </PrivateRoute>



            <Route path='/NotAuthorised' component={NotAuthorised} />
            <Route path='/NetworkError' component={NetworkError} />

            <Redirect exact to='/NotAuthorised' />
          </Switch> :
          <div></div>
      }
    </div>
  )
}

// function PublicPage() {
//   return <h3>Public</h3>;
// }

// function ProtectedPage() {
//   return <h3>Protected</h3>;
// }

// function LoginPage() {
//   let history = useHistory();
//   let location = useLocation();
//   let auth = useAuth();

//   let { from } = location.state || { from: { pathname: "/" } };
//   let login = () => {
//     auth.signin(() => {
//       history.replace(from);
//     });
//   };

//   return (
//     <div>
//       <p>You must log in to view the page at {from.pathname}</p>
//       <button onClick={login}>Log in</button>
//     </div>
//   );
// }
