import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_logdata, delete_downtime, logdata_request, logdata_Write, ReadJob, WriteJob, downtime_Write, downtime_request } from '../../api';
import { DataSortTime, DataSortTime1, TableColumbLabelAsRow, LogDataMappingName, ReportSort, TableColumbJob, TableColumbLabelAsRowG2, GetDateTime, ConvertMonentToDateTimeOne } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin, Divider } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { JJLurgiModelPage3, JJLurgiModelPage3_1, JJLurgiModelPage3_2 } from '../../DataRequest/JJLurgiModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogTT3": [],
    "LogTT3_1": [],
    "LogTT3_2": []
}
let DisableInput = false
let DisableInputOperator = false


let ParaList = Object.keys(JJLurgiModelPage3)
let ParaList_1 = Object.keys(JJLurgiModelPage3_1)
let ParaList_2 = Object.keys(JJLurgiModelPage3_2)


let columns
let columns_1
let columns_2
let prevReading = {}
let prevReading_1 = {}
let prevReading_2 = {}
let setRange = []

setRange = GetDateTime(0, 1)

const JJLurgiReportPG3 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [visible_1, setVisible_1] = useState(false);
    const [Loading_1, setLoading_1] = useState(false);
    const [visible_2, setVisible_2] = useState(false);
    const [Loading_2, setLoading_2] = useState(false);


    const user = memoryUtils.user

    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username'] === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        // console.log(setRange);
        UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3", JJLurgiModelPage3, 1)
        UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3_1", JJLurgiModelPage3_1, 2)
        UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3_2", JJLurgiModelPage3_2, 3)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Batch',
            width: 100,
            dataIndex: 'Batch',
            key: 'Batch',
            align: 'center'
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },
        {
            title: 'Cryst No.',
            width: 40,
            dataIndex: 'Cryst No.',
            key: 'Cryst No.',
            align: 'center',
        },
        {
            title: 'Loading Time',
            width: 70,
            dataIndex: 'Loading Time',
            key: 'Loading Time',
            align: 'center',

        },
        {
            title: 'Cooling Time',
            width: 70,
            dataIndex: 'Cooling Time',
            key: 'Cooling Time',
            align: 'center',
        },
        {
            title: 'RBDPO',
            children: [
                {
                    title: 'FFA',
                    width: 60,
                    dataIndex: 'RBDPO FFA',
                    key: 'RBDPO FFA',
                    align: 'center'
                },
                {
                    title: 'Col(R)',
                    width: 60,
                    dataIndex: 'RBDPO Col(R)',
                    key: 'RBDPO Col(R)',
                    align: 'center'
                },
                {
                    title: 'I.V.',
                    width: 60,
                    dataIndex: 'RBDPO I.V.',
                    key: 'RBDPO I.V.',
                    align: 'center'
                },
            ]
        },
        {
            title: 'Filtration Time',
            width: 70,
            dataIndex: 'Filtration Time',
            key: 'Filtration Time',
            align: 'center',
        },

        {
            title: 'OLEIN',
            children: [
                {
                    title: 'FFA',
                    width: 60,
                    dataIndex: 'OLEIN FFA',
                    key: 'OLEIN FFA',
                    align: 'center'
                },
                {
                    title: 'Col(R)',
                    width: 60,
                    dataIndex: 'OLEIN Col(R)',
                    key: 'OLEIN Col(R)',
                    align: 'center'
                },
                {
                    title: 'I.V.',
                    width: 60,
                    dataIndex: 'OLEIN I.V.',
                    key: 'OLEIN I.V.',
                    align: 'center'
                },
                {
                    title: 'C.P.(C)',
                    width: 60,
                    dataIndex: 'OLEIN C.P.',
                    key: 'OLEIN C.P.',
                    align: 'center'
                },
                {
                    title: 'Yield(%)',
                    width: 60,
                    dataIndex: 'OLEIN Yield(%)',
                    key: 'OLEIN Yield(%)',
                    align: 'center'
                },
                {
                    title: 'Storage',
                    width: 60,
                    dataIndex: 'OLEIN Storage',
                    key: 'OLEIN Storage',
                    align: 'center'
                },
            ]
        },

        {
            title: 'STEARIN',
            children: [
                {
                    title: 'FFA',
                    width: 60,
                    dataIndex: 'STEARIN FFA',
                    key: 'STEARIN FFA',
                    align: 'center'
                },
                {
                    title: 'Col(R)',
                    width: 60,
                    dataIndex: 'STEARIN Col(R)',
                    key: 'STEARIN Col(R)',
                    align: 'center'
                },
                {
                    title: 'I.V.',
                    width: 60,
                    dataIndex: 'STEARIN I.V.',
                    key: 'STEARIN I.V.',
                    align: 'center'
                },
                {
                    title: 'VM(%)',
                    width: 60,
                    dataIndex: 'STEARIN VM(%)',
                    key: 'STEARIN VM(%)',
                    align: 'center'
                },
                {
                    title: 'M.P.(C)',
                    width: 60,
                    dataIndex: 'STEARIN M.P.',
                    key: 'STEARIN M.P.',
                    align: 'center'
                },
                {
                    title: 'Storage',
                    width: 60,
                    dataIndex: 'STEARIN Storage',
                    key: 'STEARIN Storage',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record, 1) }
            } > Click </Button>,
        }
    ]

    columns_1 = [
        {
            title: 'Shift',
            width: 30,
            dataIndex: 'Shift',
            key: 'Shift',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Datetime',
            children: [
                {
                    title: 'From',
                    width: 40,
                    dataIndex: 'From',
                    key: 'From',
                    align: 'center'
                },
                {
                    title: 'To',
                    width: 40,
                    dataIndex: 'To',
                    key: 'To',
                    align: 'center'
                },
            ]
        },
        {
            title: 'Total (hr)',
            children: [
                {
                    title: 'Hour',
                    width: 40,
                    dataIndex: 'Hour',
                    key: 'Hour',
                    align: 'center'
                },
                {
                    title: 'Minute',
                    width: 40,
                    dataIndex: 'Minute',
                    key: 'Minute',
                    align: 'center'
                },
            ]
        },
        {
            title: 'Reason',
            width: 200,
            dataIndex: 'Reason',
            key: 'Reason',
            align: 'center',
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 30,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record, 2) }
            } > Click </Button>,
        }
    ]

    columns_2 = [
        {
            title: 'Parameter',
            width: 30,
            dataIndex: 'Parameter',
            key: 'Parameter',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Initial',
            width: 30,
            dataIndex: 'Initial',
            key: 'Initial',
            align: 'center'
        },
        {
            title: 'Final',
            width: 30,
            dataIndex: 'Final',
            key: 'Final',
            align: 'center'
        },
        {
            title: 'Difference',
            width: 30,
            dataIndex: 'Difference',
            key: 'Difference',
            align: 'center',
        },
        {
            title: 'Unit per MT',
            width: 30,
            dataIndex: 'Unit per MT',
            key: 'Unit per MT',
            align: 'center',

        },
        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 20,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record, 3) }
            } > Click </Button>,
        }
    ]

    const CollectionCreateForm_1 = ({ visible, onCreate, onCancel, initialValues }) => {

        // let f
        // let t
        // f = moment(initialValues['From'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        // t = moment(initialValues['To'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        // initialValues['DateTime'] = [f, t]

        initialValues['From'] = moment(initialValues['From'], "DD-MM-YYYY HH:mm:ss")
        initialValues['To'] = moment(initialValues['To'], "DD-MM-YYYY HH:mm:ss")
        initialValues['DateTime'] = [initialValues['From'], initialValues['To']]
        
        console.log(initialValues)
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete_1(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="Shift"
                        label="Shift"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the shift!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>Time</Title>

                    <Form.Item
                        name='From'
                        label='From'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['From']} disabled={true}/>
                    </Form.Item>

                    <Form.Item
                        name='To'
                        label='To'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime initialValues={initialValues['To']} disabled={true}/>
                    </Form.Item>

                    <Form.Item
                        name="Reason"
                        label="Reason"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the reason!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {

        if (initialValues['Loading Time'] == undefined) {
            initialValues['Loading Time'] = moment(initialValues['DateTime'], 'DD-MM-YYYY HH:mm:ss')
        }
        else {
            initialValues['Loading Time'] = moment(initialValues['Loading Time'], 'DD-MM-YYYY HH:mm:ss')
        }

        if (initialValues['Cooling Time'] == undefined) {
            initialValues['Cooling Time'] = moment(initialValues['DateTime'], 'DD-MM-YYYY HH:mm:ss')
        }
        else {
            initialValues['Cooling Time'] = moment(initialValues['Cooling Time'], 'DD-MM-YYYY HH:mm:ss')
        }

        if (initialValues['Filtration Time'] == undefined) {
            initialValues['Filtration Time'] = moment(initialValues['DateTime'], 'DD-MM-YYYY HH:mm:ss')
        }
        else {
            initialValues['Filtration Time'] = moment(initialValues['Filtration Time'], 'DD-MM-YYYY HH:mm:ss')
        }

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Batch"
                        label="Batch"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput}/>
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>Cryst</Title>

                    <Form.Item
                        name='Cryst No.'
                        label='No.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>Loading</Title>

                    <Form.Item
                        name='Loading Time'
                        label='Time'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['Loading Time']} disabled={DisableInput}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>Cooling</Title>

                    <Form.Item
                        name='Cooling Time'
                        label='Time'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime initialValues={initialValues['Cooling Time']} disabled={DisableInput}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>RBDPO</Title>

                    <Form.Item
                        name='RBDPO FFA'
                        label='FFA'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='RBDPO Col(R)'
                        label='Col(R)'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='RBDPO I.V.'
                        label='I.V.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>Filtration</Title>

                    <Form.Item
                        name='Filtration Time'
                        label='Time'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime initialValues={initialValues['Filtration Time']} disabled={DisableInput}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>OLEIN</Title>

                    <Form.Item
                        name='OLEIN FFA'
                        label='FFA'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='OLEIN Col(R)'
                        label='Col(R)'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='OLEIN I.V.'
                        label='I.V.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='OLEIN C.P.'
                        label='C.P.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='OLEIN Yield(%)'
                        label='Yield(%)'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='OLEIN Storage'
                        label='Storage'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Title level={4} mark={true} style={{ color: 'red' }}>STEARIN</Title>

                    <Form.Item
                        name='STEARIN FFA'
                        label='FFA'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='STEARIN Col(R)'
                        label='Col(R)'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='STEARIN I.V.'
                        label='I.V.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='STEARIN VM(%)'
                        label='VM(%)'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='STEARIN M.P.'
                        label='M.P.'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                    <Form.Item
                        name='STEARIN Storage'
                        label='Storage'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator}/>
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const CollectionCreateForm_2 = ({ visible, onCreate, onCancel, initialValues }) => {

        initialValues[(initialValues['Parameter']  || []).concat(' Initial')] = initialValues['Initial']
        initialValues[(initialValues['Parameter']  || []).concat(' Final')] = initialValues['Final']
        initialValues[(initialValues['Parameter']  || []).concat(' Unit per MT')] = initialValues['Unit per MT']
        initialValues[(initialValues['Parameter']  || []).concat(' Difference')] = initialValues['Difference']


        console.log(initialValues)

        
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete_2(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}

                    
                >     
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name = "Parameter"
                        label = "Parameter"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name = {(initialValues['Parameter']  || []).concat(' Initial')}
                        label='Initial'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name = {(initialValues['Parameter']  || []).concat(' Final')}
                        label='Final'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput}/>
                    </Form.Item>

                    <Form.Item
                        name = {(initialValues['Parameter']  || []).concat(' Difference')}
                        label='Difference'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={DisableInput}/>
                    </Form.Item>
                    
                    <Form.Item
                        name = {(initialValues['Parameter']  || []).concat(' Unit per MT')}
                        label='Unit per MT'
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput}/>
                    </Form.Item>


                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', "JJLurgi", 3)
        setVisible(false);
    }

    const onDelete_1 = (values) => {
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog1(ParaList_1.Shift, values, 'FGV', "JJLurgi", 3)
        setVisible_1(false);
    }

    const onDelete_2 = (values) => {
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList_2, values, 'FGV', "JJLurgi", 3)
        setVisible_2(false);
    }
    

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3", JJLurgiModelPage3, 1)
            UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3_1", JJLurgiModelPage3_1, 2)
            UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3_2", JJLurgiModelPage3_2, 3)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }

    const DeleteLog1 = async (Shift, DateTime, Site, Line, Page) => {

        const response = await delete_downtime(Shift, DateTime, Site, Line)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3", JJLurgiModelPage3, 1)
            UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3_1", JJLurgiModelPage3_1, 2)
            UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', 'JJLurgi', Page, "LogTT3_2", JJLurgiModelPage3_2, 3)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }



    const handletable = (record, section) => {
        console.log(section)
        console.log(record)
        if (section == 1) {
            prevReading = record
            setVisible(true);
        }
        else if (section == 2) {
            prevReading_1 = record
            setVisible_1(true);
        }
        else if (section == 3) {
            prevReading_2 = record
            setVisible_2(true);
        
        }
        
        // console.log(prevReading);
        // console.log(LogValue['LogTT3']);
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime
        // console.log(setRange);
        // console.log(Parameter);
        // console.log(Site);
        // console.log(Line);
        // console.log(Page);
        console.log(Section);
        let data
        var response
        if (Section == 2) {
            response = await downtime_request(StartTime, EndTime, Site, Line)
            data = DataSortTime1(response)
        }
        else {
            response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
            data = DataSortTime(response, {section: Section})
            // data = DataSortTime(response)
        }
        // console.log(response)
        
        console.log(data);
        LogValue[BufferName] = LogDataMappingName(Model, data, Section)
        console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data, section) => {
        const WriteRow = []
        WriteRow.push(data)

        for (let key in WriteRow[0]){
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }



        console.log(WriteRow);
        console.log(section);
        var response
        if (section == 2) {
            response = await downtime_Write(WriteRow, 'FGV', 'JJLurgi')
        }
        else {
            response = await logdata_Write(WriteRow, 'FGV', 'JJLurgi', 3)
        }

        if (response.success === true) {
            message.success('Successfully Add')
            console.log("Write success")
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3", JJLurgiModelPage3, 1)
            UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3_1", JJLurgiModelPage3_1, 2)
            UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3_2", JJLurgiModelPage3_2, 3)
           // UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3", JJLurgiModelPage3)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        console.log(values);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values['Filtration Time'] = moment(values['Filtration Time'].toISOString(false).split('.')[0] + "Z").utc(true).format('DD-MM-YYYY HH:mm:ss')
        values['Loading Time'] = moment(values['Loading Time'].toISOString(false).split('.')[0] + "Z").utc(true).format('DD-MM-YYYY HH:mm:ss')
        values['Cooling Time'] = moment(values['Cooling Time'].toISOString(false).split('.')[0] + "Z").utc(true).format('DD-MM-YYYY HH:mm:ss')
        setVisible(false);
        setLoading(true)

        await AddNewDataRow(values, 1)
        console.log(ParaList);
        console.log('1');
        await UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3", JJLurgiModelPage3, 1)
        // console.log(values);
        setLoading(false)
    };

    const onCreate_1 = async (values) => {
        // console.log(values);
        values['From'] = moment(values['From'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values['To'] = moment(values['To'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values['DateTime'] = [values['From'], values['To']]
        console.log(values);
        setVisible_1(false);
        setLoading_1(true)

        await AddNewDataRow(values, 2)
        console.log('2');
        await UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3_1", JJLurgiModelPage3_1, 2)
        // console.log(values);
        setLoading_1(false)
    };

    const onCreate_2 = async (values) => {
        console.log(values);
        console.log(values['DateTime']);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible_2(false);
        setLoading_2(true)

        await AddNewDataRow(values, 3)
        console.log('3');
        await UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', 'JJLurgi', 3, "LogTT3_2", JJLurgiModelPage3_2, 3)
        // console.log(values);
        setLoading_2(false)
    };

    return (
        <div>
                                    <Spin tip="Uploading" spinning={Loading}>
            <div style={{ paddingBottom: '2px' }}>
                <Divider orientation="left">Quality</Divider>
            </div>
            <ReportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT3']} Columb={columns}
                site={'JJLurgi'} page={'3'} BufferName={"LogTT3"} Model={JJLurgiModelPage3}
                AddNewDataRow={AddNewDataRow}
                section = {1}
            />
            </Spin>

            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                        UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3", JJLurgiModelPage3, 1)
                    }}
                    initialValues={prevReading}
                />
            </div>
            
            <Spin tip="Uploading" spinning={Loading_1}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">Downtimes</Divider>
                </div>
                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogTT3_1']} Columb={columns_1}
                    site={'JJLurgi'} page={'3'} BufferName={"LogTT3_1"} Model={JJLurgiModelPage3_1}
                    AddNewDataRow={AddNewDataRow}
                    section = {2}
                />

            </Spin>

            <div>
                <CollectionCreateForm_1
                    visible={visible_1}
                    onCreate={onCreate_1}
                    onCancel={() => {
                        setVisible_1(false);
                        UpdateTableData(ParaList_1, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3_1", JJLurgiModelPage3_1, 2)
                    }}
                    initialValues={prevReading_1}
                />
            </div>

            <Spin tip="Uploading" spinning={Loading_2}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">Utility Consumption</Divider>
                </div>
                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogTT3_2']} Columb={columns_2}
                    site={'JJLurgi'} page={'3'} BufferName={"LogTT3_2"} Model={JJLurgiModelPage3_2}
                    AddNewDataRow={AddNewDataRow}
                    section = {3}
                />
            </Spin>

            <div>
                <CollectionCreateForm_2
                    visible={visible_2}
                    onCreate={onCreate_2}
                    onCancel={() => {
                        setVisible_2(false);
                        UpdateTableData(ParaList_2, setRange[0], setRange[1], 'FGV', "JJLurgi", 3, "LogTT3_2", JJLurgiModelPage3_2, 3)
                    }}
                    initialValues={prevReading_2}
                />
            </div>


            
        </div>
    )
}

export default JJLurgiReportPG3
