import React, { useState, useEffect } from 'react'
import { delete_logdata, logdata_request, logdata_Write } from '../../api';
import { DataSortTime, TableColumbLabelAsRow, LogDataMappingName1, GetDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import ReportTable from '../Report/ReportTable';
import { OTKModelPage1, OTKModelPage2 } from '../../DataRequest/OiltekModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogOtk2": []
}

let ParaList = Object.keys(OTKModelPage2)
let DisableInput = false
let DisableInputOperator = false


let columns
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []
setRange = GetDateTime(0, 1)

const OiltekReportPG2 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);


    const user = memoryUtils.user

    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username'] === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        // console.log(setRange);
        UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 80,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center'
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },

        {
            title: 'BPO TANK (D701)',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'BPO TANK D701 Temperature',
                    key: 'BPO TANK D701 Temperature',
                    align: 'center'
                },
                {
                    title: 'LEVEL (%)',
                    width: 60,
                    dataIndex: 'BPO TANK D701 LEVEL',
                    key: 'BPO TANK D701 LEVEL',
                    align: 'center'
                },
            ]
        },

        {
            title: 'PHE BPO OUT TEMP (C)',
            children: [
                {
                    title: 'E706 A/B',
                    width: 60,
                    dataIndex: 'PHE BPO OUT E706 A/B Temperature',
                    key: 'PHE BPO OUT E706 A/B Temperature',
                    align: 'center'
                },
                {
                    title: 'E701',
                    width: 60,
                    dataIndex: 'PHE BPO OUT E701 Temperature',
                    key: 'PHE BPO OUT E701 Temperature',
                    align: 'center'
                },
                {
                    title: 'E702 A/B',
                    width: 60,
                    dataIndex: 'PHE BPO OUT E702 A/B Temperature',
                    key: 'PHE BPO OUT E702 A/B Temperature',
                    align: 'center'
                },
            ]
        },
        {
            title: 'VACUUM DEO (torr)',
            width: 60,
            dataIndex: 'Vacuum DEO TORR',
            key: 'Vacuum DEO TORR',
            align: 'center'
        },
        {
            title: 'BPO FLOW RATE',
            width: 60,
            dataIndex: 'BPO Flow Rate',
            key: 'BPO Flow Rate',
            align: 'center'
        },

        {
            title: 'TEMP (C)',
            children: [
                {
                    title: 'VE 702 BPO',
                    width: 60,
                    dataIndex: 'VE702 BPO Temperature',
                    key: 'VE702 BPO Temperature',
                    align: 'center'
                },
                {
                    title: 'VE 702 RPO',
                    width: 60,
                    dataIndex: 'VE702 RPO Temperature',
                    key: 'VE702 RPO Temperature',
                    align: 'center'
                },
                {
                    title: 'VE 703 BPO',
                    width: 60,
                    dataIndex: 'VE703 BPO Temperature',
                    key: 'VE703 BPO Temperature',
                    align: 'center'
                },
                {
                    title: 'VE 703A',
                    width: 60,
                    dataIndex: 'VE703A Temperature',
                    key: 'VE703A Temperature',
                    align: 'center'
                },
            ]
        },

        {
            title: 'DEOD D702 TEMP (C)',
            dataIndex: 'DEOD D702 Temperature',
            key: 'DEOD D702 Temperature',
            width: 60,
            align: 'center'
        },
        {
            title: 'RBDPO OUT TEMP (C) E702 A/B',
            dataIndex: 'RBDPO OUT E702 A/B Temperature',
            key: 'RBDPO OUT E702 A/B Temperature',
            width: 60,
            align: 'center'
        },
        {
            title: 'RBDPO OUT TEMP (C) E001 A/B',
            dataIndex: 'RBDPO OUT E001 A/B Temperature',
            key: 'RBDPO OUT E001 A/B Temperature',
            width: 60,
            align: 'center'
        },

        {
            title: 'PFAD SCR 705 TCV',
            children: [
                {
                    title: 'TEMP (c)',
                    width: 60,
                    dataIndex: 'PFAD SCR 705 TCV Temperature',
                    key: 'PFAD SCR 705 TCV Temperature',
                    align: 'center'
                },
                {
                    title: 'BAR',
                    width: 60,
                    dataIndex: 'PFAD SCR 705 TCV Bar',
                    key: 'PFAD SCR 706 TCV Bar',
                    align: 'center'
                },
            ]
        },

        {
            title: 'PFAD SCR 706 TCV',
            children: [
                {
                    title: 'TEMP (c)',
                    width: 60,
                    dataIndex: 'PFAD SCR 706 TCV Temperature',
                    key: 'PFAD SCR 706 TCV Temperature',
                    align: 'center'
                },
                {
                    title: 'BAR',
                    width: 60,
                    dataIndex: 'PFAD SCR 706 TCV Bar',
                    key: 'PFAD SCR 706 TCV Bar',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Filter Bag (F701,F702,F703) Bar',
            dataIndex: 'Filter Bag (F701,F702,F703) Bar',
            key: 'Filter Bag (F701,F702,F703) Bar',
            width: 60,
            align: 'center'
        },

        {
            title: 'TEMP (C)',
            children: [
                {
                    title: 'FRACT',
                    width: 60,
                    dataIndex: 'FRACT OILTEK Temperature',
                    key: 'FRACT OILTEK Temperature',
                    align: 'center'
                },
                {
                    title: 'STRG',
                    width: 60,
                    dataIndex: 'STRG OILTEK Temperature',
                    key: 'STRG OILTEK Temperature',
                    align: 'center'
                },

            ]
        },

        {
            title: 'RPO QUALITY',
            children: [
                {
                    title: 'CLR',
                    width: 60,
                    dataIndex: 'RBDPO Quality CLR',
                    key: 'RBDPO Quality CLR',
                    align: 'center'
                },
                {
                    title: 'FFA',
                    width: 60,
                    dataIndex: 'RBDPO Quality FFA',
                    key: 'RBDPO Quality FFA',
                    align: 'center'
                },
                {
                    title: 'BLACK PARTICAL',
                    width: 60,
                    dataIndex: 'RBDPO Quality Black Partical',
                    key: 'RBDPO Quality Black Partical',
                    align: 'center'
                },
            ]
        },
        {
            title: 'Remark',
            width: 70,
            dataIndex: 'Remark',
            key: 'Remark',
            align: 'center',
        },
        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record) }
            } > Click </Button>,
        }
    ];

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancle" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="BPO TANK D701 Temperature"
                        label="BPO TANK D701 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="BPO TANK D701 LEVEL"
                        label="BPO TANK D701 LEVEL"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE BPO OUT E706 A/B Temperature"
                        label="PHE BPO OUT E706 A/B Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE BPO OUT E701 Temperature"
                        label="PHE BPO OUT E701 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE BPO OUT E702 A/B Temperature"
                        label="PHE BPO OUT E702 A/B Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Vacuum DEO TORR"
                        label="Vacuum DEO TORR"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="BPO Flow Rate"
                        label="BPO Flow Rate"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VE702 BPO Temperature"
                        label="VE702 BPO Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VE702 RPO Temperature"
                        label="VE702 RPO Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VE703 BPO Temperature"
                        label="VE703 BPO Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VE703A Temperature"
                        label="VE703A Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="DEOD D702 Temperature"
                        label="DEOD D702 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO OUT E702 A/B Temperature"
                        label="RBDPO OUT E702 A/B Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO OUT E001 A/B Temperature"
                        label="RBDPO OUT E001 A/B Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD SCR 705 TCV Temperature"
                        label="PFAD SCR 705 TCV Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD SCR 705 TCV Bar"
                        label="PFAD SCR 705 TCV Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD SCR 706 TCV Temperature"
                        label="PFAD SCR 706 TCV Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD SCR 706 TCV Bar"
                        label="PFAD SCR 706 TCV Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filter Bag (F701,F702,F703) Bar"
                        label="Filter Bag (F701,F702,F703) Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="FRACT OILTEK Temperature"
                        label="FRACT OILTEK Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="STRG OILTEK Temperature"
                        label="STRG OILTEK Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality CLR"
                        label="RBDPO Quality CLR"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality FFA"
                        label="RBDPO Quality FFA"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality Black Partical"
                        label="RBDPO Quality Black Partical"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInputOperator}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="Remark"
                        label="Remark"
                        relues={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            }
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        console.log('delete');
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', 'OILTEK', 2)
        setVisible(false);

    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {


        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }

    const handletable = (record) => {
        prevReading = record
        setVisible(true);
        // console.log(prevReading);
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data) => {
        const WriteRow = []
        WriteRow.push(data)
        for (let key in WriteRow[0]) {
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }

        const response = await logdata_Write(WriteRow, 'FGV', 'OILTEK', 2)

        if (response.success === true) {
            message.success('Successfully Add')
            //console.log("Write success")
            //UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }


    const onCreate = async (values) => {

        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values)
        await UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        setLoading(false)
    };

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>

                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogOtk2']} Columb={columns}
                    site={'OILTEK'} page={'2'} BufferName={"LogOtk2"} Model={OTKModelPage2}
                    AddNewDataRow={AddNewDataRow}
                />
            </Spin>

            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    initialValues={prevReading}
                />
            </div>
        </div>
    )
}

export default OiltekReportPG2
