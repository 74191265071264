import React from 'react'
import MANoti from './Notification/MANoti'
import MBNoti from './Notification/MBNoti'
import MCNoti from './Notification/MCNoti'
import MPNoti from './Notification/MPNoti'
import OiltekPG2Noti from './Notification/OiltekPG2Noti'
import OiltekPG3Noti from './Notification/OiltekPG3Noti'

const MSNotification = () => {
    return (
        <div>
            <MANoti/>
            <MBNoti/>
            <MCNoti/>
            <MPNoti/>

        </div>
    )
}

export default MSNotification
