import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_logdata, logdata_request, logdata_Write, ReadJob, WriteJob } from '../../api';
import { DataSortTime, TableColumbLabelAsRow, LogDataMappingName, ReportSort, TableColumbJob, TableColumbLabelAsRowG2, GetDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { JJLurgiModelPage2 } from '../../DataRequest/JJLurgiModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogTT2": []
}
let DisableInput = false
let DisableInputOperator = false


let ParaList = Object.keys(JJLurgiModelPage2)


let columns
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []

setRange = GetDateTime(0, 1)

const JJLurgiReportPG2 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);

    if (userinfo['username']  === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username']  === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        // console.log(setRange);
        UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', "JJLurgi", 2, "LogTT2", JJLurgiModelPage2)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },

        {
            title: 'Filtering',
            children: [
                {
                    title: 'Load Pressure (Kg/cm2)',
                    width: 60,
                    dataIndex: 'Filtration Load Pressure',
                    key: 'Filtration Load Pressure',
                    align: 'center'
                },
                {
                    title: 'Squezz Time (Min)',
                    width: 60,
                    dataIndex: 'Filtration Squeeze Time',
                    key: 'Filtration Squeeze Time',
                    align: 'center'
                },
                {
                    title: 'Squezz Pressure (Kg/cm2)',
                    width: 60,
                    dataIndex: 'Filtration Squeeze Pressure',
                    key: 'Filtration Squeeze Pressure',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Air Pressure (Kg/cm2)',
            children: [
                {
                    title: 'Stearine Temp',
                    width: 60,
                    dataIndex: 'Filtration Stearine Temperature',
                    key: 'Filtration Stearine Temperature',
                    align: 'center'
                },
                {
                    title: 'Air regulator',
                    width: 60,
                    dataIndex: 'Air regulator',
                    key: 'Air regulator',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Steam Header Pressure',
            width: 60,
            dataIndex: 'Steam Header Pressure',
            key: 'Steam Header Pressure',
            align: 'center'
        },

        {
            title: 'Chiller Water Tank Temp',
            width: 60,
            dataIndex: 'Chiller Water Tank Temperature',
            key: 'Chiller Water Tank Temperature',
            align: 'center'
        },

        {
            title: 'Hot Water Temp',
            width: 60,
            dataIndex: 'Hot Water Temperature',
            key: 'Hot Water Temperature',
            align: 'center'
        },

        {
            title: 'Cooling Tower Temp',
            width: 60,
            dataIndex: 'Cooling Tower Temperature',
            key: 'Cooling Tower Temperature',
            align: 'center'
        },

        {
            title: 'Homo 5 (Tonne)',
            width: 60,
            dataIndex: 'HOMO5',
            key: 'HOMO5',
            align: 'center'
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record) }
            } > Click </Button>,
        }
    ]

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancle" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="Filtration Load Pressure"
                        label="Filtration Load Pressure"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filtration Squeeze Time"
                        label="Filtration Squeeze Time"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filtration Squeeze Pressure"
                        label="Filtration Squeeze Pressure"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filtration Stearine Temperature"
                        label="Filtration Stearine Temperature"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Air regulator"
                        label="Air regulator"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Steam Header Pressure"
                        label="Steam Header Pressure"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Chiller Water Tank Temperature"
                        label="Chiller Water Tank Temperature"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Hot Water Temperature"
                        label="Hot Water Temperature"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Cooling Tower Temperature"
                        label="Cooling Tower Temperature"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="HOMO5"
                        label="HOMO5"
                        rules={[
                            {
                                required: false,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>


                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', "JJLurgi", 2)
        setVisible(false);
    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 2, "LogTT2", JJLurgiModelPage2)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }



    const handletable = (record) => {
        prevReading = record
        setVisible(true);
        // console.log(prevReading);
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName(Model, data)
        // console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data) => {
        const WriteRow = []
        WriteRow.push(data)

        for (let key in WriteRow[0]){
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }

        // console.log(WriteRow);
        const response = await logdata_Write(WriteRow, 'FGV', 'JJLurgi', 2)

        if (response.success === true) {
            message.success('Successfully Add')
            console.log("Write success")
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 2, "LogTT2", JJLurgiModelPage2)
            //UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'JJLurgi', 2, "LogTT2", JJLurgiModelPage2)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        // console.log(values['DateTime']);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values)
        await UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', "JJLurgi", 2, "LogTT2", JJLurgiModelPage2)

        setLoading(false)
    };

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>

                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogTT2']} Columb={columns}
                    site={'JJLurgi'} page={'2'} BufferName={"LogTT2"} Model={JJLurgiModelPage2}
                    AddNewDataRow={AddNewDataRow}
                />
            </Spin>

            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    initialValues={prevReading}
                />
            </div>
        </div>
    )
}

export default JJLurgiReportPG2
