import { Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { logdata_request, downtime_request, periodiclog_request } from '../../api'
import { DataSortTime, DataSortTime1, DataSortTime2, ExportExcelWorkBookFGV, ExportExcelWorkBookFGVTT, GetDateTime, LogDataMappingName, LogDataMappingName1, LogDataMappingName3, LogDataMappingStep, LogDataMappingTime, LogDataMappingTime1, LogDataMappingTime2, LogDataMappingTime3, LogDataMappingTime4, SetDateRange } from '../../DataRequest/LogDataRequestWrap'
import { TirtiauxModelPage1_all, TirtiauxModelPage3_3, TirtiauxModelPage1_2, TirtiauxModelPage1_1, TirtiauxModelPage2, TirtiauxModelPage3, Time, TirtiauxModelPage3_1, TirtiauxModelPage3_2 } from '../../DataRequest/TirtiauxModel'
import ExportTable from '../Report/ExportTable'
import { columns1, columns1_1, columns1_2, columns3_3, columns2, columns3, columns3_1, columns3_2 } from './TirtiauxColumbs'

let LogValue = {
    "LogTT1": [],
    "LogTT1_1": [],
    "LogTT1_2": [],
    "LogTT2": [],
    "LogTT3": [],
    "LogTT3_1": [],
    "LogTT3_2": [],
    "LogTT3_3": [],
    "LogTT4": [],
    "LogTT4_1": [],
    "LogTT4_2": [],
    "LogTT5": [],
    "LogTT6": [],
    "LogTT6_1": [],
    "LogTT6_2": [],
    "LogTT6_3": [],
}

let ExcelFormat = [
     {
        startColumb:'C',
        startRow:'16',
        stopColumb: 'Z',
        stopRow:'16',
        sheet:0,
        filename:'Tirtiaux.xlsx'
    },

     {
        startColumb:'B',
        startRow:'10',
        stopColumb: 'U',
        stopRow:'10',
        sheet:1,
        filename:'Tirtiaux.xlsx'
    },

    {
        startColumb:'D',
        startRow:'10',
        stopColumb: 'W',
        stopRow:'9',
        sheet:2,
        filename:'Tirtiaux.xlsx'
    },

]

let setRange = []
setRange = GetDateTime(0, 1)

const TTPG1 = Object.keys(TirtiauxModelPage1_all)
const TTPG2 = Object.keys(TirtiauxModelPage2)
const TTPG3 = Object.keys(TirtiauxModelPage3)
const TTPG3_1 = Object.keys(TirtiauxModelPage3_1)
const TTPG3_2 = Object.keys(TirtiauxModelPage3_2)
const TTPG3_3 = Object.keys(TirtiauxModelPage3_3)

const StepRequestList = ['F2_D401_ACTUAL_STEP','F2_D402_ACTUAL_STEP','F2_D403_ACTUAL_STEP']
const StepRequestList1 = ['Washing']
const StepRequestList2 = ['Tirtiaux_D401_Remark', 'Tirtiaux_D402_Remark', 'Tirtiaux_D403_Remark']
const StepRequestList3 = ['Batch']


const TirtiauxReportExport = () => {
    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        // console.log(setRange);
        // UpdateTableData1(StepRequestList, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1", "LogTT4", TirtiauxModelPage1_all)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'Tirtiaux', 2, "LogTT2", "LogTT5", TirtiauxModelPage2)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'Tirtiaux', 3, "LogTT3", "LogTT6", TirtiauxModelPage3)

        // UpdateTableDataTime(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT4", TirtiauxModelPage1_all)
        // UpdateTableDataTime(TTPG2, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 2, "LogTT5", TirtiauxModelPage2)
        // UpdateTableDataTime(TTPG3, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 3, "LogTT6", TirtiauxModelPage3)
        return () => {
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, BufferName2, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime
        // console.log(Parameter)
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response, {section: Section})

        LogValue[BufferName] = LogDataMappingName1(Model, data, Section)
        if (Section == 3) {
            LogValue[BufferName2] = LogValue[BufferName]
        }
        else {
            LogValue[BufferName2] = LogDataMappingTime(Model, data)
        }

        console.log(LogValue[BufferName]);
        console.log(LogValue[BufferName2]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    
    const UpdateTableData1 = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, BufferName2, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await periodiclog_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime2(response)
        // console.log(response);
        LogValue[BufferName] = LogDataMappingStep(Model, data, Section)
        if (Section != 6) {
            LogValue[BufferName2] = LogDataMappingTime1(Model, data)
        }
        else {
            LogValue[BufferName2] = LogDataMappingTime3(Model, data)
        }
        console.log(LogValue[BufferName2]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const UpdateTableData2 = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, BufferName2, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response, {section: Section})

        LogValue[BufferName] = LogDataMappingName(Model, data, Section)
        LogValue[BufferName2] = LogDataMappingTime2(Model, data)

        console.log(LogValue[BufferName]);
        console.log(LogValue[BufferName2]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const UpdateTableData4 = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, BufferName2, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        // console.log(response);
        let data = DataSortTime(response, {section: Section})
        console.log(data);

        let temp = LogDataMappingName(Model, data, Section)
        let batch_id = [1, 2, 3]
        let batch_count = [0, 0, 0]
        for (let i = 0; i < batch_id.length; i++) {
            for (let j = 0; j < temp.length; j++) {
                if (temp[j]['Batch'] == batch_id[i]) {
                    batch_count[i] = batch_count[i] + 1
                }
            }
        }
        // console.log(batch_id);
        // console.log(batch_count);

        LogValue[BufferName] = LogDataMappingName3(batch_id, batch_count)
        LogValue[BufferName2] = LogDataMappingTime4(batch_id, batch_count)

        console.log(LogValue[BufferName]);
        console.log(LogValue[BufferName2]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const UpdateTableData3 = async (StartTime, EndTime, Site, Line, BufferName, BufferName2, Model, Section) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await downtime_request(StartTime, EndTime, Site, Line)
        let data = DataSortTime1(response)

        LogValue[BufferName] = LogDataMappingName(Model, data, Section)
        LogValue[BufferName2] = LogValue[BufferName]

        console.log(LogValue[BufferName]);
        console.log(LogValue[BufferName2]);
        setState(({ count }) => ({ count: count + 1 }));
    }


    // const UpdateTableDataTime = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, BufferName2, Model) => {
    //     setRange[0] = StartTime
    //     setRange[1] = EndTime

    //     const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
    //     let data = DataSortTime(response)
    //     // console.log(data);

    //     LogValue[BufferName] = LogDataMappingTime(Model, data)
    //     // console.log(LogValue[BufferName]);
    //     setState(({ count }) => ({ count: count + 1 }));
    // }


    const SetTimeRange = async (value) => {
        const DateRange = SetDateRange(value)
        await UpdateTableData1(StepRequestList, DateRange[0], DateRange[1], 'FGV', "Tirtiaux", 1, "LogTT1","LogTT4", TirtiauxModelPage1_all, 4)
        await UpdateTableData1(StepRequestList1, DateRange[0], DateRange[1], 'FGV', "Tirtiaux", 1, "LogTT1_1","LogTT4_1", TirtiauxModelPage1_1, 5)
        await UpdateTableData1(StepRequestList2, DateRange[2], DateRange[3], 'FGV', "Tirtiaux", 1, "LogTT1_2","LogTT4_2", TirtiauxModelPage1_2, 6)
        await UpdateTableData(TTPG2, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 2, "LogTT2","LogTT5",TirtiauxModelPage2, 0)
        await UpdateTableData2(TTPG3, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 3, "LogTT3","LogTT6", TirtiauxModelPage3, 1)
        await UpdateTableData4(TTPG3_3, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 3, "LogTT3_3","LogTT6_3", TirtiauxModelPage3_3, 7)
        await UpdateTableData3(DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', "LogTT3_1","LogTT6_1",TirtiauxModelPage3_1, 2)
        await UpdateTableData(TTPG3_2, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 3, "LogTT3_2","LogTT6_2",TirtiauxModelPage3_2, 3)
        

        // UpdateTableDataTime(StepRequestList, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 1, "LogTT4", TirtiauxModelPage1_all)
        // UpdateTableDataTime(TTPG2, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 2, "LogTT5", TirtiauxModelPage2)
        // UpdateTableDataTime(TTPG3, DateRange[0], DateRange[1], 'FGV', 'Tirtiaux', 3, "LogTT6", TirtiauxModelPage3)
    }

    const Export = () => {
        // console.log(LogValue['LogTT4']);
        ExportExcelWorkBookFGVTT(
            LogValue['LogTT4'], LogValue['LogTT4_1'], LogValue['LogTT4_2'], LogValue['LogTT5'], LogValue['LogTT6'],
            LogValue['LogTT6_1'], LogValue['LogTT6_2'], LogValue['LogTT6_3'],
            Time, Time, {}, {}, {},
            ExcelFormat, 'Tirtiaux.xlsx', 'Tirtiaux')
    }



    return (
        <div>
            <div>
                <DatePicker onSelect={SetTimeRange} />
                <Button onClick={Export}> Export </Button>
            </div>

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT1']} Columb={columns1}
                site={'Tirtiaux'} page={'1'} BufferName={"LogTT1"} Model={TirtiauxModelPage1_all}
                section = {4}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT1_2']} Columb={columns1_2}
                site={'Tirtiaux'} page={'1'} BufferName={"LogTT1_2"} Model={TirtiauxModelPage1_2}
                section = {6}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT1_1']} Columb={columns1_1}
                site={'Tirtiaux'} page={'1'} BufferName={"LogTT1_1"} Model={TirtiauxModelPage1_1}
                section = {5}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT2']} Columb={columns2}
                site={'Tirtiaux'} page={'2'} BufferName={"LogTT2"} Model={TirtiauxModelPage2}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT3']} Columb={columns3}
                site={'Tirtiaux'} page={'3'} BufferName={"LogTT3"} Model={TirtiauxModelPage3}
                section = {1}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT3_3']} Columb={columns3_3}
                site={'Tirtiaux'} page={'3'} BufferName={"LogTT3_3"} Model={TirtiauxModelPage3_3}
                section = {7}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT3_1']} Columb={columns3_1}
                site={'Tirtiaux'} page={'3'} BufferName={"LogTT3_1"} Model={TirtiauxModelPage3_1}
                section = {2}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogTT3_2']} Columb={columns3_2}
                site={'Tirtiaux'} page={'3'} BufferName={"LogTT3_2"} Model={TirtiauxModelPage3_2}
                section = {3}
            />


        </div>
    )
}

export default TirtiauxReportExport

