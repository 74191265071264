import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_periodiclog, periodiclog_request, periodiclog_Write, ReadJob, WriteJob } from '../../api';
import { DataSortTime, DataSortTime2, GetDateTime, LogDataMappingStep, ConvertMonentToDateTimeOne, ConvertMonentToDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, TimePicker, message, Spin, Divider } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { TirtiauxModelPage1, TirtiauxModelPage1__1, TirtiauxModelPage1__2, TirtiauxModelPage1_1, TirtiauxModelPage1_2 } from '../../DataRequest/TirtiauxModel';
import memoryUtils from '../../utils/memoryUtils';
import { columns2 } from './TirtiauxColumbs';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogTT1": [],
    "LogTT2": [],
    "LogTT3": [],
    "LogTT1_1": [],
    "LogTT1_2": []
}
let DisableInput = false


let ParaList = Object.keys(TirtiauxModelPage1)
let ParaList1 = Object.keys(TirtiauxModelPage1__1)
let ParaList2 = Object.keys(TirtiauxModelPage1__2)
let ParaList_1 = Object.keys(TirtiauxModelPage1_1)
let ParaList_2 = Object.keys(TirtiauxModelPage1_2)
const StepRequestList = ['F2_D401_ACTUAL_STEP']
const StepRequestList_1 = ['F2_D402_ACTUAL_STEP']
const StepRequestList_2 = ['F2_D403_ACTUAL_STEP']
const StepRequestList1 = ['Washing']
const StepRequestList2 = ['Tirtiaux_D401_Remark', 'Tirtiaux_D402_Remark', 'Tirtiaux_D403_Remark']

let columns, columns_1, columns_2, columns_3, columns1
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []
setRange = GetDateTime(0, 1)

const TirtiauxReportPG1 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible_1, setVisible_1] = useState(false);
    const [visible_2, setVisible_2] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [Loading1, setLoading1] = useState(false);
    const [Loading2, setLoading2] = useState(false);
    const [Loading_1, setLoading_1] = useState(false);
    const [Loading_2, setLoading_2] = useState(false);


    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else { DisableInput = false }



    useEffect(() => {
        console.log('effect');
        // setRange = GetDateTime(0, 1)

        UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1", TirtiauxModelPage1, 4)
        UpdateTableData(StepRequestList_1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT2", TirtiauxModelPage1__1, 4)
        UpdateTableData(StepRequestList_2, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT3", TirtiauxModelPage1__2, 4)
        UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1_1", TirtiauxModelPage1_1, 5)
        UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'Start',
            width: 20,
            dataIndex: 'Start',
            align: 'center'
        },
        {
            title: 'End',
            width: 20,
            dataIndex: 'End',
            align: 'center'
        },
        {
            title: 'D401',
            width: 30,
            dataIndex: 'D401',
            align: 'center',
            render(text, record) {
                let color = ''
                let name = ''

                if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
                else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
                else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
                else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
                else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
                else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
                else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
                else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }


                return {
                    props: {
                        style: {
                            background: color
                        }
                    },
                    children: <div>{name}</div>
                };
            }
        },
        // {
        //     title: 'D402',
        //     width: 30,
        //     dataIndex: 'D402',
        //     align: 'center',
        //     render(text, record) {
        //         let color = ''
        //         let name = ''

        //         if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
        //         else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
        //         else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
        //         else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
        //         else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
        //         else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
        //         else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
        //         else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }


        //         return {
        //             props: {
        //                 style: {
        //                     background: color
        //                 }
        //             },
        //             children: <div>{name}</div>
        //         };
        //     }
        // },
        // {
        //     title: 'D403',
        //     width: 30,
        //     dataIndex: 'D403',
        //     align: 'center',
        //     render(text, record) {
        //         let color = ''
        //         let name = ''

        //         if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
        //         else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
        //         else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
        //         else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
        //         else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
        //         else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
        //         else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
        //         else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }

        //         return {
        //             props: {
        //                 style: {
        //                     background: color
        //                 }
        //             },
        //             children: <div>{name}</div>
        //         };
        //     }
        // },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 10,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record, 4) }
            } > Click </Button>,
        }
    ];

    columns_1 = [
        {
            title: 'Start',
            width: 20,
            dataIndex: 'Start',
            align: 'center'
        },
        {
            title: 'End',
            width: 20,
            dataIndex: 'End',
            align: 'center'
        },

        {
            title: 'D402',
            width: 30,
            dataIndex: 'D402',
            align: 'center',
            render(text, record) {
                let color = ''
                let name = ''

                if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
                else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
                else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
                else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
                else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
                else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
                else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
                else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }


                return {
                    props: {
                        style: {
                            background: color
                        }
                    },
                    children: <div>{name}</div>
                };
            }
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 10,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable1(record, 4) }
            } > Click </Button>,
        }
    ];

    columns_2 = [
        {
            title: 'Start',
            width: 20,
            dataIndex: 'Start',
            align: 'center'
        },
        {
            title: 'End',
            width: 20,
            dataIndex: 'End',
            align: 'center'
        },

        {
            title: 'D403',
            width: 30,
            dataIndex: 'D403',
            align: 'center',
            render(text, record) {
                let color = ''
                let name = ''

                if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
                else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
                else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
                else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
                else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
                else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
                else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
                else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }

                return {
                    props: {
                        style: {
                            background: color
                        }
                    },
                    children: <div>{name}</div>
                };
            }
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 10,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable2(record, 4) }
            } > Click </Button>,
        }
    ];

    columns1 = [
        {
            title: 'Start',
            width: 10,
            dataIndex: 'Start',
            align: 'center',
            // fixed: 'left'
        },
        {
            title: 'End',
            width: 10,
            dataIndex: 'End',
            align: 'center',

        },
        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 5,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record, 5) }
            } > Click </Button>,
        }
    ];


    columns_3 = [

        {
            title: 'Parameter',
            width: 15,
            dataIndex: 'Parameter',
            key: 'Parameter',
            align: 'center'
        },
        {
            title: 'Start',
            width: 25,
            dataIndex: 'Start',
            key: 'Start',
            align: 'center'
        },
        {
            title: 'End',
            width: 25,
            dataIndex: 'End',
            key: 'End',
            align: 'center'
        },
        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 13,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable3(record, 6) }
            } > Click </Button>,
        }
    ]


    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);

        initialValues['Start'] = moment(initialValues['Start'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['End'] = moment(initialValues['End'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['DateTime'] = [moment(initialValues['Start'], "DD-MM-YYYY HH:mm:ss"), moment(initialValues['End'], "DD-MM-YYYY HH:mm:ss")]

        console.log('form')

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                console.log(values)
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={() => { onCancel(initialValues) }}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="Start"
                        label="Start"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['Start']} disabled={true} />
                        {/* <Input disabled={false} /> */}
                    </Form.Item>

                    <Form.Item
                        name="End"
                        label="End"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['End']} disabled={DisableInput} />
                        {/* <Input disabled={false} /> */}
                    </Form.Item>

                    <Form.Item name="F2_D401_ACTUAL_STEP" label="D401" className="collection-create-form_last-form-item">
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="0">Stopped</Radio>
                            <Radio value="1">Standby</Radio>
                            <Radio value="2">Filling</Radio>
                            <Radio value="3">Heat</Radio>
                            <Radio value="4">Homogenize</Radio>
                            <Radio value="5">Cool</Radio>
                            {/* <Radio value="-1">Washing</Radio> */}
                            <Radio value="-2">Filtering</Radio>

                        </Radio.Group>
                    </Form.Item>

                    {/* <Form.Item name="F2_D402_ACTUAL_STEP" label="D402" className="collection-create-form_last-form-item">
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="0">Stopped</Radio>
                            <Radio value="1">Standby</Radio>
                            <Radio value="2">Filling</Radio>
                            <Radio value="3">Heat</Radio>
                            <Radio value="4">Homogenize</Radio>
                            <Radio value="5">Cool</Radio>
                            <Radio value="-1">Washing</Radio>
                            <Radio value="-2">Filtering</Radio>

                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="F2_D403_ACTUAL_STEP" label="D403" className="collection-create-form_last-form-item">
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="0">Stopped</Radio>
                            <Radio value="1">Standby</Radio>
                            <Radio value="2">Filling</Radio>
                            <Radio value="3">Heat</Radio>
                            <Radio value="4">Homogenize</Radio>
                            <Radio value="5">Cool</Radio>
                            <Radio value="-1">Washing</Radio>
                            <Radio value="-2">Filtering</Radio>

                        </Radio.Group>
                    </Form.Item> */}

                </Form>
            </Modal>
        );
    };

    const CollectionCreateForm_1 = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        // initialValues['StartMoment'] = moment(initialValues['Start'], 'DD-MM-YYYY HH:mm:ss')
        // initialValues['EndMoment'] = moment(initialValues['End'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['Start'] = moment(initialValues['Start'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['End'] = moment(initialValues['End'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['DateTime'] = [moment(initialValues['Start'], "DD-MM-YYYY HH:mm:ss"), moment(initialValues['End'], "DD-MM-YYYY HH:mm:ss")]

        console.log('form_1')
        // console.log(LogValue)

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={() => { onCancel(initialValues) }}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete1(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="Start"
                        label="Start"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        {/* <Input disabled={false} /> */}
                        <DatePicker showTime value={initialValues['Start']} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="End"
                        label="End"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['End']} disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item name="F2_D402_ACTUAL_STEP" label="D402" className="collection-create-form_last-form-item">
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="0">Stopped</Radio>
                            <Radio value="1">Standby</Radio>
                            <Radio value="2">Filling</Radio>
                            <Radio value="3">Heat</Radio>
                            <Radio value="4">Homogenize</Radio>
                            <Radio value="5">Cool</Radio>
                            {/* <Radio value="-1">Washing</Radio> */}
                            <Radio value="-2">Filtering</Radio>

                        </Radio.Group>
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const CollectionCreateForm_2 = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        initialValues['Start'] = moment(initialValues['Start'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['End'] = moment(initialValues['End'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['DateTime'] = [moment(initialValues['Start'], "DD-MM-YYYY HH:mm:ss"), moment(initialValues['End'], "DD-MM-YYYY HH:mm:ss")]

        console.log('form_2')

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={() => { onCancel(initialValues) }}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete2(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="Start"
                        label="Start"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['Start']} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="End"
                        label="End"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['End']} disabled={DisableInput} />
                    </Form.Item>


                    <Form.Item name="F2_D403_ACTUAL_STEP" label="D403" className="collection-create-form_last-form-item">
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="0">Stopped</Radio>
                            <Radio value="1">Standby</Radio>
                            <Radio value="2">Filling</Radio>
                            <Radio value="3">Heat</Radio>
                            <Radio value="4">Homogenize</Radio>
                            <Radio value="5">Cool</Radio>
                            {/* <Radio value="-1">Washing</Radio> */}
                            <Radio value="-2">Filtering</Radio>

                        </Radio.Group>
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const CollectionCreateForm1 = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);

        initialValues['Start'] = moment(initialValues['Start'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['End'] = moment(initialValues['End'], 'DD-MM-YYYY HH:mm:ss')
        initialValues['DateTime'] = [moment(initialValues['Start'], "DD-MM-YYYY HH:mm:ss"), moment(initialValues['End'], "DD-MM-YYYY HH:mm:ss")]

        console.log('form1')

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={() => { onCancel(initialValues) }}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete_1(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="Start"
                        label="Start"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['Start']} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="End"
                        label="End"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <DatePicker showTime value={initialValues['End']} disabled={DisableInput} />
                    </Form.Item>



                </Form>
            </Modal>
        );
    };


    const CollectionCreateForm2 = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        // initialValues['Start'] = moment().format('DD-MM-YYYY').concat(" ".concat(initialValues['Start']))
        // initialValues['End'] = moment().format('DD-MM-YYYY').concat(" ".concat(initialValues['End']))

        // console.log(initialValues['Start'])

        let daterange = {
            'Start': '',
            'End': '',
            'DateTime': '',
            'Parameter': ''
        }

        console.log(initialValues)

        let recorddate = new Date(setRange[0]);
        let recorddate2 = new Date(setRange[0]);

        let recordtimesplit = initialValues['Start'].split(':')

        recorddate.setHours(recordtimesplit[0])
        recorddate.setMinutes(recordtimesplit[1])
        recorddate.setSeconds(recordtimesplit[2])

        daterange['Start'] = recorddate

        let recordtimesplit2 = initialValues['End'].split(':')

        recorddate2.setHours(recordtimesplit2[0])
        recorddate2.setMinutes(recordtimesplit2[1])
        recorddate2.setSeconds(recordtimesplit2[2])

        daterange['End'] = recorddate2

        console.log(daterange);

        daterange['Parameter'] = initialValues['Parameter']
        daterange['Start'] = moment(daterange['Start'], 'HH:mm:ss')
        daterange['End'] = moment(daterange['End'], 'HH:mm:ss')
        daterange['DateTime'] = [moment(daterange['Start'], "HH:mm:ss"), moment(daterange['End'], "HH:mm:ss")]

        console.log(daterange)

        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancel" onClick={() => { onCancel(daterange) }}>
                        Cancel
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete_2(daterange['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={daterange}
                >
                    <Form.Item
                        name="Parameter"
                        label="Parameter"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the reason!',
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Start"
                        label="Start"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <TimePicker value={daterange['Start']} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="End"
                        label="End"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <TimePicker value={daterange['End']} disabled={DisableInput} />
                    </Form.Item>



                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', "Tirtiaux", 1)
        setVisible(false);
    }

    const onDelete1 = (values) => {
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList1, values, 'FGV', "Tirtiaux", 1)
        setVisible1(false);
    }

    const onDelete2 = (values) => {
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList2, values, 'FGV', "Tirtiaux", 1)
        setVisible2(false);
    }

    const onDelete_1 = (values) => {
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList_1, values, 'FGV', "Tirtiaux", 1)
        setVisible_1(false);
    }

    const onDelete_2 = (values) => {
        console.log(values);
        values[0] = moment(values[0], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        values[1] = moment(values[1], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList_2, values, 'FGV', "Tirtiaux", 1)
        setVisible_2(false);
    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_periodiclog(Parameter, DateTime, Site, Line, Page)
        // console.log(response);
        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1, 4)
            UpdateTableData(StepRequestList_1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT2", TirtiauxModelPage1__1, 4)
            UpdateTableData(StepRequestList_2, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT3", TirtiauxModelPage1__2, 4)
            UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1_1", TirtiauxModelPage1_1, 5)
            UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }



    const handletable = (record, section) => {
        // console.log('record');
        console.log(record);
        prevReading = record
        if (section == 4) {
            setVisible(true)
        }
        else {
            setVisible_1(true)
        }
    }

    const handletable1 = (record, section) => {
        // console.log('record');
        // console.log(record);
        prevReading = record
        if (section == 4) {
            setVisible1(true)
        }
        else {
            setVisible_1(true)
        }
    }

    const handletable2 = (record, section) => {
        // console.log('record');
        // console.log(record);
        prevReading = record
        if (section == 4) {
            setVisible2(true)
        }
        else {
            setVisible_1(true)
        }
    }

    const handletable3 = (record, section) => {
        // console.log('record');
        console.log(record);
        prevReading = record
        console.log(prevReading);
        if (section == 6) {
            setVisible_2(true)
        }
        else {
            setVisible_1(true)
        }
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model, Section, speacial = false) => {
        if (speacial == false) {
            setRange[0] = StartTime
            setRange[1] = EndTime
        }
        else {
            setRange[4] = StartTime
            setRange[5] = EndTime
        }

        console.log(setRange);
        const response = await periodiclog_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime2(response)
        console.log(response);
        console.log(Section);
        LogValue[BufferName] = LogDataMappingStep(Model, data, Section)
        console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const RerenderTable = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model, Section) => {
        UpdateTableData(StepRequestList, StartTime, EndTime, 'FGV', "Tirtiaux", 1, "LogTT1", TirtiauxModelPage1, 4)
        UpdateTableData(StepRequestList_1, StartTime, EndTime, 'FGV', "Tirtiaux", 1, "LogTT2", TirtiauxModelPage1__1, 4)
        UpdateTableData(StepRequestList_2, StartTime, EndTime, 'FGV', "Tirtiaux", 1, "LogTT3", TirtiauxModelPage1__2, 4)
        UpdateTableData(StepRequestList1, StartTime, EndTime, 'FGV', "Tirtiaux", 1, "LogTT1_1", TirtiauxModelPage1_1, 5)

        let remark_start = StartTime.split('T')[0] + "T16:00:00Z"
        let remark_end = EndTime.split('T')[0] + "T15:00:00Z"
        console.log(remark_start, remark_end);

        UpdateTableData(StepRequestList2, remark_start, remark_end, 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)
    }

    const AddNewDataRow = async (data, section) => {
        const WriteRow = []
        let date = {}, date2 = {}, date4


        if (section == 4 && Object.keys(data).length > 3) {
            date['DateTime'] = data['DateTime']
            date['F2_D401_ACTUAL_STEP'] = data['F2_D401_ACTUAL_STEP']
            WriteRow.push(date)
        }
        else if (section == 5) {
            date['DateTime'] = data['DateTime']
            date['Washing'] = '0'
            WriteRow.push(date)
        }
        else if (section == 6) {
            console.log(data);


            try {
                date2['DateTime'] = ['', '']

                let YMD = data['Reportdate'].split('-')

                let datestart = new Date(data['DateTime'][0]);
                console.log(datestart);
                datestart.setFullYear(YMD[0])
                datestart.setMonth(YMD[1] - 1)
                datestart.setDate(YMD[2])
                console.log(datestart);

                let dateend = new Date(data['DateTime'][1]);
                console.log(dateend);
                dateend.setFullYear(YMD[0])
                dateend.setMonth(YMD[1] - 1)
                dateend.setDate(YMD[2])
                console.log(dateend);

                date2['DateTime'][0] = datestart
                date2['DateTime'][1] = dateend


                date2['DateTime'][0] = moment(date2['DateTime'][0], 'YYYY-MM-DD HH:mm:ss').utc(false)
                date2['DateTime'][1] = moment(date2['DateTime'][1], 'YYYY-MM-DD HH:mm:ss').utc(false)

                date['DateTime'] = ConvertMonentToDateTime(date2['DateTime'])

                console.log(data['DateTime']);

                console.log(date2['DateTime']);
                console.log(date['DateTime']);

            } catch (error) {
                console.log(error);
                date['DateTime'] = data['DateTime']
            }

            for (let key in data) {
                if (key.toString().includes('Remark')) {
                    date[key] = '0'
                }
            }
            WriteRow.push(date)
        }
        else {
            // data['Start'] = moment(data['Start'], 'DD-MM-YYYY HH:mm:ss')
            // data['End'] = moment(data['End'], 'DD-MM-YYYY HH:mm:ss')
            // data['Start'] = ConvertMonentToDateTimeOne(data['Start'])
            // data['End'] = ConvertMonentToDateTimeOne(data['End'])
            // date['DateTime'] = [data['Start'], data['End']]
            date['DateTime'] = data['DateTime']
            date['F2_D401_ACTUAL_STEP'] = '0'
            // date['F2_D402_ACTUAL_STEP'] = '0'
            // date['F2_D403_ACTUAL_STEP'] = '0'
            WriteRow.push(date)
        }
        // }

        console.log(WriteRow);
        const response = await periodiclog_Write(WriteRow, 'FGV', 'Tirtiaux', 1)
        // console.log(response);

        if (response.success === true) {
            // UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
            message.success('Successfully Add')
            console.log("Write success")
            UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1", TirtiauxModelPage1, 4)
            UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)
            UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1_1", TirtiauxModelPage1_1, 5)

        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const AddNewDataRow_1 = async (data, section) => {
        const WriteRow = []
        let date = {}

        console.log(data)
        if (Object.keys(data).length > 3) {
            date['DateTime'] = data['DateTime']
            // date['F2_D401_ACTUAL_STEP'] = data['F2_D401_ACTUAL_STEP']
            date['F2_D402_ACTUAL_STEP'] = data['F2_D402_ACTUAL_STEP']
            // date['F2_D403_ACTUAL_STEP'] = data['F2_D403_ACTUAL_STEP']
            WriteRow.push(date)
        }

        else {
            if (section == 5) {
                date['DateTime'] = data['DateTime']
                date['Washing'] = '0'
                WriteRow.push(date)
            }
            else {
                date['DateTime'] = data['DateTime']
                // date['F2_D401_ACTUAL_STEP'] = '0'
                date['F2_D402_ACTUAL_STEP'] = '0'
                // date['F2_D403_ACTUAL_STEP'] = '0'
                WriteRow.push(date)
            }
        }

        // console.log(WriteRow);
        const response = await periodiclog_Write(WriteRow, 'FGV', 'Tirtiaux', 1)
        // console.log(response);

        if (response.success === true) {
            // UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
            message.success('Successfully Add')
            console.log("Write success")
            UpdateTableData(StepRequestList_1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT2", TirtiauxModelPage1__1, 4)
            UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1_1", TirtiauxModelPage1_1, 5)
            UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)

        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const AddNewDataRow_2 = async (data, section) => {
        const WriteRow = []
        let date = {}

        console.log(data)
        if (Object.keys(data).length > 3) {
            date['DateTime'] = data['DateTime']
            // date['F2_D401_ACTUAL_STEP'] = data['F2_D401_ACTUAL_STEP']
            // date['F2_D402_ACTUAL_STEP'] = data['F2_D402_ACTUAL_STEP']
            date['F2_D403_ACTUAL_STEP'] = data['F2_D403_ACTUAL_STEP']
            WriteRow.push(date)
        }

        else {
            if (section == 5) {
                date['DateTime'] = data['DateTime']
                date['Washing'] = '0'
                WriteRow.push(date)
            }
            else {
                date['DateTime'] = data['DateTime']
                // date['F2_D401_ACTUAL_STEP'] = '0'
                // date['F2_D402_ACTUAL_STEP'] = '0'
                date['F2_D403_ACTUAL_STEP'] = '0'
                WriteRow.push(date)
            }
        }

        // console.log(WriteRow);
        const response = await periodiclog_Write(WriteRow, 'FGV', 'Tirtiaux', 1)
        // console.log(response);

        if (response.success === true) {
            // UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
            message.success('Successfully Add')
            console.log("Write success")
            UpdateTableData(StepRequestList_2, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT3", TirtiauxModelPage1__2, 4)
            UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)
            UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', "Tirtiaux", 1, "LogTT1_1", TirtiauxModelPage1_1, 5)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        // console.log(values);
        values['Start'] = moment(values['Start'], 'DD-MM-YYYY HH:mm:ss')
        values['End'] = moment(values['End'], 'DD-MM-YYYY HH:mm:ss')
        values['Start'] = ConvertMonentToDateTimeOne(values['Start'])
        values['End'] = ConvertMonentToDateTimeOne(values['End'])

        values['DateTime'] = [values['Start'], values['End']]

        if (values['F2_D401_ACTUAL_STEP'] == undefined) {
            values['F2_D401_ACTUAL_STEP'] = LogValue['LogTT1'][0]['D401']
        }
        else {
            values['F2_D401_ACTUAL_STEP'] = values['F2_D401_ACTUAL_STEP']
        }
        console.log(LogValue['LogTT1']);
        console.log(values);
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values, 4)
        await UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1, 4)

        //UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
        // console.log(values);
        setLoading(false)
    };

    const onCreate_1 = async (values) => {
        // console.log('onCreate');
        values['Start'] = moment(values['Start'], 'DD-MM-YYYY HH:mm:ss')
        values['End'] = moment(values['End'], 'DD-MM-YYYY HH:mm:ss')
        values['Start'] = ConvertMonentToDateTimeOne(values['Start'])
        values['End'] = ConvertMonentToDateTimeOne(values['End'])

        values['DateTime'] = [values['Start'], values['End']]

        if (values['F2_D402_ACTUAL_STEP'] == undefined) {
            values['F2_D402_ACTUAL_STEP'] = LogValue['LogTT2'][0]['D402']
        }
        else {
            values['F2_D402_ACTUAL_STEP'] = values['F2_D402_ACTUAL_STEP']
        }
        setVisible1(false);
        setLoading1(true)
        await AddNewDataRow_1(values, 4)
        await UpdateTableData(StepRequestList_1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT2", TirtiauxModelPage1__1, 4)

        //UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
        // console.log(values);
        setLoading1(false)
    };


    const onCreate_2 = async (values) => {
        // console.log('onCreate');
        values['Start'] = moment(values['Start'], 'DD-MM-YYYY HH:mm:ss')
        values['End'] = moment(values['End'], 'DD-MM-YYYY HH:mm:ss')
        values['Start'] = ConvertMonentToDateTimeOne(values['Start'])
        values['End'] = ConvertMonentToDateTimeOne(values['End'])

        values['DateTime'] = [values['Start'], values['End']]

        if (values['F2_D403_ACTUAL_STEP'] == undefined) {
            values['F2_D403_ACTUAL_STEP'] = LogValue['LogTT3'][0]['D403']
        }
        else {
            values['F2_D403_ACTUAL_STEP'] = values['F2_D403_ACTUAL_STEP']
        }
        setVisible2(false);
        setLoading2(true)
        await AddNewDataRow_2(values, 4)
        await UpdateTableData(StepRequestList_2, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT3", TirtiauxModelPage1__2, 4)

        //UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
        // console.log(values);
        setLoading2(false)
    };


    const onCreate1 = async (values) => {
        //console.log(values['DateTime']);
        values['Start'] = moment(values['Start'], 'DD-MM-YYYY HH:mm:ss')
        values['End'] = moment(values['End'], 'DD-MM-YYYY HH:mm:ss')
        values['Start'] = ConvertMonentToDateTimeOne(values['Start'])
        values['End'] = ConvertMonentToDateTimeOne(values['End'])

        values['DateTime'] = [values['Start'], values['End']]
        console.log(values);
        setVisible_1(false);
        setLoading_1(true)
        await AddNewDataRow(values, 5)
        await UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1_1", TirtiauxModelPage1_1, 5)

        //UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
        // console.log(values);
        setLoading_1(false)
    };

    const onCreate2 = async (values) => {
        console.log(values)
        values['Start'] = moment(values['Start'], 'DD-MM-YYYY HH:mm:ss')
        values['End'] = moment(values['End'], 'DD-MM-YYYY HH:mm:ss')
        values['Start'] = ConvertMonentToDateTimeOne(values['Start'])
        values['End'] = ConvertMonentToDateTimeOne(values['End'])

        values['DateTime'] = [values['Start'], values['End']]

        values['Tirtiaux_'.concat(values['Parameter'].concat('_Remark'))] = '0'


        console.log(values);
        setVisible_2(false);
        setLoading_2(true)
        // console.log(setRange[4], setRange[5]);
        await AddNewDataRow(values, 6)
        await UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', 'Tirtiaux', 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)

        //UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1)
        // console.log(values);
        setLoading_2(false)
    };

    const onCancel = async (values) => {

        console.log(setRange);

        // setRange[0] = 

        UpdateTableData(StepRequestList, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1", TirtiauxModelPage1, 4)
        UpdateTableData(StepRequestList_1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT2", TirtiauxModelPage1__1, 4)
        UpdateTableData(StepRequestList_2, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT3", TirtiauxModelPage1__2, 4)
        UpdateTableData(StepRequestList1, setRange[0], setRange[1], 'FGV', 'Tirtiaux', 1, "LogTT1_1", TirtiauxModelPage1_1, 5)
        UpdateTableData(StepRequestList2, setRange[4], setRange[5], 'FGV', "Tirtiaux", 1, "LogTT1_2", TirtiauxModelPage1_2, 6, true)



        setVisible(false);
        setVisible1(false);
        setVisible2(false);
        setVisible_1(false);
        setVisible_2(false);
    }

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">D401</Divider>
                </div>
                <ReportTable SetTimeRangeCallBack={RerenderTable}
                    LogValue={LogValue['LogTT1']} Columb={columns}
                    site={'Tirtiaux'} page={'1'} BufferName={"LogTT1"} Model={TirtiauxModelPage1}
                    AddNewDataRow={AddNewDataRow}
                    section={4}
                />
            </Spin>
            {/* 
            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={onCancel}
                    initialValues={prevReading}
                />
            </div> */}

            {
                visible ?
                    <div>
                        <CollectionCreateForm
                            visible={visible}
                            onCreate={onCreate}
                            onCancel={onCancel}
                            initialValues={prevReading}
                        />
                    </div> :
                    <div></div>
            }

            <Spin tip="Uploading" spinning={Loading1}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">D402</Divider>
                </div>

                <ReportTable SetTimeRangeCallBack={RerenderTable}
                    LogValue={LogValue['LogTT2']} Columb={columns_1}
                    site={'Tirtiaux'} page={'1'} BufferName={"LogTT2"} Model={TirtiauxModelPage1__1}
                    AddNewDataRow={AddNewDataRow_1}
                    section={4}
                    HideDateTime={true}
                    ButtonType={'default'}
                    ButtonName={'New Row'}
                />
            </Spin>

            {
                visible1 ?
                    <div>
                        <CollectionCreateForm_1
                            visible={visible1}
                            onCreate={onCreate_1}
                            onCancel={onCancel}
                            initialValues={prevReading}
                        />
                    </div> :
                    <div></div>
            }
            {/* 
            <div>
                <CollectionCreateForm_1
                    visible={visible1}
                    onCreate={onCreate_1}
                    onCancel={onCancel}
                    initialValues={prevReading}
                />
            </div> */}

            <Spin tip="Uploading" spinning={Loading2}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">D403</Divider>
                </div>

                <ReportTable SetTimeRangeCallBack={RerenderTable}
                    LogValue={LogValue['LogTT3']} Columb={columns_2}
                    site={'Tirtiaux'} page={'1'} BufferName={"LogTT3"} Model={TirtiauxModelPage1__2}
                    AddNewDataRow={AddNewDataRow_2}
                    section={4}
                    HideDateTime={true}
                    ButtonType={'default'}
                    ButtonName={'New Row'}

                />
            </Spin>

            {
                visible2 ?
                    <div>
                        <CollectionCreateForm_2
                            visible={visible2}
                            onCreate={onCreate_2}
                            onCancel={onCancel}
                            initialValues={prevReading}
                        />
                    </div> :
                    <div></div>
            }

            {/* <div>
                <CollectionCreateForm_2
                    visible={visible2}
                    onCreate={onCreate_2}
                    onCancel={onCancel}
                    initialValues={prevReading}
                />
            </div> */}

            <Spin tip="Uploading" spinning={Loading_2}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">Remarks</Divider>
                </div>
                <ReportTable SetTimeRangeCallBack={RerenderTable}
                    LogValue={LogValue['LogTT1_2']} Columb={columns_3}
                    site={'Tirtiaux'} page={'1'} BufferName={"LogTT1_2"} Model={TirtiauxModelPage1_2}
                    AddNewDataRow={AddNewDataRow}
                    section={6}
                    HideDateTime={true}
                    ButtonType={'default'}
                    ButtonName={'New Row'}

                />
            </Spin>

            {
                visible_2 ?
                    <div>
                        <CollectionCreateForm2
                            visible={visible_2}
                            onCreate={onCreate2}
                            onCancel={onCancel}
                            initialValues={prevReading}
                        />
                    </div> :
                    <div></div>
            }



            <Spin tip="Uploading" spinning={Loading_1}>
                <div style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Divider orientation="left">Washing</Divider>
                </div>
                <ReportTable SetTimeRangeCallBack={RerenderTable}
                    LogValue={LogValue['LogTT1_1']} Columb={columns1}
                    site={'Tirtiaux'} page={'1'} BufferName={"LogTT1_1"} Model={TirtiauxModelPage1_1}
                    AddNewDataRow={AddNewDataRow}
                    section={5}
                    HideDateTime={true}
                    ButtonType={'default'}
                    ButtonName={'New Row'}

                />
            </Spin>

            {
                visible_1 ?
                    <div>
                        <CollectionCreateForm1
                            visible={visible_1}
                            onCreate={onCreate1}
                            onCancel={onCancel}
                            initialValues={prevReading}
                        />
                    </div> :
                    <div></div>
            }

            {/* <div>
                <CollectionCreateForm1
                    visible={visible_1}
                    onCreate={onCreate1}
                    onCancel={onCancel}
                    initialValues={prevReading}
                />
            </div> */}
        </div>
    )
}

export default TirtiauxReportPG1
