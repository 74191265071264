import { BrowserRouter, Switch, Route } from "react-router-dom"
import Login from "./pages/Login/Login";
import PageLayout from "./pages/PageLayout/PageLayout";
import RTCardOiltek from "./pages/Oiltek/RTCardOiltek";
import RTCardAlfa from "./pages/AlfaLava/RTCardAlfa";
import RTCardTirtiaux from "./pages/Tirtiaux/RTCardTirtiaux";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          {/* <Route path='/Testpage2' component={Testpage2}></Route> */}
          <Route path='/OiltekCard' component={RTCardOiltek}></Route>
          <Route path='/AlfaCard' component={RTCardAlfa}></Route>
          <Route path='/TirtiauxCard' component={RTCardTirtiaux}></Route>
          <Route path='/Login' component={Login}></Route>
          <Route path='/' component={PageLayout}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
