import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { OTKModelPage2 } from '../../../DataRequest/OiltekModel'

const OiltekDeo1 = (props) => {
  let Para = props.Parameter
  Para = DataMapping(OTKModelPage2, props.Parameter)
  
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1190.55 841.89"
          width='100%'
          height='100%'
        >
          <style>
            {
              ".prefix__st0,.prefix__st1{stroke:#6d6e71;stroke-linecap:round;stroke-linejoin:round}.prefix__st0{fill:#c4c6c8;stroke-width:.75;stroke-miterlimit:10}.prefix__st1{fill:#e6e7e8;stroke-width:1.5}.prefix__st1,.prefix__st2{stroke-miterlimit:10}.prefix__st2,.prefix__st3{fill:none;stroke:#58595b;stroke-width:4;stroke-linejoin:round}.prefix__st4{fill:#58595b}.prefix__st5,.prefix__st6,.prefix__st8{fill:#c4c6c8;stroke:#58595b;stroke-miterlimit:10}.prefix__st6,.prefix__st8{fill:none}.prefix__st8{stroke-width:2}.prefix__st10{fill:#c4c6c8}.prefix__st11{fill:#e0e0e0}.prefix__st12,.prefix__st13,.prefix__st14{fill:none;stroke:#58595b;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st13,.prefix__st14{stroke:#6d6e71;stroke-linecap:round}.prefix__st14{stroke-width:4}.prefix__st15{fill:#e55125}.prefix__st16{fill:#f3a61e}.prefix__st17{fill:#f4cf71}.prefix__st18,.prefix__st19,.prefix__st20{fill:none;stroke:#58595b;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st19,.prefix__st20{fill:#e0e0e0;stroke-width:2}.prefix__st20{fill:#c4c6c8}.prefix__st21{fill:#fff200}.prefix__st22{font-family:&apos;3ds&apos;}.prefix__st23{font-size:18px}.prefix__st24{font-size:20px}.prefix__st25{fill:none}.prefix__st26{font-size:16px}.prefix__st27{font-size:12px}.prefix__st28{fill:none;stroke:#414042;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:1,3}"
            }
          </style>
          <symbol id="prefix__Turbocharger" viewBox="-18.78 -13.41 37.55 23.81">
            <path
              className="prefix__st0"
              d="M10.08-9.69L-.3-7.75l-10.39-1.94v-3.34h20.77zM.3 3.64h13.75v5.1H.3c-1.41 0-2.55-1.14-2.55-2.55S-1.11 3.64.3 3.64z"
            />
            <circle className="prefix__st0" cx={-0.3} cy={-1.65} r={10.38} />
            <path
              className="prefix__st0"
              d="M-.3.9h-13.75v-5.1H-.3c1.41 0 2.55 1.14 2.55 2.55S1.11.9-.3.9z"
            />
            <path className="prefix__st0" d="M-18.4-5.49h4.35V2.2h-4.35z" />
            <path
              transform="rotate(-180 16.226 6.189)"
              className="prefix__st0"
              d="M14.05 2.35h4.35v7.69h-4.35z"
            />
          </symbol>
          <symbol id="prefix__Valve_Auto" viewBox="-19.5 -13.29 32.68 21.98">
            <path
              className="prefix__st1"
              d="M-18.75-12.54l5.56 10.25 5.57-10.25zM-7.62 7.95l-5.56-10.24-5.57 10.24zM2.35-12.37V7.79c5.57 0 10.08-4.51 10.08-10.08S7.91-12.37 2.35-12.37zM-13.19-2.29H2.35"
            />
          </symbol>
          <symbol id="prefix__Valve_small" viewBox="-14.25 -10.57 28.51 21.14">
            <path
              className="prefix__st1"
              d="M13.5 9.82L8.17 0 2.84 9.82zM2.84-9.82L8.17 0l5.33-9.82zM-13.5-9.67h6.78V9.66h-6.78zM8.17 0H-6.72"
            />
          </symbol>
          <g id="prefix__Piping">
            <path
              className="prefix__st2"
              d="M67.4 391.08v119.95c0 3.31 2.69 6 6 6h34.43"
            />
            <path className="prefix__st3" d="M35.32 436.02v-44.94" />
            <path
              className="prefix__st4"
              d="M41.3 434.27l-5.98 10.36-5.99-10.36z"
            />
            <path
              className="prefix__st2"
              d="M107.83 454.87H67.4M144.63 447.18H235M144.63 509.2h43.75c3.31 0 6-2.69 6-6v-55.88"
            />
            <path
              className="prefix__st2"
              d="M251.75 447.31h55.75c3.31 0 6 2.69 6 6V561c0 3.31-2.69 6-6 6H73.4c-3.31 0-6 2.69-6 6v140.26c0 3.31 2.69 6 6 6h91.46M263.26 660.24h-59.91M295.24 660.24h-23.98M296.8 795.74h-50.55c-3.31 0-6-2.69-6-6v-129.5"
            />
            <path className="prefix__st3" d="M295 602.04H153.19" />
            <path
              className="prefix__st4"
              d="M154.35 598.05l-6.91 3.99 6.91 3.99z"
            />
            <path
              className="prefix__st2"
              d="M294.01 737.54h-20.75c-3.31 0-6-2.69-6-6v-129.5M352.61 795.74h39.14c3.31 0 6-2.69 6-6v-70.48h54.58M557.3 773.79h39.16c3.31 0 6-2.69 6-6V603.33M470.15 719.26h50.35M557.3 711.78h45.56M520.5 781.48h-21.73c-3.31 0-6-2.69-6-6v-55.87M352.85 737.54h11.07c3.31 0 6-2.69 6-6v-123.5c0-3.31-2.69-6-6-6h-16.09M365.92 660.24h-16.33"
            />
            <path
              className="prefix__st2"
              d="M397.76 735.15v-68.91c0-3.31-2.69-6-6-6h-17.83M454 634.37h-84.08M470.15 634.37h103.72c3.31 0 6-2.69 6-6v-25.04M470.15 548h91.21M634.7 548h77.18M454 548h-78.08c-3.31 0-6-2.69-6-6V340.08c0-3.31-2.69-6-6-6H319.5c-3.31 0-6-2.69-6-6V99.59M224.42 228.33v-25.25c0-3.31 2.69-6 6-6h28.24c3.31 0 6-2.69 6-6V88.58c0-3.31-2.69-6-6-6h-63.98c-3.31 0-6 2.69-6 6v100"
            />
            <path className="prefix__st3" d="M226.67 51.19v31.39" />
            <path
              className="prefix__st4"
              d="M220.69 52.94l5.98-10.36 5.98 10.36z"
            />
            <path className="prefix__st3" d="M51.36 272.67v-76.13" />
            <path
              className="prefix__st4"
              d="M57.34 198.29l-5.98-10.36-5.99 10.36z"
            />
            <path
              className="prefix__st2"
              d="M317.5 197h65M288.67 88.74V191c0 3.31 2.69 6 6 6h14.83M733.01 305.34v-25.25c0-3.31 2.69-6 6-6h28.24c3.31 0 6-2.69 6-6V145.62c0-3.31-2.69-6-6-6h-63.98c-3.31 0-6 2.69-6 6V297.5M731.25 548h11.95c3.31 0 6-2.69 6-6v-81.94c0-3.31-2.69-6-6-6H583.43c-3.31 0-6-2.69-6-6v-66.82M788.25 386.64V139.71"
            />
            <path className="prefix__st3" d="M1109.72 428.67h33.06" />
            <path
              className="prefix__st4"
              d="M1141.03 434.65l10.36-5.98-10.36-5.99z"
            />
            <path
              className="prefix__st2"
              d="M1089.72 428.67h-80.85c-1.59 0-3.12.63-4.24 1.76l-23.91 23.91M792.25 366.33h39.47c3.31 0 6-2.69 6-6V137.7M632.65 212.26v148.07c0 3.31 2.69 6 6 6h145.59M963 413.61H794.13c-3.31 0-6-2.69-6-6v-8.91M402.5 197h204.16"
            />
            <g>
              <path className="prefix__st3" d="M1056.38 454.33v39.56" />
              <path
                className="prefix__st4"
                d="M1050.4 492.14l5.98 10.36 5.99-10.36z"
              />
            </g>
            <g>
              <path className="prefix__st3" d="M735.26 118.62v21.09" />
              <path
                className="prefix__st4"
                d="M729.27 120.37l5.99-10.37 5.98 10.37z"
              />
            </g>
          </g>
          <g id="prefix__Component">
            <path
              className="prefix__st5"
              d="M971.38 306.53h-10V280c0-1.33.53-2.6 1.46-3.54l7.67-7.67c.94-.94 2.21-1.46 3.54-1.46h49.54v10h-47.47l-4.74 4.74v24.46z"
            />
            <path
              className="prefix__st6"
              d="M971.38 282.06l-9.63-3.96M976.11 277.33l-3.64-9.74"
            />
            <path
              d="M582.43 254.67h-10v-38.05c0-1.33.53-2.6 1.46-3.54l7.67-7.67c.94-.94 2.21-1.46 3.54-1.46h63.24v10h-61.17l-4.74 4.74v35.98z"
              fill="#c4c6c8"
              stroke="#58595b"
              strokeWidth={2}
              strokeMiterlimit={10}
            />
            <path
              className="prefix__st8"
              d="M587.17 213.95l-4.03-9.6M582.43 218.69l-9.82-3.37"
            />
            <path
              className="prefix__st5"
              d="M907.54 339.72h-10V94.54l-4.74-4.74h-94.94l-4.74 4.74V119h-10V92.47c0-1.33.53-2.6 1.46-3.54l7.66-7.67c.94-.94 2.21-1.46 3.54-1.46h99.08c1.33 0 2.6.53 3.54 1.46l7.67 7.67c.94.94 1.46 2.21 1.46 3.54v247.25z"
            />
            <path
              className="prefix__st6"
              d="M793.13 94.54l-9.84-3.31M797.87 89.8l-3.4-9.81M892.81 89.8l3.78-9.69M897.54 94.54l9.84-3.32"
            />
            <path
              className="prefix__st5"
              d="M923.54 339.72h-10V87.92l-14.1-14.11H642.39l-4.74 4.73v84.12h-10v-86.2c0-1.33.53-2.6 1.47-3.54l7.67-7.66c.94-.94 2.21-1.46 3.53-1.46h261.19c1.33 0 2.6.53 3.54 1.47l17.03 17.04c.94.94 1.46 2.21 1.46 3.53v253.88z"
            />
            <path
              className="prefix__st6"
              d="M637.65 78.54l-9.72-3.71M642.39 73.81l-4.16-9.54M899.44 73.81l3.81-10M913.54 87.92l9.52-4.2"
            />
            <path
              className="prefix__st5"
              d="M939.54 339.72h-10V81.31l-23.5-23.5H319.4l-4.74 4.73v35.99h-10V60.46c0-1.33.53-2.6 1.47-3.54l7.67-7.66c.94-.94 2.21-1.46 3.53-1.46h590.78c1.33 0 2.6.53 3.54 1.46l26.43 26.43c.94.94 1.46 2.21 1.46 3.54v260.49z"
            />
            <path
              className="prefix__st6"
              d="M314.67 62.54l-9.56-4.13M319.4 57.81l-3.78-9.7M906.04 57.81l3.68-9.73M929.54 81.31l9.6-4.04"
            />
            <path
              className="prefix__st10"
              d="M1077.96 442.24c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38z"
            />
            <path
              className="prefix__st11"
              d="M1077.96 442.24c0 11.52-9.03 20.93-20.4 21.55 6.77-4.15 11.29-11.61 11.29-20.14h-2.61v-31.61h9.33v30.2h2.39z"
            />
            <path
              className="prefix__st12"
              d="M1077.96 442.24c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38zM1037.19 442.24h38.38"
            />
            <path className="prefix__st13" d="M143.62 649.46h65.02" />
            <path className="prefix__st10" d="M147.21 649.46h57.85v82.9h-57.85z" />
            <path className="prefix__st11" d="M190.48 649.46h14.58v82.9h-14.58z" />
            <path className="prefix__st13" d="M147.21 649.46h57.85v82.9h-57.85z" />
            <path className="prefix__st14" d="M143.62 732.36h65.02" />
            <path
              className="prefix__st4"
              d="M147.44 652.2h4.78v4.78h-4.78zM152.23 658.67h4.78v4.78h-4.78zM157.01 665.14h4.78v4.78h-4.78zM161.79 671.6h4.78v4.78h-4.78zM166.57 678.07h4.78v4.78h-4.78zM171.35 684.53h4.78v4.78h-4.78zM176.13 691h4.78v4.78h-4.78zM180.91 697.47h4.78v4.78h-4.78zM185.69 703.93h4.78v4.78h-4.78zM190.48 710.4h4.78v4.78h-4.78zM195.26 716.86h4.78v4.78h-4.78zM200.04 723.33h4.78v4.78h-4.78z"
            />
            <path className="prefix__st13" d="M143.62 691.76h65.02" />
            <g>
              <path className="prefix__st13" d="M290.54 590.44h65.03" />
              <path
                className="prefix__st10"
                d="M294.13 590.44h57.85v82.9h-57.85z"
              />
              <path className="prefix__st11" d="M337.4 590.44h14.58v82.9H337.4z" />
              <path
                className="prefix__st13"
                d="M294.13 590.44h57.85v82.9h-57.85z"
              />
              <path className="prefix__st14" d="M290.54 673.34h65.03" />
              <path
                className="prefix__st4"
                d="M294.37 593.19h4.78v4.78h-4.78zM299.15 599.65h4.78v4.78h-4.78zM303.93 606.12h4.78v4.78h-4.78zM308.71 612.58h4.78v4.78h-4.78zM313.49 619.05h4.78v4.78h-4.78zM318.27 625.52h4.78v4.78h-4.78zM323.05 631.98h4.78v4.78h-4.78zM327.84 638.45h4.78v4.78h-4.78zM332.62 644.91h4.78v4.78h-4.78zM337.4 651.38h4.78v4.78h-4.78zM342.18 657.85h4.78v4.78h-4.78zM346.96 664.31h4.78v4.78h-4.78z"
              />
              <path className="prefix__st13" d="M290.54 632.74h65.03" />
            </g>
            <g>
              <path className="prefix__st13" d="M290.54 725.94h65.03" />
              <path
                className="prefix__st10"
                d="M294.13 725.94h57.85v82.9h-57.85z"
              />
              <path className="prefix__st11" d="M337.4 725.94h14.58v82.9H337.4z" />
              <path
                className="prefix__st13"
                d="M294.13 725.94h57.85v82.9h-57.85z"
              />
              <path className="prefix__st14" d="M290.54 808.84h65.03" />
              <path
                className="prefix__st4"
                d="M294.37 728.69h4.78v4.78h-4.78zM299.15 735.15h4.78v4.78h-4.78zM303.93 741.62h4.78v4.78h-4.78zM308.71 748.08h4.78v4.78h-4.78zM313.49 754.55h4.78v4.78h-4.78zM318.27 761.02h4.78v4.78h-4.78zM323.05 767.48h4.78v4.78h-4.78zM327.84 773.95h4.78v4.78h-4.78zM332.62 780.41h4.78v4.78h-4.78zM337.4 786.88h4.78v4.78h-4.78zM342.18 793.35h4.78v4.78h-4.78zM346.96 799.81h4.78v4.78h-4.78z"
              />
              <path className="prefix__st13" d="M290.54 768.24h65.03" />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M84.8 257.39v-3.35l-28.67-8.05h-9.54l-28.68 8.05v3.35h4.57v137.54h-4.57v3.35l28.68 8.05h9.54l28.67-8.05v-3.35h-4.56V257.39z"
              />
              <path
                className="prefix__st11"
                d="M80.24 257.4v137.53h4.56v3.36l-26.96 7.56 6.99-6.2V252.67l-7-6.21 26.97 7.58v3.36z"
              />
              <path
                className="prefix__st12"
                d="M84.8 257.39v-3.35l-28.67-8.05h-9.54l-28.68 8.05v3.35h4.57v137.54h-4.57v3.35l28.68 8.05h9.54l28.67-8.05v-3.35h-4.56V257.39zM22.48 257.39h57.76M17.91 254.04H84.8M22.48 394.93h57.76M17.91 398.28H84.8"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M662.75 97.76v-3.03l-25.8-7.24h-8.59l-25.81 7.24v3.03h4.11v123.78h-4.11v3.02l25.81 7.24h8.59l25.8-7.24v-3.02h-4.11V97.76z"
              />
              <path
                className="prefix__st11"
                d="M658.65 97.76v123.78h4.1v3.02l-24.27 6.81 6.3-5.58V93.51l-6.3-5.59 24.27 6.81v3.03z"
              />
              <path
                className="prefix__st12"
                d="M662.75 97.76v-3.03l-25.8-7.24h-8.59l-25.81 7.24v3.03h4.11v123.78h-4.11v3.02l25.81 7.24h8.59l25.8-7.24v-3.02h-4.11V97.76zM606.66 97.76h51.98M602.55 94.73h60.2M606.66 221.54h51.98M602.55 224.56h60.2"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M618.72 267.66v-4.14l-35.4-9.94h-11.78l-35.4 9.94v4.14h5.64v119.68h-5.64v4.15l35.4 9.94h11.78l35.4-9.94v-4.15h-5.64V267.66z"
              />
              <path
                className="prefix__st11"
                d="M613.09 267.67v119.67h5.63v4.15l-33.29 9.34 8.63-7.66V261.84l-8.64-7.67 33.3 9.35v4.15z"
              />
              <path
                className="prefix__st12"
                d="M618.72 267.66v-4.14l-35.4-9.94h-11.78l-35.4 9.94v4.14h5.64v119.68h-5.64v4.15l35.4 9.94h11.78l35.4-9.94v-4.15h-5.64V267.66zM541.78 267.66h71.3M536.14 263.52h82.58M541.78 387.34h71.3M536.14 391.49h82.58"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M967.1 464.88c8.61-.4 15.23-7.82 15.23-16.43V347.39c0-18.12-14.69-32.8-32.8-32.8h-55.7c-8.62 0-16.03 6.62-16.43 15.23-.43 9.19 6.89 16.77 15.98 16.77h46.99c5.51 0 9.97 4.46 9.97 9.97v92.34c-.02 9.09 7.57 16.41 16.76 15.98z"
              />
              <path
                className="prefix__st11"
                d="M877.38 329.81c-.43 9.19 6.89 16.78 15.98 16.78h7.46a15.932 15.932 0 01-3.5-10.78c.41-8.6 7.82-15.22 16.44-15.22h54.68a32.66 32.66 0 00-18.92-6h-55.69c-8.63 0-16.04 6.62-16.45 15.22z"
              />
              <path
                className="prefix__st12"
                d="M967.1 464.88c8.61-.4 15.23-7.82 15.23-16.43V347.39c0-18.12-14.69-32.8-32.8-32.8h-55.7c-8.62 0-16.03 6.62-16.43 15.23-.43 9.19 6.89 16.77 15.98 16.77h46.99c5.51 0 9.97 4.46 9.97 9.97v92.34c-.02 9.09 7.57 16.41 16.76 15.98z"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M1015.92 448.71v-4.97l-42.48-11.93h-14.13l-42.48 11.93v4.97h6.76v143.62h-6.76v4.97l42.48 11.93h14.13l42.48-11.93v-4.97h-6.76V448.71z"
              />
              <path
                className="prefix__st11"
                d="M1009.16 448.71v143.62h6.76v4.98l-39.95 11.21 10.36-9.2v-157.6l-10.37-9.2 39.96 11.22v4.97z"
              />
              <path
                className="prefix__st12"
                d="M1015.92 448.71v-4.97l-42.48-11.93h-14.13l-42.48 11.93v4.97h6.76v143.62h-6.76v4.97l42.48 11.93h14.13l42.48-11.93v-4.97h-6.76V448.71zM923.59 448.71h85.57M916.83 443.74h99.09M923.59 592.33h85.57M916.83 597.3h99.09"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_16_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 126.364 453.371)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_15_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 126.364 515.384)"
                overflow="visible"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_10_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 539.024 717.968)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_9_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 539.024 779.98)"
                overflow="visible"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_Auto"
              width={32.68}
              height={21.98}
              id="prefix__XMLID_4_"
              x={-19.5}
              y={-13.29}
              transform="matrix(0 -1 -1 0 242.063 434.122)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_8_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 461.39 711.686)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_7_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 461.39 539.345)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_2_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 721.628 540.095)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_6_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 461.39 625.516)"
              overflow="visible"
            />
            <g>
              <path
                className="prefix__st10"
                d="M652.33 535.49v-7.33h-3.07v-6.91l-12-12h-68.8l-12 12v6.91h-3.87v7.33h3.87v52.53h-3.87v7.33h3.87v4l-3.06 11.66h98.93l-3.07-11.66v-4h3.07v-7.33h-3.07v-52.53z"
              />
              <path
                className="prefix__st11"
                d="M649.26 535.5v52.52h3.06v7.33h-3.06v4l3.06 11.66h-24.27l-3.06-11.66v-78.1l-12-12h24.27l12 12v6.91h3.06v7.34z"
              />
              <path
                className="prefix__st13"
                d="M652.33 535.49v-7.33h-3.07v-6.91l-12-12h-68.8l-12 12v6.91h-3.87v7.33h3.87v52.53h-3.87v7.33h3.87v4l-3.06 11.66h98.93l-3.07-11.66v-4h3.07v-7.33h-3.07v-52.53zM556.46 528.16h92.8M556.46 535.49h92.8M556.46 588.02h92.8M556.46 595.35h92.8"
              />
            </g>
            <g>
              <path className="prefix__st12" d="M224.42 228.33h-21.39" />
              <path
                className="prefix__st10"
                d="M213.43 179.53v-3h-5.89v-3h-6.27v-3h-2.85v-15.07l-1.65-1.83h-16.25l-1.58 1.83v15.07h-2.85v3h-6.27v3h-5.89v3h3.12v59.9h-3.12v8.8h49.5v-8.8h-3.12v-59.9z"
              />
              <path
                className="prefix__st11"
                d="M210.31 179.53v59.91h3.12v8.79h-11.64v-8.79h-3.12v-59.91h3.12v-3h-5.89v-3h-6.27v-3h-2.85v-15.07l-1.65-1.82h11.64l1.65 1.82v15.07h2.86v3h6.26v3h5.89v3z"
              />
              <path
                className="prefix__st15"
                d="M179.6 190.07c.02.03.06.04.09.03.01 0 .01-.01.02-.01.04.01.09-.03.08-.08-.14-.68.19-1.33.66-1.8.28-.27.63-.4 1-.45-.5 1.13-.54 2.52-.49 3.7.03.76.16 1.48.46 2.18.32.74.75 1.43 1.23 2.07.04.05.12.04.12-.03-.02-.85.03-1.64.43-2.42.17-.32.38-.75.75-.87-.21.44-.29.97-.36 1.43-.09.6-.1 1.22-.1 1.83 0 1.33.11 2.63.46 3.91.32 1.17.83 2.3 1.54 3.3.31.44.65.87.99 1.29.32.41.67.78 1.14 1.03.07.04.14-.07.06-.11 0 0-.01 0-.01-.01v-.04c-.33-.67-.44-1.41-.53-2.14-.09-.73-.13-1.46-.08-2.2.05-.73.24-1.46.5-2.15.13-.34.29-.66.49-.97.12-.19.25-.36.45-.48.07-.04.13-.07.19-.11.02.7.17 1.39.48 2.02.14.29.34.56.58.78.25.23.6.34.91.46.06.03.09-.05.06-.1 0-.01 0-.02-.01-.03-.45-.83-.03-1.8.32-2.58.36-.79.74-1.58 1.12-2.37.35-.72.71-1.43.85-2.22.12-.63.02-1.3-.23-1.89.15.03.36.24.44.31.24.21.42.5.57.78.16.31.24.67.31 1 .02.08.03.15.04.23v.2c-.01.18.02.47-.06.64-.03.07.08.14.11.06.01 0 .02-.01.02-.02s.01-.01.01-.02c.39-.38.65-.96.8-1.47.2-.67.26-1.39.26-2.09 0-1.32-.33-2.54-.95-3.69.55.28.92.86 1.17 1.39.31.67.48 1.43.44 2.16 0 .06.06.07.09.05l.01.01c.03.01.07 0 .08-.04.02-.05 1.79-4.56-.38-7.76-1.44-2.13-4.26-3.21-8.38-3.21-4.13 0-6.86 1.04-8.1 3.08-1.84 3.1.33 7.38.35 7.42z"
              />
              <path
                className="prefix__st16"
                d="M181.19 184.81c.02.04.07.04.1.01.7-.72 1.73-1.62 2.81-1.36-.13.37-.32.71-.43 1.09-.13.42-.16.86-.25 1.28a16.7 16.7 0 00-.31 2.63c0 .06.09.09.12.03.31-.66 1.24-1.52 2.04-1.43-.27.56-.25 1.21-.23 1.81.03.77.04 1.51.21 2.26.2.84.59 1.61.9 2.42.33.87.34 1.73.31 2.65 0 .06.09.09.12.03a9.66 9.66 0 011.78-2.34c.75-.71 1.48-1.43 2.04-2.31.55-.87.87-1.78.98-2.8.06-.53.09-1.06.22-1.57.11-.44.91.5 1.01.67.03.04.08.04.11 0 .51-.81.55-1.92.37-2.83-.1-.51-.32-.98-.44-1.49-.06-.25.23-.14.38-.11.18.04.34.1.5.19.24.14.44.31.71.41.04.01.09-.02.08-.06-.15-1.49-1.24-2.72-2.44-3.52-.59-.4-1.25-.62-1.95-.73h-.09c-1.07-.04-2.12-.16-3.19-.09-.34.02-.77.11-1.17.12-.26.06-.52.12-.78.16-.89.16-1.78.3-2.45.96-.52.51-.8 1.23-1 1.91-.16.59-.39 1.44-.06 2.01z"
              />
              <path
                className="prefix__st17"
                d="M183.17 181.73c.5-.21 1.02-.32 1.56-.38.25-.03.51-.03.76-.04.02 0 .25-.01.25.02 0 .14-.06.28-.1.41-.33.98-.45 2.03-.33 3.06.01.05.07.09.11.05.23-.21.48-.36.76-.48.05.48.06.95.17 1.43.14.58.35 1.14.57 1.7.41 1.05.78 2.06.85 3.2 0 .05.07.09.11.05.42-.5.71-1.09 1.15-1.57.38-.42.63-.92.81-1.45.22-.64.33-1.33.41-2 .08-.6.18-1.22.06-1.81.28.29.53.6.87.82.04.03.1-.01.1-.06.03-1.13-.21-2.22-.89-3.14.57.08 1.17.1 1.69.34.01.02.03.04.04.05.05.07.16 0 .11-.06-.01-.01-.02-.02-.02-.03 0-.02-.01-.04-.03-.06-.01 0-.01 0-.02-.01-1.09-1.48-2.99-2.08-4.75-2.22-1.58-.13-3.59.56-4.3 2.09-.03.04.01.12.06.09z"
              />
              <path
                className="prefix__st12"
                d="M213.43 179.53v-3h-5.89v-3h-6.27v-3h-2.85v-15.07l-1.65-1.83h-16.25l-1.58 1.83v15.07h-2.85v3h-6.27v3h-5.89v3h3.12v59.9h-3.12v8.8h49.5v-8.8h-3.12v-59.9zM167.05 239.43h43.26M167.05 179.53h43.26M169.82 176.53h37.72M176.09 173.53h25.18M178.94 170.53h19.48"
              />
              <path className="prefix__st18" d="M178.94 155.46h19.48" />
              <circle className="prefix__st19" cx={224.42} cy={228.33} r={5.58} />
            </g>
            <g>
              <path className="prefix__st20" d="M260.53 94.93h16.17v9.31h-16.17z" />
              <path
                className="prefix__st10"
                d="M369.44 70.74v-3.26h-3v3.26h-94.5v36h94.5V110h3v-3.26s9.33-4 9.33-18-9.33-18-9.33-18z"
              />
              <path
                className="prefix__st11"
                d="M378.77 88.74c0 14-9.33 18-9.33 18V110h-3v-3.26h-16.27c1.94-3.91 3.62-9.7 3.62-18s-1.68-14.09-3.62-18h16.27v-3.26h3v3.26s9.33 4 9.33 18z"
              />
              <path
                className="prefix__st12"
                d="M369.44 70.74v-3.26h-3v3.26h-94.5v36h94.5V110h3v-3.26s9.33-4 9.33-18-9.33-18-9.33-18zM366.44 70.74v36M369.44 70.74v36M268.61 94.93v9.31"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_3_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 392.78 188.672)"
              overflow="visible"
            />
            <g>
              <path className="prefix__st12" d="M733.01 306.25h-21.4" />
              <path
                className="prefix__st10"
                d="M722.02 257.46v-3h-5.89v-3h-6.27v-3h-2.85v-15.07l-1.66-1.83h-16.24l-1.59 1.83v15.07h-2.84v3h-6.27v3h-5.89v3h3.11v59.9h-3.11v8.8h49.5v-8.8h-3.12v-59.9z"
              />
              <path
                className="prefix__st11"
                d="M718.9 257.46v59.9h3.12v8.8h-11.64v-8.8h-3.12v-59.9h3.12v-3h-5.89v-3h-6.28v-3h-2.84v-15.07l-1.66-1.83h11.64l1.66 1.83v15.07h2.85v3h6.27v3h5.89v3z"
              />
              <path
                className="prefix__st15"
                d="M689.2 267.91c.02.03.06.04.09.03.01 0 .01-.01.02-.01.04.01.09-.03.08-.08-.14-.68.19-1.33.66-1.8.28-.27.63-.4 1-.45-.5 1.13-.54 2.52-.49 3.7.03.76.16 1.48.46 2.18.32.74.75 1.43 1.23 2.07.04.05.12.04.12-.03-.02-.85.03-1.64.43-2.42.17-.32.38-.75.75-.87-.21.44-.29.97-.36 1.43-.09.6-.1 1.22-.1 1.83 0 1.33.11 2.63.46 3.91.32 1.17.83 2.3 1.54 3.3.31.44.65.87.99 1.29.32.41.67.78 1.14 1.03.07.04.14-.07.06-.11 0 0-.01 0-.01-.01v-.04c-.33-.67-.44-1.41-.53-2.14-.09-.73-.13-1.46-.08-2.2.05-.73.24-1.46.5-2.15.13-.34.29-.66.49-.97.12-.19.25-.36.45-.48.07-.04.13-.07.19-.11.02.7.17 1.39.48 2.02.14.29.34.56.58.78.25.23.6.34.91.46.06.03.09-.05.06-.1 0-.01 0-.02-.01-.03-.45-.83-.03-1.8.32-2.58.36-.79.74-1.58 1.12-2.37.35-.72.71-1.43.85-2.22.12-.63.02-1.3-.23-1.89.15.03.36.24.44.31.24.21.42.5.57.78.16.31.24.67.31 1 .02.08.03.15.04.23v.2c-.01.18.02.47-.06.64-.03.07.08.14.11.06.01 0 .02-.01.02-.02s.01-.01.01-.02c.39-.38.65-.96.8-1.47.2-.67.26-1.39.26-2.09 0-1.32-.33-2.54-.95-3.69.55.28.92.86 1.17 1.39.31.67.48 1.43.44 2.16 0 .06.06.07.09.05l.01.01c.03.01.07 0 .08-.04.02-.05 1.79-4.56-.38-7.76-1.44-2.13-4.26-3.21-8.38-3.21-4.13 0-6.86 1.04-8.1 3.08-1.84 3.09.33 7.37.35 7.42z"
              />
              <path
                className="prefix__st16"
                d="M690.79 262.64c.02.04.07.04.1.01.7-.72 1.73-1.62 2.81-1.36-.13.37-.32.71-.43 1.09-.13.42-.16.86-.25 1.28a16.7 16.7 0 00-.31 2.63c0 .06.09.09.12.03.31-.66 1.24-1.52 2.04-1.43-.27.56-.25 1.21-.23 1.81.03.77.04 1.51.21 2.26.2.84.59 1.61.9 2.42.33.87.34 1.73.31 2.65 0 .06.09.09.12.03a9.66 9.66 0 011.78-2.34c.75-.71 1.48-1.43 2.04-2.31.55-.87.87-1.78.98-2.8.06-.53.09-1.06.22-1.57.11-.44.91.5 1.01.67.03.04.08.04.11 0 .51-.81.55-1.92.37-2.83-.1-.51-.32-.98-.44-1.49-.06-.25.23-.14.38-.11.18.04.34.1.5.19.24.14.44.31.71.41.04.01.09-.02.08-.06-.15-1.49-1.24-2.72-2.44-3.52-.59-.4-1.25-.62-1.95-.73h-.09c-1.07-.04-2.12-.16-3.19-.09-.34.02-.77.11-1.17.12-.26.06-.52.12-.78.16-.89.16-1.78.3-2.45.96-.52.51-.8 1.23-1 1.91-.17.59-.4 1.44-.06 2.01z"
              />
              <path
                className="prefix__st17"
                d="M692.77 259.56c.5-.21 1.02-.32 1.56-.38.25-.03.51-.03.76-.04.02 0 .25-.01.25.02 0 .14-.06.28-.1.41-.33.98-.45 2.03-.33 3.06.01.05.07.09.11.05.23-.21.48-.36.76-.48.05.48.06.95.17 1.43.14.58.35 1.14.57 1.7.41 1.05.78 2.06.85 3.2 0 .05.07.09.11.05.42-.5.71-1.09 1.15-1.57.38-.42.63-.92.81-1.45.22-.64.33-1.33.41-2 .08-.6.18-1.22.06-1.81.28.29.53.6.87.82.04.03.1-.01.1-.06.03-1.13-.21-2.22-.89-3.14.57.08 1.17.1 1.69.34.01.02.03.04.04.05.05.07.16 0 .11-.06-.01-.01-.02-.02-.02-.03 0-.02-.01-.04-.03-.06-.01 0-.01 0-.02-.01-1.09-1.48-2.99-2.08-4.75-2.22-1.58-.13-3.59.56-4.3 2.09-.03.04 0 .12.06.09z"
              />
              <path
                className="prefix__st12"
                d="M722.02 257.46v-3h-5.89v-3h-6.27v-3h-2.85v-15.07l-1.66-1.83h-16.24l-1.59 1.83v15.07h-2.84v3h-6.27v3h-5.89v3h3.11v59.9h-3.11v8.8h49.5v-8.8h-3.12v-59.9zM675.63 317.36h43.27M675.63 257.46h43.27M678.41 254.46h37.72M684.68 251.46h25.18M687.52 248.46h19.49"
              />
              <path className="prefix__st18" d="M687.52 233.39h19.49" />
              <circle className="prefix__st19" cx={733.01} cy={306.25} r={5.58} />
            </g>
            <g>
              <path
                className="prefix__st20"
                d="M769.11 134.05h16.17v9.31h-16.17z"
              />
              <path
                className="prefix__st10"
                d="M878.03 109.86v-3.26h-3v3.26h-94.5v36h94.5v3.26h3v-3.26s9.33-4 9.33-18-9.33-18-9.33-18z"
              />
              <path
                className="prefix__st11"
                d="M887.36 127.86c0 14-9.33 18-9.33 18v3.26h-3v-3.26h-16.27c1.94-3.91 3.62-9.7 3.62-18s-1.68-14.09-3.62-18h16.27v-3.26h3v3.26s9.33 4 9.33 18z"
              />
              <path
                className="prefix__st12"
                d="M878.03 109.86v-3.26h-3v3.26h-94.5v36h94.5v3.26h3v-3.26s9.33-4 9.33-18-9.33-18-9.33-18zM875.03 109.86v36M878.03 109.86v36M777.19 134.05v9.31"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_5_"
              x={-14.25}
              y={-10.57}
              transform="matrix(1 0 0 -1 779.96 390.307)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_11_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 1099.976 420.695)"
              overflow="visible"
            />
            <g>
              <path
                className="prefix__st10"
                d="M984.38 399.52v-91.27h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v91.27h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26z"
              />
              <path
                className="prefix__st11"
                d="M984.38 308.25v91.28h3.26v3h-3.26s-2.8 6.53-11.91 8.66c3.17-2.61 4.31-5.28 4.31-5.28V301.87s-1.14-2.67-4.31-5.28c9.11 2.13 11.91 8.66 11.91 8.66h3.26v3h-3.26z"
              />
              <path
                className="prefix__st12"
                d="M984.38 399.52v-91.27h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v91.27h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26zM948.38 305.25h36M948.38 308.25h36M948.38 399.52h36M948.38 402.52h36"
              />
            </g>
          </g>
          <g id="prefix__Label">
            <path
              className="prefix__st21"
              d="M725.84 734.35H613.33c-2.07 0-3.75-1.68-3.75-3.75v-14.5c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51a3.75 3.75 0 01-3.75 3.74z"
            />
            <text
              transform="translate(612.517 705.35)"
              className="prefix__st22 prefix__st23"
            >
              {"VE702 RPO"}
            </text>
            {/* <path
              className="prefix__st21"
              d="M318.82 417.86H190.09c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c.01 2.21-1.78 4-3.99 4z"
            />
            <text
              transform="translate(191.592 413.428)"
              className="prefix__st22 prefix__st24"
            >
              {"SV:"}
            </text>
            <path className="prefix__st25" d="M232.72 399.03h94.6v15.75h-94.6z" />
            <text
              transform="translate(232.717 413.229)"
              className="prefix__st22 prefix__st24"
            >
              {"BPOsv t/h"}
            </text> */}
            <path
              className="prefix__st21"
              d="M318.82 391.86H190.09c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c.01 2.21-1.78 4-3.99 4z"
            />
            <text
              transform="translate(191.592 387.428)"
              className="prefix__st22 prefix__st24"
            >
              {"PV:"}
            </text>
            <path className="prefix__st25" d="M232.72 373.03h94.6v15.75h-94.6z" />
            <text
              transform="translate(232.717 387.229)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['BPO Flow Rate'].PV} t/h`}
            </text>
            <text
              transform="translate(186.405 363.859)"
              className="prefix__st22 prefix__st23"
            >
              {"BPO FLOW RATE"}
            </text>
            <path
              className="prefix__st21"
              d="M1162.93 403.49h-128.72c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
            />
            <text
              transform="translate(1035.708 399.058)"
              className="prefix__st22 prefix__st24"
            >
              {"PV:"}
            </text>
            {/* <path
              className="prefix__st21"
              d="M1162.93 377.49h-128.72c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
            />
            <text
              transform="translate(1035.708 373.058)"
              className="prefix__st22 prefix__st24"
            >
              {"PV:"}
            </text>
            <path
              className="prefix__st25"
              d="M1069.67 358.66h97.27v15.75h-97.27z"
            />
            <text transform="translate(1069.667 372.858)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st24">
                {"vacuum"}
              </tspan>
              <tspan x={45.18} y={0} className="prefix__st22 prefix__st26">
                {" mbar"}
              </tspan>
            </text> */}
            <path
              className="prefix__st25"
              d="M1069.67 383.77h97.27v15.75h-97.27z"
            />
            <text transform="translate(1069.667 397.974)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st24">
                {`${Para['Vacuum DEO TORR'].PV}`}
              </tspan>
              <tspan x={45.18} y={0} className="prefix__st22 prefix__st26">
                {" torr"}
              </tspan>
            </text>
            <text
              transform="translate(1041.904 368.488)"
              className="prefix__st22 prefix__st23"
            >
              {"VACUUM DEO"}
            </text>
            <path
              className="prefix__st21"
              d="M516.63 519.34h-112.5c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51a3.76 3.76 0 01-3.76 3.75z"
            />
            <path className="prefix__st25" d="M422.49 500.51h94.6v15.75h-94.6z" />
            <text
              transform="translate(422.493 514.714)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['VE702 BPO Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(411.157 490.344)"
              className="prefix__st22 prefix__st23"
            >
              {"VE 702 BPO"}
            </text>
            <path
              className="prefix__st21"
              d="M448.13 171.91h-112.5c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51a3.766 3.766 0 01-3.76 3.75z"
            />
            <path className="prefix__st25" d="M353.99 153.08h94.6v15.75h-94.6z" />
            <text
              transform="translate(353.993 167.28)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['VE703 BPO Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(342.657 143.91)"
              className="prefix__st22 prefix__st23"
            >
              {"VE 703 BPO"}
            </text>
            <path
              className="prefix__st21"
              d="M727.94 669.34H615.43c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51a3.758 3.758 0 01-3.75 3.75z"
            />
            <path className="prefix__st25" d="M633.8 650.51h94.6v15.75h-94.6z" />
            <text
              transform="translate(633.798 664.714)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['PHE BPO OUT E702 A/B Temperature'].PV} \xB0C`}
            </text>
            <path className="prefix__st25" d="M633.8 715.58h94.6v15.75h-94.6z" />
            <text
              transform="translate(633.798 729.783)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['VE702 RPO Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(611.682 640.344)"
              className="prefix__st22 prefix__st23"
            >
              {"PHE BPO OUT E702"}
            </text>
            <path
              className="prefix__st21"
              d="M869.27 562.18H756.76c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51c0 2.07-1.68 3.75-3.75 3.75z"
            />
            <path className="prefix__st25" d="M775.13 543.35h94.6v15.75h-94.6z" />
            <text
              transform="translate(775.131 557.547)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['DEOD D702 Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(766.39 534.177)"
              className="prefix__st22 prefix__st23"
            >
              {"DEOD D702"}
            </text>
            <path
              className="prefix__st21"
              d="M758.51 413.83H646c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51a3.751 3.751 0 01-3.75 3.75z"
            />
            <path className="prefix__st25" d="M664.37 395h94.6v15.75h-94.6z" />
            <text
              transform="translate(664.37 409.204)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['VE703A Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(670.726 385.834)"
              className="prefix__st22 prefix__st23"
            >
              {"VE703A"}
            </text>
            <path
              className="prefix__st21"
              d="M201.02 311.13H88.51c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51c0 2.07-1.68 3.75-3.75 3.75z"
            />
            <path className="prefix__st25" d="M106.47 292.3h90.6v15.75h-90.6z" />
            <text
              transform="translate(106.466 306.5)"
              className="prefix__st22 prefix__st24"
            >
              {`${Para['BPO TANK D701 LEVEL'].PV} \xB0C`}
            </text>
            <text
              transform="translate(101.395 283.129)"
              className="prefix__st22 prefix__st23"
            >
              {"BPO TANK"}
            </text>
            <text
              transform="translate(36.382 289.129)"
              className="prefix__st22 prefix__st27"
            >
              {"D701"}
            </text>
            <text transform="translate(38.5 317.379)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"BPO"}
              </tspan>
              <tspan x={0} y={10.4} className="prefix__st22 prefix__st27">
                {"Tank"}
              </tspan>
            </text>
            <text
              transform="translate(169.821 214.2)"
              className="prefix__st22 prefix__st27"
            >
              {"HT500"}
            </text>
            <text transform="translate(181.515 225.513)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"HP"}
              </tspan>
              <tspan x={-11.58} y={10.4} className="prefix__st22 prefix__st27">
                {"Boiler 1"}
              </tspan>
            </text>
            <text
              transform="translate(678.126 289.129)"
              className="prefix__st22 prefix__st27"
            >
              {"HT500"}
            </text>
            <text transform="translate(689.82 300.441)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"HP"}
              </tspan>
              <tspan x={-11.58} y={10.4} className="prefix__st22 prefix__st27">
                {"Boiler 2"}
              </tspan>
            </text>
            <text
              transform="translate(296.802 88.205)"
              className="prefix__st22 prefix__st27"
            >
              {"VE703"}
            </text>
            <text
              transform="translate(111.7 480.7)"
              className="prefix__st22 prefix__st27"
            >
              {"P701"}
            </text>
            <text
              transform="translate(223.057 463.763)"
              className="prefix__st22 prefix__st27"
            >
              {"FCV701"}
            </text>
            <text
              transform="translate(107.638 540.95)"
              className="prefix__st22 prefix__st27"
            >
              {"P701A"}
            </text>
            <text transform="translate(114.186 604.432)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"To"}
              </tspan>
              <tspan x={-14.89} y={10.4} className="prefix__st22 prefix__st27">
                {"E001A/B"}
              </tspan>
            </text>
            <text transform="translate(28.9 173.677)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"To Vapor"}
              </tspan>
              <tspan x={2.39} y={10.4} className="prefix__st22 prefix__st27">
                {"Ducting"}
              </tspan>
            </text>
            <text
              transform="translate(215.001 39.236)"
              className="prefix__st22 prefix__st27"
            >
              {"Vent"}
            </text>
            <text
              transform="translate(18.338 455.019)"
              className="prefix__st22 prefix__st27"
            >
              {"Cond."}
            </text>
            <text
              transform="translate(721.34 104.242)"
              className="prefix__st22 prefix__st27"
            >
              {"Vent"}
            </text>
            <text
              transform="translate(161.846 746.398)"
              className="prefix__st22 prefix__st27"
            >
              {"E701"}
            </text>
            <text
              transform="translate(304.983 686.925)"
              className="prefix__st22 prefix__st27"
            >
              {"E702B"}
            </text>
            <text
              transform="translate(439.568 565.925)"
              className="prefix__st22 prefix__st27"
            >
              {"TE702D"}
            </text>
            <text
              transform="translate(440.192 649.695)"
              className="prefix__st22 prefix__st27"
            >
              {"TE702C"}
            </text>
            <text
              transform="translate(699.83 565.924)"
              className="prefix__st22 prefix__st27"
            >
              {"TE702A"}
            </text>
            <text
              transform="translate(1081.863 448.71)"
              className="prefix__st22 prefix__st27"
            >
              {"PT702"}
            </text>
            <text
              transform="translate(1041.904 511.878)"
              className="prefix__st22 prefix__st27"
            >
              {"Drain"}
            </text>
            <text
              transform="translate(935.965 505.128)"
              className="prefix__st22 prefix__st27"
            >
              {"Deodorizer"}
            </text>
            <text
              transform="translate(1037.543 431.144)"
              className="prefix__st22 prefix__st27"
            >
              {"GP702"}
            </text>
            <text
              transform="translate(945.875 426.644)"
              className="prefix__st22 prefix__st27"
            >
              {"STA702"}
            </text>
            <text
              transform="translate(950.262 494.188)"
              className="prefix__st22 prefix__st27"
            >
              {"D702"}
            </text>
            <text
              transform="translate(548.345 317.305)"
              className="prefix__st22 prefix__st27"
            >
              {"Deodorizer"}
            </text>
            <text
              transform="translate(558.58 306.366)"
              className="prefix__st22 prefix__st27"
            >
              {"D702A"}
            </text>
            <text transform="translate(579.917 565.9)">
              <tspan x={0} y={0} className="prefix__st22 prefix__st27">
                {"Vacuum"}
              </tspan>
              <tspan x={-8.77} y={10.4} className="prefix__st22 prefix__st27">
                {"Economiser"}
              </tspan>
            </text>
            <text
              transform="translate(582.557 554.962)"
              className="prefix__st22 prefix__st27"
            >
              {"VE702"}
            </text>
            <text
              transform="translate(619.794 156.305)"
              className="prefix__st22 prefix__st27"
            >
              {"Pack"}
            </text>
            <text
              transform="translate(610.631 145.366)"
              className="prefix__st22 prefix__st27"
            >
              {"PCK703"}
            </text>
            <text
              transform="translate(943.066 382.673)"
              className="prefix__st22 prefix__st27"
            >
              {"Scrubber"}
            </text>
            <text
              transform="translate(945.16 371.734)"
              className="prefix__st22 prefix__st27"
            >
              {"SCA702"}
            </text>
            <text
              transform="translate(439.868 737.542)"
              className="prefix__st22 prefix__st27"
            >
              {"TE702B"}
            </text>
            <text
              transform="translate(520.298 744.008)"
              className="prefix__st22 prefix__st27"
            >
              {"P704A"}
            </text>
            <text
              transform="translate(520.574 804.593)"
              className="prefix__st22 prefix__st27"
            >
              {"P704B"}
            </text>
            <text
              transform="translate(304.707 824.425)"
              className="prefix__st22 prefix__st27"
            >
              {"E702A"}
            </text>
            <text
              transform="translate(293.592 99.518)"
              className="prefix__st22 prefix__st27"
            >
              {"Heater 1"}
            </text>
            <path
              className="prefix__st28"
              d="M609.59 649.7h-98.26v-34.73h-37.27M609.59 700.33H473.33"
            />
            <text
              transform="translate(807.772 126.55)"
              className="prefix__st22 prefix__st27"
            >
              {"VE703A"}
            </text>
            <text
              transform="translate(808.625 137.862)"
              className="prefix__st22 prefix__st27"
            >
              {"Heater 1"}
            </text>
            <path className="prefix__st28" d="M734.33 530.17h31" />
          </g>
        </svg>
      )
}

export default OiltekDeo1
