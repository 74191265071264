import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { OTKModelPage2 } from '../../../DataRequest/OiltekModel'

const OiltekDeo2 = (props) => {

  let Para = props.Parameter
  Para = DataMapping(OTKModelPage2, props.Parameter)
  
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1190.6 841.9"
          width='100%'
          height='100%'
        >
          <style>
            {
              ".prefix__st0,.prefix__st1{stroke:#6d6e71;stroke-linecap:round;stroke-linejoin:round}.prefix__st0{fill:#c4c6c8;stroke-width:.75;stroke-miterlimit:10}.prefix__st1{fill:#e6e7e8;stroke-width:1.5}.prefix__st1,.prefix__st2{stroke-miterlimit:10}.prefix__st2,.prefix__st3{fill:none;stroke:#58595b;stroke-width:4;stroke-linejoin:round}.prefix__st4{fill:#58595b}.prefix__st5{fill:none;stroke:#6d6e71;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st6{fill:#c4c6c8}.prefix__st7{fill:#e0e0e0}.prefix__st8,.prefix__st9{stroke-linejoin:round;stroke-miterlimit:10}.prefix__st8{fill:none;stroke:#6d6e71;stroke-width:4;stroke-linecap:round}.prefix__st9{stroke:#58595b;stroke-width:2}.prefix__st11,.prefix__st9{fill:none}.prefix__st12{font-family:&apos;3ds&apos;}.prefix__st15{font-size:12px}"
            }
          </style>
          <symbol id="prefix__Turbocharger" viewBox="-18.8 -13.4 37.6 23.8">
            <path
              className="prefix__st0"
              d="M10.1-9.7L-.3-7.8l-10.4-1.9V-13h20.8zM.3 3.6h13.8v5.1H.3c-1.4 0-2.5-1.1-2.5-2.5S-1.1 3.6.3 3.6z"
            />
            <circle className="prefix__st0" cx={-0.3} cy={-1.6} r={10.4} />
            <path
              className="prefix__st0"
              d="M-.3.9h-13.8v-5.1H-.3c1.4 0 2.5 1.1 2.5 2.5C2.2-.2 1.1.9-.3.9z"
            />
            <path className="prefix__st0" d="M-18.4-5.5h4.4v7.7h-4.4z" />
            <path
              transform="rotate(-180 16.226 6.189)"
              className="prefix__st0"
              d="M14.1 2.3h4.4V10h-4.4z"
            />
          </symbol>
          <symbol id="prefix__Valve_Auto" viewBox="-19.5 -13.3 32.7 22">
            <path
              className="prefix__st1"
              d="M-18.7-12.5l5.5 10.2 5.6-10.2zM-7.6 7.9l-5.6-10.2-5.5 10.2zM2.3-12.4V7.8c5.6 0 10.1-4.5 10.1-10.1S7.9-12.4 2.3-12.4zM-13.2-2.3H2.3"
            />
          </symbol>
          <symbol id="prefix__Valve_small" viewBox="-14.3 -10.6 28.5 21.1">
            <path
              className="prefix__st1"
              d="M13.5 9.8L8.2 0 2.8 9.8zM2.8-9.8L8.2 0l5.3-9.8zM-13.5-9.7h6.8V9.6h-6.8zM8.2 0H-6.7"
            />
          </symbol>
          <g id="prefix__Piping">
            <path
              className="prefix__st2"
              d="M113.5 495H76.2M132.9 495h157.4c2.2 0 4-1.8 4-4V370c0-2.2-1.8-4-4-4H64c-2.2 0-4-1.8-4-4V148c0-2.2 1.8-4 4-4h381.3"
            />
            <path
              className="prefix__st3"
              d="M526.9 262H237.8c-2.2 0-4-1.8-4-4V144"
            />
            <path className="prefix__st4" d="M525.1 256.1l10.4 5.9-10.4 6z" />
            <path
              className="prefix__st2"
              d="M519.3 320.2H195.9c-2.2 0-4-1.8-4-4v-114"
            />
            <path
              className="prefix__st2"
              d="M158.9 202.2h231.9c2.2 0 4 1.8 4 4v114"
            />
            <path className="prefix__st3" d="M229 78.8h212.3c2.2 0 4 1.8 4 4V262" />
            <path className="prefix__st4" d="M230.7 84.8l-10.3-6 10.3-6z" />
            <path
              className="prefix__st3"
              d="M295.4 553.2H183c-2.2 0-4 1.8-4 4v46.4c0 2.2 1.8 4 4 4h251.6"
            />
            <path className="prefix__st4" d="M293.6 547.2l10.4 6-10.4 6z" />
            <path
              className="prefix__st2"
              d="M434.6 545.4h-30.1c-2.2 0-4 1.8-4 4v58.2M870.8 708.7v25"
            />
            <path
              className="prefix__st3"
              d="M597 537.9h231c2.2 0 4 1.8 4 4v53.8c0 2.2 1.8 4 4 4h30.8c2.2 0 4 1.8 4 4v96.5"
            />
            <path className="prefix__st4" d="M864.8 698.4l6 10.3 6-10.3z" />
            <path className="prefix__st2" d="M471.4 537.9h108.1M770.7 708.8v24.7" />
            <path
              className="prefix__st3"
              d="M732 537.9v57.8c0 2.2 1.8 4 4 4h30.7c2.2 0 4 1.8 4 4v96.5"
            />
            <path className="prefix__st4" d="M764.8 698.4l5.9 10.4 6-10.4z" />
            <path
              className="prefix__st2"
              d="M670.7 708.8v20.8c0 2.2 1.8 4 4 4H961"
            />
            <path
              className="prefix__st3"
              d="M632 537.9v57.8c0 2.2 1.8 4 4 4h30.7c2.2 0 4 1.8 4 4v96.5"
            />
            <path className="prefix__st4" d="M664.8 698.4l5.9 10.4 6-10.4z" />
            <path
              className="prefix__st2"
              d="M471.4 599.6h27c2.2 0 4-1.8 4-4v-57.8M549.8 599.6v-61.7"
            />
            <path
              className="prefix__st3"
              d="M549.8 618.9v51.8c0 2.2-1.8 4-4 4H409.1"
            />
            <path className="prefix__st4" d="M410.9 668.7l-10.4 6 10.4 5.9z" />
            <path className="prefix__st3" d="M981.5 733.7h79.1" />
            <path className="prefix__st4" d="M1058.9 739.7l10.4-6-10.4-6z" />
            <path
              className="prefix__st3"
              d="M1060.6 664.3h-31.3c-2.2 0-4 1.8-4 4v65.5"
            />
            <path className="prefix__st4" d="M1058.9 658.3l10.4 6-10.4 5.9z" />
            <g>
              <path
                className="prefix__st3"
                d="M835 269h-47.5c-2.2 0-4 1.8-4 4v111.9"
              />
              <path className="prefix__st4" d="M777.5 383.1l6 10.4 6-10.4z" />
            </g>
            <path
              className="prefix__st2"
              d="M835 246h-84.3M858.6 269v43.1c0 2.2 1.8 4 4 4H940M940 378.1h-36.7c-2.2 0-4-1.8-4-4v-57.9"
            />
            <g>
              <path
                className="prefix__st3"
                d="M977.2 370.4h79.8c2.2 0 4-1.8 4-4V167.6"
              />
              <path className="prefix__st4" d="M1067 169.4l-6-10.4-6 10.4z" />
            </g>
            <path
              className="prefix__st2"
              d="M977.2 308.4h83.8M858.6 170.5v-67.2c0-2.2-1.8-4-4-4H574.7"
            />
            <path
              className="prefix__st2"
              d="M574.7 164.6H685c2.2 0 4-1.8 4-4V99.3"
            />
            <g>
              <path
                className="prefix__st3"
                d="M858.4 184.1l-40.6-40.6c-.8-.8-1.8-1.2-2.8-1.2h-25.7"
              />
              <path className="prefix__st4" d="M791 136.3l-10.3 6 10.3 6z" />
            </g>
          </g>
          <g id="prefix__Component">
            <path className="prefix__st5" d="M206.6 483.4h65" />
            <path className="prefix__st6" d="M210.1 483.4h57.8v82.9h-57.8z" />
            <path className="prefix__st7" d="M253.4 483.4H268v82.9h-14.6z" />
            <path className="prefix__st5" d="M210.1 483.4h57.8v82.9h-57.8z" />
            <path className="prefix__st8" d="M206.6 566.3h65" />
            <path
              className="prefix__st4"
              d="M210.4 486.2h4.8v4.8h-4.8zM215.2 492.6h4.8v4.8h-4.8zM219.9 499.1h4.8v4.8h-4.8zM224.7 505.5h4.8v4.8h-4.8zM229.5 512h4.8v4.8h-4.8zM234.3 518.5h4.8v4.8h-4.8zM239.1 524.9h4.8v4.8h-4.8zM243.9 531.4h4.8v4.8h-4.8zM248.6 537.9h4.8v4.8h-4.8zM253.4 544.3h4.8v4.8h-4.8zM258.2 550.8h4.8v4.8h-4.8zM263 557.3h4.8v4.8H263z"
            />
            <path className="prefix__st5" d="M206.6 525.7h65" />
            <g>
              <path className="prefix__st5" d="M273.2 250.4h65" />
              <path className="prefix__st6" d="M276.8 250.4h57.8v82.9h-57.8z" />
              <path className="prefix__st7" d="M320 250.4h14.6v82.9H320z" />
              <path className="prefix__st5" d="M276.8 250.4h57.8v82.9h-57.8z" />
              <path className="prefix__st8" d="M273.2 333.3h65" />
              <path
                className="prefix__st4"
                d="M277 253.2h4.8v4.8H277zM281.8 259.7h4.8v4.8h-4.8zM286.6 266.1h4.8v4.8h-4.8zM291.4 272.6h4.8v4.8h-4.8zM296.1 279.1h4.8v4.8h-4.8zM300.9 285.5h4.8v4.8h-4.8zM305.7 292h4.8v4.8h-4.8zM310.5 298.4h4.8v4.8h-4.8zM315.3 304.9h4.8v4.8h-4.8zM320 311.4h4.8v4.8H320zM324.8 317.8h4.8v4.8h-4.8zM329.6 324.3h4.8v4.8h-4.8z"
              />
              <path className="prefix__st5" d="M273.2 292.7h65" />
            </g>
            <g>
              <path className="prefix__st5" d="M273.2 132.4h65" />
              <path className="prefix__st6" d="M276.8 132.4h57.8v82.9h-57.8z" />
              <path className="prefix__st7" d="M320 132.4h14.6v82.9H320z" />
              <path className="prefix__st5" d="M276.8 132.4h57.8v82.9h-57.8z" />
              <path className="prefix__st8" d="M273.2 215.3h65" />
              <path
                className="prefix__st4"
                d="M277 135.2h4.8v4.8H277zM281.8 141.6h4.8v4.8h-4.8zM286.6 148.1h4.8v4.8h-4.8zM291.4 154.6h4.8v4.8h-4.8zM296.1 161h4.8v4.8h-4.8zM300.9 167.5h4.8v4.8h-4.8zM305.7 174h4.8v4.8h-4.8zM310.5 180.4h4.8v4.8h-4.8zM315.3 186.9h4.8v4.8h-4.8zM320 193.4h4.8v4.8H320zM324.8 199.8h4.8v4.8h-4.8zM329.6 206.3h4.8v4.8h-4.8z"
              />
              <path className="prefix__st5" d="M273.2 174.7h65" />
            </g>
            <g>
              <path
                className="prefix__st6"
                d="M892 178.2v-3.4l-28.7-8h-9.5l-28.7 8v3.4h4.6v102h-4.6v3.4l28.7 8h9.5l28.7-8v-3.4h-4.6v-102z"
              />
              <path
                className="prefix__st7"
                d="M887.4 178.2v102h4.6v3.4l-27 7.6 7-6.2V173.5l-7-6.2 27 7.5v3.4z"
              />
              <path
                className="prefix__st9"
                d="M892 178.2v-3.4l-28.7-8h-9.5l-28.7 8v3.4h4.6v102h-4.6v3.4l28.7 8h9.5l28.7-8v-3.4h-4.6v-102zM829.7 178.2h57.7M825.1 174.8H892M829.7 280.2h57.7M825.1 283.6H892"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.6}
                height={23.8}
                id="prefix__XMLID_6_"
                x={-18.8}
                y={-13.4}
                transform="matrix(1 0 0 -1 452.973 543.856)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.6}
                height={23.8}
                id="prefix__XMLID_3_"
                x={-18.8}
                y={-13.4}
                transform="matrix(1 0 0 -1 452.973 605.869)"
                overflow="visible"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.5}
              height={21.1}
              id="prefix__XMLID_2_"
              x={-14.3}
              y={-10.6}
              transform="matrix(-1 0 0 1 557.883 608.878)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.5}
              height={21.1}
              id="prefix__XMLID_5_"
              x={-14.3}
              y={-10.6}
              transform="matrix(0 1 1 0 589 529.878)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_Auto"
              width={32.7}
              height={22}
              x={-19.5}
              y={-13.3}
              transform="matrix(0 -1.0013 -1 0 121.095 481.986)"
              overflow="visible"
            />
            <g>
              <path
                className="prefix__st6"
                d="M693.9 584.6h-46.2v81.6H644v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6z"
              />
              <path
                className="prefix__st7"
                d="M697.5 584.6h-16V670l-5.5 4.9 21.5-6v-2.7h-3.6v-81.6z"
              />
              <path
                className="prefix__st9"
                d="M693.9 584.6h-46.2v81.6H644v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6zM647.7 666.2h46.2M644 668.9h53.5"
              />
              <path
                className="prefix__st2"
                d="M652.5 594.6v10M689 594.6v10M682.9 594.6v10M676.8 594.6v10M670.7 594.6v10M664.6 594.6v10M658.6 594.6v10M652.5 607v10M689 607v10M682.9 607v10M676.8 607v10M670.7 607v10M664.6 607v10M658.6 607v10M652.5 619.4v10M689 619.4v10M682.9 619.4v10M676.8 619.4v10M670.7 619.4v10M664.6 619.4v10M658.6 619.4v10M652.5 631.8v10M689 631.8v10M682.9 631.8v10M676.8 631.8v10M670.7 631.8v10M664.6 631.8v10M658.6 631.8v10"
              />
            </g>
            <g>
              <path
                className="prefix__st6"
                d="M793.9 584.6h-46.2v81.6H744v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6z"
              />
              <path
                className="prefix__st7"
                d="M797.5 584.6h-16V670l-5.5 4.9 21.5-6v-2.7h-3.6v-81.6z"
              />
              <path
                className="prefix__st9"
                d="M793.9 584.6h-46.2v81.6H744v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6zM747.7 666.2h46.2M744 668.9h53.5"
              />
              <path
                className="prefix__st2"
                d="M752.5 594.6v10M789 594.6v10M782.9 594.6v10M776.8 594.6v10M770.7 594.6v10M764.6 594.6v10M758.6 594.6v10M752.5 607v10M789 607v10M782.9 607v10M776.8 607v10M770.7 607v10M764.6 607v10M758.6 607v10M752.5 619.4v10M789 619.4v10M782.9 619.4v10M776.8 619.4v10M770.7 619.4v10M764.6 619.4v10M758.6 619.4v10M752.5 631.8v10M789 631.8v10M782.9 631.8v10M776.8 631.8v10M770.7 631.8v10M764.6 631.8v10M758.6 631.8v10"
              />
            </g>
            <g>
              <path
                className="prefix__st6"
                d="M893.9 584.6h-46.2v81.6H844v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6z"
              />
              <path
                className="prefix__st7"
                d="M897.5 584.6h-16V670l-5.5 4.9 21.5-6v-2.7h-3.6v-81.6z"
              />
              <path
                className="prefix__st9"
                d="M893.9 584.6h-46.2v81.6H844v2.7l23 6.4h7.6l22.9-6.4v-2.7h-3.6zM847.7 666.2h46.2M844 668.9h53.5"
              />
              <path
                className="prefix__st2"
                d="M852.5 594.6v10M889 594.6v10M882.9 594.6v10M876.8 594.6v10M870.7 594.6v10M864.6 594.6v10M858.6 594.6v10M852.5 607v10M889 607v10M882.9 607v10M876.8 607v10M870.7 607v10M864.6 607v10M858.6 607v10M852.5 619.4v10M889 619.4v10M882.9 619.4v10M876.8 619.4v10M870.7 619.4v10M864.6 619.4v10M858.6 619.4v10M852.5 631.8v10M889 631.8v10M882.9 631.8v10M876.8 631.8v10M870.7 631.8v10M864.6 631.8v10M858.6 631.8v10"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.6}
                height={23.8}
                id="prefix__XMLID_4_"
                x={-18.8}
                y={-13.4}
                transform="matrix(1 0 0 -1 958.777 314.62)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.6}
                height={23.8}
                id="prefix__XMLID_1_"
                x={-18.8}
                y={-13.4}
                transform="matrix(1 0 0 -1 958.777 376.633)"
                overflow="visible"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_Auto"
              width={32.7}
              height={22}
              x={-19.5}
              y={-13.3}
              transform="matrix(0 -1 -1 0 968.735 720.537)"
              overflow="visible"
            />
          </g>
          <g id="prefix__Label">
            <path
              d="M179.6 466.9H67.1c-2.1 0-3.7-1.7-3.7-3.7v-14.5c0-2.1 1.7-3.7 3.7-3.7h112.5c2.1 0 3.7 1.7 3.7 3.7v14.5c.1 2-1.6 3.7-3.7 3.7z"
              fill="#fff200"
            />
            <path className="prefix__st11" d="M84 448h90.6v15.8H84z" />
            <text
              transform="translate(83.984 462.63)"
              className="prefix__st12"
              fontSize={20}
            >
              {`${Para['FRACT OILTEK Temperature'].PV} \xB0C`}
            </text>
            <path className="prefix__st11" d="M63.5 425.4h119.9v19.2H63.5z" />
            <text
              transform="translate(111.787 440.425)"
              className="prefix__st12"
              fontSize={18}
            >
              {"Fact"}
            </text>
            <text
              transform="translate(286.662 229.877)"
              className="prefix__st12 prefix__st15"
            >
              {"EOO1B"}
            </text>
            <text
              transform="translate(171.662 81.877)"
              className="prefix__st12 prefix__st15"
            >
              {"To E001"}
            </text>
            <text
              transform="translate(538.662 266.12)"
              className="prefix__st12 prefix__st15"
            >
              {"To VE702"}
            </text>
            <text transform="translate(1054.208 146.04)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"To"}
              </tspan>
              <tspan x={-7.9} y={10.4} className="prefix__st12 prefix__st15">
                {"B602"}
              </tspan>
            </text>
            <text transform="translate(758.875 139.04)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"To"}
              </tspan>
              <tspan x={-7.7} y={10.4} className="prefix__st12 prefix__st15">
                {"V702"}
              </tspan>
            </text>
            <text transform="translate(687.876 239.04)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"2.5 barg"}
              </tspan>
              <tspan x={5.5} y={10.4} className="prefix__st12 prefix__st15">
                {"Steam"}
              </tspan>
            </text>
            <text
              transform="translate(351.129 678.62)"
              className="prefix__st12 prefix__st15"
            >
              {"To D701"}
            </text>
            <text transform="translate(550.376 316.163)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"From"}
              </tspan>
              <tspan x={-11} y={10.4} className="prefix__st12 prefix__st15">
                {"LCV702A"}
              </tspan>
            </text>
            <text transform="translate(515.71 87.163)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"From"}
              </tspan>
              <tspan x={-5.4} y={10.4} className="prefix__st12 prefix__st15">
                {"VE703,"}
              </tspan>
              <tspan x={-7.8} y={20.8} className="prefix__st12 prefix__st15">
                {"VE703A"}
              </tspan>
              <tspan x={-6.3} y={31.2} className="prefix__st12 prefix__st15">
                {"&amp; D702"}
              </tspan>
            </text>
            <text transform="translate(515.71 156.96)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"From"}
              </tspan>
              <tspan x={-3.8} y={10.4} className="prefix__st12 prefix__st15">
                {"VE702"}
              </tspan>
              <tspan x={-10.3} y={20.8} className="prefix__st12 prefix__st15">
                {"&amp; D702A"}
              </tspan>
            </text>
            <text
              transform="translate(767.558 407.51)"
              className="prefix__st12 prefix__st15"
            >
              {"Cond."}
            </text>
            <text transform="translate(306.886 550.812)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"Clean Cooling"}
              </tspan>
              <tspan x={0.6} y={10.4} className="prefix__st12 prefix__st15">
                {"Water Return"}
              </tspan>
            </text>
            <text transform="translate(108.038 198.831)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"From"}
              </tspan>
              <tspan x={-0.5} y={11.4} className="prefix__st12 prefix__st15">
                {"P001"}
              </tspan>
            </text>
            <text
              transform="translate(286.662 347.227)"
              className="prefix__st12 prefix__st15"
            >
              {"EOO1A"}
            </text>
            <text
              transform="translate(224.786 578.48)"
              className="prefix__st12 prefix__st15"
            >
              {"E704"}
            </text>
            <text
              transform="translate(437.777 568.98)"
              className="prefix__st12 prefix__st15"
            >
              {"P707"}
            </text>
            <text
              transform="translate(433.715 631.847)"
              className="prefix__st12 prefix__st15"
            >
              {"P707A"}
            </text>
            <text
              transform="translate(574.715 613.62)"
              className="prefix__st12 prefix__st15"
            >
              {"V707_7"}
            </text>
            <text
              transform="translate(574.715 512.37)"
              className="prefix__st12 prefix__st15"
            >
              {"V707"}
            </text>
            <text
              transform="translate(844.212 203.537)"
              className="prefix__st12 prefix__st15"
            >
              {"T702"}
            </text>
            <text
              transform="translate(940.582 339.204)"
              className="prefix__st12 prefix__st15"
            >
              {"PT702"}
            </text>
            <text
              transform="translate(936.52 401.537)"
              className="prefix__st12 prefix__st15"
            >
              {"PT702A"}
            </text>
            <text transform="translate(840.756 222.87)">
              <tspan x={0} y={0} className="prefix__st12 prefix__st15">
                {"Splash"}
              </tspan>
              <tspan x={-4.5} y={11.4} className="prefix__st12 prefix__st15">
                {"Oil Tank"}
              </tspan>
            </text>
            <text
              transform="translate(656.465 581.37)"
              className="prefix__st12 prefix__st15"
            >
              {"F701"}
            </text>
            <text
              transform="translate(756.552 581.37)"
              className="prefix__st12 prefix__st15"
            >
              {"F702"}
            </text>
            <text
              transform="translate(855.553 581.37)"
              className="prefix__st12 prefix__st15"
            >
              {"F703"}
            </text>
            <text
              transform="translate(948.803 750.12)"
              className="prefix__st12 prefix__st15"
            >
              {"FTQ704"}
            </text>
            <text
              transform="translate(108.535 513.48)"
              className="prefix__st12 prefix__st15"
            >
              {"TCV704"}
            </text>
            <path
              className="prefix__st4"
              d="M734.5 248h5.8v4l10.4-6-10.4-6v4h-5.8zM558.5 101.3h5.8v4l10.4-6-10.4-6v4h-5.8zM535.5 318.2h-5.8v-3.9l-10.4 5.9 10.4 6v-4h5.8zM142.7 204.2h5.8v4l10.4-6-10.4-6v4h-5.8zM558.5 166.5h5.8v4l10.4-6-10.4-6v4h-5.8zM60 497h5.8v4l10.4-6-10.4-6v4H60z"
            />
          </g>
        </svg>
      )
}

export default OiltekDeo2
