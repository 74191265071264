export const Margerine = {
    'Salt Tank AT201 Load Cell LC-201': { PV: 'None', SV: 'None', Line:'Margarine Aques' },
    'Outlet Return PHE E-921 Temperature TE-921': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Circulation Tank FT411 Temperature TE-411': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Discharge Pump P311 Pressure PT-311': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Weighing Tank BT311 Temperature TE-311': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Weighing Tank BT311 Load Cell LC-311': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Emulsifier Tank ET112 Load Cell LC-112': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Emulsifier Tank ET111 Load Cell LC-111': { PV: 'None', SV: 'None', Line:'Margarine BLENDING' },
    'Pin Rotor WU7111 Rotation SIC-7111': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Pin Rotor WU7112 Rotation SIC-7112': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Filling Point Temperature TE-812': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Filling Point Pressure PT-812': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Outlet Pin Rotor WU7112 Temperature TE-7022': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Outlet Pin Rotor WU7111 Temperature TE-7021': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Outlet SSHE CH612 Temperature CVQ-6023': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Outlet SSHE CH6111 Temperature CVQ-6021': { PV: 'None', SV: 'None', Line:'Margarine CHILL' },
    'Outlet SSHE CH6112 Temperature CVQ-6022': { PV: 'None', SV: 'None' , Line:'Margarine CHILL'},
    'Discharge Piston Pump P412 Pressure PT-412B': { PV: 'None', SV: 'None', Line:'Margarine PASTUER' },
    'Piston Pump P412 Flowrate SIC-412': { PV: 'None', SV: 'None', Line:'Margarine PASTUER' },
    'Suction Piston Pump P412 Pressure PT-412A': { PV: 'None', SV: 'None' , Line:'Margarine PASTUER'},
    'Outlet Pasteur Cold PHE 411 Temperature TEE-411B': { PV: 'None', SV: 'None', Line:'Margarine PASTUER' },
    'Outlet Pasteur Hot PHE 411 Temperature TEE-411A': { PV: 'None', SV: 'None', Line:'Margarine PASTUER' },
    'Discharge Pump P411 Pressure PT-411': { PV: 'None', SV: 'None', Line:'Margarine PASTUER' },
}


export const Shortening = {
    'Outlet Return PHE E-901 Temperature TEE-901': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Circulation Tank FT401 Temperature TE-401': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Discharge Pump P301 Pressure PT-301': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Weighing Tank BT301 Temperature TE-301': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Weighing Tank BT301 Load Cell LC-301': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Emulsifier Tank ET102 Load Cell LC-102': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Emulsifier Tank ET101 Load Cell LC-101': { PV: 'None', SV: 'None', Line:'Shortening BLENDING' },
    'Filling Point Temperature TE-802': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Filling Point Pressure PT-802': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Pin Rotor WU7012 Rotation SIC-7012': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Pin Rotor WU7011 Rotation SIC-7011': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Outlet Pin Rotor WU7011 Temperature TE-7011': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Outlet Pin Rotor WU7012 Temperature TE-7012': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Outlet SSHE CH6012 Temperature CVQ-6012': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Outlet SSHE CH6011 Temperature CVQ-6011': { PV: 'None', SV: 'None', Line:'Shortening CHILL' },
    'Piston Pump P402 Flowrate FIC-501': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
    'Piston Pump P402 Flowrate SIC-402': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
    'Discharge Piston Pump P402 Pressure PT-402B': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
    'Suction Piston Pump P402 Pressure PT-402A': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
    'Outlet Pasteur PHE E-401 Temperature TEE-401': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
    'Discharge Pump P401 Pressure PT-401': { PV: 'None', SV: 'None', Line:'Shortening PASTUER' },
}