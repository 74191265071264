export const columns1 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'CPO 26E00',
        children: [
            {
                title: 'TEMP IN (C)',
                width: 60,
                dataIndex: 'CPO 26E00 Temperature',
                key: 'CPO 26E00 Temperature',
                align: 'center'
            },
            {
                title: 'FLO (m3/Hr)',
                width: 60,
                dataIndex: 'CPO 26E00 Flow Rate',
                key: 'CPO 26E00 Flow Rate',
                align: 'center'
            },

        ]
    },

    {
        title: 'H3PO4',
        children: [
            {
                title: '26E00 TEMP',
                width: 60,
                dataIndex: 'PHE CPO Out H3PO4 26E00 Temperature',
                key: 'PHE CPO Out H3PO4 26E00 Temperature',
                align: 'center'

            },

            {
                title: '26E01 TEMP',
                width: 60,
                dataIndex: 'PHE CPO Out H3PO4 26E01 Temperature',
                key: 'PHE CPO Out H3PO4 26E01 Temperature',
                align: 'center'

            },
        ]
    },
    {
        title: 'H3PO4',
        children: [
            {
                title: 'Stroke/F req (%)',
                width: 60,
                dataIndex: 'H3PO4 Stroke/F req (%)',
                key: 'H3PO4 Stroke/F req (%)',
                align: 'center'
            },

            {
                title: 'LEVEL (Kg)',
                width: 60,
                dataIndex: 'H3PO4 Level (Kg)',
                key: 'H3PO4 Level (Kg)',
                align: 'center'
            },

            {
                title: 'Usage (Kg/hr)',
                width: 60,
                dataIndex: 'H3PO4 Level (Kg/hr)',
                key: 'H3PO4 Level (Kg/hr)',
                align: 'center'
            },
        ]
    },

    {
        title: 'VAC (mm/Hg) Torr',
        dataIndex: 'VAC mm/Hg Torr',
        key: 'VAC mm/Hg Torr',
        width: 60,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Setting (%)',
        dataIndex: 'Bleaching Earth Setting',
        key: 'Bleaching Earth Setting',
        width: 60,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Weight',
        dataIndex: 'Bleaching Earth Weight',
        key: 'Bleaching Earth Weight',
        width: 60,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Usage',
        dataIndex: 'Bleaching Earth Usage',
        key: 'Bleaching Earth Usage',
        width: 60,
        align: 'center'
    },
    {
        title: 'Filter No.1 Bar',
        dataIndex: 'Filter No.1',
        key: 'Filter No.1',
        width: 60,
        align: 'center'
    },
    {
        title: 'Filter No.2 Bar',
        dataIndex: 'Filter No.2',
        key: 'Filter No.2',
        width: 60,
        align: 'center'
    },
    {
        title: 'Filter Bag Pressure Bar',
        dataIndex: 'Filter Bag Pressure Bar',
        key: 'Filter Bag Pressure Bar',
        width: 60,
        align: 'center'
    },
    {
        title: 'F BPO QLTY',
        children: [
            {
                title: 'COLOR RED',
                width: 60,
                dataIndex: 'F BPO QLTY Color Red',
                key: 'F BPO QLTY Color Red',
                align: 'center'
            },
            {
                title: 'BLACK PARTICAL',
                width: 60,
                dataIndex: 'F BPO QLTY Black Partical',
                key: 'F BPO QLTY Black Partical',
                align: 'center'
            },

        ]
    },
    {
        title: 'HP BOILER',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'HP Boiler Temperature',
                key: 'HP Boiler Temperature',
                align: 'center'
            },
            {
                title: 'Barg',
                width: 60,
                dataIndex: 'HP Boiler Barg',
                key: 'HP Boiler Barg',
                align: 'center'
            },

        ]
    },
]


export const columns2 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'BPO Tank',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'BPO Tank Temperature',
                align: 'center'
            },
            {
                title: 'Level(%)',
                width: 60,
                dataIndex: 'BPO Tank Level',
                align: 'center'
            },

        ]
    },

    {
        title: 'BPO FILTER BAG',
        width: 60,
        dataIndex: 'BPO Filter Bag',
        align: 'center'
    },

    {
        title: 'PHE 27E01 BPO TEMP (C)',
        width: 60,
        dataIndex: 'PHE 27E01 BPO Temperature',
        align: 'center'
    },

    {
        title: 'FLOW RATE',
        width: 60,
        dataIndex: 'Flow Rate',
        align: 'center'
    },

    {
        title: 'TEMP (C)',
        children: [
            {
                title: '27E03 VHE OUT',
                width: 60,
                dataIndex: '27E03 VHE Out Temperature',
                align: 'center'

            },

            {
                title: '27E04 FINAL HEATER OUT',
                width: 60,
                dataIndex: '27E04 Final Heater Out Temperature',
                align: 'center'

            }]
    },

    {
        title: 'VACUUM TORR',
        width: 60,
        dataIndex: 'Vacuum Torr',
        align: 'center'
    },

    {
        title: 'DEOD 27C03 TEMP(C)',
        width: 60,
        dataIndex: 'DEOD 27C03 Temperature',
        align: 'center'
    },

    {
        title: '26E00 RBD OUT',
        dataIndex: '26E00 RBD Out Temperature',
        width: 60,
        align: 'center'
    },
    {
        title: '27E06 RBD OUT',
        dataIndex: '27E06 RBD Out Temperature',
        width: 60,
        align: 'center'
    },

    {
        title: 'PFAD 27T05',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'PFAD 27T05 Temperature',
                align: 'center'
            },
            {
                title: 'BAR',
                width: 60,
                dataIndex: 'PFAD 27T05 Bar',
                align: 'center'
            },

        ]
    },
    {
        title: 'FILTER BAG BAR',
        width: 60,
        dataIndex: 'Filter Bag Bar',
        align: 'center'
    },

    {
        title: 'TEMP (C)',
        children: [
            {
                title: 'FRACT',
                width: 60,
                dataIndex: 'FRACT Temperature',
                align: 'center'
            },
            {
                title: 'STRG',
                width: 60,
                dataIndex: 'STRG Temperature',
                align: 'center'
            },

        ]
    },

    {
        title: 'RPO QUALITY',
        children: [
            {
                title: 'CLR',
                width: 60,
                dataIndex: 'RBDPO Quality CLR',
                align: 'center'
            },
            {
                title: 'FFA',
                width: 60,
                dataIndex: 'RBDPO Quality FFA',
                align: 'center'
            },
            {
                title: 'BLACK PARTICAL',
                width: 60,
                dataIndex: 'RBDPO Quality Black Partical',
                align: 'center'
            },
        ]
    },
]

export const columns3 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'MAIN STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'Main Steam Header Press. Kg/Cm2',
        key: 'Main Steam Header Press. Kg/Cm2',
        align: 'center'
    },
    {
        title: 'VAC. STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'VAC Steam Header Press. Kg/Cm2',
        key: 'VAC Steam Header Press. Kg/Cm2',
        align: 'center'
    },
    {
        title: 'SPRG. STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'SPRG Steam Header Press. Kg/Cm2',
        key: 'SPRG Steam Header Press. Kg/Cm2',
        align: 'center'
    },
    {
        title: 'B/PLANT STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'B/Plant Steam Header Press. Kg/Cm2',
        key: 'B/Plant Steam Header Press. Kg/Cm2',
        align: 'center'
    },
    {
        title: 'NIAGARA STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'Niagara Steam Header Press. Kg/Cm2',
        key: 'Niagara Steam Header Press. Kg/Cm2',
        align: 'center'
    },

    {
        title: 'C.T. PUMP PRESS. Kg/cm2',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'C.T. Pump Press No.1 Kg/Cm2',
                key: 'C.T. Pump Press No.1 Kg/Cm2',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'C.T. Pump Press No.2 Kg/Cm2',
                key: 'C.T. Pump Press No.2 Kg/Cm2',
                align: 'center'
            },
        ]
    },

    {
        title: 'C.T. FAN IN OPERATION',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'C.T. Fan Press No.1 Kg/Cm2',
                key: 'C.T. Fan Press No.1 Kg/Cm2',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'C.T. Fan Press No.2 Kg/Cm2',
                key: 'C.T. Fan Press No.2 Kg/Cm2',
                align: 'center'
            },
        ]
    },

    {
        title: 'COOLING TOWER WATER HEADER',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'Cooling Tower Water Header',
                key: 'Cooling Tower Water Header',
                align: 'center'
            },
            {
                title: 'PRESS Kg/cm2',
                width: 60,
                dataIndex: 'Cooling Tower Water Header Press. Kg/Cm2',
                key: 'Cooling Tower Water Header Press. Kg/Cm2',
                align: 'center'
            },
        ]
    },

    {
        title: 'H.W. PUMP PRESS Kg/cm2',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'H.W. Pump Press. No.1 Kg/cm2',
                key: 'H.W. Pump Press. No.1 Kg/cm2',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'H.W. Pump Press. No.2 Kg/cm2',
                key: 'H.W. Pump Press. No.2 Kg/cm2',
                align: 'center'
            },
        ]
    },

    {
        title: 'CLEAN COOLING TOWER',
        children: [
            {
                title: '1',
                width: 60,
                dataIndex: 'Clean Cooling Tower No.1',
                key: 'Clean Cooling Tower No.1',
                align: 'center'
            },
            {
                title: '2',
                width: 60,
                dataIndex: 'Clean Cooling Tower No.2',
                key: 'Clean Cooling Tower No.2',
                align: 'center'
            },
        ]
    },
]

