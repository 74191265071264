export const OTKModelPage1 = {
    'Operator Name': { PV: 'None', SV: 'None' },
    'Lab Operator Name': { PV: 'None', SV: 'None' },
    'CPO T001 Temperature': { PV: 'None', SV: 'None' },
    'CPO T001 Flow Rate': { PV: 'None', SV: 'None' },
    'PHE CPO OUT HE 001A/B Temperature': { PV: 'None', SV: 'None' },
    'PHE CPO OUT HE001 Temperature': { PV: 'None', SV: 'None' },
    'H3PO4 Stroke/F': { PV: 'None', SV: 'None' },
    'H3PO4 Level': { PV: 'None', SV: 'None' },
    'H3PO4 Usage': { PV: 'None', SV: 'None' },
    'VAC TORR OILTEK': { PV: 'None', SV: 'None' },
    'B/E Setting': { PV: 'None', SV: 'None' },
    'Bleaching Earth Weight': { PV: 'None', SV: 'None' },
    'Bleaching Earth Usage': { PV: 'None', SV: 'None' },
    'Niagara Filter 601': { PV: 'None', SV: 'None' },
    'Niagara Filter 602': { PV: 'None', SV: 'None' },
    'Niagara Filter 603': { PV: 'None', SV: 'None' },
    'Filter Bag Pressure Bar F604/5/6': { PV: 'None', SV: 'None' },
    'F BPO QLTY Color Red': { PV: 'None', SV: 'None' },
    'F BPO QLTY Black Partical': { PV: 'None', SV: 'None' },
    'HP Boiler HT500 Temperature': { PV: 'None', SV: 'None' },
    'HP Boiler HT500 Barg': { PV: 'None', SV: 'None' },
    'HP Boiler HT501 Temperature': { PV: 'None', SV: 'None' },
    'HP Boiler HT501 Barg': { PV: 'None', SV: 'None' },
    'Remark': { PV: 'None', SV: 'None' },
    'Vacuum DEO': { PV: 'None', SV: 'None' },
    'VAC OILTEK': { PV: 'None', SV: 'None' },
}

export const OTKModelPage2 = {
    'Operator Name': { PV: 'None', SV: 'None' },
    'Lab Operator Name': { PV: 'None', SV: 'None' },
    'BPO TANK D701 Temperature': { PV: 'None', SV: 'None' },
    'BPO TANK D701 LEVEL': { PV: 'None', SV: 'None' },
    'PHE BPO OUT E706 A/B Temperature': { PV: 'None', SV: 'None' },
    'PHE BPO OUT E701 Temperature': { PV: 'None', SV: 'None' },
    'PHE BPO OUT E702 A/B Temperature': { PV: 'None', SV: 'None' },
    'Vacuum DEO TORR': { PV: 'None', SV: 'None' },
    'BPO Flow Rate': { PV: 'None', SV: 'None' },
    'VE702 BPO Temperature': { PV: 'None', SV: 'None' },
    'VE702 RPO Temperature': { PV: 'None', SV: 'None' },
    'VE703 BPO Temperature': { PV: 'None', SV: 'None' },
    'VE703A Temperature': { PV: 'None', SV: 'None' },
    'DEOD D702 Temperature': { PV: 'None', SV: 'None' },
    'RBDPO OUT E702 A/B Temperature': { PV: 'None', SV: 'None' },
    'RBDPO OUT E001 A/B Temperature': { PV: 'None', SV: 'None' },
    'PFAD SCR 705 TCV Temperature': { PV: 'None', SV: 'None' },
    'PFAD SCR 705 TCV Bar': { PV: 'None', SV: 'None' },
    'PFAD SCR 706 TCV Temperature': { PV: 'None', SV: 'None' },
    'PFAD SCR 706 TCV Bar': { PV: 'None', SV: 'None' },
    'Filter Bag (F701,F702,F703) Bar': { PV: 'None', SV: 'None' },
    'FRACT OILTEK Temperature': { PV: 'None', SV: 'None' },
    'STRG OILTEK Temperature': { PV: 'None', SV: 'None' },
    'RBDPO Quality CLR': { PV: 'None', SV: 'None' },
    'RBDPO Quality FFA': { PV: 'None', SV: 'None' },
    'RBDPO Quality Black Partical': { PV: 'None', SV: 'None' },
    'Remark': { PV: 'None', SV: 'None' },

}

export const OTKModelPage3 = {
    'Operator Name': { PV: 'None', SV: 'None' },
    'Lab Operator Name': { PV: 'None', SV: 'None' },
    'MAIN STEAM HEADER PRESS': { PV: 'None', SV: 'None' },
    'VAC. STEAM HEADER DEO PRESS': { PV: 'None', SV: 'None' },
    'SPRG. STEAM HEADER DEO PRESS': { PV: 'None', SV: 'None' },
    'B/PLANT STEAM HEADER PRESS': { PV: 'None', SV: 'None' },
    'NIAGARA STEAM HEADER PRESS': { PV: 'None', SV: 'None' },
    'C.T. PUMP No.1': { PV: 'None', SV: 'None' },
    'C.T. PUMP No.2': { PV: 'None', SV: 'None' },
    'C.T. FAN No.1': { PV: 'None', SV: 'None' },
    'C.T. FAN No.2': { PV: 'None', SV: 'None' },
    'Cooling Tower Water Header Temperature': { PV: 'None', SV: 'None' },
    'COOLING TOWER WATER HEADER PRESS': { PV: 'None', SV: 'None' },
    'H.W. PUMP PRESS No.1': { PV: 'None', SV: 'None' },
    'H.W. PUMP PRESS No.2': { PV: 'None', SV: 'None' },
    'CLEAN COOLING TOWER No.1': { PV: 'None', SV: 'None' },
    'CLEAN COOLING TOWER No.2': { PV: 'None', SV: 'None' },
}

export const Time = {
    '07:00':{PV:'none', SV:'none'},
    '08:00':{PV:'none', SV:'none'},
    '09:00':{PV:'none', SV:'none'},
    '10:00':{PV:'none', SV:'none'},
    '11:00':{PV:'none', SV:'none'},
    '12:00':{PV:'none', SV:'none'},
    '13:00':{PV:'none', SV:'none'},
    '14:00':{PV:'none', SV:'none'},
    '15:00':{PV:'none', SV:'none'},
    '16:00':{PV:'none', SV:'none'},
    '17:00':{PV:'none', SV:'none'},
    '18:00':{PV:'none', SV:'none'},
    '19:00':{PV:'none', SV:'none'},
    '20:00':{PV:'none', SV:'none'},
    '21:00':{PV:'none', SV:'none'},
    '22:00':{PV:'none', SV:'none'},
    '23:00':{PV:'none', SV:'none'},
    '00:00':{PV:'none', SV:'none'},
    '01:00':{PV:'none', SV:'none'},
    '02:00':{PV:'none', SV:'none'},
    '03:00':{PV:'none', SV:'none'},
    '04:00':{PV:'none', SV:'none'},
    '05:00':{PV:'none', SV:'none'},
    '06:00':{PV:'none', SV:'none'},
}