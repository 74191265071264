import { Result } from 'antd'
import React from 'react'

const NotAuthorised = () => {
    return (
        <div>
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
            />
        </div>
    )
}

export default NotAuthorised
