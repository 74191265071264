export const columns1 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'CPO T001',
        children: [
            {
                title: 'TEMP IN (C)',
                width: 40,
                dataIndex: 'CPO T001 Temperature',
                key: 'CPO T001 Temperature',
                align: 'center'
            },
            {
                title: 'FLO (m3/Hr)',
                width: 40,
                dataIndex: 'CPO T001 Flow Rate',
                key: 'CPO T001 Flow Rate',
                align: 'center'
            },
        ]
    },

    {
        title: 'PHE CPO OUT',
        children: [
            {
                title: 'HE001A/B TEMP',
                width: 40,
                dataIndex: 'PHE CPO OUT HE 001A/B Temperature',
                key: 'PHE CPO OUT HE 001A/B Temperature',
                align: 'center'

            },

            {
                title: 'HE001 TEMP',
                width: 40,
                dataIndex: 'PHE CPO OUT HE001 Temperature',
                key: 'PHE CPO OUT HE001 Temperature',
                align: 'center'

            },
        ]
    },

    {
        title: 'H3PO4',
        children: [
            {
                title: 'Stroke/F req (%)',
                width: 40,
                dataIndex: 'H3PO4 Stroke/F',
                key: 'H3PO4 Stroke/F',
                align: 'center'
            },

            {
                title: 'LEVEL (Kg)',
                width: 40,
                dataIndex: 'H3PO4 Level',
                key: 'H3PO4 Level',
                align: 'center'
            },

            {
                title: 'Usage (Kg/hr)',
                width: 40,
                dataIndex: 'H3PO4 Usage',
                key: 'H3PO4 Usage',
                align: 'center'
            },
        ]
    },

    {
        title: 'VAC (mm/Hg) Torr',
        dataIndex: 'VAC TORR OILTEK',
        key: 'VAC TORR OILTEK',
        width: 40,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Setting (%)',
        dataIndex: 'B/E Setting',
        key: 'B/E Setting',
        width: 40,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Weight',
        dataIndex: 'Bleaching Earth Weight',
        key: 'Bleaching Earth Weight',
        width: 40,
        align: 'center'
    },
    {
        title: 'Bleaching Earth Usage',
        dataIndex: 'Bleaching Earth Usage',
        key: 'Bleaching Earth Usage',
        width: 40,
        align: 'center'
    },

    {
        title: 'NIAGARA FILTER Bar',
        children: [
            {
                title: '601',
                width: 40,
                dataIndex: 'Niagara Filter 601',
                key: 'Niagara Filter 601',
                align: 'center'
            },

            {
                title: '602',
                width: 40,
                dataIndex: 'Niagara Filter 602',
                key: 'Niagara Filter 602',
                align: 'center'
            },

            {
                title: '603',
                width: 40,
                dataIndex: 'Niagara Filter 603',
                key: 'Niagara Filter 603',
                align: 'center'
            },
        ]
    },

    {
        title: 'Filter Bag Pressure Bar A',
        dataIndex: 'Filter Bag Pressure Bar F604/5/6',
        key: 'Filter Bag Pressure Bar F604/5/6',
        width: 40,
        align: 'center'
    },

    {
        title: 'F BPO QLTY',
        children: [
            {
                title: 'COLOR RED',
                width: 40,
                dataIndex: 'F BPO QLTY Color Red',
                key: 'F BPO QLTY Color Red',
                align: 'center'
            },
            {
                title: 'BLACK PARTICAL',
                width: 40,
                dataIndex: 'F BPO QLTY Black Partical',
                key: 'F BPO QLTY Black Partical',
                align: 'center'
            },

        ]
    },

    {
        title: 'HP BOILER',
        children: [
            {
                title: 'HT 500',
                children: [

                    {
                        title: 'TEMP (C)',
                        width: 40,
                        dataIndex: 'HP Boiler HT500 Temperature',
                        key: 'HP Boiler HT500 Temperature',
                        align: 'center'
                    },
                    {
                        title: 'Barg',
                        width: 40,
                        dataIndex: 'HP Boiler HT500 Barg',
                        key: 'HP Boiler HT500 Barg',
                        align: 'center'
                    },]
            },

            {
                title: 'HT 501',
                children: [

                    {
                        title: 'TEMP (C)',
                        width: 40,
                        dataIndex: 'HP Boiler HT501 Temperature',
                        key: 'HP Boiler HT501 Temperature',
                        align: 'center'
                    },
                    {
                        title: 'Barg',
                        width: 40,
                        dataIndex: 'HP Boiler HT501 Barg',
                        key: 'HP Boiler HT501 Barg',
                        align: 'center'
                    },]
            },
        ]
    },
    {
        title: 'Remark',
        width: 70,
        dataIndex: 'Remark',
        key: 'Remark',
        align: 'center',
    },
]


export const columns2 = [
    {
        title: 'DateTime',
        width: 80,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center'
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'BPO TANK (D701)',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'BPO TANK D701 Temperature',
                key: 'BPO TANK D701 Temperature',
                align: 'center'
            },
            {
                title: 'LEVEL (%)',
                width: 60,
                dataIndex: 'BPO TANK D701 LEVEL',
                key: 'BPO TANK D701 LEVEL',
                align: 'center'
            },
        ]
    },

    {
        title: 'PHE BPO OUT TEMP (C)',
        children: [
            {
                title: 'E706 A/B',
                width: 60,
                dataIndex: 'PHE BPO OUT E706 A/B Temperature',
                key: 'PHE BPO OUT E706 A/B Temperature',
                align: 'center'
            },
            {
                title: 'E701',
                width: 60,
                dataIndex: 'PHE BPO OUT E701 Temperature',
                key: 'PHE BPO OUT E701 Temperature',
                align: 'center'
            },
            {
                title: 'E702 A/B',
                width: 60,
                dataIndex: 'PHE BPO OUT E702 A/B Temperature',
                key: 'PHE BPO OUT E702 A/B Temperature',
                align: 'center'
            },
        ]
    },
    {
        title: 'VACUUM DEO (torr)',
        width: 60,
        dataIndex: 'Vacuum DEO TORR',
        key: 'Vacuum DEO TORR',
        align: 'center'
    },
    {
        title: 'BPO FLOW RATE',
        width: 60,
        dataIndex: 'BPO Flow Rate',
        key: 'BPO Flow Rate',
        align: 'center'
    },

    {
        title: 'TEMP (C)',
        children: [
            {
                title: 'VE 702 BPO',
                width: 60,
                dataIndex: 'VE702 BPO Temperature',
                key: 'VE702 BPO Temperature',
                align: 'center'
            },
            {
                title: 'VE 702 RPO',
                width: 60,
                dataIndex: 'VE702 RPO Temperature',
                key: 'VE702 RPO Temperature',
                align: 'center'
            },
            {
                title: 'VE 703 BPO',
                width: 60,
                dataIndex: 'VE703 BPO Temperature',
                key: 'VE703 BPO Temperature',
                align: 'center'
            },
            {
                title: 'VE 703A',
                width: 60,
                dataIndex: 'VE703A Temperature',
                key: 'VE703A Temperature',
                align: 'center'
            },
        ]
    },

    {
        title: 'DEOD D702 TEMP (C)',
        dataIndex: 'DEOD D702 Temperature',
        key: 'DEOD D702 Temperature',
        width: 60,
        align: 'center'
    },
    {
        title: 'RBDPO OUT TEMP (C) E702 A/B',
        dataIndex: 'RBDPO OUT E702 A/B Temperature',
        key: 'RBDPO OUT E702 A/B Temperature',
        width: 60,
        align: 'center'
    },
    {
        title: 'RBDPO OUT TEMP (C) E001 A/B',
        dataIndex: 'RBDPO OUT E001 A/B Temperature',
        key: 'RBDPO OUT E001 A/B Temperature',
        width: 60,
        align: 'center'
    },

    {
        title: 'PFAD SCR 705 TCV',
        children: [
            {
                title: 'TEMP (c)',
                width: 60,
                dataIndex: 'PFAD SCR 705 TCV Temperature',
                key: 'PFAD SCR 705 TCV Temperature',
                align: 'center'
            },
            {
                title: 'BAR',
                width: 60,
                dataIndex: 'PFAD SCR 705 TCV Bar',
                key: 'PFAD SCR 706 TCV Bar',
                align: 'center'
            },
        ]
    },

    {
        title: 'PFAD SCR 706 TCV',
        children: [
            {
                title: 'TEMP (c)',
                width: 60,
                dataIndex: 'PFAD SCR 706 TCV Temperature',
                key: 'PFAD SCR 706 TCV Temperature',
                align: 'center'
            },
            {
                title: 'BAR',
                width: 60,
                dataIndex: 'PFAD SCR 706 TCV Bar',
                key: 'PFAD SCR 706 TCV Bar',
                align: 'center'
            },
        ]
    },

    {
        title: 'Filter Bag (F701,F702,F703) Bar',
        dataIndex: 'Filter Bag (F701,F702,F703) Bar',
        key: 'Filter Bag (F701,F702,F703) Bar',
        width: 60,
        align: 'center'
    },

    {
        title: 'TEMP (C)',
        children: [
            {
                title: 'FRACT',
                width: 60,
                dataIndex: 'FRACT OILTEK Temperature',
                key: 'FRACT OILTEK Temperature',
                align: 'center'
            },
            {
                title: 'STRG',
                width: 60,
                dataIndex: 'STRG OILTEK Temperature',
                key: 'STRG OILTEK Temperature',
                align: 'center'
            },

        ]
    },

    {
        title: 'RPO QUALITY',
        children: [
            {
                title: 'CLR',
                width: 60,
                dataIndex: 'RBDPO Quality CLR',
                key: 'RBDPO Quality CLR',
                align: 'center'
            },
            {
                title: 'FFA',
                width: 60,
                dataIndex: 'RBDPO Quality FFA',
                key: 'RBDPO Quality FFA',
                align: 'center'
            },
            {
                title: 'BLACK PARTICAL',
                width: 60,
                dataIndex: 'RBDPO Quality Black Partical',
                key: 'RBDPO Quality Black Partical',
                align: 'center'
            },
        ]
    },
    {
        title: 'Remark',
        width: 70,
        dataIndex: 'Remark',
        key: 'Remark',
        align: 'center',
    },
]

export const columns3 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center'
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },
    {
        title: 'MAIN STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'MAIN STEAM HEADER PRESS',
        key: 'MAIN STEAM HEADER PRESS',
        align: 'center'
    },
    {
        title: 'VAC. STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'VAC. STEAM HEADER DEO PRESS',
        key: 'VAC. STEAM HEADER DEO PRESS',
        align: 'center'
    },
    {
        title: 'SPRG. STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'SPRG. STEAM HEADER DEO PRESS',
        key: 'SPRG. STEAM HEADER DEO PRESS',
        align: 'center'
    },
    {
        title: 'B/PLANT STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'B/PLANT STEAM HEADER PRESS',
        key: 'B/PLANT STEAM HEADER PRESS',
        align: 'center'
    },
    {
        title: 'NIAGARA STEAM HEADER PRESS. Kg/cm2',
        width: 100,
        dataIndex: 'NIAGARA STEAM HEADER PRESS',
        key: 'NIAGARA STEAM HEADER PRESS',
        align: 'center'
    },

    {
        title: 'C.T. PUMP PRESS. Kg/cm2',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'C.T. PUMP No.1',
                key: 'C.T. PUMP No.1',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'C.T. PUMP No.2',
                key: 'C.T. PUMP No.2',
                align: 'center'
            },
        ]
    },

    {
        title: 'C.T. FAN IN OPERATION',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'C.T. FAN No.1',
                key: 'C.T. FAN No.1',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'C.T. FAN No.2',
                key: 'C.T. FAN No.2',
                align: 'center'
            },
        ]
    },

    {
        title: 'COOLING TOWER WATER HEADER',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'COOLING TOWER WATER HEADER Temperature',
                key: 'COOLING TOWER WATER HEADER Temperature',
                align: 'center'
            },
            {
                title: 'PRESS Kg/cm2',
                width: 60,
                dataIndex: 'COOLING TOWER WATER HEADER PRESS',
                key: 'COOLING TOWER WATER HEADER PRESS',
                align: 'center'
            },
        ]
    },

    {
        title: 'H.W. PUMP PRESS Kg/cm2',
        children: [
            {
                title: 'No.1',
                width: 60,
                dataIndex: 'H.W. PUMP PRESS No.1',
                key: 'H.W. PUMP PRESS No.1',
                align: 'center'
            },
            {
                title: 'No.2',
                width: 60,
                dataIndex: 'H.W. PUMP PRESS No.2',
                key: 'H.W. PUMP PRESS No.2',
                align: 'center'
            },
        ]
    },

    {
        title: 'CLEAN COOLING TOWER',
        children: [
            {
                title: 'TEMP (C)',
                width: 60,
                dataIndex: 'CLEAN COOLING TOWER No.1',
                key: 'CLEAN COOLING TOWER No.1',
                align: 'center'
            },
            {
                title: 'PRESS',
                width: 60,
                dataIndex: 'CLEAN COOLING TOWER No.2',
                key: 'CLEAN COOLING TOWER No.2',
                align: 'center'
            },
        ]
    }
]

