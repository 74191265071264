import React from 'react'
import OiltekNPG1Noti from './Notification/TirtiauxPG1Noti'

const OiltekNNotification = () => {
    return (
        <div>
            <OiltekNPG1Noti/>
        </div>
    )
}

export default OiltekNNotification
