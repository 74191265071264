export const columns1 = [
    {
        title: 'Start',
        width: 20,
        dataIndex: 'Start',
        align: 'center'
    },
    {
        title: 'End',
        width: 20,
        dataIndex: 'End',
        align: 'center'
    },
    {
        title: 'T401',
        width: 30,
        dataIndex: 'T401',
        align: 'center',
        render(text, record) {
            let color = ''
            let name = ''

            if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
            else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
            else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
            else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
            else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
            else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
            else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
            else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }


            return {
                props: {
                    style: {
                        background: color
                    }
                },
                children: <div>{name}</div>
            };
        }
    },
    {
        title: 'T402',
        width: 30,
        dataIndex: 'T402',
        align: 'center',
        render(text, record) {
            let color = ''
            let name = ''

            if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
            else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
            else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
            else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
            else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
            else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
            else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
            else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }


            return {
                props: {
                    style: {
                        background: color
                    }
                },
                children: <div>{name}</div>
            };
        }
    },
    {
        title: 'T403',
        width: 30,
        dataIndex: 'T403',
        align: 'center',
        render(text, record) {
            let color = ''
            let name = ''

            if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
            else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
            else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
            else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
            else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
            else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
            else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
            else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }

            return {
                props: {
                    style: {
                        background: color
                    }
                },
                children: <div>{name}</div>
            };
        }
    },
    {
        title: 'T404',
        width: 30,
        dataIndex: 'T404',
        align: 'center',
        render(text, record) {
            let color = ''
            let name = ''

            if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
            else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
            else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
            else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
            else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
            else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
            else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
            else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }

            return {
                props: {
                    style: {
                        background: color
                    }
                },
                children: <div>{name}</div>
            };
        }
    },
    {
        title: 'T405',
        width: 30,
        dataIndex: 'T405',
        align: 'center',
        render(text, record) {
            let color = ''
            let name = ''

            if (parseInt(text) === 2) { color = "#fcaa58"; name = "Filling" }
            else if (parseInt(text) === 1) { color = "#fcf758"; name = "Standby" }
            else if (parseInt(text) === 0) { color = "#cc3e25"; name = "Stopped" }
            else if (parseInt(text) === 4) { color = "#04ba10"; name = "Homogenize" }
            else if (parseInt(text) === 3) { color = "#fc6358"; name = "Heating" }
            else if (parseInt(text) >= 5) { color = "#42b6f5"; name = "Cool" }
            else if (parseInt(text) === -1) { color = "#4032a8"; name = "Washing" }
            else if (parseInt(text) === -2) { color = "#78eba2"; name = "Filtering" }

            return {
                props: {
                    style: {
                        background: color
                    }
                },
                children: <div>{name}</div>
            };
        }
    },
]


export const columns1_1 = [
    {
        title: 'Start',
        width: 10,
        dataIndex: 'Start',
        align: 'center',
        // fixed: 'left'
    },
    {
        title: 'End',
        width: 10,
        dataIndex: 'End',
        align: 'center',

    },
]

export const columns1_2 = [
    {
        title: 'Parameter',
        width: 15,
        dataIndex: 'Parameter',
        key: 'Parameter',
        align: 'center'
    },
    {
        title: 'Start',
        width: 25,
        dataIndex: 'Start',
        key: 'Start',
        align: 'center'
    },
    {
        title: 'End',
        width: 25,
        dataIndex: 'End',
        key: 'End',
        align: 'center'
    },
]


export const columns2 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },

    {
        title: 'Filtering',
        children: [
            {
                title: 'Load Pressure (Kg/cm2)',
                width: 60,
                dataIndex: 'Filtration Load Pressure',
                key: 'Filtration Load Pressure',
                align: 'center'
            },
            {
                title: 'Squezz Time (Min)',
                width: 60,
                dataIndex: 'Filtration Squeeze Time',
                key: 'Filtration Squeeze Time',
                align: 'center'
            },
            {
                title: 'Squezz Pressure (Kg/cm2)',
                width: 60,
                dataIndex: 'Filtration Squeeze Pressure',
                key: 'Filtration Squeeze Pressure',
                align: 'center'
            },
        ]
    },

    {
        title: 'Air Pressure (Kg/cm2)',
        children: [
            {
                title: 'Stearine Temp',
                width: 60,
                dataIndex: 'Filtration Stearine Temperature',
                key: 'Filtration Stearine Temperature',
                align: 'center'
            },
            {
                title: 'Air regulator',
                width: 60,
                dataIndex: 'Air regulator',
                key: 'Air regulator',
                align: 'center'
            },
        ]
    },

    {
        title: 'Steam Header Pressure',
        width: 60,
        dataIndex: 'Steam Header Pressure',
        key: 'Steam Header Pressure',
        align: 'center'
    },

    {
        title: 'Chiller Water Tank Temp',
        width: 60,
        dataIndex: 'Chiller Water Tank Temperature',
        key: 'Chiller Water Tank Temperature',
        align: 'center'
    },

    {
        title: 'Hot Water Temp',
        width: 60,
        dataIndex: 'Hot Water Temperature',
        key: 'Hot Water Temperature',
        align: 'center'
    },

    {
        title: 'Cooling Tower Temp',
        width: 60,
        dataIndex: 'Cooling Tower Temperature',
        key: 'Cooling Tower Temperature',
        align: 'center'
    },

    {
        title: 'Homo 3 (Tonne)',
        width: 60,
        dataIndex: 'HOMO3',
        key: 'HOMO3',
        align: 'center'
    },
]

export const columns3 = [
    {
        title: 'DateTime',
        width: 70,
        dataIndex: 'DateTime',
        key: 'DateTime',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Batch',
        width: 100,
        dataIndex: 'Batch',
        key: 'Batch',
        align: 'center'
    },
    {
        title: 'Name',
        width: 100,
        dataIndex: 'Operator Name',
        key: 'Operator Name',
        align: 'center'
    },
    {
        title: 'Lab Operator Name',
        width: 100,
        dataIndex: 'Lab Operator Name',
        key: 'Lab Operator Name',
        align: 'center'
    },
    {
        title: 'Cryst No.',
        width: 40,
        dataIndex: 'Cryst No.',
        key: 'Cryst No.',
        align: 'center',
    },
    {
        title: 'Loading Time',
        width: 70,
        dataIndex: 'Loading Time',
        key: 'Loading Time',
        align: 'center',

    },
    {
        title: 'Cooling Time',
        width: 70,
        dataIndex: 'Cooling Time',
        key: 'Cooling Time',
        align: 'center',
    },
    {
        title: 'RBDPO',
        children: [
            {
                title: 'FFA',
                width: 60,
                dataIndex: 'RBDPO FFA',
                key: 'RBDPO FFA',
                align: 'center'
            },
            {
                title: 'Col(R)',
                width: 60,
                dataIndex: 'RBDPO Col(R)',
                key: 'RBDPO Col(R)',
                align: 'center'
            },
            {
                title: 'I.V.',
                width: 60,
                dataIndex: 'RBDPO I.V.',
                key: 'RBDPO I.V.',
                align: 'center'
            },
        ]
    },
    {
        title: 'Filtration Time',
        width: 70,
        dataIndex: 'Filtration Time',
        key: 'Filtration Time',
        align: 'center',
    },

    {
        title: 'OLEIN',
        children: [
            {
                title: 'FFA',
                width: 60,
                dataIndex: 'OLEIN FFA',
                key: 'OLEIN FFA',
                align: 'center'
            },
            {
                title: 'Col(R)',
                width: 60,
                dataIndex: 'OLEIN Col(R)',
                key: 'OLEIN Col(R)',
                align: 'center'
            },
            {
                title: 'I.V.',
                width: 60,
                dataIndex: 'OLEIN I.V.',
                key: 'OLEIN I.V.',
                align: 'center'
            },
            {
                title: 'C.P.(C)',
                width: 60,
                dataIndex: 'OLEIN C.P.',
                key: 'OLEIN C.P.',
                align: 'center'
            },
            {
                title: 'Yield(%)',
                width: 60,
                dataIndex: 'OLEIN Yield(%)',
                key: 'OLEIN Yield(%)',
                align: 'center'
            },
            {
                title: 'Storage',
                width: 60,
                dataIndex: 'OLEIN Storage',
                key: 'OLEIN Storage',
                align: 'center'
            },
        ]
    },

    {
        title: 'STEARIN',
        children: [
            {
                title: 'FFA',
                width: 60,
                dataIndex: 'STEARIN FFA',
                key: 'STEARIN FFA',
                align: 'center'
            },
            {
                title: 'Col(R)',
                width: 60,
                dataIndex: 'STEARIN Col(R)',
                key: 'STEARIN Col(R)',
                align: 'center'
            },
            {
                title: 'I.V.',
                width: 60,
                dataIndex: 'STEARIN I.V.',
                key: 'STEARIN I.V.',
                align: 'center'
            },
            {
                title: 'VM(%)',
                width: 60,
                dataIndex: 'STEARIN VM(%)',
                key: 'STEARIN VM(%)',
                align: 'center'
            },
            {
                title: 'M.P.(C)',
                width: 60,
                dataIndex: 'STEARIN M.P.',
                key: 'STEARIN M.P.',
                align: 'center'
            },
            {
                title: 'Storage',
                width: 60,
                dataIndex: 'STEARIN Storage',
                key: 'STEARIN Storage',
                align: 'center'
            },
        ]
    },
]


export const columns3_1 = [

    {
        title: 'Shift',
        width: 30,
        dataIndex: 'Shift',
        key: 'Shift',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Datetime',
        children: [
            {
                title: 'From',
                width: 40,
                dataIndex: 'From',
                key: 'From',
                align: 'center'
            },
            {
                title: 'To',
                width: 40,
                dataIndex: 'To',
                key: 'To',
                align: 'center'
            },
        ]
    },
    {
        title: 'Total (hr)',
        children: [
            {
                title: 'Hour',
                width: 40,
                dataIndex: 'Hour',
                key: 'Hour',
                align: 'center'
            },
            {
                title: 'Minute',
                width: 40,
                dataIndex: 'Minute',
                key: 'Minute',
                align: 'center'
            },
        ]
    },
    {
        title: 'Reason',
        width: 200,
        dataIndex: 'Reason',
        key: 'Reason',
        align: 'center',
    },

]


export const columns3_2 = [

    {
        title: 'Parameter',
        width: 30,
        dataIndex: 'Parameter',
        key: 'Parameter',
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'Initial',
        width: 30,
        dataIndex: 'Initial',
        key: 'Initial',
        align: 'center'
    },
    {
        title: 'Final',
        width: 30,
        dataIndex: 'Final',
        key: 'Final',
        align: 'center'
    },
    {
        title: 'Difference',
        width: 30,
        dataIndex: 'Difference',
        key: 'Difference',
        align: 'center',
    },
    {
        title: 'Unit per MT',
        width: 30,
        dataIndex: 'Unit per MT',
        key: 'Unit per MT',
        align: 'center',

    },
]


export const columns3_3 = [
    {
        title: 'Batch',
        width: 25,
        dataIndex: 'Batch',
        key: 'Batch',
        align: 'center'
    },
    {
        title: 'Number',
        width: 25,
        dataIndex: 'Number',
        key: 'Number',
        align: 'center'
    },
]
