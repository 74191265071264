import React, { useState, useEffect } from 'react'
import { RealtimeData_Request } from '../../api'
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap'
import OiltekBF from './HMI/OiltekBF';
import OiltekDeo1 from './HMI/OiltekDeo1';
import OiltekFatty from './HMI/OiltekFatty';
import OiltekDeo2 from './HMI/OiltekDeo2';
import { OTKModelPage1, OTKModelPage2, OTKModelPage3 } from '../../DataRequest/OiltekModel'
import FullHMIWrapper from '../Realtime/FullHMIWrapper'

let PG1DataModel = OTKModelPage1
let PG2DataModel = OTKModelPage2
let PG3DataModel = OTKModelPage3


let RealTimeValue = {
    "PG1HMI": PG1DataModel,
    "PG2HMI": PG2DataModel,
    "PG3HMI": PG3DataModel,
    "PG1Table": [],
    "PG2Table": [],
    "PG3Table": [],
    "PG1Graph": [],
    "PG2Graph": [],
    "PG3Graph": [],
}


const RTCardOiltek = () => {
    const [state, setState] = useState({ count: 0 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        RealTimeValue[HMIName] = RealTimeValue[TableName]
        // console.log(RealTimeValue[HMIName])
    }

    const RenderNewData = () => {

        DataRequest([], 'FGV', '1', 'Oiltek', 'PG1HMI', "PG1Table")
        DataRequest([], 'FGV', '2', 'Oiltek', 'PG2HMI', "PG2Table")
        DataRequest([], 'FGV', '3', 'Oiltek', 'PG3HMI', "PG3Table")

        setState(({ count }) => ({ count: count + 1 }));
    }

    useEffect(() => {
        RenderNewData()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [])


    return (
        <div>
            <FullHMIWrapper Tab={['Bleaching', 'Deodorization 1', 'Deodorization 2', 'FattyAcid']}>
                <OiltekBF key={'Bleaching'} Parameter={RealTimeValue['PG1HMI']} />
                <OiltekDeo1 key={'Deodorization 1'} Parameter={RealTimeValue['PG2HMI']} />
                <OiltekDeo2 key={'Deodorization 2'} Parameter={RealTimeValue['PG2HMI']} />
                <OiltekFatty key={'FattyAcid'} Parameter={RealTimeValue['PG2HMI']} Expara={RealTimeValue['PG3HMI']} />
            </FullHMIWrapper>
        </div>
    )
}

export default RTCardOiltek
