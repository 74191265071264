import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { AlfaModelPage1 } from '../../../DataRequest/AlfaModel'

const AlfaBleaching = (props) => {
  let Para = props.Parameter
  Para = DataMapping(AlfaModelPage1, props.Parameter)

    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1156.34 784.87"
          width='100%'
          height='100%'
        >
          <defs>
            <symbol
              id="prefix__Turbocharger"
              data-name="Turbocharger"
              viewBox="0 0 47.55 24.81"
            >
              <path
                className="prefix__cls-1"
                d="M24.08 8.77h18.75v-5.1H24.08a2.55 2.55 0 100 5.1z"
              />
              <circle className="prefix__cls-1" cx={23.48} cy={14.05} r={10.38} />
              <path
                className="prefix__cls-1"
                d="M23.48 11.51H4.73v5.1h18.75a2.55 2.55 0 100-5.1zM.38 8.21h4.35V19.9H.38zM42.83.38h4.35v11.68h-4.35V.38z"
              />
            </symbol>
            <symbol
              id="prefix__Valve_small"
              data-name="Valve small"
              viewBox="0 0 28.51 21.14"
            >
              <path
                className="prefix__cls-2"
                d="M27.76.75l-5.33 9.82L17.09.75h10.67zM17.09 20.39l5.33-9.82 5.34 9.82H17.09zM.75.9h6.78v19.33H.75zM22.43 10.57H7.53"
              />
            </symbol>
            <symbol
              id="prefix__Valve_Auto"
              data-name="Valve Auto"
              viewBox="0 0 49.72 33.18"
            >
              <path
                className="prefix__cls-2"
                d="M.75 32.43l8.6-15.84 8.61 15.84H.75zM17.96.75l-8.6 15.84L.75.75h17.21zM33.38 32.18V1a15.59 15.59 0 110 31.18zM9.35 16.59h24.03"
              />
            </symbol>
            <linearGradient
              id="prefix__linear-gradient"
              x1={491.51}
              y1={293.89}
              x2={539.18}
              y2={293.89}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.71} stopColor="#c4c6c8" />
              <stop offset={0.71} stopColor="#e0e0e0" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-2"
              x1={493.23}
              y1={303.8}
              x2={537.46}
              y2={303.8}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.72} stopColor="#c4c6c8" />
              <stop offset={0.72} stopColor="#e0e0e0" />
            </linearGradient>
            <style>
              {
                ".prefix__cls-1,.prefix__cls-10,.prefix__cls-13,.prefix__cls-20{fill:#c4c6c8}.prefix__cls-1,.prefix__cls-19,.prefix__cls-2{stroke:#414042}.prefix__cls-1,.prefix__cls-15,.prefix__cls-19,.prefix__cls-2{stroke-linecap:round}.prefix__cls-1,.prefix__cls-10,.prefix__cls-11,.prefix__cls-15,.prefix__cls-16,.prefix__cls-19,.prefix__cls-2,.prefix__cls-20,.prefix__cls-23,.prefix__cls-3,.prefix__cls-4{stroke-linejoin:round}.prefix__cls-1,.prefix__cls-19{stroke-width:.75px}.prefix__cls-2{fill:#e6e7e8;stroke-width:1.5px}.prefix__cls-15,.prefix__cls-16,.prefix__cls-19,.prefix__cls-23,.prefix__cls-3,.prefix__cls-4{fill:none}.prefix__cls-16,.prefix__cls-3,.prefix__cls-4{stroke:#58595b}.prefix__cls-16,.prefix__cls-3{stroke-width:6px}.prefix__cls-4{stroke-width:3px}.prefix__cls-10,.prefix__cls-11,.prefix__cls-15,.prefix__cls-20,.prefix__cls-23{stroke:#6d6e71}.prefix__cls-10,.prefix__cls-11,.prefix__cls-15,.prefix__cls-23{stroke-width:2px}.prefix__cls-11,.prefix__cls-14{fill:#e0e0e0}.prefix__cls-16{stroke-linecap:square}.prefix__cls-18{fill:#414042}.prefix__cls-24{fill:#fff200}.prefix__cls-25{font-size:25px}.prefix__cls-25,.prefix__cls-27,.prefix__cls-31{fill:#231f20;font-family:3ds,3ds}.prefix__cls-27{font-size:12px}.prefix__cls-28{letter-spacing:-.01em}.prefix__cls-29{letter-spacing:.01em}.prefix__cls-30{letter-spacing:0}.prefix__cls-31{font-size:16px}.prefix__cls-32{letter-spacing:-.02em}.prefix__cls-34{letter-spacing:.01em}"
              }
            </style>
          </defs>
          <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Piping">
              <path
                className="prefix__cls-3"
                d="M45.96 128.34H3M46 214.34H9a6 6 0 01-6-6V83.43M86.34 206.5h28.98M175.17 555.59h20.57M115.32 258.07V126.5a6 6 0 00-6-6H92.76M115.32 278.24v45.22M195.74 622.75v37.49a6 6 0 006 6h14.33M160.17 469.59h29.57a6 6 0 016 6v123.58M245.57 666.24h59.64M305.21 638.41h-15a6 6 0 01-6-6v-113.6M351.14 638.41h25.93a6 6 0 006-6v-276a6 6 0 016-6H491.4M515.23 389.86v67.89a6 6 0 006 6H616a6 6 0 006-6v-12M914.75 612.26v27.24M926.75 649.71v19.42a6 6 0 006 6h47.69M902.75 649.71v105.41a6 6 0 006 6h71.69M1027.25 667.29h81.74M1027.25 753.29h80.49M1063.87 706.66v-39.37M1063.87 726.3v26.99M1046.9 588.16v-14.31a6 6 0 00-6-6h-74.49M1046.9 611.92v23.26a6 6 0 006 6h56.1M620.19 166.68v58.73a6 6 0 006 6h158.46M708.05 175.28v56.13M781.07 403.07H644.73M914.75 418.48v-9.41a6 6 0 00-6-6H799.07M921.71 140.41h134.39a6 6 0 016 6v79a6 6 0 01-6 6h-6"
              />
              <path
                className="prefix__cls-3"
                d="M773.32 231.41v-85a6 6 0 016-6h133.39M115.32 477.43h13.04M917.09 183.16v-71.64a6 6 0 016-6h42M115.32 425.24v132.19a6 6 0 006 6h7M351.34 666.22h24.73a6 6 0 016 6v37.85M262.2 686.47h16a6 6 0 016 6v35.6a6 6 0 01-6 6H156.74M216.07 686.47h-59.33M433.47 224.59h25V349.9"
              />
              <path className="prefix__cls-4" d="M388.07 507.24h2" />
              <path
                strokeDasharray="3.47 3.47"
                strokeWidth={3}
                stroke="#58595b"
                fill="none"
                strokeLinejoin="round"
                d="M393.54 507.24h19.08"
              />
              <path
                className="prefix__cls-4"
                d="M414.35 507.24h2M315.81 507.24h2"
              />
              <path
                strokeDasharray="3.88 3.88"
                strokeWidth={3}
                stroke="#58595b"
                fill="none"
                strokeLinejoin="round"
                d="M321.69 507.24h52.44"
              />
              <path
                className="prefix__cls-4"
                d="M376.07 507.24h2M388.07 610.91h2"
              />
              <path
                strokeDasharray="3.47 3.47"
                strokeWidth={3}
                stroke="#58595b"
                fill="none"
                strokeLinejoin="round"
                d="M393.54 610.91h19.08"
              />
              <path
                className="prefix__cls-4"
                d="M414.35 610.91h2M289.24 610.91h2"
              />
              <path
                strokeDasharray="4.04 4.04"
                strokeWidth={3}
                stroke="#58595b"
                fill="none"
                strokeLinejoin="round"
                d="M295.28 610.91h78.77"
              />
              <path
                className="prefix__cls-4"
                d="M376.07 610.91h2M227.03 610.91h2"
              />
              <path
                strokeDasharray="3.71 3.71"
                strokeWidth={3}
                stroke="#58595b"
                fill="none"
                strokeLinejoin="round"
                d="M232.74 610.91h42.64"
              />
              <path className="prefix__cls-4" d="M277.24 610.91h2" />
              <path
                className="prefix__cls-3"
                d="M513.89 69.9h595.84a6 6 0 016 6v443.76a6 6 0 01-6 6h-153"
              />
              <path
                className="prefix__cls-3"
                d="M1034.43 403.07H957.2a6 6 0 00-6 6v37.58M1106.46 231.41h-20a6 6 0 00-6 6v159.66a6 6 0 01-6 6h-22.05M412.74 224.59H269.79v25.83M380.29 250.12v-25.53M195.74 216.88v86.92h74.05v-25.21M380.29 278.59v25.21h-110.5"
              />
            </g>
            <g id="prefix__Component">
              <path
                className="prefix__cls-10"
                d="M921.68 506l-123.06-29.6a18.94 18.94 0 01-14.56-18.47v-22.54h12v22.51a7 7 0 005.36 6.81l123.06 29.6z"
              />
              <path
                className="prefix__cls-11"
                d="M911.82 506l123.06-29.6a18.94 18.94 0 0014.56-18.47v-22.54h-12v22.51a7 7 0 01-5.36 6.81L909 494.31z"
              />
              <path
                fill="#e0e0e0"
                strokeWidth={2}
                stroke="#6d6e71"
                strokeLinejoin="round"
                strokeLinecap="round"
                d="M926.92 436.63h-24.33l9.27-22.15h5.8l9.26 22.15z"
              />
              <path
                className="prefix__cls-13"
                d="M914.75 429.7a95.4 95.4 0 00-42.75 9.9 16.19 16.19 0 00-8.87 14.48v133.8a16.19 16.19 0 008.87 14.48 97.37 97.37 0 0085.57 0 16.19 16.19 0 008.87-14.48v-133.8a16.19 16.19 0 00-8.87-14.48 95.4 95.4 0 00-42.82-9.9z"
              />
              <path
                className="prefix__cls-14"
                d="M966.41 454.08v133.79a16.18 16.18 0 01-8.87 14.48A92.11 92.11 0 01936 610a17.73 17.73 0 009.53-15.76V447.77A17.69 17.69 0 00936 432a92.42 92.42 0 0121.54 7.6 16.19 16.19 0 018.87 14.48z"
              />
              <path
                className="prefix__cls-15"
                d="M914.75 429.7a95.4 95.4 0 00-42.75 9.9 16.19 16.19 0 00-8.87 14.48v133.8a16.19 16.19 0 008.87 14.48 97.37 97.37 0 0085.57 0 16.19 16.19 0 008.87-14.48v-133.8a16.19 16.19 0 00-8.87-14.48 95.4 95.4 0 00-42.82-9.9z"
              />
              <path
                className="prefix__cls-13"
                d="M622 287.22a68.93 68.93 0 00-30.91 7.16 11.7 11.7 0 00-6.41 10.46v123.25a11.7 11.7 0 006.41 10.46 70.35 70.35 0 0061.82 0 11.7 11.7 0 006.41-10.46V304.84a11.7 11.7 0 00-6.41-10.46 68.93 68.93 0 00-30.91-7.16z"
              />
              <path
                className="prefix__cls-14"
                d="M659.3 304.84v123.24a11.69 11.69 0 01-6.41 10.46 66.55 66.55 0 01-15.54 5.46 12.81 12.81 0 006.89-11.39V300.28a12.78 12.78 0 00-6.91-11.39 66.77 66.77 0 0115.56 5.49 11.7 11.7 0 016.41 10.46z"
              />
              <path
                className="prefix__cls-15"
                d="M622 287.22a68.93 68.93 0 00-30.91 7.16 11.7 11.7 0 00-6.41 10.46v123.25a11.7 11.7 0 006.41 10.46 70.35 70.35 0 0061.82 0 11.7 11.7 0 006.41-10.46V304.84a11.7 11.7 0 00-6.41-10.46 68.93 68.93 0 00-30.91-7.16z"
              />
              <path
                className="prefix__cls-13"
                d="M515.23 15.31a34.46 34.46 0 00-15.46 3.58 5.85 5.85 0 00-3.2 5.23v91.56a5.85 5.85 0 003.2 5.23 35.17 35.17 0 0030.91 0 5.85 5.85 0 003.2-5.23V24.12a5.85 5.85 0 00-3.2-5.23 34.46 34.46 0 00-15.45-3.58z"
              />
              <path
                className="prefix__cls-14"
                d="M533.89 24.12v91.56a5.85 5.85 0 01-3.2 5.23 33.28 33.28 0 01-7.77 2.75 6.41 6.41 0 003.44-5.69V21.84a6.39 6.39 0 00-3.45-5.7 33.39 33.39 0 017.78 2.75 5.85 5.85 0 013.2 5.23z"
              />
              <path
                className="prefix__cls-15"
                d="M515.23 15.31a34.46 34.46 0 00-15.46 3.58 5.85 5.85 0 00-3.2 5.23v91.56a5.85 5.85 0 003.2 5.23 35.17 35.17 0 0030.91 0 5.85 5.85 0 003.2-5.23V24.12a5.85 5.85 0 00-3.2-5.23 34.46 34.46 0 00-15.45-3.58z"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(980.07 661.07)"
                xlinkHref="#prefix__Turbocharger"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(980.07 747.07)"
                xlinkHref="#prefix__Turbocharger"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(45.58 114.28)"
                xlinkHref="#prefix__Turbocharger"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(45.58 200.28)"
                xlinkHref="#prefix__Turbocharger"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(127.99 463.37)"
                xlinkHref="#prefix__Turbocharger"
              />
              <use
                width={47.55}
                height={24.81}
                transform="translate(127.99 549.37)"
                xlinkHref="#prefix__Turbocharger"
              />
              <path
                className="prefix__cls-1"
                d="M894.18 639.5h41.15v10.21h-41.15zM935.33 638.76h4.35v11.69h-4.35z"
              />
              <path
                className="prefix__cls-1"
                d="M889.83 638.76h4.35v11.69h-4.35z"
              />
              <use
                width={28.51}
                height={21.14}
                transform="translate(767.56 312.07)"
                xlinkHref="#prefix__Valve_small"
              />
              <use
                width={28.51}
                height={21.14}
                transform="rotate(180 68.745 139.12)"
                xlinkHref="#prefix__Valve_small"
              />
              <use
                width={28.51}
                height={21.14}
                transform="rotate(90 115.86 317.71)"
                xlinkHref="#prefix__Valve_small"
              />
              <path
                className="prefix__cls-13"
                d="M148.04 412.35l-32.72 18.9-32.72-18.9v-88.89h65.44v88.89z"
              />
              <path
                className="prefix__cls-14"
                d="M148.04 323.46v88.89l-15.95 9.21v-98.1h15.95z"
              />
              <path
                className="prefix__cls-15"
                d="M148.04 412.35l-32.72 18.9-32.72-18.9v-88.89h65.44v88.89z"
              />
              <path
                className="prefix__cls-13"
                d="M223.55 216.34l-27.81 16.06-27.82-16.06v-54.99h55.63v54.99z"
              />
              <path
                className="prefix__cls-14"
                d="M223.55 161.35v54.99l-13.56 7.83v-62.82h13.56z"
              />
              <path
                className="prefix__cls-15"
                d="M223.55 216.34l-27.81 16.06-27.82-16.06v-54.99h55.63v54.99z"
              />
              <path
                className="prefix__cls-13"
                d="M652.7 157.47l-32.72 18.89-32.72-18.89v-70.9h65.44v70.9z"
              />
              <path
                className="prefix__cls-14"
                d="M652.7 86.58v70.89l-15.95 9.21v-80.1h15.95z"
              />
              <path
                className="prefix__cls-15"
                d="M652.7 157.47l-32.72 18.89-32.72-18.89v-70.9h65.44v70.9z"
              />
              <path
                className="prefix__cls-13"
                d="M740.78 157.47l-32.73 18.89-32.72-18.89v-70.9h65.45v70.9z"
              />
              <path
                className="prefix__cls-14"
                d="M740.77 86.58v70.89l-15.95 9.21v-80.1h15.95z"
              />
              <path
                className="prefix__cls-15"
                d="M740.78 157.47l-32.73 18.89-32.72-18.89v-70.9h65.45v70.9z"
              />
              <use
                width={49.72}
                height={33.18}
                transform="matrix(.75 0 0 .75 188.31 598.42)"
                xlinkHref="#prefix__Valve_Auto"
              />
              <use
                width={49.72}
                height={33.18}
                transform="matrix(.75 0 0 .75 277.08 494.49)"
                xlinkHref="#prefix__Valve_Auto"
              />
              <use
                width={49.72}
                height={33.18}
                transform="matrix(.75 0 0 .75 1039.89 587.6)"
                xlinkHref="#prefix__Valve_Auto"
              />
              <path
                className="prefix__cls-1"
                d="M221.51 658.72h35.25v35.25h-35.25zM256.76 656.47h5.44v39.75h-5.44zM216.07 656.47h5.44v39.75h-5.44z"
              />
              <path
                className="prefix__cls-3"
                d="M221.51 666.22h35.25M256.76 686.47h-35.25"
              />
              <path
                className="prefix__cls-1"
                d="M310.65 634.66h35.25v35.25h-35.25z"
              />
              <path
                className="prefix__cls-16"
                d="M328.27 652.28l17.63 10.13M310.65 642.16l17.62 10.12"
              />
              <path
                transform="rotate(-29.87 328.363 652.306)"
                fill="#58595b"
                d="M305.42 648.44h45.79v7.63h-45.79z"
              />
              <path
                className="prefix__cls-13"
                d="M345.9 638.91l2.8 4.88-38 21.81-2.8-4.88 38-21.81m.74-2.73l-1.73 1-38 21.81-1.73 1 1 1.73 2.8 4.88 1 1.73 1.73-1 38-21.81 1.73-1-1-1.73-2.8-4.88-1-1.73z"
              />
              <path
                className="prefix__cls-1"
                d="M305.21 632.41h5.44v39.75h-5.44zM345.9 632.41h5.44v39.75h-5.44zM978.98 101.11l21.01-3.52v-7.65h-21.01v11.17z"
              />
              <circle className="prefix__cls-1" cx={979.22} cy={105.52} r={15.58} />
              <circle className="prefix__cls-1" cx={979.22} cy={105.52} r={13.09} />
              <circle className="prefix__cls-18" cx={979.22} cy={105.52} r={3.21} />
              <path
                className="prefix__cls-19"
                d="M972.67 94.18l13.1 22.68M966.13 105.52h26.18M972.67 116.86l13.1-22.68"
              />
              <circle className="prefix__cls-1" cx={708.05} cy={202.14} r={15.58} />
              <circle className="prefix__cls-1" cx={708.05} cy={202.14} r={13.09} />
              <circle className="prefix__cls-18" cx={708.05} cy={202.14} r={3.21} />
              <path
                className="prefix__cls-19"
                d="M701.51 190.8l13.09 22.68M694.96 202.14h26.19M701.51 213.48l13.09-22.68"
              />
              <circle className="prefix__cls-1" cx={619.98} cy={202.14} r={15.58} />
              <circle className="prefix__cls-1" cx={619.98} cy={202.14} r={13.09} />
              <circle className="prefix__cls-18" cx={619.98} cy={202.14} r={3.21} />
              <path
                className="prefix__cls-19"
                d="M613.43 190.8l13.1 22.68M606.89 202.14h26.18M613.43 213.48l13.1-22.68"
              />
              <path
                className="prefix__cls-20"
                d="M505.99 262.6h18.71v11.65h-18.71zM515.32 256.76a14.85 14.85 0 019.38 3.44H506a14.66 14.66 0 019.32-3.44z"
              />
              <path
                fill="url(#prefix__linear-gradient)"
                strokeWidth={2}
                stroke="#6d6e71"
                strokeLinejoin="round"
                strokeLinecap="round"
                d="M491.51 291.17h47.67v5.44h-47.67z"
              />
              <path
                d="M536.48 303.49a29 29 0 01.82-6.88h-44.07a28.44 28.44 0 011 7.5 29 29 0 01-.82 6.88h44.06a28.44 28.44 0 01-.99-7.5z"
                fill="url(#prefix__linear-gradient-2)"
                strokeWidth={2}
                stroke="#6d6e71"
                strokeLinejoin="round"
                strokeLinecap="round"
              />
              <path
                className="prefix__cls-10"
                d="M521.22 283.68a29 29 0 01.82-6.88h-13.55a28.44 28.44 0 011 7.5 29 29 0 01-.82 6.88h13.53a28.44 28.44 0 01-.98-7.5z"
              />
              <path
                className="prefix__cls-20"
                d="M503.89 274.41h22.92v2.4h-22.92zM503.89 260.2h22.92v2.4h-22.92z"
              />
              <path
                className="prefix__cls-13"
                d="M539.18 375.5a7.69 7.69 0 01-2.92 6.05c-4.08 3.2-12 8.31-20.91 8.31s-16.83-5.11-20.91-8.31a7.69 7.69 0 01-2.92-6.05V311h47.67z"
              />
              <path
                className="prefix__cls-14"
                d="M539.18 311v64.5a7.66 7.66 0 01-2.93 6.05 40.89 40.89 0 01-13.75 7.27 9.55 9.55 0 002.56-6.53V311z"
              />
              <path
                className="prefix__cls-15"
                d="M539.18 375.5a7.69 7.69 0 01-2.92 6.05c-4.08 3.2-12 8.31-20.91 8.31s-16.83-5.11-20.91-8.31a7.69 7.69 0 01-2.92-6.05V311h47.67z"
              />
              <use
                width={28.51}
                height={21.14}
                transform="translate(768.23 413.48)"
                xlinkHref="#prefix__Valve_small"
              />
              <use
                width={28.51}
                height={21.14}
                transform="translate(1041.86 705.16)"
                xlinkHref="#prefix__Valve_small"
              />
              <use
                id="prefix__Valve_small-13"
                data-name="Valve small"
                width={28.51}
                height={21.14}
                transform="rotate(180 533.125 166.605)"
                xlinkHref="#prefix__Valve_small"
              />
              <use
                width={28.51}
                height={21.14}
                transform="rotate(180 532.9 217.31)"
                xlinkHref="#prefix__Valve_small"
              />
              <path className="prefix__cls-10" d="M784.07 334.21h12v78.41h-12z" />
              <path className="prefix__cls-11" d="M1037.43 334.21h12v78.41h-12z" />
              <path
                className="prefix__cls-10"
                d="M943.51 204.49h-52.85l5-21.33h42.85l5 21.33z"
              />
              <path
                className="prefix__cls-13"
                d="M784.07 204.49v106.57h12v-37.79l121.02-29.11 121.01 29.11v37.79h12V204.49H784.07z"
              />
              <path
                className="prefix__cls-14"
                d="M1050.1 204.49v106.58h-12v-37.8l-37.69-9.07v-59.71h49.69z"
              />
              <path
                className="prefix__cls-23"
                d="M784.07 204.49v106.57h12v-37.79l121.02-29.11 121.01 29.11v37.79h12V204.49H784.07z"
              />
              <path
                className="prefix__cls-13"
                d="M1115.74 209.31l-9.28 5.53v33.14l9.28 5.52 9.28-5.52v-33.14l-9.28-5.53z"
              />
              <path
                className="prefix__cls-14"
                d="M1115.74 209.31v44.19l9.28-5.52v-33.14l-9.28-5.53z"
              />
              <path
                className="prefix__cls-23"
                d="M1115.74 209.31l-9.28 5.53v33.14l9.28 5.52 9.28-5.52v-33.14l-9.28-5.53z"
              />
              <path className="prefix__cls-10" d="M352 258.41h21.35v12.18H352z" />
              <path
                className="prefix__cls-11"
                d="M373.35 256.18h13.88v16.65h-13.88zM375.21 250.42h10.16l.65 5.76h-11.46l.65-5.76zM375.21 278.59h10.16l.65-5.76h-11.46l.65 5.76z"
              />
              <circle className="prefix__cls-13"  cx={352} cy={264.5} r={19.49}/>
              <path
                className="prefix__cls-14"
                d="M371.5 264.5a19.5 19.5 0 01-12.5 18.21v-36.42a19.5 19.5 0 0112.5 18.21z"
              />
              <circle className="prefix__cls-15" cx={352} cy={264.5} r={19.49} />
              <path className="prefix__cls-15" d="M358.96 246.29v36.42" />
              <path
                className="prefix__cls-10"
                d="M241.51 258.41h21.35v12.18h-21.35z"
              />
              <path
                className="prefix__cls-11"
                d="M262.85 256.18h13.88v16.65h-13.88zM264.71 250.42h10.16l.65 5.76h-11.46l.65-5.76zM264.71 278.59h10.16l.65-5.76h-11.46l.65 5.76z"
              />
              <circle className="prefix__cls-13" cx={241.51} cy={264.5} r={19.49} />
              <path
                className="prefix__cls-14"
                d="M261 264.5a19.5 19.5 0 01-12.54 18.21v-36.42A19.5 19.5 0 01261 264.5z"
              />
              <circle className="prefix__cls-15" cx={241.51} cy={264.5} r={19.49} />
              <path className="prefix__cls-15" d="M248.46 246.29v36.42" />
            </g>
            <g id="prefix__Label">
              <rect
                className="prefix__cls-24"
                x={416.14}
                y={494.54}
                width={115.06}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(417.53 515.34)">
                {`${Para['PHE CPO Out H3PO4 26E01 Temperature'].PV}`}
                <tspan x={75.2} y={0} fontSize={24}>
                  {"\xB0C"}
                </tspan>
              </text>
              <rect
                className="prefix__cls-24"
                x={416.35}
                y={584.59}
                width={129.96}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(418.63 605.39)">
                {`${Para['CPO 26E00 Flow Rate'].PV} t/h`}
              </text>
              <rect
                className="prefix__cls-24"
                x={416.35}
                y={611.59}
                width={103.78}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(418.87 632.39)">
                {"20973 t"}
              </text>
              <text className="prefix__cls-27" transform="translate(48.13 153.29)">
                {"CP0P1"}
              </text>
              <text className="prefix__cls-27" transform="translate(48.13 239.39)">
                {"CP0P2"}
              </text>
              <text className="prefix__cls-27" transform="translate(64.75 271.08)">
                {"V6T00"}
              </text>
              <text className="prefix__cls-27" transform="translate(98.27 360.17)">
                {"26T00"}
              </text>
              <text className="prefix__cls-27" transform="translate(131.01 500.99)">
                {"26P00A"}
              </text>
              <text className="prefix__cls-27" transform="translate(130.54 585.58)">
                {"26P00B"}
              </text>
              <text className="prefix__cls-27" transform="translate(145.08 616.81)">
                {"26FY01"}
              </text>
              <text className="prefix__cls-27" transform="translate(218.08 709.31)">
                {"26"}
                <tspan className="prefix__cls-28" x={14.52} y={0}>
                  {"E"}
                </tspan>
                <tspan className="prefix__cls-29" x={21.23} y={0}>
                  {"O"}
                </tspan>
                <tspan className="prefix__cls-30" x={29.53} y={0}>
                  {"O"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(309.25 686.47)">
                {"26"}
                <tspan className="prefix__cls-28" x={14.52} y={0}>
                  {"E"}
                </tspan>
                <tspan x={21.23} y={0}>
                  {"O1"}
                </tspan>
              </text>
              <text className="prefix__cls-31" transform="translate(386.72 487.89)">
                {"PHE CPO OUT TEMP"}
              </text>
              <rect
                className="prefix__cls-24"
                x={192.48}
                y={310.96}
                width={98.05}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(193.72 331.76)">
                {`${Para['Bleaching Earth Setting'].PV} %`}
              </text>
              <rect
                className="prefix__cls-24"
                x={302.98}
                y={310.96}
                width={98.05}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(304.22 331.76)">
              {`${Para['Bleaching Earth Setting'].PV} %`}
              </text>
              <text className="prefix__cls-31" transform="translate(403.84 581.59)">
                {"CPO 26E00 F"}
                <tspan className="prefix__cls-32" x={95.55} y={0}>
                  {"L"}
                </tspan>
                <tspan x={103.47} y={0}>
                  {"O"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(319.46 500.99)">
                {"26"}
                <tspan x={14.52} y={0} letterSpacing=".01em">
                  {"T"}
                </tspan>
                <tspan className="prefix__cls-34" x={21.6} y={0}>
                  {"V"}
                </tspan>
                <tspan x={28.96} y={0}>
                  {"O1"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(494.85 250.24)">
                {"26MO1"}
              </text>
              <text className="prefix__cls-27" transform="translate(409.86 240)">
                {"V623"}
              </text>
              <text className="prefix__cls-27" transform="translate(323.61 296)">
                {"26P32B"}
              </text>
              <text className="prefix__cls-27" transform="translate(215.28 296)">
                {"26P32A"}
              </text>
              <text className="prefix__cls-27" transform="translate(177.8 178.49)">
                {"26T23"}
              </text>
              <text className="prefix__cls-27" transform="translate(494.96 9.99)">
                {"26"}
                <tspan className="prefix__cls-29" x={14.52} y={0}>
                  {"Q"}
                </tspan>
                <tspan x={22.85} y={0}>
                  {"O1"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(602.21 112.24)">
                {"SI"}
                <tspan className="prefix__cls-32" x={10.45} y={0}>
                  {"L"}
                </tspan>
                <tspan x={16.39} y={0}>
                  {"O1"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(692.48 112.24)">
                {"SI"}
                <tspan className="prefix__cls-32" x={10.45} y={0}>
                  {"L"}
                </tspan>
                <tspan x={16.39} y={0}>
                  {"O2"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(603.13 281.99)">
                {"26RO1"}
              </text>
              <text className="prefix__cls-27" transform="translate(715.38 428.96)">
                {"26V032A"}
              </text>
              <text className="prefix__cls-27" transform="translate(715.38 327.55)">
                {"26V031A"}
              </text>
              <text className="prefix__cls-27" transform="translate(986.38 428.96)">
                {"26V032B"}
              </text>
              <text className="prefix__cls-27" transform="translate(898.71 219.96)">
                {"26T03"}
              </text>
              <text className="prefix__cls-27" transform="translate(898.71 197.8)">
                {"26F09"}
              </text>
              <text className="prefix__cls-27" transform="translate(984.71 327.55)">
                {"26V031B"}
              </text>
              <text className="prefix__cls-27" transform="translate(959.53 130.22)">
                {"26Q02"}
              </text>
              <text
                className="prefix__cls-27"
                transform="translate(1120.38 261.22)"
              >
                {"26C01"}
              </text>
              <text className="prefix__cls-27" transform="translate(982.86 697.5)">
                {"26P01"}
              </text>
              <text className="prefix__cls-27" transform="translate(982.39 782.09)">
                {"26P02"}
              </text>
              <text
                className="prefix__cls-27"
                transform="translate(1073.39 720.64)"
              >
                {"V6F1/2"}
              </text>
              <text
                className="prefix__cls-27"
                transform="translate(1077.94 603.98)"
              >
                {"26"}
                <tspan x={14.52} y={0} letterSpacing="-.09em">
                  {"L"}
                </tspan>
                <tspan className="prefix__cls-30" x={19.62} y={0}>
                  {"V01"}
                </tspan>
              </text>
              <text className="prefix__cls-27" transform="translate(897.18 454.65)">
                {"26R02"}
              </text>
              <rect
                className="prefix__cls-24"
                x={863.05}
                y={280.46}
                width={108.07}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(867.71 301.26)">
              {`${Para['Bleaching Earth Weight'].PV} Kg`}
              </text>
              <text className="prefix__cls-31" transform="translate(844.47 277.46)">
                {"BLEACHING EARTH"}
              </text>
              <rect
                className="prefix__cls-24"
                x={848.73}
                y={367.47}
                width={136.7}
                height={25}
                rx={3.9}
                ry={3.9}
              />
              <text className="prefix__cls-25" transform="translate(851.75 388.27)">
              {`${Para['VAC mm/Hg Torr'].PV} torr`}
              </text>
              <text className="prefix__cls-31" transform="translate(900.99 364.47)">
                <tspan className="prefix__cls-34">{"V"}</tspan>
                <tspan x={9.81} y={0}>
                  {"AC"}
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      )
}

export default AlfaBleaching
