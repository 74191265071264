import { Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { logdata_request } from '../../api'
import { DataSortTime, ExportExcelWorkBook, ExportExcelWorkBookFGV, GetDateTime, LogDataMappingName, LogDataMappingName1, LogDataMappingTime, SetDateRange } from '../../DataRequest/LogDataRequestWrap'
import { OTKModelPage1, OTKModelPage2, OTKModelPage3, Time } from '../../DataRequest/OiltekModel'
import ExportTable from '../Report/ExportTable'
import ReportTable from '../Report/ReportTable'
import { columns1, columns2, columns3 } from './OiltekColumbs'

let LogValue = {
    "LogOtk1": [],
    "LogOtk2": [],
    "LogOtk3": [],
    "LogOtk4": []

}

let ExcelFormat = [
     {
        startColumb:'C',
        startRow:'9',
        stopColumb: 'X',
        stopRow:'9',
        sheet:0,
        filename:'OTR.xlsx'
    },

    {
        startColumb:'B',
        startRow:'10',
        stopColumb: 'Z',
        stopRow:'10',
        sheet:1,
        filename:'OTR.xlsx'
    },

     {
        startColumb:'D',
        startRow:'10',
        stopColumb: 'W',
        stopRow:'9',
        sheet:2,
        filename:'OTR.xlsx'
    },

]

let setRange = []
setRange = GetDateTime(0, 1)

const OTKPG1 = Object.keys(OTKModelPage1)
const OTKPG2 = Object.keys(OTKModelPage2)
const OTKPG3 = Object.keys(OTKModelPage3)

const OiltekReportExport = () => {
    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        // console.log(setRange);
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 1, "LogOtk1", OTKModelPage1)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)

        // UpdateTableDataTime([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk4", OTKModelPage3)

        return () => {
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
         console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const UpdateTableDataTime = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)
        console.log(data);

        LogValue[BufferName] = LogDataMappingTime(Model, data)
         console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const SetTimeRange = async(value) => {
        const DateRange = SetDateRange(value)
        await UpdateTableData(OTKPG1, DateRange[0], DateRange[1], 'FGV', 'OILTEK', 1, "LogOtk1", OTKModelPage1)
        await UpdateTableData(OTKPG2, DateRange[0], DateRange[1], 'FGV', 'OILTEK', 2, "LogOtk2", OTKModelPage2)
        await UpdateTableData([], DateRange[0], DateRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)

        await UpdateTableDataTime(OTKPG3, DateRange[0], DateRange[1], 'FGV', 'OILTEK', 3, "LogOtk4", OTKModelPage3)
    }

    const Export = () => {
        console.log(LogValue['LogOtk1']);
        ExportExcelWorkBookFGV(
            LogValue['LogOtk1'], LogValue['LogOtk2'], LogValue['LogOtk4'],
            OTKModelPage1, OTKModelPage2, Time,
            ExcelFormat, 'OTR.xlsx', "OTR")

    }


    return (
        <div>
            <div>
                <DatePicker onChange={SetTimeRange} />
                <Button onClick={Export}> Export </Button>
            </div>

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogOtk1']} Columb={columns1}
                site={'OILTEK'} page={'1'} BufferName={"LogOtk1"} Model={OTKModelPage1}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogOtk2']} Columb={columns2}
                site={'OILTEK'} page={'2'} BufferName={"LogOtk2"} Model={OTKModelPage2}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogOtk3']} Columb={columns3}
                site={'OILTEK'} page={'3'} BufferName={"LogOtk3"} Model={OTKModelPage3}
            />


        </div>
    )
}

export default OiltekReportExport
