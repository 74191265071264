import { Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { logdata_request } from '../../api'
import { DataSortTime, ExportExcelWorkBookFGV, GetDateTime, LogDataMappingName,LogDataMappingName1, LogDataMappingTime, SetDateRange } from '../../DataRequest/LogDataRequestWrap'
import { AlfaModelPage1, AlfaModelPage2, AlfaModelPage3, Time } from '../../DataRequest/AlfaModel'
import ExportTable from '../Report/ExportTable'
import { columns1, columns2, columns3 } from './AlfaColumbs'

let LogValue = {
    "LogALR1": [],
    "LogALR2": [],
    "LogALR3": [],
    "LogALR4": []

}

let ExcelFormat = [
     {
        startColumb:'C',
        startRow:'9',
        stopColumb: 'T',
        stopRow:'9',
        sheet:0,
        filename:'ALFA.xlsx'
    },

     {
        startColumb:'B',
        startRow:'10',
        stopColumb: 'U',
        stopRow:'10',
        sheet:1,
        filename:'ALFA.xlsx'
    },

    {
        startColumb:'D',
        startRow:'10',
        stopColumb: 'W',
        stopRow:'9',
        sheet:2,
        filename:'ALFA.xlsx'
    },

]

let setRange = []
setRange = GetDateTime(0, 1)

const ALFAPG1 = Object.keys(AlfaModelPage1)
const ALFAPG2 = Object.keys(AlfaModelPage2)
const ALFAPG3 = Object.keys(AlfaModelPage3)

const AlfaReportExport = () => {
    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        // console.log(setRange);
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 1, "LogALR1", AlfaModelPage1)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 2, "LogALR2", AlfaModelPage2)
        // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 3, "LogALR3", AlfaModelPage3)

        // UpdateTableDataTime([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 3, "LogALR4", AlfaModelPage3)

        return () => {
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
        //  console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const UpdateTableDataTime = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)
        // console.log(data);

        LogValue[BufferName] = LogDataMappingTime(Model, data)
        //  console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const SetTimeRange = async(value) => {
        const DateRange = SetDateRange(value)
        await UpdateTableData(ALFAPG1, DateRange[0], DateRange[1], 'FGV', 'ALPHA LAVAL', 1, "LogALR1", AlfaModelPage1)
        await UpdateTableData(ALFAPG2, DateRange[0], DateRange[1], 'FGV', 'ALPHA LAVAL', 2, "LogALR2", AlfaModelPage2)
        await UpdateTableData(ALFAPG3, DateRange[0], DateRange[1], 'FGV', 'ALPHA LAVAL', 3, "LogALR3", AlfaModelPage3)

        await UpdateTableDataTime(ALFAPG3, DateRange[0], DateRange[1], 'FGV', 'ALPHA LAVAL', 3, "LogALR4", AlfaModelPage3)
    }

    const Export = () => {
        ExportExcelWorkBookFGV(
            LogValue['LogALR1'], LogValue['LogALR2'], LogValue['LogALR4'],
            AlfaModelPage1, AlfaModelPage2, Time,
            ExcelFormat, 'ALFA.xlsx', 'ALFA')

    }


    return (
        <div>
            <div>
                <DatePicker onChange={SetTimeRange} />
                <Button onClick={Export}> Export </Button>
            </div>

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogALR1']} Columb={columns1}
                site={'ALPHA LAVAL'} page={'1'} BufferName={"LogALR1"} Model={AlfaModelPage1}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogALR2']} Columb={columns2}
                site={'ALPHA LAVAL'} page={'2'} BufferName={"LogALR2"} Model={AlfaModelPage2}
            />

            <ExportTable SetTimeRangeCallBack={UpdateTableData}
                LogValue={LogValue['LogALR3']} Columb={columns3}
                site={'ALPHA LAVAL'} page={'3'} BufferName={"LogALR3"} Model={AlfaModelPage3}
            />


        </div>
    )
}

export default AlfaReportExport
