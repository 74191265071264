export const AlfaModelPage1 = {
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'CPO 26E00 Temperature'                     : { PV: 'None', SV: 'None' },
    'CPO 26E00 Flow Rate'                       : { PV: 'None', SV: 'None' },
    'PHE CPO Out H3PO4 26E00 Temperature'       : { PV: 'None', SV: 'None' },
    'PHE CPO Out H3PO4 26E01 Temperature'       : { PV: 'None', SV: 'None' },
    'H3PO4 Stroke/F req (%)'                    : { PV: 'None', SV: 'None' },
    'H3PO4 Level (Kg)'                          : { PV: 'None', SV: 'None' },
    'H3PO4 Level (Kg/hr)'                       : { PV: 'None', SV: 'None' },
    'VAC mm/Hg Torr'                            : { PV: 'None', SV: 'None' },

    'Bleaching Earth Setting'                   : { PV: 'None', SV: 'None' },
    'Bleaching Earth Weight'                    : { PV: 'None', SV: 'None' },
    'Bleaching Earth Usage'                     : { PV: 'None', SV: 'None' },
    'Filter No.1'                               : { PV: 'None', SV: 'None' },
    'Filter No.2'                               : { PV: 'None', SV: 'None' },

    'Filter Bag Pressure Bar'                   : { PV: 'None', SV: 'None' },
    'F BPO QLTY Color Red'                      : { PV: 'None', SV: 'None' },
    'F BPO QLTY Black Partical'                 : { PV: 'None', SV: 'None' },

    'HP Boiler Temperature'                     : { PV: 'None', SV: 'None' },
    'HP Boiler Barg'                            : { PV: 'None', SV: 'None' },
}

export const AlfaModelPage2 = {
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'BPO Tank Temperature'                      : { PV: 'None', SV: 'None' },
    'BPO Tank Level'                            : { PV: 'None', SV: 'None' },
    'BPO Filter Bag'                            : { PV: 'None', SV: 'None' },
    'PHE 27E01 BPO Temperature'                 : { PV: 'None', SV: 'None' },
    'Flow Rate'                                  : { PV: 'None', SV: 'None' },
    '27E03 VHE Out Temperature'                 : { PV: 'None', SV: 'None' },
    '27E04 Final Heater Out Temperature'        : { PV: 'None', SV: 'None' },
    'Vacuum Torr'                               : { PV: 'None', SV: 'None' },
    'DEOD 27C03 Temperature'                    : { PV: 'None', SV: 'None' },
    '26E00 RBD Out Temperature'                 : { PV: 'None', SV: 'None' },
    '27E06 RBD Out Temperature'                 : { PV: 'None', SV: 'None' },
    'PFAD 27T05 Temperature'                    : { PV: 'None', SV: 'None' },
    'PFAD 27T05 Bar'                            : { PV: 'None', SV: 'None' },
    'Filter Bag Bar'                            : { PV: 'None', SV: 'None' },
    'FRACT Temperature'                         : { PV: 'None', SV: 'None' },
    'STRG Temperature'                          : { PV: 'None', SV: 'None' },
    'RBDPO Quality CLR'                         : { PV: 'None', SV: 'None' },
    'RBDPO Quality FFA'                         : { PV: 'None', SV: 'None' },
    'RBDPO Quality Black Partical'              : { PV: 'None', SV: 'None' },
}

export const AlfaModelPage3 = {
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'Main Steam Header Press. Kg/Cm2'           : { PV: 'None', SV: 'None' },
    'VAC Steam Header Press. Kg/Cm2'            : { PV: 'None', SV: 'None' },
    'SPRG Steam Header Press. Kg/Cm2'           : { PV: 'None', SV: 'None' },
    'B/Plant Steam Header Press. Kg/Cm2'        : { PV: 'None', SV: 'None' },
    'Niagara Steam Header Press. Kg/Cm2'        : { PV: 'None', SV: 'None' },
    'C.T. Pump Press No.1 Kg/Cm2'                    : { PV: 'None', SV: 'None' },
    'C.T. Pump Press No.2 Kg/Cm2'                    : { PV: 'None', SV: 'None' },
    'C.T. Fan Press No.1 Kg/Cm2'                    : { PV: 'None', SV: 'None' },
    'C.T. Fan Press No.2 Kg/Cm2'                    : { PV: 'None', SV: 'None' },

    'Cooling Tower Water Header'                : { PV: 'None', SV: 'None' },
    'Cooling Tower Water Header Press. Kg/Cm2'  : { PV: 'None', SV: 'None' },
    'H.W. Pump Press. No.1 Kg/cm2'                   : { PV: 'None', SV: 'None' },
    'H.W. Pump Press. No.2 Kg/cm2'                   : { PV: 'None', SV: 'None' },

    'Clean Cooling Tower No.1'                       : { PV: 'None', SV: 'None' },
    'Clean Cooling Tower No.2'                       : { PV: 'None', SV: 'None' },

}

export const Time = {
    '07:00':{PV:'none', SV:'none'},
    '08:00':{PV:'none', SV:'none'},
    '09:00':{PV:'none', SV:'none'},
    '10:00':{PV:'none', SV:'none'},
    '11:00':{PV:'none', SV:'none'},
    '12:00':{PV:'none', SV:'none'},
    '13:00':{PV:'none', SV:'none'},
    '14:00':{PV:'none', SV:'none'},
    '15:00':{PV:'none', SV:'none'},
    '16:00':{PV:'none', SV:'none'},
    '17:00':{PV:'none', SV:'none'},
    '18:00':{PV:'none', SV:'none'},
    '19:00':{PV:'none', SV:'none'},
    '20:00':{PV:'none', SV:'none'},
    '21:00':{PV:'none', SV:'none'},
    '22:00':{PV:'none', SV:'none'},
    '23:00':{PV:'none', SV:'none'},
    '00:00':{PV:'none', SV:'none'},
    '01:00':{PV:'none', SV:'none'},
    '02:00':{PV:'none', SV:'none'},
    '03:00':{PV:'none', SV:'none'},
    '04:00':{PV:'none', SV:'none'},
    '05:00':{PV:'none', SV:'none'},
    '06:00':{PV:'none', SV:'none'},
}

