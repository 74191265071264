/*
用来在内存保存一些数据的工具模块
 */
export default {
  user: {
    name:'',
    site:'',
    role:'',
    autolevel:'',
  }, // 保存当前登陆的user
}