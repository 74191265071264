import React from 'react'
import TirtiauxPG1Noti from './Notification/TirtiauxPG1Noti'

const TirtiauxNotification = () => {
    return (
        <div>
            <TirtiauxPG1Noti/>
        </div>
    )
}

export default TirtiauxNotification
