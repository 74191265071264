import { Button, Col, Divider, Row, DatePicker, Card, Table, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, RealtimeData_Request } from '../../api'
import { ConvertMonentToDateTime, DataSortTime, GetDateTime } from '../../DataRequest/LogDataRequestWrap'
import { Line } from '@antv/g2plot';
import moment from 'moment';
import { OTKModelPage1, OTKModelPage2, OTKModelPage3 } from '../../DataRequest/OiltekModel';
import { Margerine, Shortening } from '../../DataRequest/MarShorModel';
import { CSVLink, CSVDownload } from "react-csv";


let DTinitial = GetDateTime(1, 1)
let BlankPage, DTO1 = [], DTO2 = [], DTO3 = []
let O1DTRange = [], O2DTRange = []
let optionMargerine = 'Salt Tank AT201 Load Cell LC-201', optionSortening = 'Outlet Return PHE E-901 Temperature TEE-901'

DTO1.push(moment(DTinitial[2]))
DTO1.push(moment(DTinitial[3]))
DTO2 = DTO1
DTO3 = DTO1



let LogValue = {
    "MargerineGraph": [],
    "ShorteningGraph": [],
    "MargerineSelected": [],
    "ShorteningSelected": [],
    "Oiltek3Selected": [],
    "Buffer": [],
    "MargerineTable": [],
    "ShorteningTable": [],
    "LineSelector": []
}

const headersLog = [
    { label: "date", key: "date" },
    { label: "Parameter", key: "Parameter" },
    { label: "Value", key: "Value" },
];

const { RangePicker } = DatePicker;
const { Option } = Select;

let Option1 = Object.keys(Margerine)
let Option2 = Object.keys(Shortening)

const OptionSelector1 = [];
const OptionSelector2 = [];


LogValue['MargerineSelected'] = [Option1[0], Option1[1]];
LogValue['ShorteningSelected'] = [Option2[0], Option2[1]];


Option1.forEach(element => {
    OptionSelector1.push(<Option key={element}>{element}</Option>)
});
Option2.forEach(element => {
    OptionSelector2.push(<Option key={element}>{element}</Option>)
});



const columns1 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        // filters: data_filter1,
        // filterMode: 'tree',
        // filterSearch: true,
        // onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        // sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Line',
        dataIndex: 'Line',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        // sorter: (a, b) => parseDMYhm1(a.date) - parseDMYhm1(b.date),
    },
];




const columns2 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
    },
    {
        title: 'Line',
        dataIndex: 'Line',
    },
    {
        title: 'Date',
        dataIndex: 'date',
    },
];

let line, line2

const MSDataLog = () => {

    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        console.log('Reffect');

        line = new Line('OiltekGraph1', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line.render() }
        catch { }

        line2 = new Line('OiltekGraph2', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line2.render() }
        catch { }

        return () => {
            line.destroy();
            line2.destroy();
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        // console.log(response);
        let data = DataSortTime(response)
        LogValue[BufferName] = data
        // console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const O1LogRequest = async (DT) => {
        DTO1 = DT
        O1DTRange = ConvertMonentToDateTime(DT)
        let SelectMagerin = Margerine[optionMargerine]
        // console.log([optionMargerine]);
        await UpdateTableData([optionMargerine], O1DTRange[0], O1DTRange[1], 'FGV', SelectMagerin.Line, '1', "MargerineTable", OTKModelPage1)
        // console.log(LogValue["MargerineTable"]);
        line.changeData(LogValue["MargerineTable"])
    }

    const O2LogRequest = async (DT) => {
        DTO2 = DT
        O2DTRange = ConvertMonentToDateTime(DT)
        let SelectShortening = Shortening[optionSortening]

        await UpdateTableData([optionSortening], O2DTRange[0], O2DTRange[1], 'FGV', SelectShortening.Line, '1', "ShorteningTable", OTKModelPage2)
        line2.changeData(LogValue["ShorteningTable"])
    }

    const ChangeO1Select = (value) => {
        optionMargerine = value
    }

    const ChangeO2Select = (value) => {
        optionSortening = value
    }

    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Margerine</Divider>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={O1LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { O1LogRequest(DTO1) }}>Update</Button>
                <Button type='danger' style={{ marginLeft: '10px' }} >
                    <CSVLink data={LogValue['MargerineTable']} target="_blank" filename={"MarDataLog.csv"} headers={headersLog}>
                        Export
                    </CSVLink>
                </Button>
            </div>

            <Select
                size="middle"
                placeholder="Please select"
                defaultValue={[Option1[0]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeO1Select}
            >
                {OptionSelector1}
            </Select>

            <Card title='Margerine' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns1} dataSource={LogValue['MargerineTable']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'Margerine Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='OiltekGraph1'></div>
            </Card>


            <div style={{ paddingBottom: '10px', paddingTop: '20px' }}>
                <Divider orientation="left">Shortening</Divider>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={O2LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { O2LogRequest(DTO2) }}>Update</Button>
                <Button type='danger' style={{ marginLeft: '10px' }} >
                    <CSVLink data={LogValue['ShorteningTable']} target="_blank" filename={"ShortDataLog.csv"} headers={headersLog}>
                        Export
                    </CSVLink>
                </Button>
            </div>

            <Select
                size="middle"
                placeholder="Please select"
                defaultValue={[Option2[0]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeO2Select}

            >
                {OptionSelector2}
            </Select>

            <Card title='Shortening' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns2} dataSource={LogValue['ShorteningTable']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'Shortening Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='OiltekGraph2' ></div>
            </Card>

        </div>
    )
}

export default MSDataLog