import {
    DesktopOutlined, DashboardOutlined,
    HomeOutlined, ConsoleSqlOutlined,
    SendOutlined, UserSwitchOutlined, StockOutlined
  } from '@ant-design/icons';
  
  
  const menuList = [
    {
      title: 'Home',
      key: '/Home',
      icon: <HomeOutlined />,
      isPublic: true,
    },
  
    // {
    //     title: 'Line 1',
    //     key: '/Line 1',
    //     icon: <DesktopOutlined />,
    //     children: [
    //       {
    //         title: 'RealTime',
    //         key: '/PolymerC3RealTime',
    //         icon: <DashboardOutlined />
    //       },
    //       {
    //         title: 'DataVisualize',
    //         key: '/DataVisualize',
    //         icon: <StockOutlined />
    //       },
    //       {
    //         title: 'Report C3',
    //         key: '/C3Report',
    //         icon: <ConsoleSqlOutlined />
    //       },
    //       {
    //         title: 'Report G2',
    //         key: '/G2Report',
    //         icon: <ConsoleSqlOutlined />
    //       },
    //     ]
    //   },

      {
        title: 'Oiltek Ref',
        key: '/Oiltek',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/OiltekRealTime',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/OiltekDataVisualize',
            icon: <StockOutlined />
          },
          {
            title: 'PRE-Treatment & Bleaching',
            key: '/OiltekReportPG1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Deacidification & Deororization',
            key: '/OiltekReportPG2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Ancillary Equipments',
            key: '/OiltekReportPG3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Notification',
            key: '/OiltekNotification',
            icon: <SendOutlined />,
          },
          {
            title: 'ReportExport',
            key: '/OiltekReportExport',
            icon: <SendOutlined />,
          },
        ]
      },

      {
        title: 'Alfa Lava Ref',
        key: '/Alfa',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/AlfaRealTime',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/AlfaDataVisualize',
            icon: <StockOutlined />
          },
          {
            title: 'PRE-Treatment & Bleaching',
            key: '/AlfaReportPG1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Deacidification & Deororization',
            key: '/AlfaReportPG2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Ancillary Equipments',
            key: '/AlfaReportPG3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Notification',
            key: '/AlfaNotification',
            icon: <SendOutlined />,
          },
          {
            title: 'ReportExport',
            key: '/AlfaReportExport',
            icon: <SendOutlined />,
          },
        ]
      },

      {
        title: 'Tirtiaux Frac',
        key: '/Tirtiaux',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/TirtiauxRealTime',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/TirtiauxDataVisualize',
            icon: <StockOutlined />
          },
          {
            title: 'TirtiauxReportPG1',
            key: '/TirtiauxReportPG1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'TirtiauxReportPG2',
            key: '/TirtiauxReportPG2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'TirtiauxReportPG3',
            key: '/TirtiauxReportPG3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Notification',
            key: '/TirtiauxNotification',
            icon: <SendOutlined />,
          },
          {
            title: 'ReportExport',
            key: '/TirtiauxReportExport',
            icon: <SendOutlined />,
          },
        ]
      },

      {
        title: 'Oiltek Frac',
        key: '/OiltekN',
        icon: <DesktopOutlined />,
        children: [
          // {
          //   title: 'RealTime',
          //   key: '/OiltekNRealTime',
          //   icon: <DashboardOutlined />
          // },
          {
            title: 'DataVisualize',
            key: '/OiltekNDataVisualize',
            icon: <StockOutlined />
          },
          {
            title: 'OiltekNReportPG1',
            key: '/OiltekNReportPG1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'OiltekNReportPG2',
            key: '/OiltekNReportPG2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'OiltekNReportPG3',
            key: '/OiltekNReportPG3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Notification',
            key: '/OiltekNNotification',
            icon: <SendOutlined />,
          },
          {
            title: 'ReportExport',
            key: '/OiltekNReportExport',
            icon: <SendOutlined />,
          },
        ]
      },

      {
        title: 'JJ Lurgi Frac',
        key: '/JJLurgi',
        icon: <DesktopOutlined />,
        children: [
          // {
          //   title: 'RealTime',
          //   key: '/JJLurgiRealTime',
          //   icon: <DashboardOutlined />
          // },
          {
            title: 'DataVisualize',
            key: '/JJLurgiDataVisualize',
            icon: <StockOutlined />
          },
          {
            title: 'JJLurgiReportPG1',
            key: '/JJLurgiReportPG1',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'JJLurgiReportPG2',
            key: '/JJLurgiReportPG2',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'JJLurgiReportPG3',
            key: '/JJLurgiReportPG3',
            icon: <ConsoleSqlOutlined />
          },
          {
            title: 'Notification',
            key: '/JJLurgiNotification',
            icon: <SendOutlined />,
          },
          {
            title: 'ReportExport',
            key: '/JJLurgiReportExport',
            icon: <SendOutlined />,
          },
        ]
      },

      {
        title: 'Margerine & Shortening',
        key: '/MarShor',
        icon: <DesktopOutlined />,
        children: [
          {
            title: 'RealTime',
            key: '/MSRT',
            icon: <DashboardOutlined />
          },
          {
            title: 'DataVisualize',
            key: '/MSDataLog',
            icon: <StockOutlined />
          },
          {
            title: 'Notification',
            key: '/MSNotification',
            icon: <ConsoleSqlOutlined />
          },
        ]
      },



  
      // {
      //   title: 'Alfa Laval',
      //   key: '/Alfa',
      //   icon: <DesktopOutlined />,
      //   children: [
      //     {
      //       title: 'RealTime',
      //       key: '/AlfaRealTime',
      //       icon: <DashboardOutlined />
      //     },
      //     {
      //       title: 'DataVisualize',
      //       key: '/DataVisualAlfaLava',
      //       icon: <StockOutlined />
      //     },
      //     {
      //       title: 'Report',
      //       key: '/AlfaLavalReport',
      //       icon: <ConsoleSqlOutlined />
      //     },
      //   ]
      // },
  
      // {
      //   title: 'Tirtiaux',
      //   key: '/Tirtiaux',
      //   icon: <DesktopOutlined />,
      //   children: [
      //     {
      //       title: 'RealTime',
      //       key: '/TirtiauxRealTime',
      //       icon: <DashboardOutlined />
      //     },
      //     {
      //       title: 'DataVisualize',
      //       key: '/DataVisualTritiaux',
      //       icon: <StockOutlined />
      //     },
      //     {
      //       title: 'Report',
      //       key: '/TirtiauxReport',
      //       icon: <ConsoleSqlOutlined />
      //     },
      //   ]
      // },
  
      // {
      //   title: 'NotificationLine1',
      //   key: '/NotificationLine1',
      //   icon: <SendOutlined />,
      // },
    
      // {
      //   title: 'Test',
      //   key: '/Testpage1',
      //   icon: <UserSwitchOutlined />
      // }
  
  ]
  
  export default menuList