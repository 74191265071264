import { DatePicker, message, Modal, TimePicker, Typography, Select } from 'antd'
import React from 'react'
import moment from 'moment';
import { ConvertMonentToDateTime, ConvertMonentToDateTimeOne, GetDateTime, SetDateRange } from '../../DataRequest/LogDataRequestWrap';


const AddRowModel = (props) => {
    let date, parameter = "", batch = "", remark = "", reportdate
    const { Option } = Select;
    const NowDate = GetDateTime(0, 0)
    // console.log(props);

    const ReportdateHandler = (value) => {
        console.log(value)
        reportdate = value
    }

    const RecordRow = (value) => {
        console.log(value)
        // let remarktime

        // console.log(ConvertMonentToDateTimeOne(reportdate).split('T')[0]);

        // try {
        //     console.log(ConvertMonentToDateTime(value).split('T')[1]);
        //     remarktime = ConvertMonentToDateTime(value).split('T')[1]
        // } catch (error) {
            
        // }

        // let Reportdate = ConvertMonentToDateTimeOne(reportdate).split('T')[0]
        

        // let Reporttime = ConvertMonentToDateTime(value).split('T')[1]

        // let reporttimerange =  `${Reportdate}T${Reporttime[0]}`

        // console.log(reporttimerange);
        date = value
        if (parameter == "") {
            parameter = 'Water Meter'
        }
        if (batch == "") {
            batch = '1'
        }
        if (remark == "") {
            remark = 'None'
        }
    }
    const RecordRow1 = (value) => {
        // console.log(value)
        parameter = value
        console.log(parameter)
    }
    const RecordRow2 = (value) => {
        // console.log(value)
        batch = value
        console.log(batch)
    }
    const RecordRow3 = (value) => {
        // console.log(value)
        remark = value
        console.log(remark)
    }
    const { Title } = Typography
    const { RangePicker } = DatePicker;

    // const handleChange = (value) => {
    //     console.log(`selected ${value}`);
    // };


    if (props.Section == 3) {
        return (
            <div>
                <Modal title={'Add New Row'} visible={props.Visibility}
                    onCancel={props.CloseModal} onOk={() => { props.SaveRow(date, parameter, batch, remark, reportdate) }}
                >

                    <div>
                        <DatePicker showTime={{ format: 'HH:00:00' }} showHour={true}
                            defaultValue={moment(moment(NowDate[2], "YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm:ss")}
                            onOk={RecordRow}
                        />
                    </div>

                    <div style={{ paddingTop: '20px' }}>
                        <Select
                            defaultValue="Water Meter"
                            style={{
                                width: 120,
                            }}
                            onChange={RecordRow1}
                        >
                            <Option value="Water Meter">Water Meter</Option>
                            <Option value="KWH Meter">KWH Meter</Option>
                            <Option value="Cooling Tower Meter">Cooling Tower Meter</Option>
                            <Option value="Steam (flow meter)">Steam (flow meter)</Option>
                            <Option value="Steam (computer)">Steam (computer)</Option>
                            <Option value="Electric Panel">Electric Panel</Option>
                            <Option value="Electric Chiller A">Electric Chiller A</Option>
                            <Option value="Electric Chiller B">Electric Chiller B</Option>
                            <Option value="Electric C/Tower">Electric C/Tower</Option>
                            <Option value="Electric Condensate">Electric Condensate</Option>
                            <Option value="H2O Cooling Meter">H2O Cooling Meter</Option>
                            <Option value="Steam">Steam</Option>
                            <Option value="Flowmeter RBDPO">Flowmeter RBDPO</Option>
                            <Option value="Flowmeter RBDPL">Flowmeter RBDPL</Option>
                            <Option value="Flowmeter RBDPS">Flowmeter RBDPS</Option>
                            <Option value="Steam (FIRQ)">Steam (FIRQ)</Option>
                            <Option value="Steam (S.Tank)">Steam (S.Tank)</Option>
                            <Option value="C.T. Water">C.T. Water</Option>
                        </Select>
                    </div>


                </Modal>
            </div>
        )
    }
    else if (props.Section == 2 || props.Section == 4 || props.Section == 5) {
        return (
            <div>
                <Modal title={'Add New Row'} visible={props.Visibility}
                    onCancel={props.CloseModal} onOk={() => { props.SaveRow(date, parameter, batch, remark) }}
                >

                    <div>
                        <Title level={4}>Select DateTime:</Title>
                        <RangePicker showTime
                            onOk={RecordRow}
                        />
                    </div>



                </Modal>
            </div>
        )
    }
    else if (props.Section == 1) {
        return (
            <div>
                <Modal title={'Add New Row'} visible={props.Visibility}
                    onCancel={props.CloseModal} onOk={() => { props.SaveRow(date, parameter, batch, remark) }}
                >

                    <div>
                        <Title level={4}>Select DateTime:</Title>

                        <DatePicker showTime={{ format: 'HH:mm:00' }} showHour={true} showMinute={true}
                            defaultValue={moment(moment(NowDate[2], "YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm:ss")}
                            onOk={RecordRow}
                        />


                    </div>


                    <div style={{ paddingTop: '20px' }}>
                        <Select
                            defaultValue="1"
                            style={{
                                width: 120,
                            }}
                            onChange={RecordRow2}
                        >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            {
                                (props.Site == "OiltekN" || props.Site == "JJLurgi") ? <Option value="4">4</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="5">5</Option> : null
                            }
                        </Select>
                    </div>


                </Modal>
            </div>
        )

    }
    else if (props.Section == 6) {
        return (
            <div>
                <Modal title={'Add New Row'} visible={props.Visibility}
                    onCancel={props.CloseModal} onOk={() => { props.SaveRow(date, parameter, batch, remark, reportdate) }}
                >

                    <div>
                        <Title level={4}>Select Report Date:</Title>
                        <DatePicker
                            onSelect={ReportdateHandler}
                        />
                    </div>

                    <div style={{paddingTop:'20px'}}>
                        <Title level={4}>Select DateTime:</Title>
                        <TimePicker.RangePicker
                            onOk={RecordRow}
                        />
                    </div>


                    <div style={{ paddingTop: '20px' }}>
                        <Select
                            // defaultValue="D401_Remark"
                            style={{
                                width: 120,
                            }}
                            onChange={RecordRow3}
                        >

                            {
                                (props.Site == "Tirtiaux") ? <Option value="Tirtiaux_D401_Remark">Tirtiaux D401</Option> : null
                            }
                            {
                                (props.Site == "Tirtiaux") ? <Option value="Tirtiaux_D402_Remark">Tirtiaux D402</Option> : null
                            }
                            {
                                (props.Site == "Tirtiaux") ? <Option value="Tirtiaux_D403_Remark">Tirtiaux D403</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="Oiltek_T401_Remark">Oiltek T401</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="Oiltek_T402_Remark">Oiltek T402</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="Oiltek_T403_Remark">Oiltek T403</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="Oiltek_T404_Remark">Oiltek T404</Option> : null
                            }
                            {
                                (props.Site == "OiltekN") ? <Option value="Oiltek_T405_Remark">Oiltek T405</Option> : null
                            }
                            {
                                (props.Site == "JJLurgi") ? <Option value="JJLurgi_D401_Remark">JJ Lurgi D401</Option> : null
                            }
                            {
                                (props.Site == "JJLurgi") ? <Option value="JJLurgi_D402_Remark">JJ Lurgi D402</Option> : null
                            }
                            {
                                (props.Site == "JJLurgi") ? <Option value="JJLurgi_D403_Remark">JJ Lurgi D403</Option> : null
                            }
                            {
                                (props.Site == "JJLurgi") ? <Option value="JJLurgi_D404_Remark">JJ Lurgi D404</Option> : null
                            }
                        </Select>
                    </div>


                </Modal>
            </div>
        )

    }
    else {
        return (
            <div>
                <Modal title={'Add New Row'} visible={props.Visibility}
                    onCancel={props.CloseModal} onOk={() => { props.SaveRow(date, parameter, batch, remark) }}
                >

                    <div>
                        <Title level={4}>Select DateTime:</Title>


                        <DatePicker showTime={{ format: 'HH:mm:00' }} showHour={true} showMinute={true}
                            defaultValue={moment(moment(NowDate[2], "YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm:ss")}
                            onOk={RecordRow}
                        />


                    </div>


                </Modal>
            </div>
        )

    }

}

export default AddRowModel
