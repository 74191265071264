import { Button, Col, Divider, Row, DatePicker, Card, Table, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, RealtimeData_Request } from '../../api'
import { ConvertMonentToDateTime, DataSortTime, GetDateTime } from '../../DataRequest/LogDataRequestWrap'
import { Line } from '@antv/g2plot';
import moment from 'moment';
import { AlfaModelPage1, AlfaModelPage2, AlfaModelPage3 } from '../../DataRequest/AlfaModel';


let DTinitial = GetDateTime(1,1)
let BlankPage, DTO1 = [] , DTO2 = [], DTO3 = []
let O1DTRange = [], O2DTRange = [], O3DTRange = []

DTO1.push(moment(DTinitial[2]))
DTO1.push(moment(DTinitial[3]))
DTO2 = DTO1
DTO3 = DTO1



let LogValue = {
    "Alfa1Table": [],
    "Alfa2Table": [],
    "Alfa3Table": [],
    "Alfa1Graph": [],
    "Alfa2Graph": [],
    "Alfa3Graph": [],
    "Alfa1Selected": [],
    "Alfa2Selected": [],
    "Alfa3Selected": [],
}

const { RangePicker } = DatePicker;
const { Option } = Select;

let Option1 = Object.keys(AlfaModelPage1)
Option1.shift()
Option1.shift()

let Option2 = Object.keys(AlfaModelPage2)
Option2.shift()
Option2.shift()

let Option3 = Object.keys(AlfaModelPage3)
Option3.shift()
Option3.shift()


const OptionSelector1 = [];
const OptionSelector2 = [];
const OptionSelector3 = [];


LogValue['Alfa1Selected'] = [Option1[0], Option1[1]];
LogValue['Alfa2Selected'] = [Option2[0], Option2[1]];
LogValue['Alfa3Selected'] = [Option3[0], Option3[1]];


Option1.forEach(element => {
    OptionSelector1.push(<Option key={element}>{element}</Option>)
});
Option2.forEach(element => {
    OptionSelector2.push(<Option key={element}>{element}</Option>)
});
Option3.forEach(element => {
    OptionSelector3.push(<Option key={element}>{element}</Option>)
});

let data_filter1 = Option1.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhm1 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columns1 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filter1,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhm1(a.date) - parseDMYhm1(b.date),
    },
];



let data_filter2 = Option2.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhm2 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columns2 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filter2,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhm2(a.date) - parseDMYhm2(b.date),
    },
];

let data_filter3 = Option3.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhm3 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columns3 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filter3,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhm3(a.date) - parseDMYhm3(b.date),
    },
];

let line, line2, line3

const AlfaDataLog = () => {

    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        // console.log('Reffect');

        line = new Line('AlfaGraph1', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line.render() }
        catch { }

        line2 = new Line('AlfaGraph2', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line2.render() }
        catch { }

        line3 = new Line('AlfaGraph3', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line3.render() }
        catch { }

        return () => {
            line.destroy();
            line2.destroy();
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        // console.log(response);
        let data = DataSortTime(response)
        LogValue[BufferName] = data
        // console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const A1LogRequest = async (DT) => {
        DTO1 = DT
        // console.log(DTO1);
        O1DTRange = ConvertMonentToDateTime(DT)
        // console.log(O1DTRange);
        await UpdateTableData(LogValue['Alfa1Selected'], O1DTRange[0], O1DTRange[1], 'FGV', 'ALPHA LAVAL', '1', "Alfa1Table", AlfaModelPage1)
        // console.log(LogValue["Alfa1Table"]);
        line.changeData(LogValue["Alfa1Table"])
    }

    const A2LogRequest = async (DT) => {
        DTO2 = DT
        O2DTRange = ConvertMonentToDateTime(DT)
        await UpdateTableData(LogValue['Alfa2Selected'], O2DTRange[0], O2DTRange[1], 'FGV', 'ALPHA LAVAL', '2', "Alfa2Table", AlfaModelPage2)
        line2.changeData(LogValue["Alfa2Table"])
    }

    const A3LogRequest = async (DT) => {
        DTO3 = DT
        O3DTRange = ConvertMonentToDateTime(DT)
        await UpdateTableData(LogValue['Alfa3Selected'], O3DTRange[0], O3DTRange[1], 'FGV', 'ALPHA LAVAL', '3', "Alfa2Table", AlfaModelPage3)
        line3.changeData(LogValue["Alfa3Table"])
    }

    const ChangeO1Select = (value) => {
        LogValue['Alfa1Selected'] = value
    }

    const ChangeO2Select = (value) => {
        LogValue['Alfa2Selected'] = value
    }

    const ChangeO3Select = (value) => {
        LogValue['Alfa3Selected'] = value
    }


    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Alfa Page 1</Divider>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={A1LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { A1LogRequest(DTO1) }}>Update</Button>
            </div>
            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[Option1[0], Option1[1]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeO1Select}
            >
                {OptionSelector1}
            </Select>

            <Card title='Alfa PG 1' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns1} dataSource={LogValue['Alfa1Table']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'Alfa PG 1 Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='AlfaGraph1'></div>
            </Card>


            <div style={{ paddingBottom: '10px', paddingTop:'20px' }}>
                <Divider orientation="left">Alfa Page 2</Divider>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={A2LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { A2LogRequest(DTO2) }}>Update</Button>
            </div>

            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[Option2[0], Option2[1]]}
                style={{ width: '100%', paddingBottom: '20px'   }}
                onChange={ChangeO2Select}

            >
                {OptionSelector2}
            </Select>

            <Card title='Alfa PG 2' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns2} dataSource={LogValue['Alfa2Table']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'Alfa PG 2 Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='AlfaGraph2' ></div>
            </Card>


            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Alfa Page 3</Divider>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={A3LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { A3LogRequest(DTO3) }}>Update</Button>
            </div>
            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[Option3[0], Option3[1]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeO3Select}
            >
                {OptionSelector3}
            </Select>

            <Card title='Alfa PG 3' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns3} dataSource={LogValue['Alfa3Table']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'Alfa PG 3 Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='AlfaGraph3'></div>
            </Card>


        </div>
    )
}

export default AlfaDataLog


