import { Button, Col, Divider, Row, Card, Table, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { RealtimeData_Request } from '../../api'
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap'
import HMIWrapper from '../Realtime/HMIWrapper'
import RealTimeGraphWrapper from '../Realtime/RealTimeGraphWrapper'
import RealTimeTableWrapper from '../Realtime/RealTimeTableWrapper'
import OiltekBF from './HMI/OiltekBF';
import OiltekDeo1 from './HMI/OiltekDeo1';
import OiltekFatty from './HMI/OiltekFatty';
import OiltekDeo2 from './HMI/OiltekDeo2';
import { OTKModelPage1, OTKModelPage2, OTKModelPage3 } from '../../DataRequest/OiltekModel'
import { Margerine, Shortening } from '../../DataRequest/MarShorModel'

let PG1DataModel = OTKModelPage1
let PG2DataModel = OTKModelPage2
let PG3DataModel = OTKModelPage3


let RealTimeValue = {
    "Buffer": [],
    "MargerineTable": [],
    "ShorteningTable": []
}

const columns = [
    {
        title: 'Parameter',
        dataIndex: 'Label',
    },
    {
        title: 'PV',
        dataIndex: 'Value',
    },
    {
        title: 'Line',
        dataIndex: 'Line',
    },
    {
        title: 'Update Time',
        dataIndex: 'time',
    },
];

const { Title } = Typography;


const MSRT = () => {
    const [state, setState] = useState({ count: 0 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        // RealTimeValue[HMIName] = RealTimeValue[TableName]
        console.log(RealTimeValue[TableName])
    }

    const RenderNewData = async () => {
        RealTimeValue['MargerineTable'] = []
        RealTimeValue['ShorteningTable'] = []


        await DataRequest([], 'FGV', '1', 'Margarine Aques', 'PG1HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['MargerineTable'].push(element)
        });
        await DataRequest([], 'FGV', '1', 'Margarine BLENDING', 'PG2HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['MargerineTable'].push(element)
        });
        await DataRequest([], 'FGV', '1', 'Margarine CHILL', 'PG3HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['MargerineTable'].push(element)
        });
        await DataRequest([], 'FGV', '1', 'Margarine PASTUER', 'PG3HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['MargerineTable'].push(element)
        });

        await DataRequest([], 'FGV', '1', 'Shortening BLENDING', 'PG2HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['ShorteningTable'].push(element)
        });
        await DataRequest([], 'FGV', '1', 'Shortening CHILL', 'PG3HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['ShorteningTable'].push(element)
        });
        await DataRequest([], 'FGV', '1', 'Shortening PASTUER', 'PG3HMI', "Buffer")
        RealTimeValue['Buffer'].forEach(element => {
            RealTimeValue['ShorteningTable'].push(element)
        });


        console.log(RealTimeValue['Margerine']);

        setState(({ count }) => ({ count: count + 1 }));
    }

    useEffect(() => {
        RenderNewData()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [])


    return (
        <div>
            <Divider orientation="left">Parameter List</Divider>

            <div>
                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={12}>
                        <div>
                            <Title level={2} underline>
                                {'Margerine'}
                            </Title>
                            <Card title='RealTime' bordered={true}
                                style={{ width: '100%', borderRadius: 15, }} hoverable={true}>
                                <Table bordered columns={columns} dataSource={RealTimeValue['MargerineTable']} size="small" scroll={{ y: 500 }} pagination={false} />
                            </Card>
                        </div>
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                        <div>
                            <Title level={2} underline>
                                {'Shortening'}
                            </Title>
                            <Card title='RealTime' bordered={true}
                                style={{ width: '100%', borderRadius: 15, }} hoverable={true}>
                                <Table bordered columns={columns} dataSource={RealTimeValue['ShorteningTable']} size="small" scroll={{ y: 500 }} pagination={false} />
                            </Card>
                        </div>
                    </Col>
     
                </Row>
            </div>

            <Divider orientation="left" style={{ marginTop: '30px' }}>Trending Graph</Divider>


            <div>
                <Row gutter={[16, 16]}>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'Bleaching & Refining'}
                            DataModel={Margerine}
                            RawDataReceive={RealTimeValue['MargerineTable']}
                            Divname={'BFGraph'}
                        />
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'Deodorization'}
                            DataModel={Shortening}
                            RawDataReceive={RealTimeValue['ShorteningTable']}
                            Divname={'DeoGraph'}
                        />
                    </Col>

                </Row>
            </div>

        </div>
    )
}

export default MSRT
