export const TirtiauxModelPage1_all = {
    'F2_D401_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
    'F2_D402_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
    'F2_D403_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
}


export const TirtiauxModelPage1 = {
    'F2_D401_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
    // 'F2_D402_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
    // 'F2_D403_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage1__1 = {
    'F2_D402_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage1__2 = {
    'F2_D403_ACTUAL_STEP'                             : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage1_1 = {
    'Washing'                             : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage1_2 = {
    'Tirtiaux_D401_Remark'                             : { PV: 'None', SV: 'None' },
    'Tirtiaux_D402_Remark'                             : { PV: 'None', SV: 'None' },
    'Tirtiaux_D403_Remark'                             : { PV: 'None', SV: 'None' },
}



export const TirtiauxModelPage2 = {
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'Filtration Load Pressure'          : { PV: 'None', SV: 'None' },
    'Filtration Squeeze Time'               : { PV: 'None', SV: 'None' },
    'Filtration Squeeze Pressure'                 : { PV: 'None', SV: 'None' },
    'Filtration Stearine Temperature'          : { PV: 'None', SV: 'None' },
    'Air regulator'                : { PV: 'None', SV: 'None' },
    'Steam Header Pressure'                     : { PV: 'None', SV: 'None' },
    'Chiller Water Tank Temperature'            : { PV: 'None', SV: 'None' },
    'Hot Water Temperature'                     : { PV: 'None', SV: 'None' },
    'Cooling Tower Temperature'                 : { PV: 'None', SV: 'None' },
    'HOMO1'                            : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage3 = {
    'Batch'                             : { PV: 'None', SV: 'None' },
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'Cryst No.'                                 : { PV: 'None', SV: 'None' },
    'Loading Time'                              : { PV: 'None', SV: 'None' },
    'Cooling Time'                              : { PV: 'None', SV: 'None' },
    'RBDPO FFA'                                 : { PV: 'None', SV: 'None' },
    'RBDPO Col(R)'                              : { PV: 'None', SV: 'None' },
    'RBDPO I.V.'                                : { PV: 'None', SV: 'None' },
    'Filtration Time'                           : { PV: 'None', SV: 'None' },
    'OLEIN FFA'                                 : { PV: 'None', SV: 'None' },
    'OLEIN Col(R)'                              : { PV: 'None', SV: 'None' },
    'OLEIN I.V.'                                : { PV: 'None', SV: 'None' },
    'OLEIN C.P.'                                : { PV: 'None', SV: 'None' },
    'OLEIN Yield(%)'                            : { PV: 'None', SV: 'None' },
    'OLEIN Storage'                             : { PV: 'None', SV: 'None' },
    'STEARIN FFA'                               : { PV: 'None', SV: 'None' },
    'STEARIN Col(R)'                            : { PV: 'None', SV: 'None' },
    'STEARIN I.V.'                              : { PV: 'None', SV: 'None' },
    'STEARIN M.P.'                              : { PV: 'None', SV: 'None' },
    'STEARIN VM(%)'                          : { PV: 'None', SV: 'None' },
    'STEARIN Storage'                           : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage3_1 = {
    'Shift'                             : { PV: 'None', SV: 'None' },
    'From'                              : { PV: 'None', SV: 'None' },
    'To'                                : { PV: 'None', SV: 'None' },
    'Hour'                              : { PV: 'None', SV: 'None' },
    'Minute'                            : { PV: 'None', SV: 'None' },
    'Reason'                            : { PV: 'None', SV: 'None' }
}

export const TirtiauxModelPage3_2 = {
    'Water Meter Initial'                                         : { PV: 'None', SV: 'None' },
    'KWH Meter Initial'                                           : { PV: 'None', SV: 'None' },
    'Cooling Tower Meter Initial'                                 : { PV: 'None', SV: 'None' },
    'Steam (flow meter) Initial'                                  : { PV: 'None', SV: 'None' },
    'Steam (computer) Initial'                                    : { PV: 'None', SV: 'None' },
    'Electric Panel Initial'                                         : { PV: 'None', SV: 'None' },
    'Electric Chiller A Initial'                                           : { PV: 'None', SV: 'None' },
    'Electric Chiller B Initial'                                 : { PV: 'None', SV: 'None' },
    'Electric C/Tower Initial'                                  : { PV: 'None', SV: 'None' },
    'Electric Condensate Initial'                                    : { PV: 'None', SV: 'None' },
    'H2O Cooling Meter Initial'                                         : { PV: 'None', SV: 'None' },
    'Steam Initial'                                           : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPO Initial'                                 : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPL Initial'                                  : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPS Initial'                                    : { PV: 'None', SV: 'None' },
    'Steam (FIRQ) Initial'                                         : { PV: 'None', SV: 'None' },
    'Steam (S.Tank) Initial'                                           : { PV: 'None', SV: 'None' },
    'C.T. Water Initial'                                 : { PV: 'None', SV: 'None' },

    'Water Meter Final'                                         : { PV: 'None', SV: 'None' },
    'KWH Meter Final'                                           : { PV: 'None', SV: 'None' },
    'Cooling Tower Meter Final'                                 : { PV: 'None', SV: 'None' },
    'Steam (flow meter) Final'                                  : { PV: 'None', SV: 'None' },
    'Steam (computer) Final'                                    : { PV: 'None', SV: 'None' },
    'Electric Panel Final'                                         : { PV: 'None', SV: 'None' },
    'Electric Chiller A Final'                                           : { PV: 'None', SV: 'None' },
    'Electric Chiller B Final'                                 : { PV: 'None', SV: 'None' },
    'Electric C/Tower Final'                                  : { PV: 'None', SV: 'None' },
    'Electric Condensate Final'                                    : { PV: 'None', SV: 'None' },
    'H2O Cooling Meter Final'                                         : { PV: 'None', SV: 'None' },
    'Steam Final'                                           : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPO Final'                                 : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPL Final'                                  : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPS Final'                                    : { PV: 'None', SV: 'None' },
    'Steam (FIRQ) Final'                                         : { PV: 'None', SV: 'None' },
    'Steam (S.Tank) Final'                                           : { PV: 'None', SV: 'None' },
    'C.T. Water Final'                                 : { PV: 'None', SV: 'None' },

    'Water Meter Difference'                                         : { PV: 'None', SV: 'None' },
    'KWH Meter Difference'                                           : { PV: 'None', SV: 'None' },
    'Cooling Tower Meter Difference'                                 : { PV: 'None', SV: 'None' },
    'Steam (flow meter) Difference'                                  : { PV: 'None', SV: 'None' },
    'Steam (computer) Difference'                                    : { PV: 'None', SV: 'None' },
    'Electric Panel Difference'                                         : { PV: 'None', SV: 'None' },
    'Electric Chiller A Difference'                                           : { PV: 'None', SV: 'None' },
    'Electric Chiller B Difference'                                 : { PV: 'None', SV: 'None' },
    'Electric C/Tower Difference'                                  : { PV: 'None', SV: 'None' },
    'Electric Condensate Difference'                                    : { PV: 'None', SV: 'None' },
    'H2O Cooling Meter Difference'                                         : { PV: 'None', SV: 'None' },
    'Steam Difference'                                           : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPO Difference'                                 : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPL Difference'                                  : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPS Difference'                                    : { PV: 'None', SV: 'None' },
    'Steam (FIRQ) Difference'                                         : { PV: 'None', SV: 'None' },
    'Steam (S.Tank) Difference'                                           : { PV: 'None', SV: 'None' },
    'C.T. Water Difference'                                 : { PV: 'None', SV: 'None' },

    'Water Meter Unit per MT'                                         : { PV: 'None', SV: 'None' },
    'KWH Meter Unit per MT'                                           : { PV: 'None', SV: 'None' },
    'Cooling Tower Meter Unit per MT'                                 : { PV: 'None', SV: 'None' },
    'Steam (flow meter) Unit per MT'                                  : { PV: 'None', SV: 'None' },
    'Steam (computer) Unit per MT'                                    : { PV: 'None', SV: 'None' },
    'Electric Panel Unit per MT'                                         : { PV: 'None', SV: 'None' },
    'Electric Chiller A Unit per MT'                                           : { PV: 'None', SV: 'None' },
    'Electric Chiller B Unit per MT'                                 : { PV: 'None', SV: 'None' },
    'Electric C/Tower Unit per MT'                                  : { PV: 'None', SV: 'None' },
    'Electric Condensate Unit per MT'                                    : { PV: 'None', SV: 'None' },
    'H2O Cooling Meter Unit per MT'                                         : { PV: 'None', SV: 'None' },
    'Steam Unit per MT'                                           : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPO Unit per MT'                                 : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPL Unit per MT'                                  : { PV: 'None', SV: 'None' },
    'Flowmeter RBDPS Unit per MT'                                    : { PV: 'None', SV: 'None' },
    'Steam (FIRQ) Unit per MT'                                         : { PV: 'None', SV: 'None' },
    'Steam (S.Tank) Unit per MT'                                           : { PV: 'None', SV: 'None' },
    'C.T. Water Unit per MT'                                 : { PV: 'None', SV: 'None' },

}

export const TirtiauxModelPage3_3 = {
    'Batch'                             : { PV: 'None', SV: 'None' },
}

export const TirtiauxModelPage4 = {
    'Operator Name'                             : { PV: 'None', SV: 'None' },
    'Lab Operator Name'                         : { PV: 'None', SV: 'None' },
    'Elect Panel'                               : { PV: 'None', SV: 'None' },
    'Chiller A'                                 : { PV: 'None', SV: 'None' },
    'Chiller B'                                 : { PV: 'None', SV: 'None' },
    'Steam (Comp)'                              : { PV: 'None', SV: 'None' },
    'Steam (FIRO)'                              : { PV: 'None', SV: 'None' },
    'Steam (S.Tank)'                            : { PV: 'None', SV: 'None' },
    'C.T Water'                                 : { PV: 'None', SV: 'None' },
}

export const Time = {
    '07:00':{PV:'none', SV:'none'},
    '08:00':{PV:'none', SV:'none'},
    '09:00':{PV:'none', SV:'none'},
    '10:00':{PV:'none', SV:'none'},
    '11:00':{PV:'none', SV:'none'},
    '12:00':{PV:'none', SV:'none'},
    '13:00':{PV:'none', SV:'none'},
    '14:00':{PV:'none', SV:'none'},
    '15:00':{PV:'none', SV:'none'},
    '16:00':{PV:'none', SV:'none'},
    '17:00':{PV:'none', SV:'none'},
    '18:00':{PV:'none', SV:'none'},
    '19:00':{PV:'none', SV:'none'},
    '20:00':{PV:'none', SV:'none'},
    '21:00':{PV:'none', SV:'none'},
    '22:00':{PV:'none', SV:'none'},
    '23:00':{PV:'none', SV:'none'},
    '00:00':{PV:'none', SV:'none'},
    '01:00':{PV:'none', SV:'none'},
    '02:00':{PV:'none', SV:'none'},
    '03:00':{PV:'none', SV:'none'},
    '04:00':{PV:'none', SV:'none'},
    '05:00':{PV:'none', SV:'none'},
    '06:00':{PV:'none', SV:'none'},
}