import { Button } from 'antd';
import moment from 'moment';
import XlsxPopulate from 'xlsx-populate'
import { Time } from './OiltekModel';


export const TableColumbTimeAsRow = () => {
    let Columb = []
    let TimeRow = []

    for (let i = 0; i < 24; i++) {
        let n
        i < 10 ? n = `0${i}` : n = i
        TimeRow.push(`${n}:00`)
    }

    Columb.push(
        {
            title: 'Parameter',
            width: 100,
            dataIndex: 'Parameter',
            key: 'Parameter',
            fixed: 'left',
            align: 'center',
        })

    TimeRow.forEach(element => {
        Columb.push(
            {
                title: element,
                width: 70,
                dataIndex: element,
                key: element,
                align: 'center',
            }
        )
    });
    return Columb
}

export const TableColumbLabelAsRowG2 = (Datamodel, InputBagCallBack) => {
    let Columb = []

    Columb.push(
        {
            title: 'Time',
            width: 60,
            dataIndex: 'Time',
            key: 'Time',
            fixed: 'left',
            align: 'center',
        })

    const name = Object.keys(Datamodel)
    name.forEach(element => {
        Columb.push(
            {
                title: element,
                width: 70,
                dataIndex: element,
                key: element,
                align: 'center',
            }
        )
    })

    Columb.push(
        {
            title: 'View',
            width: 60,
            dataIndex: 'View',
            key: 'View',
            align: 'center',
            render: (text, record, index) => <Button onClick={
                (e) => { InputBagCallBack(record) }
            } > Click </Button>,
        })

    //console.log(Columb);
    return Columb
}

export const TableColumbLabelAsRow = (Datamodel) => {
    let Columb = []

    Columb.push(
        {
            title: 'Time',
            width: 60,
            dataIndex: 'Time',
            key: 'Time',
            fixed: 'left',
            align: 'center',
        })

    const name = Object.keys(Datamodel)
    name.forEach(element => {
        Columb.push(
            {
                title: element,
                width: 70,
                dataIndex: element,
                key: element,
                align: 'center',
            }
        )
    })

    //console.log(Columb);
    return Columb
}

export const TableColumbJob = (Datamodel, Viewhandler, DeleteHandler, line, page, button1name, button2name) => {
    let Columb = []

    const name = Object.keys(Datamodel)
    name.forEach(element => {
        Columb.push(
            {
                title: element,
                width: 70,
                dataIndex: element,
                key: element,
                align: 'center',
            }
        )
    })

    Columb.push(
        {
            title: `${button1name}`,
            width: 60,
            dataIndex: 'View',
            key: 'View',
            align: 'center',
            render: (text, record, index) => <Button onClick={
                (e) => { Viewhandler(record) }
            } > Click </Button>,
        })

    Columb.push(
        {
            title: `${button2name}`,
            width: 60,
            dataIndex: 'Delete',
            key: 'Delete',
            align: 'center',
            render: (text, record, index) => <Button onClick={
                (e) => { DeleteHandler(record, line, page) }
            } > {button2name} </Button>,
        })

    //console.log(Columb);
    return Columb
}

export const TableColumbJobHome = (Datamodel, line, page, button1name, button2name) => {
    let Columb = []

    Columb.push(
        {
            title: 'Line',
            width: 70,
            dataIndex: 'Line',
            key: 'Line',
            align: 'center',
        })

    const name = Object.keys(Datamodel)
    name.forEach(element => {
        Columb.push(
            {
                title: element,
                width: 70,
                dataIndex: element,
                key: element,
                align: 'center',
            }
        )
    })

    //console.log(Columb);
    return Columb
}

//offset initial date from today and offset ending date by today
export const GetDateTime = (OffsetInitialDay, OffsetDayEnd) => {

    let DateTimeArray = []
    let separator = '-'

    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + OffsetDayEnd)
    today.setDate(today.getDate() - OffsetInitialDay)

    let date1 = today.getDate();
    let month1 = today.getMonth() + 1;
    let year1 = today.getFullYear();

    let date2 = tomorrow.getDate();
    let month2 = tomorrow.getMonth() + 1;
    let year2 = tomorrow.getFullYear();


    let Start_time = `${year1}${separator}${month1 < 10 ? `0${month1}` : `${month1}`}${separator}${date1} 07:00:00`
    let End_time = `${year2}${separator}${month2 < 10 ? `0${month2}` : `${month2}`}${separator}${date2} 06:00:00`

    let Start_time_remark = `${year1}${separator}${month1 < 10 ? `0${month1}` : `${month1}`}${separator}${date1} 00:00:00`
    let End_time_remark = `${year1}${separator}${month1 < 10 ? `0${month1}` : `${month1}`}${separator}${date1} 23:59:59`

    DateTimeArray[0] = moment(Start_time).toISOString(false).split('.')[0] + "Z"
    DateTimeArray[1] = moment(End_time).toISOString(false).split('.')[0] + "Z"
    DateTimeArray[2] = Start_time
    DateTimeArray[3] = End_time
    DateTimeArray[4] = moment(Start_time_remark).toISOString(false).split('.')[0] + "Z"
    DateTimeArray[5] = moment(End_time_remark).toISOString(false).split('.')[0] + "Z"
    //console.log(DateTimeArray);
    //console.log('asdasd 999');
    return DateTimeArray
}


export const SetDateRange = (datetime) => {
    let DateTime = []
    let ReturnDT = []
    //convert datepicker value to Z format
    DateTime[0] = moment(datetime, "DD-MM-YYYY").subtract(1, 'days')
    DateTime[1] = moment(datetime, "DD-MM-YYYY").subtract(0, 'days')

    ReturnDT[0] = DateTime[0].toISOString(false).split('T')[0] + "T23:00:00Z"
    ReturnDT[1] = DateTime[1].toISOString(false).split('T')[0] + "T22:00:00Z"

    ReturnDT[2] = DateTime[0].toISOString(false).split('T')[0] + "T16:00:00Z"
    ReturnDT[3] = DateTime[1].toISOString(false).split('T')[0] + "T15:59:00Z"

    console.log(ReturnDT)
    return ReturnDT
}

//convert datetimepicker value to Z format
export const ConvertMonentToDateTime = (momentDateTime) => {
    let DateTimeReturn = []

    try {
        DateTimeReturn[0] = momentDateTime[0].toISOString(false).split('.')[0] + "Z"
        DateTimeReturn[1] = momentDateTime[1].toISOString(false).split('.')[0] + "Z"
    }
    catch { }
    return DateTimeReturn
}

//convert datetimepicker value to Z format
export const ConvertMonentToDateTimeOne = (momentDateTime) => {
    let DateTimeReturn

    try {
        DateTimeReturn = momentDateTime.toISOString(false).split('.')[0] + "Z"
    }
    catch { }
    console.log(DateTimeReturn)
    return DateTimeReturn
}

export const DataSortTime = (response, { section = null } = {}) => {
    let DataReturn
    console.log(response);
    console.log(section);
    // if (section == 3) {
    //     console.log("IT is 3")
    // }
    // else if (section == null) {
    //     console.log("IT is null")
    // }
    // else {
    //     console.log("IT is ...")
    // }

    function ConvertToFloat(value) {

        let checkNan

        if (value.indexOf("-") > 0) {
            return (value)
        }

        else {
            checkNan = parseFloat(value)

            if (isNaN(checkNan)) {
                return (value)
            }

            else {
                return (parseFloat(value))
            }
        }

    }


    if (response.success === true) {
        const data = response.data

        DataReturn = data.map(obj => {
            let cobj = []
            let date_new = moment(obj.fields.DateCreated);
            let DateComponent = date_new.utc(true).format('DD-MM-YYYY HH:mm:ss')
            let TimeComponent = date_new.utc(true).format('HH:mm:ss')
            let ar0 = TimeComponent.split(':')
            let ar1 = ar0[0] + ':' + ar0[1]
            // let SV = obj.fields.

            if (section == 3) {
                cobj = {
                    'key': `${obj.fields.Parameter}  ${DateComponent}`,
                    'time': ar1,
                    'date': DateComponent,
                    'Parameter': obj.fields.Parameter,
                    'Value': obj.fields.Value,
                    'Line': obj.fields.Line
                }
            }
            else {
                cobj = {
                    'key': `${obj.fields.Parameter}  ${DateComponent}`,
                    'time': ar1,
                    'date': DateComponent,
                    'Parameter': obj.fields.Parameter,
                    'Value': ConvertToFloat(obj.fields.Value),
                    'Line': obj.fields.Line
                }
            }
            return cobj
        })
        //console.log(DataReturn);
    }
    else {
        DataReturn = []
        console.log(response)
    }
    return DataReturn
}


export const DataSortTime1 = (response) => {
    let DataReturn
    console.log(response);

    function ConvertToFloat(value) {

        let checkNan
        checkNan = parseFloat(value)

        if (isNaN(checkNan)) {
            return (value)
        }

        else {
            return (parseFloat(value))
        }

    }


    if (response.success === true) {
        const data = response.data

        DataReturn = data.map(obj => {
            let cobj = []
            let start = moment(obj.fields.Start);
            let DateComponent = start.utc(true).format('DD-MM-YYYY HH:mm:ss')
            let TimeComponent = start.utc(true).format('HH:mm:ss')
            let ar0 = TimeComponent.split(':')
            let ar1 = ar0[0] + ':' + ar0[1]

            let end = moment(obj.fields.End);
            let DateComponent_1 = end.utc(true).format('DD-MM-YYYY HH:mm:ss')
            let TimeComponent_1 = end.utc(true).format('HH:mm:ss')
            let ar0_1 = TimeComponent_1.split(':')
            let ar1_1 = ar0_1[0] + ':' + ar0_1[1]
            // let SV = obj.fields.

            cobj = {
                'key': `${obj.fields.Shift}  ${DateComponent}`,
                'start_time': ar1,
                'end_time': ar1_1,
                'Start': DateComponent,
                'End': DateComponent_1,
                'Shift': obj.fields.Shift,
                'Reason': obj.fields.Reason,
                'Site': obj.fields.Site,
                'Line': obj.fields.Line,
                'Hour': obj.fields.hour,
                'Minute': obj.fields.minute
            }
            return cobj
        })
        //console.log(DataReturn);
    }
    else {
        DataReturn = []
        console.log(response)
    }
    return DataReturn
}

export const DataSortTime2 = (response) => {
    let DataReturn
    console.log(response);

    function ConvertToFloat(value) {

        let checkNan
        checkNan = parseFloat(value)

        if (isNaN(checkNan)) {
            return (value)
        }

        else {
            return (parseFloat(value))
        }

    }


    if (response.success === true) {
        const data = response.data

        DataReturn = data.map(obj => {
            let cobj = []
            let start = moment(obj.fields.Start);
            let DateComponent = start.utc(true).format('DD-MM-YYYY HH:mm:ss')
            let TimeComponent = start.utc(true).format('HH:mm:ss')
            let ar0 = TimeComponent.split(':')
            let ar1 = ar0[0] + ':' + ar0[1]

            let end = moment(obj.fields.End);
            let DateComponent_1 = end.utc(true).format('DD-MM-YYYY HH:mm:ss')
            let TimeComponent_1 = end.utc(true).format('HH:mm:ss')
            let ar0_1 = TimeComponent_1.split(':')
            let ar1_1 = ar0_1[0] + ':' + ar0_1[1]
            // let SV = obj.fields.

            cobj = {
                'key': `${obj.fields.Parameter}  ${DateComponent}`,
                'start_time': ar1,
                'end_time': ar1_1,
                'Start': DateComponent,
                'End': DateComponent_1,
                'Parameter': obj.fields.Parameter,
                'Value': obj.fields.Value,
                'Site': obj.fields.Site,
                'Line': obj.fields.Line,
                'Days': obj.fields.Days,
                'Hours': obj.fields.Hours,
                'Minutes': obj.fields.Minutes,
                'Seconds': obj.fields.Seconds
            }
            return cobj
        })
        //console.log(DataReturn);
    }
    else {
        DataReturn = []
        console.log(response)
    }
    return DataReturn
}


export const ReportSort = (response) => {
    let DataReturn
    if (response.success === true) {
        const data = response.data

        DataReturn = data.map(obj => {
            let cobj = []
            let DateTime_Start = (moment(obj.fields.DateTime_Start)).utc(true).format('DD-MM-YYYY HH:mm:ss')
            let DateTime_End = (moment(obj.fields.DateTime_End)).utc(true).format('DD-MM-YYYY HH:mm:ss')
            // let ar0 = TimeComponent.split(':')
            // let ar1 = ar0[0] + ':' + ar0[1]

            cobj = {
                'key': obj.fields.Info1,
                'Line': obj.fields.Line,
                'Start Time': DateTime_Start,
                'End Time': DateTime_End,
                'Job No': obj.fields.Info1,
                'Grade': obj.fields.Info2,
                'Color': obj.fields.Info3,
            }
            return cobj
        })
        //console.log(DataReturn);
    }
    else {
        console.log(response)
    }
    return DataReturn
}

export const LogDataMappingTime = (Datamodel, RawData) => {
    const name = Object.keys(Datamodel)
    let ReturnValue = []
    let bufferModel = {}
    bufferModel.Parameter = 0
    const TimeModel = Object.keys(Time)

    console.log(RawData)

    for (let i = 0; i < 24; i++) {
        bufferModel[`${TimeModel[i]}`] = ' '
    }

    name.forEach(element => {
        let Buffer = {}
        Object.assign(Buffer, bufferModel)
        Buffer.Parameter = element

        RawData.forEach(element2 => {
            if (element2.time in Buffer) {
                if (element2.Parameter === element) {
                    Buffer[element2.time] = element2.Value
                    Buffer['key'] = element2.Parameter
                }
            }
        })
        ReturnValue.push(Buffer)
    })
    return ReturnValue
}

export const LogDataMappingTime1 = (Datamodel, RawData) => {
    const name = Object.keys(Datamodel)
    let ReturnValue = []
    let bufferModel = {}
    bufferModel.Parameter = 0
    const TimeModel = Object.keys(Time)

    console.log(RawData)

    for (let i = 0; i < 24; i++) {
        bufferModel[`${TimeModel[i]}`] = ' '
    }

    name.forEach(element => {
        let Buffer = {}
        let Buffer2 = {}
        Object.assign(Buffer, bufferModel)
        Buffer.Parameter = element
        Object.assign(Buffer2, bufferModel)
        Buffer2.Parameter = element

        RawData.forEach(element2 => {
            let temp
            let starthour
            let endhour
            if (element2.Parameter === element) {
                console.log(element2);
                temp = element2.end_time.substr(3)
                if (temp == '00') {
                    temp = element2.end_time.substring(0, 2)
                    temp = parseInt(temp)
                    if (temp != 7) {
                        if (temp == 0) {
                            temp = 23
                        }
                        else {
                            temp = temp - 1
                        }
                    }
                    endhour = temp
                    if (temp > 9) {
                        temp = temp.toString().concat(":00")
                    }
                    else {
                        temp = '0'.concat(temp.toString().concat(":00"))
                    }
                    console.log(temp)
                    console.log(endhour)
                }
                else {
                    temp = element2.end_time.substring(0, 2)
                    endhour = parseInt(temp)

                    temp = temp.concat(":00")
                }
                console.log(temp)
                Buffer[temp] = element2.Value

                temp = element2.start_time.substring(0, 2)
                starthour = parseInt(temp)
                temp = temp.concat(":00")
                Buffer[temp] = element2.Value
                Buffer2[temp] = element2.start_time.concat(" - ".concat(element2.end_time))

                // let diff
                // if (end > starthour) {
                //     diff = endhour - starthour - 1
                // }
                // else if (end < starthour) {
                //     diff = (24 - starthour - 1) + endhour
                // }
                // else {
                //     diff = 0
                // }
                if (starthour != endhour) {
                    let first = false
                    let s = false
                    let go = false
                    for (let key in Buffer) {
                        if (key != 'Parameter') {
                            if (key == temp && first != true) {
                                console.log(key, Buffer[key]);
                                first = true
                                go = true
                            }
                            if (go == true) {
                                if (Buffer[key] != element2.Value) {
                                    Buffer[key] = element2.Value
                                }
                                else {
                                    if (s == true) {
                                        go = false
                                    }
                                    s = true
                                }
                            }
                        }
                    }
                }

                Buffer['key'] = element2.Parameter
                Buffer2['key'] = element2.Parameter
            }
        })
        ReturnValue.push(Buffer)
        ReturnValue.push(Buffer2)

    })
    console.log(ReturnValue);
    return ReturnValue
}


export const LogDataMappingTime2 = (Datamodel, RawData) => {
    const name = Object.keys(Datamodel)
    let ReturnValue = []
    let bufferModel = {}
    bufferModel.Parameter = 0
    const TimeModel = Object.keys(Time)
    let highest_count = 0
    let count = 0
    let longest_element = {}

    // for (let i = 0; i < 24; i++) {
    //     bufferModel[`${TimeModel[i]}`] = ' '
    // }
    console.log(RawData);
    name.forEach(element => {
        let Buffer = {}
        Object.assign(Buffer, bufferModel)
        Buffer.Parameter = element

        RawData.forEach(element2 => {
            // console.log(element2)
            if (element2.Parameter === element) {
                Buffer[element2.time] = element2.Value
                Buffer['key'] = element2.Parameter
            }
        })
        ReturnValue.push(Buffer)
    })

    ReturnValue.forEach(element3 => {
        // console.log(element3)
        count = Object.keys(element3).length

        if (count > highest_count) {
            highest_count = count
            longest_element = element3
        }

    })

    ReturnValue.forEach(element3 => {
        if (Object.keys(element3).length < highest_count) {
            for (let i = 0; i < highest_count; i++) {
                element3[Object.keys(longest_element)[i]] ?? (element3[Object.keys(longest_element)[i]] = null)
            }
        }

    })
    let temp = {}
    for (let i = 0; i < highest_count; i++) {
        temp[Object.keys(longest_element)[i]] ?? (temp[Object.keys(longest_element)[i]] = Object.keys(longest_element)[i])
    }
    // console.log(ReturnValue);
    ReturnValue.splice(3, 0, temp)
    ReturnValue.splice(4, 0, ReturnValue[0])
    console.log(ReturnValue);
    return ReturnValue
}


export const LogDataMappingTime3 = (Datamodel, RawData) => {
    const name = Object.keys(Datamodel)
    let ReturnValue = []
    let bufferModel = {}
    bufferModel.Parameter = 0
    const TimeModel = Object.keys(Time)

    console.log(RawData)
    console.log(name)

    for (let i = 0; i < 1; i++) {
        bufferModel[`${i}`] = ' '
    }

    name.forEach(element => {
        let Buffer = {}
        let Buffer2 = {}
        Object.assign(Buffer, bufferModel)
        Buffer.Parameter = element
        Object.assign(Buffer2, bufferModel)
        Buffer2.Parameter = element

        RawData.forEach(element2 => {

            if (element2.Parameter === element) {
                let start_time = element2.Start.substr(11, 5)
                let end_time = element2.End.substr(11, 5)
                console.log(start_time);
                console.log(end_time);
                // Buffer2[name.indexOf(element)] = start_time.concat(" - ".concat(end_time))
                Buffer2['0'] = start_time.concat(" - ".concat(end_time))

                Buffer['key'] = element2.Parameter
                Buffer2['key'] = element2.Parameter
            }
        })
        ReturnValue.push(Buffer)
        ReturnValue.push(Buffer2)
        ReturnValue.push(Buffer)

    })
    console.log(ReturnValue);
    return ReturnValue
}

export const LogDataMappingTime4 = (batch_id, batch_count) => {

    let data_buf = {}
    let ReturnValue = []
    data_buf['key'] = 'batch_count'
    for (let i = 0; i < batch_id.length; i++) {
        data_buf[batch_id[i]] = batch_count[i]
    }
    ReturnValue.push(data_buf)

    // console.log(ReturnValue)
    return ReturnValue

}


export const LogDataMappingName = (Datamodel, RawData, Section) => {
    const name = Object.keys(Datamodel)

    let prevtime = [], period = "", ReturnValue = []
    let temp = ""
    let param = ""

    const parasearch = (item) => {

        if (Section == 3) {
            temp = item.Parameter.replace(" Initial", "");
            temp = temp.replace(" Final", "");
            temp = temp.replace(" Difference", "");
            temp = temp.replace(" Unit per MT", "");
            if (temp == param) {
                return true
            }

        }
        else {
            if (item.date === period) { return true; }
        }
        return false
    }

    RawData.forEach(element => {
        // console.log(element)
        if (element.date === prevtime && Section != 2) { }

        else {
            prevtime = element.date
            period = element.date
            if (Section == 3) {
                param = element.Parameter.replace(" Initial", "");
                param = param.replace(" Final", "");
                param = param.replace(" Difference", "");
                param = param.replace(" Unit per MT", "");
            }
            // console.log(param)

            let data_buf3 = {}
            let data_buf2
            if (Section != 2) {
                data_buf2 = RawData.filter(parasearch)
            }
            else {
                data_buf2 = [element]
            }
            // console.log(parasearch)
            // console.log(data_buf2)

            if (Section == 2) {
                data_buf2.map(obj => {
                    data_buf3['key'] = obj.Start.concat(obj.End)
                    data_buf3['From'] = obj.Start
                    data_buf3['To'] = obj.End
                    data_buf3['Shift'] = obj.Shift
                    data_buf3['Hour'] = obj.Hour
                    data_buf3['Minute'] = obj.Minute
                    data_buf3['Reason'] = obj.Reason
                    return data_buf3
                })
            }
            else if (Section == 3) {
                data_buf2.map(obj => {
                    // console.log(obj)
                    let t = obj.Parameter.replace(param, "")
                    t = t.replace(" ", "")
                    data_buf3['key'] = param
                    data_buf3['DateTime'] = obj.date
                    data_buf3['Parameter'] = param
                    data_buf3[t] = obj.Value
                    return data_buf3
                })
            }
            else {
                data_buf2.map(obj => {
                    data_buf3['key'] = obj.date
                    data_buf3['DateTime'] = obj.date
                    data_buf3[obj.Parameter] = obj.Value
                    return data_buf3
                })
            }
            // console.log(data_buf3)
            ReturnValue.push(data_buf3)
        }
    })
    // console.log(ReturnValue)
    return ReturnValue
}


export const LogDataMappingName1 = (Datamodel, RawData, Section) => {
    const name = Object.keys(Datamodel)

    console.log(Section);

    let prevtime = [], period = "", ReturnValue = []
    let temp = ""
    let param = ""

    const parasearch = (item) => {

        if (Section == 3) {
            temp = item.Parameter.replace(" Initial", "");
            temp = temp.replace(" Final", "");
            temp = temp.replace(" Difference", "");
            temp = temp.replace(" Unit per MT", "");
            if (temp == param) {
                return true
            }

        }
        else {
            if (item.date === period) { return true; }
        }
        return false
    }

    RawData.forEach(element => {
        // console.log(element)
        if (element.date === prevtime && Section != 2) { }

        else {
            prevtime = element.date
            period = element.date
            if (Section == 3) {
                param = element.Parameter.replace(" Initial", "");
                param = param.replace(" Final", "");
                param = param.replace(" Difference", "");
                param = param.replace(" Unit per MT", "");
            }
            // console.log(param)

            let data_buf3 = {}
            let data_buf2
            if (Section != 2) {
                data_buf2 = RawData.filter(parasearch)
            }
            else {
                data_buf2 = [element]
            }
            // console.log(parasearch)
            // console.log(data_buf2)

            if (Section == 2) {
                data_buf2.map(obj => {
                    data_buf3['key'] = obj.Start.concat(obj.End)
                    data_buf3['From'] = obj.Start
                    data_buf3['To'] = obj.End
                    data_buf3['Shift'] = obj.Shift
                    data_buf3['Hour'] = obj.Hour
                    data_buf3['Minute'] = obj.Minute
                    data_buf3['Reason'] = obj.Reason
                    return data_buf3
                })
            }
            else if (Section == 3) {
                data_buf2.map(obj => {
                    // console.log(obj)
                    let t = obj.Parameter.replace(param, "")
                    t = t.replace(" ", "")
                    data_buf3['key'] = param
                    data_buf3['DateTime'] = obj.date
                    data_buf3['Parameter'] = param
                    data_buf3[t] = obj.Value
                    return data_buf3
                })
            }
            else {
                data_buf2.map(obj => {
                    // console.log(obj);
                    let temp = obj.date.substr(14, 2)
                    // console.log(temp);
                    if (temp == '00') {
                        // console.log('yes');
                        data_buf3['key'] = obj.date
                        data_buf3['DateTime'] = obj.date
                        data_buf3[obj.Parameter] = obj.Value
                        return data_buf3
                    }
                })
            }
            // console.log(data_buf3)
            if (Object.keys(data_buf3).length != 0) {
                // console.log('yesss');
                ReturnValue.push(data_buf3)
            }
        }
    })
    // console.log(ReturnValue)
    return ReturnValue
}


export const LogDataMappingName3 = (batch_id, batch_count) => {

    let ReturnValue = []
    for (let i = 0; i < batch_id.length; i++) {
        let data_buf = {}
        data_buf['Batch'] = batch_id[i]
        data_buf['Number'] = batch_count[i]
        data_buf['key'] = batch_id[i]
        ReturnValue.push(data_buf)
    }

    // console.log(ReturnValue)
    return ReturnValue
}

export const LogDataMappingStep = (Datamodel, RawData, Section) => {
    const name = Object.keys(Datamodel)

    let prevtime = [], period = "", ReturnValue = []

    const parasearch = (item) => {

        if (item.Start === period) { return true; }
        return false
    }

    // console.log(RawData);

    RawData.forEach(element => {

        if (element.Start === prevtime && (Section != 5 && Section != 6)) { }

        else {
            prevtime = element.Start
            period = element.Start

            let data_buf3 = {}
            let data_buf2

            if (Section != 5 && Section != 6) {
                data_buf2 = RawData.filter(parasearch)
            }
            else {
                data_buf2 = [element]
            }

            // console.log(parasearch)
            // console.log(data_buf2)

            if (Section == 5) {
                data_buf2.map(obj => {
                    data_buf3['key'] = obj.Start.concat(obj.End).concat(obj.Parameter)
                    data_buf3['Start'] = obj.Start
                    data_buf3['End'] = obj.End
                    data_buf3[obj.Parameter] = obj.Value
                    data_buf3['Hours'] = obj.Hours
                    data_buf3['Minutes'] = obj.Minutes
                    data_buf3['Seconds'] = obj.Seconds
                    data_buf3['Days'] = obj.Days
                    return data_buf3
                })
            }

            else {
                // console.log(data_buf2[0].Parameter.split('_')[1])

                if (data_buf2[0].Parameter.split('_')[1] != undefined) {
                    if (Section != 6) {
                        data_buf2.map(obj => {
                            data_buf3['key'] = obj.Start.concat(obj.End).concat(obj.Parameter)
                            data_buf3['Start'] = obj.Start
                            data_buf3['End'] = obj.End
                            data_buf3[obj.Parameter.split('_')[1]] = obj.Value
                            data_buf3['Hours'] = obj.Hours
                            data_buf3['Minutes'] = obj.Minutes
                            data_buf3['Seconds'] = obj.Seconds
                            data_buf3['Days'] = obj.Days
                            return data_buf3
                        })
                    }
                    else {
                        data_buf2.map(obj => {
                            data_buf3['key'] = obj.Start.concat(obj.End).concat(obj.Parameter)
                            data_buf3['Start'] = obj.Start.substr(11, 8)
                            data_buf3['End'] = obj.End.substr(11, 8)
                            // data_buf3['StartDT'] = obj.Start
                            // data_buf3['EndDT'] = obj.End
                            data_buf3[obj.Parameter] = obj.Value
                            data_buf3['Parameter'] = obj.Parameter.split('_')[1]
                            data_buf3['Hours'] = obj.Hours
                            data_buf3['Minutes'] = obj.Minutes
                            data_buf3['Seconds'] = obj.Seconds
                            data_buf3['Days'] = obj.Days
                            return data_buf3
                        })
                    }
                }
                else {
                    data_buf2.map(obj => {
                        data_buf3['key'] = obj.Start.concat(obj.End).concat(obj.Parameter)
                        data_buf3['Start'] = obj.Start
                        data_buf3['End'] = obj.End
                        data_buf3[obj.Parameter] = obj.Value
                        data_buf3['Hours'] = obj.Hours
                        data_buf3['Minutes'] = obj.Minutes
                        data_buf3['Seconds'] = obj.Seconds
                        data_buf3['Days'] = obj.Days
                        return data_buf3
                    })
                }
            }

            ReturnValue.push(data_buf3)
        }
    })

    // console.log(ReturnValue);

    return ReturnValue
}


export const SortToArrayExcel = (DataJson, DataModel) => {
    // console.log(DataJson);
    // let bufferArray = []
    let bufferArray2 = []
    const para = Object.keys(DataJson[1])
    const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }
    // console.log(para);
    // console.log(name);
    // bufferArray.push(DataJson[1]['Time'])

    DataJson.forEach(element => {
        let bufferArray = []
        bufferArray.push(element['Time'])

        name.forEach(element1 => {

            para.forEach(element2 => {
                // console.log('new');
                // console.log(element1);
                // console.log(element2);

                let number

                if (element2 === element1) {
                    try {
                        //console.log(element2);

                        number = parseFloat(element[element2])
                    }
                    catch {
                        console.log('error');
                        number = parseFloat(0)
                    }

                    //console.log(LogValue['LogC3'][1][element2])
                    bufferArray.push(number)
                }
            })

        })
        bufferArray2.push(bufferArray)
    });

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = bufferArray2[0].length

    return ReturnPara
}


export const ExportExcelWorkBook = (DataJson, DataModel, startCol, startRow, endCol, endRow, FileName, JobInfo, Cell) => {

    fetch(FileName)
        .then(res => res.arrayBuffer())
        .then(buffer => {
            XlsxPopulate.fromDataAsync(buffer)
                .then(workbook => {

                    // r = workbook.sheet(0).cell("A8").value("foo");
                    let setting = SortToArrayExcel(DataJson, DataModel)

                    const r = workbook.sheet(0).range(`${startCol}${startRow}:${endCol}${endRow + setting.height}`);
                    const Jobname = workbook.sheet(0).cell(Cell[0]).value(`Job No: ${JobInfo.Info1}`);
                    const Grade = workbook.sheet(0).cell(Cell[1]).value(`Grade: ${JobInfo.Info5}`);
                    const Colour = workbook.sheet(0).cell(Cell[2]).value(`Colour: ${JobInfo.Info4}`);
                    const Start = workbook.sheet(0).cell(Cell[3]).value(`Start: ${JobInfo.Info2}`);
                    const End = workbook.sheet(0).cell(Cell[4]).value(`End: ${JobInfo.Info3}`);




                    r.value(setting.Array);

                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob, "out.xlsx");
                            }

                            else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = FileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                })
                .catch(err => console.error(err));
        })
}

export const SortToArrayExcelFGV = (DataJson, DataModel) => {
    let bufferArray2 = []
    const name = Object.keys(DataModel)
    // console.log('name')
    // console.log(name)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }
    // console.log('json')
    // console.log(DataJson);

    for (let index = 0; index < DataJson.length; index++) {

        let bufferArray = []
        const para = Object.keys(DataJson[index])


        name.forEach(element => {
            let number = ''

            para.forEach(element2 => {

                if (element2 === element) {
                    number = parseFloat(element2[element])
                    if (isNaN(number)) {
                        number = DataJson[index][element2]
                    }
                }
                else {

                }
            })
            bufferArray.push(number)
        });

        bufferArray.shift()
        bufferArray.shift()

        bufferArray2.push(bufferArray)
        // console.log(bufferArray2);
    }

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = bufferArray2[0].length

    return ReturnPara
}

export const SortToArrayExcelFGV3 = (DataJson, DataModel) => {
    let bufferArray2 = []
    const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }

    // console.log(DataJson)

    for (let index = 0; index < DataJson.length; index++) {

        let bufferArray = []
        const para = Object.keys(DataJson[index])


        name.forEach(element => {
            // console.log(name);
            let number = ''

            para.forEach(element2 => {

                if (element2 === element) {
                    // console.log(element2)
                    number = parseFloat(element2[element])

                    if (isNaN(number)) {
                        // console.log('nansda');
                        number = DataJson[index][element2]
                    }
                }
                else {

                }
            })
            bufferArray.push(number)
            // console.log(number)

        });

        bufferArray2.push(bufferArray)
    }
    console.log(bufferArray2)

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = (bufferArray2[0] || 0).length

    return ReturnPara
}

export const SortToArrayExcelFGV3_1 = (DataJson, DataModel) => {
    let bufferArray2 = []
    // const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }

    let properties = ['Shift', 'From', 'To', 'Hour', 'Minute', 'Reason']
    DataJson.forEach(element2 => {
        let index = 0
        let bufferArray = []

        for (let key in element2) {
            if (key != "key") {

                let temp
                temp = element2[properties[index]]
                if (index != 1 && index != 2) {
                    temp = parseFloat(temp)

                    if (isNaN(temp)) {
                        temp = element2[properties[index]]
                    }
                }
                if (index == 1 || index == 2) {
                    temp = temp.substr(11)
                }


                if (index > 1 && index < 6) {
                    bufferArray.push(temp)
                    bufferArray.push(null)
                }
                else {
                    bufferArray.push(temp)
                }
                index = index + 1
            }
        }
        bufferArray2.push(bufferArray)
    })

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = (bufferArray2[0] || 0).length
    return ReturnPara
}


export const SortToArrayExcelFGV3_2 = (DataJson, DataModel) => {
    let bufferArray2 = []
    // const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }

    let properties = ['Parameter', 'Initial', 'Final', 'Difference', 'Unit per MT']
    DataJson.forEach(element2 => {
        let index = 0
        let bufferArray = []

        for (let key in element2) {
            if (key != "key") {

                let temp
                temp = element2[properties[index]]
                if (index == 1 || index == 2) {
                    temp = parseFloat(temp)

                    if (isNaN(temp)) {
                        temp = element2[properties[index]]
                    }
                }
                bufferArray.push(temp)
                bufferArray.push(null)
                bufferArray.push(null)

                index = index + 1
            }
        }
        bufferArray2.push(bufferArray)
    })

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = (bufferArray2[0] || 0).length

    return ReturnPara
}

export const SortToArrayExcelFGV3_3 = (DataJson, DataModel) => {
    let bufferArray2 = []
    // const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }
    console.log(DataJson);
    DataJson.forEach(element2 => {

        // let bufferArray = []

        // bufferArray.push(element2['0'])


        bufferArray2.push([element2['0']])
    })

    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = (bufferArray2[0] || 0).length

    return ReturnPara
}


export const SortToArrayExcelFGV3_4 = (DataJson, DataModel) => {
    let bufferArray2 = [], bufferArray = [], total = 0
    // const name = Object.keys(DataModel)
    let ReturnPara = {
        Array: [],
        height: 1,
        width: 1
    }
    console.log(DataJson);

    for (let key in DataJson[0]) {
        if (key != 'key') {
            bufferArray.push(DataJson[0][key])
            total = total + DataJson[0][key]
        }
    }
    console.log(total);
    bufferArray.push(total)
    bufferArray2.push(bufferArray)
    ReturnPara.Array = bufferArray2
    ReturnPara.height = bufferArray2.length
    ReturnPara.width = (bufferArray2[0] || 0).length

    return ReturnPara
}




export const ExportExcelWorkBookFGV = (DataJson1, DataJson2, DataJson3, DataModel1, DataModel2, DataModel3, Excelformat, filename, ExportName) => {

    let date = DataJson1[0].DateTime.split(' ')[0]

    console.log(date);
    fetch(filename)
        .then(res => res.arrayBuffer())
        .then(buffer => {
            XlsxPopulate.fromDataAsync(buffer)
                .then(workbook => {

                    // r = workbook.sheet(0).cell("A8").value("foo");
                    let setting1 = SortToArrayExcelFGV(DataJson1, DataModel1)
                    let setting2 = SortToArrayExcelFGV(DataJson2, DataModel2)
                    let setting3 = SortToArrayExcelFGV3(DataJson3, DataModel3)
                    setting3.Array.shift()
                    setting3.Array.shift()

                     console.log(setting1);
                    // let setting3 = SortToArrayExcelFGV(DataJson3, DataModel3)


                    const sheet1 = workbook.sheet(0).range(`${Excelformat[0].startColumb}${Excelformat[0].startRow}:${Excelformat[0].stopColumb}${Excelformat[0].stopRow + setting1.height}`);
                    const sheet2 = workbook.sheet(1).range(`${Excelformat[1].startColumb}${Excelformat[1].startRow}:${Excelformat[1].stopColumb}${Excelformat[1].stopRow + setting2.height}`);
                    const sheet1_date = workbook.sheet(0).range('Y4:Y4');
                    const sheet2_date = workbook.sheet(1).range('Z4:Z4');
                    const sheet3_date = workbook.sheet(2).range('X7:X7');
                    const sheet3_1 = workbook.sheet(2).range('D10:AA10');
                    const sheet3_2 = workbook.sheet(2).range('D12:AA12');
                    const sheet3_3 = workbook.sheet(2).range('D14:AA14');
                    const sheet3_4 = workbook.sheet(2).range('D16:AA16');
                    const sheet3_5 = workbook.sheet(2).range('D18:AA18');
                    const sheet3 = workbook.sheet(2).range('D20:AA29');

                    // console.log(setting3.Array.slice(5));

                    sheet1_date.value(date)
                    sheet2_date.value(date)
                    sheet3_date.value(date)

                    sheet1.value(setting1.Array);
                    sheet2.value(setting2.Array);
                    sheet3_1.value([setting3.Array[0]]);
                    sheet3_2.value([setting3.Array[1]]);
                    sheet3_3.value([setting3.Array[2]]);
                    sheet3_4.value([setting3.Array[3]]);
                    sheet3_5.value([setting3.Array[4]]);
                    sheet3.value(setting3.Array.slice(5));


                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob, "out.xlsx");
                            }

                            else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = ExportName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                })
                .catch(err => console.error(err));
        })
}


export const ExportExcelWorkBookFGVTT = (DataJson1, DataJson1_1, DataJson1_2, DataJson2, DataJson3, DataJson3_1, DataJson3_2, DataJson3_3, DataModel1, DataModel2, DataModel3, DataModel3_1, DataModel3_2, Excelformat, filename, ExportName) => {

    // let setting1 = SortToArrayExcelFGV3(DataJson1, DataModel1)
    // let setting2 = SortToArrayExcelFGV3(DataJson2, DataModel2)
    // let setting3 = SortToArrayExcelFGV3(DataJson3, DataModel3)
    // setting3.Array.shift()
    // setting3.Array.shift()
    // setting2.Array.shift()
    // setting2.Array.shift()

    // console.log(setting1);
    // console.log(setting2);
    // console.log(setting3);


    fetch(filename)
        .then(res => res.arrayBuffer())
        .then(buffer => {
            XlsxPopulate.fromDataAsync(buffer)
                .then(workbook => {

                    let count = 0
                    let highest_count = 0

                    DataJson3.forEach(element3 => {
                        // console.log(element3)
                        count = Object.keys(element3).length

                        if (count > highest_count) {
                            highest_count = count
                        }

                    })

                    console.log(DataJson3_3)
                    console.log(DataJson1)


                    let d = {}
                    let e = {}

                    for (let i = 0; i < highest_count; i++) {

                        d = DataJson3[3]
                        console.log(d)
                        e = Object.keys(d)[i]
                        console.log(e)
                        if (e.toString() != "Parameter" && e.toString() != "key") {

                            DataModel3[e] ?? (DataModel3[e] = null)
                        }
                    }

                    d = {}
                    e = {}

                    for (let k = 0; k < Object.keys(DataJson3).length; k++) {
                        for (let i = 0; i < highest_count; i++) {
                            d = DataJson3[k]
                            e = Object.keys(d)[i]
                            // console.log(d)
                            if (d['Parameter'].toString().includes("Time") && d[e] != null && e.toString() != "Parameter" && e.toString() != "key") {
                                // console.log(d[e])
                                d[e] = d[e].substr(11, 5)
                            }
                        }
                    }


                    console.log(DataModel3)

                    let setting1 = SortToArrayExcelFGV3(DataJson1, DataModel1)
                    let setting1_1 = SortToArrayExcelFGV3(DataJson1_1, DataModel1)
                    let setting1_2 = SortToArrayExcelFGV3_3(DataJson1_2, DataModel3)
                    let setting2 = SortToArrayExcelFGV3(DataJson2, DataModel2)
                    let setting3 = SortToArrayExcelFGV3(DataJson3, DataModel3)
                    let setting3_1 = SortToArrayExcelFGV3_1(DataJson3_1, DataModel3_1)
                    let setting3_2 = SortToArrayExcelFGV3_2(DataJson3_2, DataModel3_2)
                    let setting3_3 = SortToArrayExcelFGV3_4(DataJson3_3, DataModel3_2)


                    // setting2.Array.forEach(element => {
                    //     element.shift()
                    // });
                    console.log(setting3_3)
                    console.log(setting1_2)
                    setting3.Array.shift()
                    setting3.Array.shift()
                    setting3.Array.shift()
                    setting3.Array.shift()
                    setting2.Array.shift()
                    setting2.Array.shift()

                    console.log(setting1)
                    // console.log(setting3_1)
                    // console.log(setting3_2)

                    const cellColor = (arr, rowNo) => {
                        let columnNo = 4
                        let columnAlpha

                        for (let i = 0; i < arr.length; i++) {

                            let CharCode = columnNo + 64
                            if (CharCode > 90) {
                                columnAlpha = String.fromCharCode(CharCode % 90 + 64)
                                // console.log(CharCode);
                                // console.log(CharCode%90);
                                // console.log(columnAlpha);
                                let temp = columnAlpha

                                let multiple = CharCode / 90
                                multiple = Math.floor(multiple)
                                for (let j = 0; j < multiple; j++) {
                                    columnAlpha = columnAlpha.concat(temp)
                                }
                            }
                            else {
                                columnAlpha = String.fromCharCode(CharCode)
                            }

                            if (arr[i] == "0") {
                                // console.log(columnNo+64)
                                console.log(columnAlpha)
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "922B21 ")
                            }
                            else if (arr[i] == "1") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "F7DC6F")
                            }
                            else if (arr[i] == "2") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "EB984E")
                            }
                            else if (arr[i] == "3") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "F1948A")
                            }
                            else if (arr[i] == "4") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "7DCEA0")
                            }
                            else if (arr[i] == "5") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "3498DB")
                            }
                            else if (arr[i] == "-2") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(columnAlpha.concat(rowNo)).style("fill", "DCFFA9")
                            }
                            columnNo = columnNo + 1
                        }

                    }

                    const cellColor1 = (arr, rowNo) => {
                        let columnNo = 4
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i] == "0") {
                                // console.log(String.fromCharCode(columnNo+64))
                                workbook.sheet(2).cell(String.fromCharCode(columnNo + 64).concat(rowNo)).style("fill", "AEB6BF ")
                            }
                            columnNo = columnNo + 1
                        }

                    }

                    if (ExportName == 'Tirtiaux') {
                        cellColor(setting1.Array[0], '15')
                        cellColor(setting1.Array[2], '18')
                        cellColor(setting1.Array[4], '21')

                        cellColor1(setting1_1.Array[0], '24')


                        // const sheet1_1 = workbook.sheet(2).range('C15:Z15');
                        const sheet_1_1 = workbook.sheet(2).range('D16:AA16');
                        // const sheet1_2 = workbook.sheet(2).range('C18:Z18');
                        const sheet_1_2 = workbook.sheet(2).range('D19:AA19');
                        // const sheet1_3 = workbook.sheet(2).range('C21:Z21');
                        const sheet_1_3 = workbook.sheet(2).range('D22:AA22');
                        // const sheet1_3_1 = workbook.sheet(2).range('C24:Z24');
                        const sheet_1_3_1 = workbook.sheet(2).range('D25:AA25');
                        const sheet_1_3_2 = workbook.sheet(2).cell("C15");

                        const sheet1_4 = workbook.sheet(2).range('D27:Z36');

                        // sheet1_1.value([setting1.Array[0]])
                        sheet_1_1.value([setting1.Array[1]])

                        // sheet1_2.value([setting1.Array[2]])
                        sheet_1_2.value([setting1.Array[3]])

                        // sheet1_3.value([setting1.Array[4]])
                        sheet_1_3.value([setting1.Array[5]])

                        // sheet1_3_1.style("fill", "0000ff")
                        sheet_1_3_1.value([setting1_1.Array[1]])

                        console.log(setting1_2.Array);
                        sheet_1_3_2.value(setting1_2.Array)

                        console.log(setting2.Array);
                        sheet1_4.value(setting2.Array)
                    }

                    else if (ExportName == 'OiltekN') {
                        cellColor(setting1.Array[0], '15')
                        cellColor(setting1.Array[2], '18')
                        cellColor(setting1.Array[4], '21')
                        cellColor(setting1.Array[6], '24')
                        cellColor(setting1.Array[8], '27')

                        cellColor1(setting1_1.Array[0], '30')


                        // const sheet1_1 = workbook.sheet(2).range('C15:Z15');
                        const sheet_1_1 = workbook.sheet(2).range('D16:AA16');
                        // const sheet1_2 = workbook.sheet(2).range('C18:Z18');
                        const sheet_1_2 = workbook.sheet(2).range('D19:AA19');
                        // const sheet1_3 = workbook.sheet(2).range('C21:Z21');
                        const sheet_1_3 = workbook.sheet(2).range('D22:AA22');

                        const sheet_1_4 = workbook.sheet(2).range('D25:AA25');
                        const sheet_1_5 = workbook.sheet(2).range('D28:AA28');

                        // const sheet1_3_1 = workbook.sheet(2).range('C24:Z24');
                        const sheet_1_3_1 = workbook.sheet(2).range('D31:AA31');
                        const sheet_1_3_2 = workbook.sheet(2).cell("C15");


                        const sheet1_4 = workbook.sheet(2).range('Z33:Z42');

                        // sheet1_1.value([setting1.Array[0]])
                        sheet_1_1.value([setting1.Array[1]])

                        // sheet1_2.value([setting1.Array[2]])
                        sheet_1_2.value([setting1.Array[3]])

                        // sheet1_3.value([setting1.Array[4]])
                        sheet_1_3.value([setting1.Array[5]])

                        sheet_1_4.value([setting1.Array[7]])

                        sheet_1_5.value([setting1.Array[9]])

                        // sheet1_3_1.style("fill", "0000ff")
                        sheet_1_3_1.value([setting1_1.Array[1]])

                        sheet_1_3_2.value(setting1_2.Array)

                        sheet1_4.value(setting2.Array)


                    }

                    else if (ExportName == 'JJLurgi') {
                        cellColor(setting1.Array[0], '15')
                        cellColor(setting1.Array[2], '18')
                        cellColor(setting1.Array[4], '21')
                        cellColor(setting1.Array[6], '24')

                        cellColor1(setting1_1.Array[0], '27')


                        // const sheet1_1 = workbook.sheet(2).range('C15:Z15');
                        const sheet_1_1 = workbook.sheet(2).range('D16:AA16');
                        // const sheet1_2 = workbook.sheet(2).range('C18:Z18');
                        const sheet_1_2 = workbook.sheet(2).range('D19:AA19');
                        // const sheet1_3 = workbook.sheet(2).range('C21:Z21');
                        const sheet_1_3 = workbook.sheet(2).range('D22:AA22');
                        const sheet_1_4 = workbook.sheet(2).range('D25:AA25');
                        // const sheet1_3_1 = workbook.sheet(2).range('C24:Z24');
                        const sheet_1_3_1 = workbook.sheet(2).range('D28:AA28');
                        const sheet_1_3_2 = workbook.sheet(2).cell("C15");


                        const sheet1_4 = workbook.sheet(2).range('D30:Z39');

                        // sheet1_1.value([setting1.Array[0]])
                        sheet_1_1.value([setting1.Array[1]])

                        // sheet1_2.value([setting1.Array[2]])
                        sheet_1_2.value([setting1.Array[3]])

                        // sheet1_3.value([setting1.Array[4]])
                        sheet_1_3.value([setting1.Array[5]])

                        sheet_1_4.value([setting1.Array[7]])

                        // sheet1_3_1.style("fill", "0000ff")
                        sheet_1_3_1.value([setting1_1.Array[1]])

                        sheet_1_3_2.value(setting1_2.Array)

                        sheet1_4.value(setting2.Array)
                    }



                    const sheet2_1 = workbook.sheet(3).range('C7:AA26');
                    const sheet2_2 = workbook.sheet(3).range('A32:I34');
                    const sheet2_3 = workbook.sheet(3).range('A39:M48');
                    const sheet2_4 = workbook.sheet(3).range('Q39:V39');



                    // console.log(setting3.Array.slice(5));
                    console.log(setting3_2.Array);
                    console.log(setting3_3.Array);
                    sheet2_1.value(setting3.Array)
                    sheet2_2.value(setting3_1.Array)
                    sheet2_3.value(setting3_2.Array)
                    sheet2_4.value(setting3_3.Array)


                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob, "out.xlsx");
                            }

                            else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = ExportName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                })
                .catch(err => console.error(err));
        })
}

export const FilterOutEndedJob = (JobList) => {
    let FilterJob = []

    const Current = new Date()
    const NowTime = new Date(moment(Current).format('DD-MM-yyyy HH:mm:ss')) //when wan to change datetime from (new dete()) to the usual view

    JobList.forEach(element => {
        try {
            let DateBuf = new Date(element['End Time'])

            if (DateBuf > NowTime) {
                FilterJob.push(element)
            }
        }
        catch { }
    });

    return FilterJob
}

export const VisualizeLogColumb = (DataOption) => {

    let data_filter = DataOption.map(obj => {
        let cobj = []

        cobj = {
            text: obj,
            value: obj,
        }
        return cobj
    })

    const parseDMYhm = (s) => {
        var b = s.split(/\D/);
        return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
    }

    const columns = [
        {
            title: 'Parameter',
            dataIndex: 'Parameter',
            filters: data_filter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.Parameter.includes(value),
            width: '30%',
        },
        {
            title: 'Value',
            dataIndex: 'Value',
            sorter: (a, b) => a.Value - b.Value,
        },
        {
            title: 'time',
            dataIndex: 'time',
            sorter: (a, b) => parseDMYhm(a.time) - parseDMYhm(b.time),
        },
    ];

    return columns
}

