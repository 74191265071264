import React from 'react'
import JJLurgiPG1Noti from './Notification/TirtiauxPG1Noti'

const JJLurgiNotification = () => {
    return (
        <div>
            <JJLurgiPG1Noti/>
        </div>
    )
}

export default JJLurgiNotification
