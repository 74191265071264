import { Button, Col, Divider, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { RealtimeData_Request } from '../../api'
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap'
import HMIWrapper from '../Realtime/HMIWrapper'
import RealTimeGraphWrapper from '../Realtime/RealTimeGraphWrapper'
import RealTimeTableWrapper from '../Realtime/RealTimeTableWrapper'
import OiltekBF from './HMI/OiltekBF';
import OiltekDeo1 from './HMI/OiltekDeo1';
import OiltekFatty from './HMI/OiltekFatty';
import OiltekDeo2 from './HMI/OiltekDeo2';
import { OTKModelPage1, OTKModelPage2, OTKModelPage3 } from '../../DataRequest/OiltekModel'

let PG1DataModel = OTKModelPage1
let PG2DataModel = OTKModelPage2
let PG3DataModel = OTKModelPage3


let RealTimeValue = {
    "PG1HMI": PG1DataModel,
    "PG2HMI": PG2DataModel,
    "PG3HMI": PG3DataModel,
    "PG1Table": [],
    "PG2Table": [],
    "PG3Table": [],
    "PG1Graph": [],
    "PG2Graph": [],
    "PG3Graph": [],
}


const OiltekRT = () => {
    const [state, setState] = useState({ count: 0 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        RealTimeValue[HMIName] = RealTimeValue[TableName]
        // console.log(RealTimeValue[HMIName])
    }

    const RenderNewData = () => {

        DataRequest([], 'FGV', '1', 'Oiltek', 'PG1HMI', "PG1Table")
        DataRequest([], 'FGV', '2', 'Oiltek', 'PG2HMI', "PG2Table")
        DataRequest([], 'FGV', '3', 'Oiltek', 'PG3HMI', "PG3Table")

        setState(({ count }) => ({ count: count + 1 }));
    }

    useEffect(() => {
        RenderNewData()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [])


    return (
        <div>
            <HMIWrapper Tab={['Bleaching', 'Deodorization 1', 'Deodorization 2', 'FattyAcid']}>
                <OiltekBF key={'Bleaching'} Parameter={RealTimeValue['PG1HMI']} />
                <OiltekDeo1 key={'Deodorization 1'} Parameter={RealTimeValue['PG2HMI']} />
                <OiltekDeo2 key={'Deodorization 2'} Parameter={RealTimeValue['PG2HMI']} />
                <OiltekFatty key={'FattyAcid'} Parameter={RealTimeValue['PG2HMI']} Expara={RealTimeValue['PG3HMI']} />
            </HMIWrapper>

            <Divider orientation="left">Parameter List</Divider>

            <div>
                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} xl={8}>
                        <RealTimeTableWrapper TableTitle={'Bleaching'} TableData={RealTimeValue['PG1Table']} />
                    </Col>
                    <Col span={12} xs={24} xl={8}>
                        <RealTimeTableWrapper TableTitle={'Deodorization'} TableData={RealTimeValue['PG2Table']} />
                    </Col>
                    <Col span={12} xs={24} xl={8}>
                        <RealTimeTableWrapper TableTitle={'Ancilary Equipments'} TableData={RealTimeValue['PG3Table']} />
                    </Col>

                </Row>
            </div>

            <Divider orientation="left" style={{marginTop:'30px'}}>Trending Graph</Divider>


            <div>
                <Row gutter={[16, 16]}>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'Bleaching & Refining'}
                            DataModel={PG1DataModel}
                            RawDataReceive={RealTimeValue['PG1Table']}
                            Divname={'BFGraph'}
                        />
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                        <RealTimeGraphWrapper GarphTitle={'Deodorization'}
                            DataModel={PG2DataModel}
                            RawDataReceive={RealTimeValue['PG2Table']}
                            Divname={'DeoGraph'}
                        />
                    </Col>

                </Row>
            </div>

        </div>
    )
}

export default OiltekRT
