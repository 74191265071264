import React from 'react'
import AlfaPG1Noti from './Notification/AlfaPG1Noti'
import AlfaPG2Noti from './Notification/AlfaPG2Noti'
import AlfaPG3Noti from './Notification/AlfaPG3Noti'

const AlfaNotification = () => {
    return (
        <div>
            <AlfaPG1Noti/>
            <AlfaPG2Noti/>
            <AlfaPG3Noti/>
        </div>
    )
}

export default AlfaNotification
