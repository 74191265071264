import { Button, Col, Divider, Row, DatePicker, Card, Table, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, RealtimeData_Request } from '../../api'
import { ConvertMonentToDateTime, DataSortTime, GetDateTime } from '../../DataRequest/LogDataRequestWrap'
import { Line } from '@antv/g2plot';
import moment from 'moment';
import { JJLurgiModelPage1, JJLurgiModelPage2, JJLurgiModelPage3 } from '../../DataRequest/JJLurgiModel';


let DTinitial = GetDateTime(1,1)
let DTO1 = [] 
let O1DTRange = []

DTO1.push(moment(DTinitial[2]))
DTO1.push(moment(DTinitial[3]))


let LogValue = {
    "JJLurgi1Table": [],
    "JJLurgi1Graph": [],
    "JJLurgi1Selected": [],
}

const { RangePicker } = DatePicker;
const { Option } = Select;

let Option1 = Object.keys(JJLurgiModelPage2)
Option1.shift()
Option1.shift()


const OptionSelector1 = [];

LogValue['JJLurgi1Selected'] = [Option1[0], Option1[1]];


Option1.forEach(element => {
    OptionSelector1.push(<Option key={element}>{element}</Option>)
});

let data_filter1 = Option1.map(obj => {
    let cobj = []

    cobj = {
        text: obj,
        value: obj,
    }
    return cobj
})

const parseDMYhm1 = (s) => {
    var b = s.split(/\D/);
    return new Date(b[2], b[1] - 1, b[2], b[3], b[4]);
}

const columns1 = [
    {
        title: 'Parameter',
        dataIndex: 'Parameter',
        filters: data_filter1,
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.Parameter.includes(value),
        width: '30%',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        sorter: (a, b) => a.Value - b.Value,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => parseDMYhm1(a.date) - parseDMYhm1(b.date),
    },
];

let line

const JJLurgiDataLog = () => {

    const [state, setState] = useState({ count: 0 });

    useEffect(() => {
        console.log('Reffect');

        line = new Line('JJLurgiGraph1', {
            data: [],
            xField: 'date',
            yField: 'Value',
            seriesField: 'Parameter',
        })

        try { line.render() }
        catch { }

        return () => {
            line.destroy();
        }
    }, [])

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        console.log(response);
        let data = DataSortTime(response)
        LogValue[BufferName] = data
        console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const T1LogRequest = async (DT) => {
        DTO1 = DT
        // console.log(DTO1);
        O1DTRange = ConvertMonentToDateTime(DT)
        // console.log(O1DTRange);
        await UpdateTableData(LogValue['JJLurgi1Selected'], O1DTRange[0], O1DTRange[1], 'FGV', 'JJLurgi', '2', "JJLurgi1Table", JJLurgiModelPage2)
        // console.log(LogValue["JJLurgi1Table"]);
        line.changeData(LogValue["JJLurgi1Table"])
    }

    const ChangeO1Select = (value) => {
        LogValue['JJLurgi1Selected'] = value
        console.log(value);
    }

    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">JJLurgi Page 1</Divider>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <RangePicker showTime onOk={T1LogRequest} />
                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { T1LogRequest(DTO1) }}>Update</Button>
            </div>
            <Select
                mode="multiple"
                size="middle"
                placeholder="Please select"
                defaultValue={[Option1[0], Option1[1]]}
                style={{ width: '100%', paddingBottom: '20px' }}
                onChange={ChangeO1Select}
            >
                {OptionSelector1}
            </Select>

            <Card title='JJLurgi PG 1' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={columns1} dataSource={LogValue['JJLurgi1Table']} size="small" scroll={{ y: 500 }} pagination={false} />
            </Card>

            <Card title={'JJLurgi PG 1 Visualize'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                <div id='JJLurgiGraph1'></div>
            </Card>

        </div>
    )
}

export default JJLurgiDataLog


