import React, { useState, useEffect } from 'react'
import { RealtimeData_Request } from '../../api'
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap'


import AlfaBleaching from './HMI/AlfaBleaching'
import AlfaDeodorization from './HMI/AlfaDeodorization'
import AlfaFiltrationHmi from './HMI/AlfaFiltrationHmi'
import AlfaUtilities from './HMI/AlfaUtilities'


import { AlfaModelPage1, AlfaModelPage2, AlfaModelPage3 } from '../../DataRequest/AlfaModel'
import FullHMIWrapper from '../Realtime/FullHMIWrapper'

let PG1DataModel = AlfaModelPage1
let PG2DataModel = AlfaModelPage2
let PG3DataModel = AlfaModelPage3


let RealTimeValue = {
    "PG1HMI": PG1DataModel,
    "PG2HMI": PG2DataModel,
    "PG3HMI": PG3DataModel,
    "PG1Table": [],
    "PG2Table": [],
    "PG3Table": [],
    "PG1Graph": [],
    "PG2Graph": [],
    "PG3Graph": [],
}


const RTCardAlfa = () => {
    const [state, setState] = useState({ count: 0 });

    const DataRequest = async (label, site, page, line, HMIName, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)
        RealTimeValue[TableName] = DataSortRT(response)
        RealTimeValue[HMIName] = RealTimeValue[TableName]
          console.log(RealTimeValue[HMIName])
    }

    const RenderNewData = () => {

        DataRequest([], 'FGV', '1', 'ALPHA LAVAL', 'PG1HMI', "PG1Table")
        DataRequest([], 'FGV', '2', 'ALPHA LAVAL', 'PG2HMI', "PG2Table")
        DataRequest([], 'FGV', '3', 'ALPHA LAVAL', 'PG3HMI', "PG3Table")

        setState(({ count }) => ({ count: count + 1 }));
    }

    useEffect(() => {
        RenderNewData()

        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
            console.log('Unmount Effect Line1RT');
        }

    }, [])


    return (
        <div>
            <FullHMIWrapper Tab={['Bleaching', 'Filtration', 'Deodorization', 'Utilities']}>
                <AlfaBleaching key={'Bleaching'} Parameter={RealTimeValue['PG1HMI']} />
                <AlfaDeodorization key={'Deodorization'} Parameter={RealTimeValue['PG1HMI'] } Parameter2={RealTimeValue['PG2HMI'] }/>
                <AlfaFiltrationHmi key={'Filtration'} Parameter={RealTimeValue['PG1HMI'] } Parameter2={RealTimeValue['PG2HMI']} />
                <AlfaUtilities key={'Utilities'} Parameter={RealTimeValue['PG3HMI']} />
            </FullHMIWrapper>
        </div>
    )
}

export default RTCardAlfa

