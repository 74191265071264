import moment from 'moment';


export const DataSortRT = (response) => {

    if (response.success !== false) {
        const data = response.Data

        let DataReturn = data.map(obj => {
            let cobj = []
            let ar0 = moment(obj.fields.LastChange).utc(true).format('MM/DD/YY HH:mm:ss')

            cobj = {
                'time': ar0,
                'Label': obj.fields.DisplayLabel,
                'Value': obj.fields.Value,
                'SV': obj.fields.SetValue,
                'Line': obj.fields.Line,
                'key': obj.fields.Label,
                'Page': obj.fields.Page,
                'label': obj.fields.Label,
                'LowThreshold': obj.fields.LowThreshold,
                'LowThreshold_active': obj.fields.LowThreshold_active,
                'HighThreshold': obj.fields.HighThreshold,
                'HighThreshold_active': obj.fields.HighThreshold_active,

            }
            return cobj
        })
        // console.log(data);
        return DataReturn
    }
    else {
        console.log('Data Request Error');
    }
}

export const DataSortST = (response) => {

    if (response.success !== false) {
        const data = response.Data

        let DataReturn = data.map(obj => {
            let cobj = []
            let ar0 = moment(obj.fields.LastChange).utc(true).format('MM/DD/YY HH:mm:ss')

            cobj = {
                'time': ar0,
                'Parameter': obj.fields.DisplayLabel,
                'Line': obj.fields.Line,
                'key': obj.fields.Label,
                'label': obj.fields.Label,
                'LowThreshold': obj.fields.LowThreshold,
                'LowThreshold_active': obj.fields.LowThreshold_active,
                'HighThreshold': obj.fields.HighThreshold,
                'HighThreshold_active': obj.fields.HighThreshold_active,

            }
            return cobj
        })
        // console.log(data);
        return DataReturn
    }
    else {
        console.log('Data Request Error');
    }
}


export const DataMapping = (Datamodel, RawData) => {

    const name = Object.keys(Datamodel)
    let ReturnValue = Datamodel
    name.forEach(element => {
        try {
            let DataFound = RawData.find(({ Label }) => Label === element)
            ReturnValue[element].PV = DataFound.Value
            ReturnValue[element].SV = DataFound.SV
        }
        catch {
            console.log('None');
        }

        // ReturnValue['Time'].PV = DataFound.time

    });

    return ReturnValue
}

export const GraphDataFind = (SelectedLabel, RawData) => {

    let FoundDataArray = []

    SelectedLabel.forEach(element => {
        try {
            let obj = {}
            let FoundData = RawData.find(({ Label }) => Label === element)
            obj['Label'] = FoundData.Label
            obj['time'] = FoundData.time
            obj['Value'] = parseFloat(FoundData.Value)

            var CurrentTime = new Date()
            var LabelTime = new Date(obj['time'])
            var NewLabelTime = LabelTime.getTime() + 30000

            if (CurrentTime.getTime() < NewLabelTime) {
                //console.log('NewData');
                FoundDataArray.push(obj)
            }
            else {
                //console.log('OldData');
            }

        }

        catch {
            console.log(('Data Not Found'));
        }
    })

    //console.log(FoundDataArray);

    return FoundDataArray
}



export const Subscribtion_data = (response) => {
    let DataReturn
    let dayremain
    console.log(response);

    if (response.success === true) {
        const data = response.data
        DataReturn = data.map(obj => {
            let cobj = []

            let start_date = moment(obj.fields.DateTime_Start);
            let end_date = moment(obj.fields.DateTime_End);

            let status

            if (obj.fields.Status == 'Expired'){
                status = true
            }
            else{status = false}

            const endDate = new Date(obj.fields.DateTime_End);
            const startDate = new Date();
    
            // Calculate the time difference in milliseconds
            const timeDifference = endDate - startDate;
    
            // Convert milliseconds to days
            let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            if (daysDifference < 0){
                daysDifference = daysDifference*-1
            }
    
            // console.log(`The difference in days is: ${daysDifference}`  );

            cobj = {
                'Start_Date': start_date.utc(true).format('DD/MM/YYYY'),
                'End_date': end_date.utc(true).format('DD/MM/YYYY'),
                'Status': status,
                'DayRemain': daysDifference
            }
            return cobj
        })

    }
    else {
        DataReturn = []
        console.log(response)
    }
    return DataReturn
}