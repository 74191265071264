import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout, Row, Col, Modal, Avatar, Badge, PageHeader, Descriptions, Statistic, Card } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserSwitchOutlined, UserOutlined, AlertOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import 'antd/dist/antd.css';
import './pagelayout.css';
import logo from '../../assets/logo2.png'
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'
import { LogoutBackend, Subscrition_request } from '../../api';
import Sidebar from './Sidebar/Sidebar';
import { RouteWrapper } from './Authentication';
import { success_state, setA, userinfo, setB } from '../constants'
import { Subscribtion_data } from '../../DataRequest/RealTimeRequestWrap';

const { Header, Content, Footer, Sider } = Layout;

let user_info = {
  "username": "",
  "name": "",
  "Site": "",
  "Line": "",
  "authorization_level": ""
}
 let Expired = false

const PageLayout = () => {

  let history = useHistory();
  const [collapsed, setcollapsed] = useState(false)
  const [NetworkError, setNetworkError] = useState(false)
  const [state, setState] = useState({ count: 0 });
  const [SubscriptionDate , setSubscriptionDate] = useState({
    'Start_Date': '',
    'End_date': '',
    'Status': false,
    'DayRemain': 0
  })


  useEffect(() => {
    try {
      CheckSubscribtion()

      CheckSession()
      
    }
    catch { }

    return () => {
      console.log("unmount");
    }
  }, []);


  // const user = memoryUtils.user
  // if (Object.keys(user).length === 0) {
  //   console.log('dont have')
  //   return <Redirect to='/Login' />
  // }

  // console.log(user);

  const toggle = () => {
    setcollapsed(!collapsed)
  };

  const logout = () => {
    Modal.confirm({
      content: 'Confirm Logout?',
      onOk: async () => {

        const response = await LogoutBackend()

        if (response.message === 'success') {
          console.log('logouted')
          console.log(success_state);
          setA(false)
          console.log(success_state);

          // storageUtils.removeUser()
          // memoryUtils.user = {}
          history.replace('/login')
        }


      }
    })
  }

  const SessionNotExistHandler = () => {
    console.log('logouted')
    // storageUtils.removeUser()
    // memoryUtils.user = {}
    history.replace('/login')
  }

  // Line: "All"
  // Site: "All"
  // authorization_level: "5"
  // name: ""
  // username: "SuperVisor"

  const CheckSession = async () => {

    axios({
      method: 'post',
      url: 'https://www.iotcs.app/api/test2/',
      withCredentials: true
    })

      .then(function (response) {
        response.data.success ? console.log('ok') : SessionNotExistHandler()
        console.log(response)
        user_info['name'] = response.data.user.name
        user_info['username'] = response.data.user.username
        user_info['Site'] = response.data.user.Site
        user_info['Line'] = response.data.user.Line
        user_info['authorization_level'] = response.data.user.authorization_level

        setB(user_info)

        console.log(userinfo);
        setNetworkError(false)
        setState(({ count }) => ({ count: count + 1 }));

      })

      .catch(function (error) {
        history.replace('/login')

        //console.log(error);
        setNetworkError(true)
      });

  }

  const CheckSubscribtion = async () => {

    const response = await Subscrition_request()
    let sub_state =  Subscribtion_data(response)

    console.log(sub_state);
    
    setSubscriptionDate({
      'Start_Date': sub_state[0].Start_Date,
      'End_date': sub_state[0].End_date,
      'Status': sub_state[0].Status,
      'DayRemain': sub_state[0].DayRemain
    });

    // setState(({ count }) => ({ count: count + 1 }));

  }



  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsedWidth={60} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" >
          <img src={logo} alt='Company logo'></img>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row>
            <Col xs={4} xl={1} >
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
            </Col>

            <Col xs={12} xl={19} >
              <img className="header-logo" src={logo} alt='Company logo'></img>
            </Col>

            <Col xs={8} xl={4} style={{ float: 'right' }}>

              <div className='logout' >
                <Avatar size={40} icon={<UserOutlined />}
                  style={{ float: 'right', backgroundColor: '#87d068' }}
                  onClick={logout} />
              </div>

            </Col>
          </Row>

        </Header>
        <Content style={{ margin: '16px 16px' }}>

          <div className="site-page-header-ghost-wrapper" style={{ borderRadius: '15px' }}>
            <PageHeader
              className="site-page-header-responsive"
              title="FGV-Delima"
              subTitle={window.location.pathname}
              ghost={true}
            >
              <Row gutter={[16, 16]}>
                <Statistic
                  style={{
                    marginRight: '32px',
                  }}
                  title="Name" value={userinfo['name']} />
                <Statistic
                  title="Role"
                  value={userinfo['username']}
                  style={{
                    marginRight: '32px',
                  }}
                />
                <Statistic
                  style={{
                    marginRight: '32px',
                  }}
                  title="Production Line" value={userinfo['Line']} />

                <Statistic
                  title={`Server Subscription: ${SubscriptionDate.Start_Date} - ${SubscriptionDate.End_date}`}
                  value={SubscriptionDate.Status?`Expired ${SubscriptionDate.DayRemain} Days` : `${SubscriptionDate.DayRemain} Days`}
                  valueStyle={{ color: SubscriptionDate.Status? '#cf1322':'#000000' }}
                  prefix={ SubscriptionDate.Status? <ExclamationCircleOutlined/> : null }
                >
                </Statistic>
                {/* 
                <Card style={{ backgroundColor: '#e7bb91', marginTop: "0" }} bordered={true}>
                  <Statistic
                    title="Server Subscription"
                    value={"00/00/0000 - 00/00/0000"}
                    valueStyle={{ color: '#cf1322' }}
                    prefix={<ArrowUpOutlined />}
                  >
                  </Statistic>
                </Card> */}
              </Row>

            </PageHeader>
          </div>

          <div style={{ padding: 24, minHeight: 360 }}>
            <RouteWrapper Error={NetworkError} userinfo={userinfo} />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>IOT Control Solution Sdn. Bhd.</Footer>
      </Layout>
    </Layout>
  )
}

export default PageLayout

