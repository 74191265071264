import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { AlfaModelPage1 } from '../../../DataRequest/AlfaModel'
import { AlfaModelPage2 } from '../../../DataRequest/AlfaModel'

const AlfaFiltrationHmi = (props) => {
  let Para = props.Parameter
  Para = DataMapping(AlfaModelPage1, props.Parameter)

  let Para2 = props.Parameter
  Para2 = DataMapping(AlfaModelPage2, props.Parameter2)



  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1190.55 841.89"
      width='100%'
      height='100%'
    >
      <style>
        {
          ".prefix__st0,.prefix__st1,.prefix__st2{stroke-linejoin:round;stroke-miterlimit:10}.prefix__st0{fill:#e6e7e8;stroke:#414042;stroke-width:1.5;stroke-linecap:round}.prefix__st1,.prefix__st2{fill:none;stroke:#58595b;stroke-width:6}.prefix__st2{stroke-linecap:round}.prefix__st4{fill:#c4c6c8}.prefix__st5{fill:#e0e0e0}.prefix__st6,.prefix__st7,.prefix__st8{stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st6{fill:none;stroke:#6d6e71;stroke-width:2}.prefix__st7,.prefix__st8{fill:#a7a9ac;stroke:#414042}.prefix__st8{fill:#e6e7e8;stroke-width:2}.prefix__st9{fill:#c4c6c8;stroke-width:.75}.prefix__st11,.prefix__st12,.prefix__st9{stroke:#414042;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st11{fill:#e6e7e8;stroke-width:3}.prefix__st12{fill:#c4c6c8}.prefix__st13{font-family:&apos;3ds&apos;}.prefix__st14{font-size:12px}.prefix__st15{font-size:16px}.prefix__st16{fill:#fff200}.prefix__st17{font-size:24.5733px}.prefix__st18{font-size:17.8812px}"
        }
      </style>
      <symbol id="prefix__Valve_small" viewBox="-14.25 -10.57 28.51 21.14">
        <path
          className="prefix__st0"
          d="M13.5 9.82L8.17 0 2.84 9.82zM2.84-9.82L8.17 0l5.33-9.82zM-13.5-9.67h6.78V9.66h-6.78zM8.17 0H-6.72"
        />
      </symbol>
      <g id="prefix__Piping">
        <path
          className="prefix__st1"
          d="M152.01 171v-41c0-3.31-2.69-6-6-6H36.67M151.73 350.5v68.64M163.73 424.24v38.53c0 3.31 2.69 6 6 6h20.57M141.01 424.24v84.88c0 3.31 2.69 6 6 6h43.29M414.33 460.94h140.42M355.8 460.94h48.53M230.53 460.94H345.8M732.5 507.28h76.07c9.89 0 12.57-2.69 12.57-6v-34.03c0-3.31 2.69-6 6-6h54.11M414.33 507.28H722.5M355.8 507.28h48.53M230.53 507.28H345.8M264.62 475.9v-14.96M264.62 495.38v11.9M583.23 389.45h18.27c3.31 0 6 2.69 6 6v59.49c0 3.31-2.69 6-6 6h-27.67M901.58 389.45h24.92c3.31 0 6 2.69 6 6v59.49c0 3.31-2.69 6-6 6h-27.67M565.14 321.22h81.11M565.14 220.22h81.11M331.13 113.59V259c0 3.31-2.69 6-6 6H184M709.67 103.59V87.67c0-3.31-2.69-6-6-6H337.13c-3.31 0-6 2.69-6 6v15.93M664 321.22h39.67c3.31 0 6-2.69 6-6V113.59M898.83 620v84.67c0 3.31 2.69 6 6 6h122.1M732.5 545.67h160.33c3.31 0 6 2.69 6 6V610M414.33 545.67H722.5M350.8 362.7v176.97c0 3.31 2.69 6 6 6h47.53"
        />
        <path
          className="prefix__st1"
          d="M203.66 281h199.67c3.31 0 6 2.69 6 6v453.42c0 3.31 2.69 6 6 6h12.33M447 746.42h30.67M522.9 754.26h18.35c3.31 0 6 2.69 6 6v34.34c0 3.31-2.69 6-6 6H522.9M547.25 777.43h98.94c3.31 0 6-2.69 6-6v-19.85M460.17 746.42v40.34c0 3.31 2.69 6 6 6h9.93M652.19 590.17v-13.42c0-3.31-2.69-6-6-6h-50.91M745.67 103.59V56c0-3.31 2.69-6 6-6h357M714.67 220.22h25c3.31 0 6-2.69 6-6V113.59M664 220.22h40.67M901.58 321.22h57.17M901.58 220.22h57.17"
        />
        <path
          className="prefix__st1"
          d="M999.67 108.59h-701c-3.31 0-6 2.69-6 6V243c0 3.31-2.69 6-6 6h-83M979.33 321.22h74.49c3.31 0 6-2.69 6-6V114.59c0-3.31-2.69-6-6-6h-44.16"
        />
        <path
          className="prefix__st1"
          d="M978.67 220.22h20c3.31 0 6-2.69 6-6V50M978.5 428.77h35.08c3.31 0 6 2.69 6 6V609c0 3.31-2.69 6-6 6H675.4"
        />
        <path
          className="prefix__st1"
          d="M663.81 425.2h57.69c3.31 0 6 2.69 6 6V615M350.8 331.02V303c0-3.31-2.69-6-6-6H203.66M644.44 425.2H607.5M958.67 428.77H932.5"
        />
      </g>
      <g id="prefix__Component">
        <path
          fill="#c4c6c8"
          stroke="#6d6e71"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M675.4 594.68v32.2l-23.21 13.4-23.21-13.4v-32.2l8.54-4.93h29.34z"
        />
        <path
          className="prefix__st4"
          d="M697.48 727.98l-45.29 26.15-45.29-26.15v-88.79h90.58z"
        />
        <path
          className="prefix__st5"
          d="M697.48 639.19v88.79l-22.08 12.75V639.19z"
        />
        <path
          className="prefix__st6"
          d="M697.48 727.98l-45.29 26.15-45.29-26.15v-88.79h90.58z"
        />
        <path
          className="prefix__st7"
          d="M537.73 318.67h41.15v5.1h-41.15zM578.88 315.37h4.35v11.69h-4.35zM537.73 386.9h41.15v5.1h-41.15zM578.88 383.61h4.35v11.69h-4.35zM537.73 217.67h41.15v5.1h-41.15zM578.88 214.37h4.35v11.69h-4.35z"
        />
        <path
          className="prefix__st4"
          d="M558.33 177.48c-6.96-3.1-19.14-6.48-39.16-6.48s-32.2 3.38-39.16 6.48c-4.86 2.17-8.06 7.72-8.06 13.91v149.07c0 3 .75 5.96 2.18 8.59l36.43 67.06h17.21l36.43-67.06a18.03 18.03 0 002.18-8.59V191.39c.01-6.18-3.19-11.74-8.05-13.91z"
        />
        <path
          className="prefix__st5"
          d="M566.39 191.39v149.08c0 3-.75 5.95-2.18 8.59l-17.29 31.83v-194.7c0-5.78-2.66-11.03-6.83-13.72 8.24 1.26 14.14 3.19 18.24 5.01 4.86 2.17 8.06 7.73 8.06 13.91z"
        />
        <path
          className="prefix__st6"
          d="M558.33 177.48c-6.96-3.1-19.14-6.48-39.16-6.48s-32.2 3.38-39.16 6.48c-4.86 2.17-8.06 7.72-8.06 13.91v149.07c0 3 .75 5.96 2.18 8.59l36.43 67.06h17.21l36.43-67.06a18.03 18.03 0 002.18-8.59V191.39c.01-6.18-3.19-11.74-8.05-13.91z"
        />
        <path className="prefix__st4" d="M466.36 197.38h80.56v11.38h-80.56z" />
        <path className="prefix__st5" d="M546.92 197.38h25.06v11.38h-25.06z" />
        <path className="prefix__st6" d="M466.36 197.38h105.62v11.38H466.36z" />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          id="prefix__XMLID_1_"
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 564.59 452.813)"
          overflow="visible"
        />
        <path
          className="prefix__st8"
          d="M527.77 416.12l-8.6 15.84-8.61-15.84zM510.56 447.8l8.6-15.84 8.61 15.84zM484.21 416.36h10.94v31.19h-10.94zM519.17 431.96h-24.03"
        />
        <g>
          <path
            className="prefix__st7"
            d="M855.58 318.67h41.15v5.1h-41.15zM896.73 315.37h4.35v11.69h-4.35zM855.58 386.9h41.15v5.1h-41.15zM896.73 383.61h4.35v11.69h-4.35zM855.58 217.67h41.15v5.1h-41.15zM896.73 214.37h4.35v11.69h-4.35z"
          />
          <g>
            <path
              className="prefix__st4"
              d="M876.18 177.48c-6.96-3.1-19.14-6.48-39.16-6.48s-32.2 3.38-39.16 6.48c-4.86 2.17-8.06 7.72-8.06 13.91v149.07c0 3 .75 5.96 2.18 8.59l36.43 67.06h17.21l36.43-67.06a18.03 18.03 0 002.18-8.59V191.39c.01-6.18-3.19-11.74-8.05-13.91z"
            />
            <path
              className="prefix__st5"
              d="M884.24 191.39v149.08c0 3-.75 5.95-2.18 8.59l-17.29 31.83v-194.7c0-5.78-2.66-11.03-6.83-13.72 8.24 1.26 14.14 3.19 18.24 5.01 4.86 2.17 8.06 7.73 8.06 13.91z"
            />
            <path
              className="prefix__st6"
              d="M876.18 177.48c-6.96-3.1-19.14-6.48-39.16-6.48s-32.2 3.38-39.16 6.48c-4.86 2.17-8.06 7.72-8.06 13.91v149.07c0 3 .75 5.96 2.18 8.59l36.43 67.06h17.21l36.43-67.06a18.03 18.03 0 002.18-8.59V191.39c.01-6.18-3.19-11.74-8.05-13.91z"
            />
            <path
              className="prefix__st4"
              d="M784.21 197.38h80.56v11.38h-80.56z"
            />
            <path
              className="prefix__st5"
              d="M864.77 197.38h25.06v11.38h-25.06z"
            />
            <path
              className="prefix__st6"
              d="M784.21 197.38h105.62v11.38H784.21z"
            />
          </g>
          <use
            xlinkHref="#prefix__Valve_small"
            width={28.51}
            height={21.14}
            id="prefix__XMLID_6_"
            x={-14.25}
            y={-10.57}
            transform="matrix(0 1 1 0 882.44 452.813)"
            overflow="visible"
          />
          <g>
            <path
              className="prefix__st8"
              d="M845.62 416.12l-8.6 15.84-8.61-15.84zM828.41 447.8l8.6-15.84 8.61 15.84zM802.06 416.36H813v31.19h-10.94zM837.02 431.96h-24.03"
            />
          </g>
        </g>
        <g>
          <path
            className="prefix__st9"
            d="M131.16 414.04h41.15v10.21h-41.15zM172.31 413.3h4.35v11.69h-4.35zM126.81 413.3h4.35v11.69h-4.35z"
          />
        </g>
        <g>
          <path
            fill="#e0e0e0"
            stroke="#6d6e71"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M164.17 193.15h-24.33l9.28-22.15h5.79z"
          />
          <path
            className="prefix__st4"
            d="M152.01 186.22c-19.28 0-34.07 5.51-42.79 9.9-5.46 2.75-8.87 8.37-8.87 14.48v133.79c0 6.11 3.41 11.73 8.87 14.48 8.71 4.39 23.5 9.9 42.79 9.9s34.07-5.51 42.79-9.9c5.46-2.75 8.87-8.37 8.87-14.48V210.6c0-6.11-3.41-11.73-8.87-14.48-8.72-4.39-23.51-9.9-42.79-9.9z"
          />
          <path
            className="prefix__st5"
            d="M203.66 210.6v133.79c0 6.12-3.41 11.73-8.87 14.48-5.15 2.6-12.43 5.59-21.51 7.6 5.87-3.04 9.53-9.14 9.53-15.76V204.29c0-6.65-3.67-12.74-9.56-15.77 9.09 2.01 16.38 5 21.54 7.6 5.46 2.75 8.87 8.37 8.87 14.48z"
          />
          <path
            className="prefix__st6"
            d="M152.01 186.22c-19.28 0-34.07 5.51-42.79 9.9-5.46 2.75-8.87 8.37-8.87 14.48v133.79c0 6.11 3.41 11.73 8.87 14.48 8.71 4.39 23.5 9.9 42.79 9.9s34.07-5.51 42.79-9.9c5.46-2.75 8.87-8.37 8.87-14.48V210.6c0-6.11-3.41-11.73-8.87-14.48-8.72-4.39-23.51-9.9-42.79-9.9z"
          />
        </g>
        <g>
          <path
            className="prefix__st11"
            d="M342.19 362.7l8.6-15.84 8.61 15.84zM359.4 331.02l-8.6 15.84-8.61-15.84zM374.82 362.45v-31.18c8.61 0 15.59 6.98 15.59 15.59s-6.98 15.59-15.59 15.59zM350.79 346.86h24.03"
          />
        </g>
        <g>
          <path
            className="prefix__st9"
            d="M214 463.49h18.75v-5.1H214c-1.41 0-2.55 1.14-2.55 2.55s1.14 2.55 2.55 2.55z"
          />
          <circle className="prefix__st9" cx={213.4} cy={468.78} r={10.38} />
          <path
            className="prefix__st9"
            d="M213.4 466.23h-18.75v5.1h18.75c1.41 0 2.55-1.14 2.55-2.55s-1.14-2.55-2.55-2.55zM190.3 462.93h4.35v11.69h-4.35z"
          />
          <path
            transform="rotate(-180 234.926 460.94)"
            className="prefix__st9"
            d="M232.75 455.1h4.35v11.69h-4.35z"
          />
        </g>
        <g>
          <path
            className="prefix__st9"
            d="M214 509.83h18.75v-5.1H214c-1.41 0-2.55 1.14-2.55 2.55s1.14 2.55 2.55 2.55z"
          />
          <circle className="prefix__st9" cx={213.4} cy={515.12} r={10.38} />
          <path
            className="prefix__st9"
            d="M213.4 512.57h-18.75v5.1h18.75c1.41 0 2.55-1.14 2.55-2.55s-1.14-2.55-2.55-2.55zM190.3 509.28h4.35v11.69h-4.35z"
          />
          <path
            transform="rotate(-180 234.926 507.283)"
            className="prefix__st9"
            d="M232.75 501.44h4.35v11.69h-4.35z"
          />
        </g>
        <g>
          <path
            className="prefix__st12"
            d="M499.2 748.97h-18.75v-5.1h18.75c1.41 0 2.55 1.14 2.55 2.55s-1.14 2.55-2.55 2.55z"
          />
          <circle className="prefix__st12" cx={499.8} cy={754.26} r={10.38} />
          <path
            className="prefix__st12"
            d="M499.8 751.71h18.75v5.1H499.8c-1.41 0-2.55-1.14-2.55-2.55s1.14-2.55 2.55-2.55z"
          />
          <path
            transform="rotate(-180 520.726 754.258)"
            className="prefix__st12"
            d="M518.55 748.41h4.35v11.69h-4.35z"
          />
          <path className="prefix__st12" d="M476.1 740.58h4.35v11.69h-4.35z" />
        </g>
        <g>
          <path
            className="prefix__st12"
            d="M499.2 795.31h-18.75v-5.1h18.75c1.41 0 2.55 1.14 2.55 2.55s-1.14 2.55-2.55 2.55z"
          />
          <circle className="prefix__st12" cx={499.8} cy={800.6} r={10.38} />
          <path
            className="prefix__st12"
            d="M499.8 798.05h18.75v5.1H499.8c-1.41 0-2.55-1.14-2.55-2.55s1.14-2.55 2.55-2.55z"
          />
          <path
            transform="rotate(-180 520.726 800.6)"
            className="prefix__st12"
            d="M518.55 794.76h4.35v11.69h-4.35z"
          />
          <path className="prefix__st12" d="M476.1 786.92h4.35v11.69h-4.35z" />
        </g>
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          id="prefix__XMLID_2_"
          x={-14.25}
          y={-10.57}
          transform="rotate(180 136.37 242.781)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          id="prefix__XMLID_4_"
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 654.167 312.564)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          id="prefix__XMLID_3_"
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 654.167 416.705)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 654.167 211.555)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 968.354 312.564)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 968.354 420.945)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 968.354 211.555)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          x={-14.25}
          y={-10.57}
          transform="matrix(0 1 1 0 436.868 737.761)"
          overflow="visible"
        />
        <g>
          <path
            className="prefix__st4"
            d="M1059.82 669.7c-12.28 0-21.69 3.51-27.24 6.3-3.48 1.75-5.65 5.33-5.65 9.22v85.18c0 3.89 2.17 7.47 5.65 9.22 5.55 2.79 14.96 6.3 27.24 6.3s21.69-3.51 27.24-6.3c3.48-1.75 5.65-5.33 5.65-9.22v-85.18c0-3.89-2.17-7.47-5.65-9.22-5.55-2.79-14.96-6.3-27.24-6.3z"
          />
          <path
            className="prefix__st5"
            d="M1092.71 685.23v85.17c0 3.9-2.17 7.47-5.65 9.22-3.28 1.66-7.91 3.56-13.69 4.84 3.74-1.94 6.07-5.82 6.07-10.03v-93.21c0-4.23-2.34-8.11-6.09-10.04 5.79 1.28 10.43 3.18 13.71 4.84 3.47 1.74 5.65 5.32 5.65 9.21z"
          />
          <path
            className="prefix__st6"
            d="M1059.82 669.7c-12.28 0-21.69 3.51-27.24 6.3-3.48 1.75-5.65 5.33-5.65 9.22v85.18c0 3.89 2.17 7.47 5.65 9.22 5.55 2.79 14.96 6.3 27.24 6.3s21.69-3.51 27.24-6.3c3.48-1.75 5.65-5.33 5.65-9.22v-85.18c0-3.89-2.17-7.47-5.65-9.22-5.55-2.79-14.96-6.3-27.24-6.3z"
          />
        </g>
      </g>
      <g id="prefix__Label">
        <text
          transform="translate(636.5 337.81)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1.3"}
        </text>
        <text
          transform="translate(439.666 434.458)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1.0"}
        </text>
        <text
          transform="translate(288.779 489.708)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1/2"}
        </text>
        <text
          transform="translate(196.98 490.3)"
          className="prefix__st13 prefix__st14"
        >
          {"26P01"}
        </text>
        <text
          transform="translate(304.23 350.5)"
          className="prefix__st13 prefix__st14"
        >
          {"26LV1"}
        </text>
        <text
          transform="translate(472.357 164.715)"
          className="prefix__st13 prefix__st15"
        >
          {"FILTER NO.1"}
        </text>
        <text
          transform="translate(791.8 164.715)"
          className="prefix__st13 prefix__st15"
        >
          {"FILTER NO.2"}
        </text>
        <text
          transform="translate(131.157 217.667)"
          className="prefix__st13 prefix__st14"
        >
          {"26F01"}
        </text>
        <text
          transform="translate(196.98 536.3)"
          className="prefix__st13 prefix__st14"
        >
          {"26P02"}
        </text>
        <text
          transform="translate(634.647 607.3)"
          className="prefix__st13 prefix__st14"
        >
          {"26C02"}
        </text>
        <text
          transform="translate(636.5 657.3)"
          className="prefix__st13 prefix__st14"
        >
          {"26T12"}
        </text>
        <text
          transform="translate(480.449 775.781)"
          className="prefix__st13 prefix__st14"
        >
          {"26P12A"}
        </text>
        <text
          transform="translate(480.449 821.448)"
          className="prefix__st13 prefix__st14"
        >
          {"26P12B"}
        </text>
        <text
          transform="translate(546.916 480.208)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1.1"}
        </text>
        <text
          transform="translate(417.583 764.643)"
          className="prefix__st13 prefix__st14"
        >
          {"V6P12"}
        </text>
        <text
          transform="translate(560.533 574.542)"
          className="prefix__st13 prefix__st14"
        >
          {"VENT"}
        </text>
        <text
          transform="translate(637.916 445.458)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1.2"}
        </text>
        <text
          transform="translate(636.5 237.81)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F1.4"}
        </text>
        <text
          transform="translate(950 337.81)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F2.3"}
        </text>
        <text
          transform="translate(759.666 435.81)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F2.0"}
        </text>
        <text
          transform="translate(870 480.208)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F2.1"}
        </text>
        <text
          transform="translate(950 447.553)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F2.2"}
        </text>
        <text
          transform="translate(950 237.81)"
          className="prefix__st13 prefix__st14"
        >
          {"V6F2.4"}
        </text>
        <path
          className="prefix__st16"
          d="M559.95 300.61H478.4c-2.21 0-4-1.79-4-4v-28.87c0-2.21 1.79-4 4-4h81.55c2.21 0 4 1.79 4 4v28.87c0 2.21-1.79 4-4 4zM877.8 300.61h-81.55c-2.21 0-4-1.79-4-4v-28.87c0-2.21 1.79-4 4-4h81.55c2.21 0 4 1.79 4 4v28.87c0 2.21-1.8 4-4 4z"
        />
        <text transform="matrix(.7592 0 0 1 807.058 289.974)">
          <tspan x={0} y={0} className="prefix__st13 prefix__st17">
          {`${Para['Filter No.1'].PV}`}
          </tspan>
          <tspan x={48.11} y={0} className="prefix__st13 prefix__st18">
            {" barg"}
          </tspan>
        </text>
        <text transform="matrix(.7276 0 0 1 489.99 288.975)">
          <tspan x={0} y={0} className="prefix__st13 prefix__st17">
          {`${Para['Filter No.2'].PV}`}
          </tspan>
          <tspan x={48.11} y={0} className="prefix__st13 prefix__st18">
            {" barg"}
          </tspan>
        </text>
      </g>
    </svg>
  )
    
}

export default AlfaFiltrationHmi
