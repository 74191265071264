import { Result } from 'antd'
import React from 'react'

const NetworkError = () => {
    console.log('error');
    return (
        <div>
            <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."

            />
        </div>
    )
}

export default NetworkError