import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { OTKModelPage2, OTKModelPage3 } from '../../../DataRequest/OiltekModel'

const OiltekFatty = (props) => {

  let Para = props.Parameter
  Para = DataMapping(OTKModelPage2, props.Parameter)

  let Para2 = props.Expara
  Para2 = DataMapping(OTKModelPage3, props.Expara)

    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1190.55 841.89"
          width='100%'
          height='100%'
        >
          <style>
            {
              ".prefix__st0,.prefix__st1{stroke:#6d6e71;stroke-linecap:round;stroke-linejoin:round}.prefix__st0{fill:#c4c6c8;stroke-width:.75;stroke-miterlimit:10}.prefix__st1{fill:#e6e7e8;stroke-width:1.5}.prefix__st1,.prefix__st2{stroke-miterlimit:10}.prefix__st2,.prefix__st3{fill:none;stroke:#58595b;stroke-width:4;stroke-linejoin:round}.prefix__st4{fill:#58595b}.prefix__st5{stroke-linejoin:round}.prefix__st5,.prefix__st6,.prefix__st7,.prefix__st8,.prefix__st9{fill:#c4c6c8;stroke:#58595b;stroke-miterlimit:10}.prefix__st7,.prefix__st8,.prefix__st9{fill:none}.prefix__st8,.prefix__st9{stroke-linejoin:round}.prefix__st9{stroke:#6d6e71;stroke-width:2;stroke-linecap:round}.prefix__st10{fill:#c4c6c8}.prefix__st11{fill:#e0e0e0}.prefix__st12,.prefix__st13,.prefix__st14{fill:none;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st12{stroke:#6d6e71;stroke-width:4;stroke-linecap:round}.prefix__st13,.prefix__st14{stroke:#58595b;stroke-width:2}.prefix__st14{stroke-linecap:round}.prefix__st16{fill:#fff200}.prefix__st17{fill:none}.prefix__st18{font-family:&apos;3ds&apos;}.prefix__st19{font-size:20px}.prefix__st20{font-size:18px}.prefix__st21{font-size:12px}.prefix__st22{fill:none;stroke:#414042;stroke-width:2;stroke-linecap:round;stroke-linejoin:round}"
            }
          </style>
          <symbol id="prefix__Turbocharger" viewBox="-18.78 -13.41 37.55 23.81">
            <path
              className="prefix__st0"
              d="M10.08-9.69L-.3-7.75l-10.39-1.94v-3.34h20.77zM.3 3.64h13.75v5.1H.3c-1.41 0-2.55-1.14-2.55-2.55S-1.11 3.64.3 3.64z"
            />
            <circle className="prefix__st0" cx={-0.3} cy={-1.65} r={10.38} />
            <path
              className="prefix__st0"
              d="M-.3.9h-13.75v-5.1H-.3c1.41 0 2.55 1.14 2.55 2.55S1.11.9-.3.9z"
            />
            <path className="prefix__st0" d="M-18.4-5.49h4.35V2.2h-4.35z" />
            <path
              transform="rotate(-180 16.226 6.189)"
              className="prefix__st0"
              d="M14.05 2.35h4.35v7.69h-4.35z"
            />
          </symbol>
          <symbol id="prefix__Valve_Auto" viewBox="-19.5 -13.29 32.68 21.98">
            <path
              className="prefix__st1"
              d="M-18.75-12.54l5.56 10.25 5.57-10.25zM-7.62 7.95l-5.56-10.24-5.57 10.24zM2.35-12.37V7.79c5.57 0 10.08-4.51 10.08-10.08S7.91-12.37 2.35-12.37zM-13.19-2.29H2.35"
            />
          </symbol>
          <symbol id="prefix__Valve_small" viewBox="-14.25 -10.57 28.51 21.14">
            <path
              className="prefix__st1"
              d="M13.5 9.82L8.17 0 2.84 9.82zM2.84-9.82L8.17 0l5.33-9.82zM-13.5-9.67h6.78V9.66h-6.78zM8.17 0H-6.72"
            />
          </symbol>
          <g id="prefix__Piping">
            <path
              className="prefix__st2"
              d="M232.72 453.33v72.17M232.72 400.67v44.66M176.5 256.27v21.23c0 3.31 2.69 6 6 6h44.22c3.31 0 6 2.69 6 6v103.17M154.41 191.33h16.09c3.31 0 6 2.69 6 6v50.93M232.72 610.51v105.16c0 3.31 2.69 6 6 6h49.82M288.54 659.66h-55.82M325.35 652h90.71M416.06 725.58H370.7"
            />
            <path
              className="prefix__st2"
              d="M472.71 600.27H573.5c3.31 0 6-2.69 6-6V455.33c0-3.31-2.69-6-6-6H74.67c-3.31 0-6-2.69-6-6V222.94c0-3.31 2.69-6 6-6h57.7M325.35 713.98h18.8c3.31 0 6-2.69 6-6V652M395.64 721.58V652M414.89 783.78h-13.25c-3.31 0-6-2.69-6-6v-48.19"
            />
            <path
              className="prefix__st3"
              d="M370.7 656v121.78c0 3.31-2.69 6-6 6h-76.78"
            />
            <path
              className="prefix__st4"
              d="M289.67 777.8l-10.36 5.98 10.36 5.98z"
            />
            <path
              className="prefix__st2"
              d="M416.06 600.27H376.7c-3.31 0-6 2.69-6 6V648M641.55 565.29v105.16c0 3.31 2.69 6 6 6h49.82M697.37 614.44h-55.82M732 606.78h187.25M919.25 680.36h-45.36M734.18 668.76h18.8c3.31 0 6-2.69 6-6v-55.98M898.83 676.36v-69.58M918.08 738.56h-13.25c-3.31 0-6-2.69-6-6v-48.19"
            />
            <path
              className="prefix__st3"
              d="M873.89 610.78v175.86c0 3.31-2.69 6-6 6h-76.78"
            />
            <path
              className="prefix__st4"
              d="M792.86 786.65l-10.36 5.99 10.36 5.98z"
            />
            <path
              className="prefix__st2"
              d="M919.25 555.05h-39.36c-3.31 0-6 2.69-6 6v41.73M253.18 400.67v22c0 3.31 2.69 6 6 6h376.35c3.31 0 6 2.69 6 6v112.95M154.41 252.27h92.77c3.31 0 6 2.69 6 6v134.4M842.5 606.74V533.9M1017.53 602.74v-47.69M975.9 680.36h35.64c3.31 0 6-2.69 6-6v-63.63"
            />
            <path
              className="prefix__st3"
              d="M842.5 514.67v-87.72c0-3.31 2.69-6 6-6h93.45"
            />
            <path
              className="prefix__st4"
              d="M940.2 426.93l10.36-5.98-10.36-5.99z"
            />
            <path
              className="prefix__st2"
              d="M472.71 725.58h35.64c3.31 0 6-2.69 6-6V600.27M977.1 555.05h58.9c3.31 0 6-2.69 6-6V402.67c0-3.31-2.69-6-6-6H180M977.1 606.74h126.24c3.31 0 6 2.69 6 6v119.82c0 3.31-2.69 6-6 6H976.86M873.89 792.64h116.82c3.31 0 6-2.69 6-6v-48.08M343.98 233.44v96.23M392.67 327.65V123.33c0-3.31 2.69-6 6-6h217.99c3.31 0 6 2.69 6 6v110.11"
            />
            <path
              className="prefix__st2"
              d="M485.59 327.65V187.41c0-3.31 2.69-6 6-6H605c3.31 0 6 2.69 6 6v46.03M518.58 285.67v41.98"
            />
            <path
              className="prefix__st2"
              d="M541 327.65v-42.04c0-3.31 2.69-6 6-6h58c3.31 0 6-2.69 6-6v-17.98M678.53 233.44v-21.77"
            />
            <path
              className="prefix__st3"
              d="M678.53 192v-68.67c0-3.31 2.69-6 6-6h117.3"
            />
            <path
              className="prefix__st4"
              d="M800.08 123.32l10.36-5.99-10.36-5.98z"
            />
            <path
              className="prefix__st2"
              d="M523.28 242.17h26.47c3.31 0 6 2.69 6 6v55.33c0 3.31 2.69 6 6 6h78.8M657.75 309.5H808.1M518.58 238.72V102.67c0-3.31 2.69-6 6-6h489.09c3.31 0 6 2.69 6 6v130.77"
            />
            <path
              className="prefix__st2"
              d="M441.54 151.11V85.75c0-3.31 2.69-6 6-6h583.04c3.31 0 6 2.69 6 6v147.69"
            />
            <path
              className="prefix__st2"
              d="M343.98 152.38V68.83c0-3.31 2.69-6 6-6h697.53c3.31 0 6 2.69 6 6v164.6"
            />
            <path
              className="prefix__st2"
              d="M286.64 138.58V51.92c0-3.31 2.69-6 6-6h771.78c3.31 0 6 2.69 6 6v181.52"
            />
          </g>
          <g id="prefix__Component">
            <path
              className="prefix__st5"
              d="M522.33 284.15l-3.75-10.63-3.75 10.63v4.17h7.5zM514.83 253.37l3.75 19.15 3.75-19.15v-6.5h-7.5z"
            />
            <path
              transform="rotate(-180 518.583 242.174)"
              className="prefix__st5"
              d="M513.88 237.47h9.4v9.4h-9.4z"
            />
            <path
              transform="rotate(-180 508.736 242.174)"
              className="prefix__st5"
              d="M503.59 238.42h10.29v7.5h-10.29z"
            />
            <path
              className="prefix__st6"
              d="M467.59 249.9h-18.38c-1.33 0-2.6-.53-3.54-1.46l-7.67-7.67a4.994 4.994 0 01-1.46-3.54v-38.06h10v35.98l4.74 4.74h16.31v10.01z"
            />
            <path
              className="prefix__st7"
              d="M451.28 239.9l-3.92 9.64M446.54 235.16l-9.59 4.03"
            />
            <path
              className="prefix__st5"
              d="M436.54 172.64l5 25.53 5-25.53v-9h-10z"
            />
            <path
              transform="rotate(-180 441.545 157.378)"
              className="prefix__st5"
              d="M435.28 151.11h12.53v12.53h-12.53z"
            />
            <path
              transform="rotate(-180 428.416 157.378)"
              className="prefix__st5"
              d="M421.55 152.38h13.72v10h-13.72z"
            />
            <path
              className="prefix__st5"
              d="M281.64 212.17l5-25.53 5 25.53v10h-10zM302.91 223.44l25.53 5-25.53 5h-10v-10zM353.97 233.44l-25.53-5 25.53-5h10v10zM280.37 222.17h12.53v12.53h-12.53zM348.98 172.64l-5 25.53-5-25.53v-12h10z"
            />
            <path
              className="prefix__st5"
              d="M338.98 224.7l5-25.53 5 25.53v10h-10zM337.71 151.11h12.53v12.53h-12.53zM350.24 152.38h13.72v10h-13.72zM142.37 179.04h-10v-26.53c0-1.33.53-2.6 1.46-3.54l7.67-7.67c.94-.94 2.21-1.46 3.54-1.46h135.34v10H147.11l-4.74 4.74v24.46z"
            />
            <path
              className="prefix__st8"
              d="M142.37 154.58l-9.65-3.89M147.11 149.84l-4.4-9.42"
            />
            <path
              className="prefix__st5"
              d="M291.64 160.11l-5 25.53-5-25.53v-9h10zM280.37 138.58h12.53v12.53h-12.53z"
            />
            <path
              className="prefix__st6"
              d="M78.3 153.23v48.7h-10v-46.62l-4.73-4.74H54.5v-10h11.14c1.33 0 2.6.53 3.53 1.46l7.67 7.67c.94.94 1.46 2.21 1.46 3.53z"
            />
            <path
              className="prefix__st7"
              d="M63.57 150.57l3.78-9.69M68.3 155.31l9.84-3.33"
            />
            <path
              className="prefix__st6"
              d="M94.3 146.61v55.32h-10v-53.25l-14.1-14.11H54.5v-10h17.77c1.33 0 2.6.53 3.54 1.47l17.03 17.04c.94.94 1.46 2.21 1.46 3.53z"
            />
            <path
              className="prefix__st7"
              d="M70.2 134.57l3.81-10M84.3 148.68l9.52-4.2"
            />
            <path
              className="prefix__st6"
              d="M110.3 140v61.93h-10v-59.86l-23.5-23.5H54.5v-10h24.38c1.32 0 2.59.53 3.53 1.47l26.43 26.43a4.97 4.97 0 011.46 3.53zM76.8 118.57l3.68-9.73M100.3 142.07l9.6-4.03"
            />
            <g>
              <path className="prefix__st9" d="M412.47 582.2h65.03" />
              <path className="prefix__st10" d="M416.06 582.2h57.85v82.9h-57.85z" />
              <path className="prefix__st11" d="M459.33 582.2h14.58v82.9h-14.58z" />
              <path className="prefix__st9" d="M416.06 582.2h57.85v82.9h-57.85z" />
              <path className="prefix__st12" d="M412.47 665.11h65.03" />
              <path
                className="prefix__st4"
                d="M416.3 584.95h4.78v4.78h-4.78zM421.08 591.41h4.78v4.78h-4.78zM425.86 597.88h4.78v4.78h-4.78zM430.64 604.35h4.78v4.78h-4.78zM435.42 610.81h4.78v4.78h-4.78zM440.2 617.28h4.78v4.78h-4.78zM444.98 623.74h4.78v4.78h-4.78zM449.77 630.21h4.78v4.78h-4.78zM454.55 636.68h4.78v4.78h-4.78zM459.33 643.14h4.78v4.78h-4.78zM464.11 649.61h4.78v4.78h-4.78zM468.89 656.08h4.78v4.78h-4.78z"
              />
              <path className="prefix__st9" d="M412.47 624.5h65.03" />
            </g>
            <g>
              <path className="prefix__st9" d="M412.47 713.98h65.03" />
              <path
                className="prefix__st10"
                d="M416.06 713.98h57.85v82.9h-57.85z"
              />
              <path
                className="prefix__st11"
                d="M459.33 713.98h14.58v82.9h-14.58z"
              />
              <path className="prefix__st9" d="M416.06 713.98h57.85v82.9h-57.85z" />
              <path className="prefix__st12" d="M412.47 796.89h65.03" />
              <path
                className="prefix__st4"
                d="M416.3 716.73h4.78v4.78h-4.78zM421.08 723.19h4.78v4.78h-4.78zM425.86 729.66h4.78v4.78h-4.78zM430.64 736.13h4.78v4.78h-4.78zM435.42 742.59h4.78v4.78h-4.78zM440.2 749.06h4.78v4.78h-4.78zM444.98 755.52h4.78v4.78h-4.78zM449.77 761.99h4.78v4.78h-4.78zM454.55 768.46h4.78v4.78h-4.78zM459.33 774.92h4.78v4.78h-4.78zM464.11 781.39h4.78v4.78h-4.78zM468.89 787.85h4.78v4.78h-4.78z"
              />
              <path className="prefix__st9" d="M412.47 756.28h65.03" />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M426.12 142.67v-3.36l-28.68-8.05h-9.53l-28.68 8.05v3.36h4.57v102.04h-4.57v3.36l28.68 8.04h9.53l28.68-8.04v-3.36h-4.57V142.67z"
              />
              <path
                className="prefix__st11"
                d="M421.56 142.67v102.04h4.56v3.36l-26.97 7.57 6.99-6.21V137.95l-6.99-6.21 26.97 7.57v3.36z"
              />
              <path
                className="prefix__st13"
                d="M426.12 142.67v-3.36l-28.68-8.05h-9.53l-28.68 8.05v3.36h4.57v102.04h-4.57v3.36l28.68 8.04h9.53l28.68-8.04v-3.36h-4.57V142.67zM363.8 142.67h57.75M359.23 139.31h66.89M363.8 244.71h57.75M359.23 248.07h66.89"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M266.17 517.24v-3.36l-28.68-8.05h-9.54l-28.67 8.05v3.36h4.56v102.04h-4.56v3.35l28.67 8.05h9.54l28.68-8.05v-3.35h-4.57V517.24z"
              />
              <path
                className="prefix__st11"
                d="M261.6 517.24v102.04h4.57v3.35l-26.97 7.57 6.99-6.2V512.52l-7-6.21 26.98 7.57v3.36z"
              />
              <path
                className="prefix__st13"
                d="M266.17 517.24v-3.36l-28.68-8.05h-9.54l-28.67 8.05v3.36h4.56v102.04h-4.56v3.35l28.67 8.05h9.54l28.68-8.05v-3.35h-4.57V517.24zM203.84 517.24h57.76M199.28 513.88h66.89M203.84 619.28h57.76M199.28 622.63h66.89"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_2_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 306.945 658.158)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_1_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 306.945 720.17)"
                overflow="visible"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_Auto"
              width={32.68}
              height={21.98}
              id="prefix__XMLID_7_"
              x={-19.5}
              y={-13.29}
              transform="matrix(-1 0 0 1 829.332 528.285)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_3_"
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 546.623 592.221)"
              overflow="visible"
            />
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              id="prefix__XMLID_4_"
              x={-14.25}
              y={-10.57}
              transform="matrix(-1 0 0 1 686.747 201.406)"
              overflow="visible"
            />
            <g>
              <path
                className="prefix__st10"
                d="M900.89 267.79v52.53h3.07v7.33h-99.74v-7.33h3.88v-52.53h-3.88v-7.33h3.88v-6.91l11.99-11.99h68.81l11.99 11.99v6.91h3.07v7.33z"
              />
              <path
                className="prefix__st11"
                d="M900.89 267.8v52.52h3.06v7.33h-27.33v-74.1l-12-11.99h24.27l12 11.99v6.91h3.06v7.34z"
              />
              <path
                className="prefix__st13"
                d="M900.89 267.79v52.53h3.07v7.33h-99.74v-7.33h3.88v-52.53h-3.88v-7.33h3.88v-6.91l11.99-11.99h68.81l11.99 11.99v6.91h3.07v7.33z"
              />
              <path
                className="prefix__st14"
                d="M808.1 260.46h92.79M808.1 267.79h92.79M808.1 320.32h92.79"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M503.59 267.96v-58.48h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v58.48h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26z"
              />
              <path
                className="prefix__st11"
                d="M503.59 209.47v58.49h3.26v3h-3.26s-2.8 6.53-11.91 8.66c3.17-2.61 4.31-5.28 4.31-5.28v-71.25s-1.14-2.67-4.31-5.28c9.11 2.13 11.91 8.66 11.91 8.66h3.26v3h-3.26z"
              />
              <path
                className="prefix__st13"
                d="M503.59 267.96v-58.48h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v58.48h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26zM467.59 206.47h36M467.59 209.47h36M467.59 267.96h36M467.59 270.96h36"
              />
            </g>
            <g>
              <path className="prefix__st9" d="M915.66 536.98h65.02" />
              <path
                className="prefix__st10"
                d="M919.25 536.98h57.85v82.9h-57.85z"
              />
              <path
                className="prefix__st11"
                d="M962.52 536.98h14.58v82.9h-14.58z"
              />
              <path className="prefix__st9" d="M919.25 536.98h57.85v82.9h-57.85z" />
              <path className="prefix__st12" d="M915.66 619.89h65.02" />
              <path
                className="prefix__st4"
                d="M919.48 539.73h4.78v4.78h-4.78zM924.27 546.19h4.78v4.78h-4.78zM929.05 552.66h4.78v4.78h-4.78zM933.83 559.13h4.78v4.78h-4.78zM938.61 565.59h4.78v4.78h-4.78zM943.39 572.06h4.78v4.78h-4.78zM948.17 578.52h4.78v4.78h-4.78zM952.95 584.99h4.78v4.78h-4.78zM957.73 591.46h4.78v4.78h-4.78zM962.52 597.92h4.78v4.78h-4.78zM967.3 604.39h4.78v4.78h-4.78zM972.08 610.86h4.78v4.78h-4.78z"
              />
              <path className="prefix__st9" d="M915.66 579.28h65.02" />
            </g>
            <g>
              <path className="prefix__st9" d="M915.66 668.76h65.02" />
              <path
                className="prefix__st10"
                d="M919.25 668.76h57.85v82.9h-57.85z"
              />
              <path
                className="prefix__st11"
                d="M962.52 668.76h14.58v82.9h-14.58z"
              />
              <path className="prefix__st9" d="M919.25 668.76h57.85v82.9h-57.85z" />
              <path className="prefix__st12" d="M915.66 751.67h65.02" />
              <path
                className="prefix__st4"
                d="M919.48 671.51h4.78v4.78h-4.78zM924.27 677.97h4.78v4.78h-4.78zM929.05 684.44h4.78v4.78h-4.78zM933.83 690.91h4.78v4.78h-4.78zM938.61 697.37h4.78v4.78h-4.78zM943.39 703.84h4.78v4.78h-4.78zM948.17 710.3h4.78v4.78h-4.78zM952.95 716.77h4.78v4.78h-4.78zM957.73 723.24h4.78v4.78h-4.78zM962.52 729.7h4.78v4.78h-4.78zM967.3 736.17h4.78v4.78h-4.78zM972.08 742.63h4.78v4.78h-4.78z"
              />
              <path className="prefix__st9" d="M915.66 711.06h65.02" />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M675 472.02v-3.36l-28.68-8.05h-9.54l-28.67 8.05v3.36h4.56v102.03h-4.56v3.36l28.67 8.05h9.54l28.68-8.05v-3.36h-4.57V472.02z"
              />
              <path
                className="prefix__st11"
                d="M670.43 472.02v102.03H675v3.36l-26.97 7.57 6.99-6.2V467.3l-7-6.21 26.98 7.57v3.36z"
              />
              <path
                className="prefix__st13"
                d="M675 472.02v-3.36l-28.68-8.05h-9.54l-28.67 8.05v3.36h4.56v102.03h-4.56v3.36l28.67 8.05h9.54l28.68-8.05v-3.36h-4.57V472.02zM612.67 472.02h57.76M608.11 468.66H675M612.67 574.05h57.76M608.11 577.41H675"
              />
            </g>
            <g>
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_6_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 715.776 612.937)"
                overflow="visible"
              />
              <use
                xlinkHref="#prefix__Turbocharger"
                width={37.55}
                height={23.81}
                id="prefix__XMLID_5_"
                x={-18.78}
                y={-13.41}
                transform="matrix(1 0 0 -1 715.776 674.95)"
                overflow="visible"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_Auto"
              width={32.68}
              height={21.98}
              x={-19.5}
              y={-13.29}
              transform="matrix(0 -1 -1 0 1040.802 725.526)"
              overflow="visible"
            />
            <g>
              <path className="prefix__st10" d="M335.99 327.65h213v39.01h-213z" />
              <path
                className="prefix__st11"
                d="M498.99 327.65h49.99v39.01h-49.99z"
              />
              <path className="prefix__st13" d="M335.99 327.65h213v39.01h-213z" />
              <path
                fill="none"
                stroke="#58595b"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M330.94 366.67h223.1"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M701 233.44H595.28c0 1.94-1.58 3.51-3.52 3.51s-3.51-1.57-3.51-3.51h-18.63v27.02h18.63c0-1.94 1.57-3.51 3.51-3.51s3.52 1.57 3.52 3.51H701l10.5-4.37V237.8l-10.5-4.36z"
              />
              <path
                className="prefix__st11"
                d="M711.5 237.81v18.29l-10.5 4.36h-25v-27.02h25z"
              />
              <path
                className="prefix__st13"
                d="M701 233.44H595.28c0 1.94-1.58 3.51-3.52 3.51s-3.51-1.57-3.51-3.51h-18.63v27.02h18.63c0-1.94 1.57-3.51 3.51-3.51s3.52 1.57 3.52 3.51H701l10.5-4.37V237.8l-10.5-4.36zM701 233.44v27.02M595.28 233.44v26.31M588.25 233.44v27.02"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M1108.47 233.44h-105.72c0 1.94-1.58 3.51-3.52 3.51s-3.51-1.57-3.51-3.51H977.1v27.02h18.63c0-1.94 1.57-3.51 3.51-3.51s3.52 1.57 3.52 3.51h105.72l10.5-4.37V237.8l-10.51-4.36z"
              />
              <path
                className="prefix__st11"
                d="M1118.97 237.81v18.29l-10.5 4.36h-25v-27.02h25z"
              />
              <path
                className="prefix__st13"
                d="M1108.47 233.44h-105.72c0 1.94-1.58 3.51-3.52 3.51s-3.51-1.57-3.51-3.51H977.1v27.02h18.63c0-1.94 1.57-3.51 3.51-3.51s3.52 1.57 3.52 3.51h105.72l10.5-4.37V237.8l-10.51-4.36zM1108.47 233.44v27.02M1002.76 233.44v26.31M995.73 233.44v27.02"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M137.34 327.41c8.61-.4 15.23-7.82 15.23-16.43V209.92c0-18.12-14.69-32.8-32.8-32.8h-55.7c-8.62 0-16.03 6.62-16.43 15.23-.43 9.19 6.89 16.77 15.98 16.77h46.99c5.51 0 9.97 4.46 9.97 9.97v92.34c-.01 9.09 7.57 16.41 16.76 15.98z"
              />
              <path
                className="prefix__st11"
                d="M47.63 192.34c-.43 9.19 6.89 16.78 15.98 16.78h7.46a15.932 15.932 0 01-3.5-10.78c.41-8.6 7.82-15.22 16.44-15.22h54.68a32.66 32.66 0 00-18.92-6h-55.7c-8.62 0-16.03 6.62-16.44 15.22z"
              />
              <path
                className="prefix__st13"
                d="M137.34 327.41c8.61-.4 15.23-7.82 15.23-16.43V209.92c0-18.12-14.69-32.8-32.8-32.8h-55.7c-8.62 0-16.03 6.62-16.43 15.23-.43 9.19 6.89 16.77 15.98 16.77h46.99c5.51 0 9.97 4.46 9.97 9.97v92.34c-.01 9.09 7.57 16.41 16.76 15.98z"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M186.17 311.24v-4.98l-42.49-11.92h-14.13l-42.48 11.92v4.98h6.77v143.62h-6.77v4.97l42.48 11.92h14.13l42.49-11.92v-4.97h-6.77V311.24z"
              />
              <path
                className="prefix__st11"
                d="M179.41 311.24v143.62h6.76v4.97l-39.95 11.21 10.35-9.19v-157.6l-10.36-9.2 39.96 11.21v4.98z"
              />
              <path
                className="prefix__st13"
                d="M186.17 311.24v-4.98l-42.49-11.92h-14.13l-42.48 11.92v4.98h6.77v143.62h-6.77v4.97l42.48 11.92h14.13l42.49-11.92v-4.97h-6.77V311.24zM93.84 311.24h85.56M87.07 306.26h99.1M93.84 454.86h85.56M87.07 459.83h99.1"
              />
            </g>
            <g>
              <path
                className="prefix__st10"
                d="M154.62 262.05v-91.27h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v91.27h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26z"
              />
              <path
                className="prefix__st11"
                d="M154.62 170.77v91.28h3.26v3h-3.26s-2.8 6.53-11.91 8.66c3.17-2.61 4.31-5.28 4.31-5.28V164.39s-1.14-2.67-4.31-5.28c9.11 2.13 11.91 8.66 11.91 8.66h3.26v3h-3.26z"
              />
              <path
                className="prefix__st13"
                d="M154.62 262.05v-91.27h3.26v-3h-3.26s-4-9.33-18-9.33-18 9.33-18 9.33h-3.26v3h3.26v91.27h-3.26v3h3.26s4 9.33 18 9.33 18-9.33 18-9.33h3.26v-3h-3.26zM118.62 167.78h36M118.62 170.78h36M118.62 262.05h36M118.62 265.05h36"
              />
            </g>
            <use
              xlinkHref="#prefix__Valve_small"
              width={28.51}
              height={21.14}
              x={-14.25}
              y={-10.57}
              transform="matrix(0 1 1 0 648.25 301.5)"
              overflow="visible"
            />
          </g>
          <g id="prefix__Label">
            <path
              className="prefix__st16"
              d="M512.05 555.9H399.54c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51c0 2.07-1.68 3.75-3.75 3.75z"
            />
            <path className="prefix__st17" d="M416.39 537.07h90.6v15.75h-90.6z" />
            <text
              transform="translate(416.391 551.27)"
              className="prefix__st18 prefix__st19"
            >
              {`${Para['PFAD SCR 705 TCV Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(395.92 529.466)"
              className="prefix__st18 prefix__st20"
            >
              {"PFAD SCR 705"}
            </text>
            <path
              className="prefix__st16"
              d="M808.05 550.2H695.54c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51c0 2.07-1.68 3.75-3.75 3.75z"
            />
            <path className="prefix__st17" d="M712.39 531.37h90.6v15.75h-90.6z" />
            <text
              transform="translate(712.391 545.57)"
              className="prefix__st18 prefix__st19"
            >
              {`${Para['PFAD SCR 706 TCV Temperature'].PV} \xB0C`}
            </text>
            <text
              transform="translate(691.92 523.765)"
              className="prefix__st18 prefix__st20"
            >
              {"PFAD SCR 706"}
            </text>
            <path
              className="prefix__st16"
              d="M822.05 219.69H709.54c-2.07 0-3.75-1.68-3.75-3.75v-14.51c0-2.07 1.68-3.75 3.75-3.75h112.51c2.07 0 3.75 1.68 3.75 3.75v14.51c0 2.07-1.68 3.75-3.75 3.75z"
            />
            <path className="prefix__st17" d="M726.39 200.86h90.6v15.75h-90.6z" />
            <text
              transform="translate(726.391 215.062)"
              className="prefix__st18 prefix__st19"
            >
              {`${Para2['Cooling Tower Water Header Temperature'].PV} \xB0C`}
            </text>
            <text transform="translate(686.75 163.25)">
              <tspan x={0} y={0} className="prefix__st18 prefix__st20">
                {"Cooling Tower Water"}
              </tspan>
              <tspan x={51.8} y={21.6} className="prefix__st18 prefix__st20">
                {"Header"}
              </tspan>
            </text>
            <text
              transform="translate(105.266 367.654)"
              className="prefix__st18 prefix__st21"
            >
              {"Deodorizer"}
            </text>
            <text transform="translate(622.947 523.035)">
              <tspan x={0} y={0} className="prefix__st18 prefix__st21">
                {"Neutral"}
              </tspan>
              <tspan x={11.56} y={10.4} className="prefix__st18 prefix__st21">
                {"Oil"}
              </tspan>
            </text>
            <text transform="translate(220.12 577.412)">
              <tspan x={0} y={0} className="prefix__st18 prefix__st21">
                {"Fatty"}
              </tspan>
              <tspan x={1.46} y={10.4} className="prefix__st18 prefix__st21">
                {"Acid"}
              </tspan>
            </text>
            <text transform="translate(839.168 296.217)">
              <tspan x={0} y={0} className="prefix__st18 prefix__st21">
                {"Water"}
              </tspan>
              <tspan x={2.9} y={10.4} className="prefix__st18 prefix__st21">
                {"Tank"}
              </tspan>
            </text>
            <text
              transform="translate(816.671 120.79)"
              className="prefix__st18 prefix__st21"
            >
              {"Dirty Cooling Water"}
            </text>
            <text
              transform="translate(239.193 188.613)"
              className="prefix__st18 prefix__st21"
            >
              {"Hogger"}
            </text>
            <text
              transform="translate(116.12 289.17)"
              className="prefix__st18 prefix__st21"
            >
              {"STA702"}
            </text>
            <text
              transform="translate(115.404 234.261)"
              className="prefix__st18 prefix__st21"
            >
              {"SCA702"}
            </text>
            <text
              transform="translate(373.07 188.613)"
              className="prefix__st18 prefix__st21"
            >
              {"V702-1"}
            </text>
            <text
              transform="translate(119.563 354.613)"
              className="prefix__st18 prefix__st21"
            >
              {"D702"}
            </text>
            <text
              transform="translate(211.204 565.285)"
              className="prefix__st18 prefix__st21"
            >
              {"SCR705"}
            </text>
            <text
              transform="translate(294.52 687.285)"
              className="prefix__st18 prefix__st21"
            >
              {"P705"}
            </text>
            <text
              transform="translate(290.848 749.448)"
              className="prefix__st18 prefix__st21"
            >
              {"P705A"}
            </text>
            <text
              transform="translate(703.35 641.673)"
              className="prefix__st18 prefix__st21"
            >
              {"P706"}
            </text>
            <text
              transform="translate(699.678 703.836)"
              className="prefix__st18 prefix__st21"
            >
              {"P706A"}
            </text>
            <text
              transform="translate(432.2 685.285)"
              className="prefix__st18 prefix__st21"
            >
              {"E705"}
            </text>
            <text
              transform="translate(527.579 620.867)"
              className="prefix__st18 prefix__st21"
            >
              {"TCV705"}
            </text>
            <text
              transform="translate(1024.053 761.367)"
              className="prefix__st18 prefix__st21"
            >
              {"TCV706"}
            </text>
            <text
              transform="translate(852.23 529.466)"
              className="prefix__st18 prefix__st21"
            >
              {"LCV705"}
            </text>
            <text
              transform="translate(954.436 426.466)"
              className="prefix__st18 prefix__st21"
            >
              {"To B602"}
            </text>
            <text
              transform="translate(709.327 796.969)"
              className="prefix__st18 prefix__st21"
            >
              {"BPO To E701"}
            </text>
            <text transform="translate(202.609 779.703)">
              <tspan x={0} y={0} className="prefix__st18 prefix__st21">
                {"Clean Cooling"}
              </tspan>
              <tspan x={1.22} y={10.4} className="prefix__st18 prefix__st21">
                {"Tower Return"}
              </tspan>
            </text>
            <text
              transform="translate(622.564 509.169)"
              className="prefix__st18 prefix__st21"
            >
              {"SCR706"}
            </text>
            <text
              transform="translate(428.528 816.948)"
              className="prefix__st18 prefix__st21"
            >
              {"E705A"}
            </text>
            <text
              transform="translate(935.987 636.32)"
              className="prefix__st18 prefix__st21"
            >
              {"E706"}
            </text>
            <text
              transform="translate(932.315 767.984)"
              className="prefix__st18 prefix__st21"
            >
              {"E706A"}
            </text>
            <text
              transform="translate(466.404 233.44)"
              className="prefix__st18 prefix__st21"
            >
              {"V702-2"}
            </text>
            <text
              transform="translate(622.667 252.268)"
              className="prefix__st18 prefix__st21"
            >
              {"WH706"}
            </text>
            <text
              transform="translate(1027.667 252.268)"
              className="prefix__st18 prefix__st21"
            >
              {"SH706"}
            </text>
            <text
              transform="translate(629.917 326.518)"
              className="prefix__st18 prefix__st21"
            >
              {"VP702"}
            </text>
            <text
              transform="translate(628.29 206.475)"
              className="prefix__st18 prefix__st21"
            >
              {"TE706B"}
            </text>
            <text
              transform="translate(415.29 350.475)"
              className="prefix__st18 prefix__st21"
            >
              {"HOTWELL"}
            </text>
            <path className="prefix__st22" d="M515.8 523.04h.5" />
            <path
              fill="none"
              stroke="#414042"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray=".9621,2.8864"
              d="M519.18 523.04h25.5"
            />
            <path className="prefix__st22" d="M546.12 523.04h.5v.5" />
            <path
              fill="none"
              stroke="#414042"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="1.0238,3.0715"
              d="M546.62 526.61v47.6"
            />
            <path className="prefix__st22" d="M546.62 575.75v.5" />
          </g>
        </svg>
      )
}

export default OiltekFatty
