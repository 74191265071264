import React, { useState, useEffect } from 'react'
import { Line } from '@antv/g2plot';
import { DatePicker, Space, Row, Col, Card, Select, Divider, Table, Button } from 'antd';
import moment from 'moment';
import { RealtimeData_Request } from '../../api/index'
import axios from 'axios';
import JJLurgiRecepi401 from '../JJ Lurgi/HMI/TirtiauxRecepi401';
import JJLurgiRecepi402 from '../JJ Lurgi/HMI/TirtiauxRecepi402';
import JJLurgiRecepi403 from '../JJ Lurgi/HMI/TirtiauxRecepi403';
let previousTime = '2021-12-13 11:39:28'

let data_option = ["Cooling Tower Temperature", 'Chiller Water Tank Temperature', 'Filtration Stearine Temperature',]


let table_data = [
    'Cooling Tower Temperature',
    'Chiller Water Tank Temperature',
    'Filtration Stearine Temperature',
    'Filtration Squeeze Time',
    'Filtration Squeeze Pressure',
    'Filtration Load Pressure',
    'Load Pressure(Kg/cm)', 'Squezz Pressure',
    'HOMO5', 'Steam Header Pressure'
]

let recepimodel = {
    step: '',
    step2: '',
    step2Filling: '',
    step3Heating: '',
    step6Delta: '',
    step4time: '',
    step4water: '',
    step1time: '',
    totaltime: '',
    stepmode1: '',
    stepmode2: '',
    stepmode3: '',
    stepmode4: '',
    stepmode5: '',
    stepmode6: '',
    stepmode7: '',
    stepmode8: '',
    stepmode9: '',
    stepmode10: '',
    SpeedSv1: '',
    SpeedSv2: '',
    SpeedSv3: '',
    SpeedSv4: '',
    SpeedSv5: '',
    SpeedSv6: '',
    SpeedSv7: '',
    SpeedSv8: '',
    SpeedSv9: '',
    SpeedSv10: '',
    WaterTempsv1: '',
    WaterTempsv2: '',
    WaterTempsv3: '',
    WaterTempsv4: '',
    WaterTempsv5: '',
    WaterTempsv6: '',
    WaterTempsv7: '',
    WaterTempsv8: '',
    WaterTempsv9: '',
    WaterTempsv10: '',
    OilTempsv1: '',
    OilTempsv2: '',
    OilTempsv3: '',
    OilTempsv4: '',
    OilTempsv5: '',
    OilTempsv6: '',
    OilTempsv7: '',
    OilTempsv8: '',
    OilTempsv9: '',
    OilTempsv10: '',
    Delta_Tsv1: '',
    Delta_Tsv2: '',
    Delta_Tsv3: '',
    Delta_Tsv4: '',
    Delta_Tsv5: '',
    Delta_Tsv6: '',
    Delta_Tsv7: '',
    Delta_Tsv8: '',
    Delta_Tsv9: '',
    Delta_Tsv10: '',
    Timesv1: '',
    Timesv2: '',
    Timesv3: '',
    Timesv4: '',
    Timesv5: '',
    Timesv6: '',
    Timesv7: '',
    Timesv8: '',
    Timesv9: '',
    Timesv10: '',
    Timepv1: '',
    Timepv2: '',
    Timepv3: '',
    Timepv4: '',
    Timepv5: '',
    Timepv6: '',
    Timepv7: '',
    Timepv8: '',
    Timepv9: '',
    Timepv10: '',
    Time2pv1: '',
    Time2pv2: '',
    Time2pv3: '',
    Time2pv4: '',
    TimeSteppv1: '',
    TimeSteppv2: '',
    TimeSteppv3: '',
    TimeSteppv4: '',
    SpeedStepSv1: '',
    SpeedStepSv2: '',
    SpeedStepSv3: '',
    SpeedStepSv4: '',
}


let RT_graphbuf = {}, RT_tablebuf = {}, RT_tablebuf2 = [],
    line, data2 = [], recepibuf = [], recepibuf2,
    R401 = {}, R402 = {}, R403 = {}, buf1 = [], buf2 = [], buf3 = []


const columns = [
    {
        title: 'Parameter',
        dataIndex: 'Label',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
    },
    {
        title: 'Update Time',
        dataIndex: 'time',
    },
];

const JJLurgiRT = () => {

    const [JJLurgi, setJJLurgi] = useState([{}])
    const [JJLurgiTable, setJJLurgiTable] = useState([])
    const [Recepi401, set401] = useState({})
    const [Recepi402, set402] = useState({})
    const [Recepi403, set403] = useState({})
    const [tabkey, settabkey] = useState('Recepi D401')

    useEffect(() => {

        line = new Line('container', {
            data: JJLurgi,
            xField: 'time',
            yField: 'Value',
            seriesField: 'Label',
        });

        line.render();

        data2 = []
        GetRealTime_JJLurgi(table_data, 'FGV', '2', 'JJLurgi')
        GetRealTime_JJLurgiGraph(data_option)
        AppendData(RT_graphbuf)

        Get_Recepi([], 'FGV', '401', 'JJLurgi', '401', buf1)
        Get_Recepi([], 'FGV', '402', 'JJLurgi', '402', buf2)
        Get_Recepi([], 'FGV', '403', 'JJLurgi', '403', buf3)

        const interval = setInterval(() => {

            Get_Recepi([], 'FGV', '401', 'JJLurgi', '401', buf1)
            Get_Recepi([], 'FGV', '402', 'JJLurgi', '402', buf2)
            Get_Recepi([], 'FGV', '403', 'JJLurgi', '403', buf3)

            GetRealTime_JJLurgi(table_data, 'FGV', '2', 'JJLurgi')
            GetRealTime_JJLurgiGraph(data_option)
            AppendData(RT_graphbuf)

        }, 5000)

        return () => {
            clearInterval(interval)
            line.destroy();
            //console.log("unmount");
        }

    }, []);

    const RowClickhandler = (value) => {
        data2 = []
        //console.log(value)
        data_option[0] = value.Label
        data2 = []
    }

    const AppendData = (data) => {
        if (Object.keys(data).length === 0) { console.log();}
        else {
            if (previousTime !== data[0].time) {

                data.forEach(element => {

                    let obj = {}
                    obj['time'] = element.time
                    obj['Label'] = element.Label
                    obj['Value'] = parseFloat(element.Value)

                    data2.push(obj)
                });

                if (data2.length > 1000) {
                    data2.shift()
                    //console.log('shift')
                }
                console.log(data2);
                //setOiltek(R_oli)
                //console.log(Oiltek)
                line.changeData(data2)
                previousTime = data[0].time

            }
            // }
        }
    }
    const GetRealTime_JJLurgi = async (para_request, side, page, line) => {
        const realtimedata = await RealtimeData_Request(para_request, side, page, line)
        console.log(realtimedata)
        if (realtimedata.success !== false) {

            const data = realtimedata.Data

            RT_tablebuf2 = data.map(obj => {
                let cobj = []
                let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                cobj = {
                    'time': ar0,
                    'Label': obj.fields.Label,
                    'Value': obj.fields.Value,
                    'Line': obj.fields.Line,
                    'key': obj.fields.Label,
                    'Page': obj.fields.Page,
                }
                return cobj
            })

            RT_tablebuf = RT_tablebuf2

            RT_tablebuf = RT_tablebuf.sort((a, b) => (a['Label'] > b['Label']) ? 1 : ((b['Label'] > a['Label']) ? -1 : 0))
            setJJLurgiTable(RT_tablebuf)
            console.log('RT');
            console.log(RT_tablebuf)
        }
        else { console.log(realtimedata) }
    }

    function findPara(para) {

        let para2 = buf1.find(x => x['Label'] === para)

        try {
            return para2['Value']
        }
        catch {
            return 'None'
        }

    }

    function findPara2(para) {

        let para2 = buf2.find(x => x['Label'] === para)

        try {
            return para2['Value']
        }
        catch {
            return 'None'
        }

    }

    function findPara3(para) {

        let para2 = buf3.find(x => x['Label'] === para)

        try {
            return para2['Value']
        }
        catch {
            return 'None'
        }

    }

    const controlStateWrapper = (state) => {
        switch (state) {
            case "0.0":
                return 'None'
            case "1.0":
                return 'Water'
            case "2.0":
                return 'Oil'
            case "3.0":
                return 'Delta T'
            default:
                return 'xxxx'
        }
    }


    const Get_Recepi = async (data, side, page, line, recepitype, buf) => {
        const Recepi = await RealtimeData_Request(data, side, page, line)

        if (Recepi.success !== false) {

            const data = Recepi.Data
            //buf = []

            buf = data.map(obj => {
                let cobj = []
                let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                cobj = {
                    'time': ar0,
                    'Label': obj.fields.Label,
                    'Value': obj.fields.Value,
                }
                return cobj
            })

            switch (recepitype) {
                case '401':
                    buf1 = data.map(obj => {
                        let cobj = []
                        let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                        cobj = {
                            'time': ar0,
                            'Label': obj.fields.Label,
                            'Value': obj.fields.Value,
                        }
                        return cobj
                    })

                    // buf1 = recepibuf2
                    R401 = recepimodel

                    R401.step = findPara('F2_D401_STEP') //first 4 step (0-6)
                    R401.step2 = findPara('F2_D401_SEG_STEP')  // remaining step (0-10)
                    R401.step2Filling = findPara('F2_D401_REC_STEP2_FILLING_SV')
                    R401.step3Heating = findPara('F2_D401_REC_STEP3_HEATING_SV')
                    R401.step4water = findPara('F2_D401_REC_STEP4_WTR_TEMP_SV')
                    R401.step1time = findPara('F2_D401_REC_SEG_1_MODE')
                    R401.stepmode1 = controlStateWrapper(findPara('F2_D401_REC_SEG_1_MODE'))
                    R401.stepmode2 = controlStateWrapper(findPara('F2_D401_REC_SEG_2_MODE'))
                    R401.stepmode3 = controlStateWrapper(findPara('F2_D401_REC_SEG_3_MODE'))
                    R401.stepmode4 = controlStateWrapper(findPara('F2_D401_REC_SEG_4_MODE'))
                    R401.stepmode5 = controlStateWrapper(findPara('F2_D401_REC_SEG_5_MODE'))
                    R401.stepmode6 = controlStateWrapper(findPara('F2_D401_REC_SEG_6_MODE'))
                    R401.stepmode7 = controlStateWrapper(findPara('F2_D401_REC_SEG_7_MODE'))
                    R401.stepmode8 = controlStateWrapper(findPara('F2_D401_REC_SEG_8_MODE'))
                    R401.stepmode9 = controlStateWrapper(findPara('F2_D401_REC_SEG_9_MODE'))
                    R401.stepmode10 = controlStateWrapper(findPara('F2_D401_REC_SEG_10_MODE'))
                    R401.SpeedSv1 = findPara('F2_D401_REC_SEG_1_SPEED_SV')
                    R401.SpeedSv2 = findPara('F2_D401_REC_SEG_2_SPEED_SV')
                    R401.SpeedSv3 = findPara('F2_D401_REC_SEG_3_SPEED_SV')
                    R401.SpeedSv4 = findPara('F2_D401_REC_SEG_4_SPEED_SV')
                    R401.SpeedSv5 = findPara('F2_D401_REC_SEG_5_SPEED_SV')
                    R401.SpeedSv6 = findPara('F2_D401_REC_SEG_6_SPEED_SV')
                    R401.SpeedSv7 = findPara('F2_D401_REC_SEG_7_SPEED_SV')
                    R401.SpeedSv8 = findPara('F2_D401_REC_SEG_8_SPEED_SV')
                    R401.SpeedSv9 = findPara('F2_D401_REC_SEG_9_SPEED_SV')
                    R401.SpeedSv10 = findPara('F2_D401_REC_SEG_10_SPEED_SV')
                    R401.WaterTempsv1 = findPara('F2_D401_REC_SEG_1_WTR_TEMP_SV')
                    R401.WaterTempsv2 = findPara('F2_D401_REC_SEG_2_WTR_TEMP_SV')
                    R401.WaterTempsv3 = findPara('F2_D401_REC_SEG_3_WTR_TEMP_SV')
                    R401.WaterTempsv4 = findPara('F2_D401_REC_SEG_4_WTR_TEMP_SV')
                    R401.WaterTempsv5 = findPara('F2_D401_REC_SEG_5_WTR_TEMP_SV')
                    R401.WaterTempsv6 = findPara('F2_D401_REC_SEG_6_WTR_TEMP_SV')
                    R401.WaterTempsv7 = findPara('F2_D401_REC_SEG_7_WTR_TEMP_SV')
                    R401.WaterTempsv8 = findPara('F2_D401_REC_SEG_8_WTR_TEMP_SV')
                    R401.WaterTempsv9 = findPara('F2_D401_REC_SEG_9_WTR_TEMP_SV')
                    R401.WaterTempsv10 = findPara('F2_D401_REC_SEG_10_WTR_TEMP_SV')
                    R401.OilTempsv1 = findPara('F2_D401_REC_SEG_1_OIL_TEMP_SV')
                    R401.OilTempsv2 = findPara('F2_D401_REC_SEG_2_OIL_TEMP_SV')
                    R401.OilTempsv3 = findPara('F2_D401_REC_SEG_3_OIL_TEMP_SV')
                    R401.OilTempsv4 = findPara('F2_D401_REC_SEG_4_OIL_TEMP_SV')
                    R401.OilTempsv5 = findPara('F2_D401_REC_SEG_5_OIL_TEMP_SV')
                    R401.OilTempsv6 = findPara('F2_D401_REC_SEG_6_OIL_TEMP_SV')
                    R401.OilTempsv7 = findPara('F2_D401_REC_SEG_7_OIL_TEMP_SV')
                    R401.OilTempsv8 = findPara('F2_D401_REC_SEG_8_OIL_TEMP_SV')
                    R401.OilTempsv9 = findPara('F2_D401_REC_SEG_9_OIL_TEMP_SV')
                    R401.OilTempsv10 = findPara('F2_D401_REC_SEG_10_OIL_TEMP_SV')
                    R401.Delta_Tsv1 = findPara('F2_D401_REC_SEG_1_DELTA_T_SV')
                    R401.Delta_Tsv2 = findPara('F2_D401_REC_SEG_2_DELTA_T_SV')
                    R401.Delta_Tsv3 = findPara('F2_D401_REC_SEG_3_DELTA_T_SV')
                    R401.Delta_Tsv4 = findPara('F2_D401_REC_SEG_4_DELTA_T_SV')
                    R401.Delta_Tsv5 = findPara('F2_D401_REC_SEG_5_DELTA_T_SV')
                    R401.Delta_Tsv6 = findPara('F2_D401_REC_SEG_6_DELTA_T_SV')
                    R401.Delta_Tsv7 = findPara('F2_D401_REC_SEG_7_DELTA_T_SV')
                    R401.Delta_Tsv8 = findPara('F2_D401_REC_SEG_8_DELTA_T_SV')
                    R401.Delta_Tsv9 = findPara('F2_D401_REC_SEG_9_DELTA_T_SV')
                    R401.Delta_Tsv10 = findPara('F2_D401_REC_SEG_10_DELTA_T_SV')
                    R401.Timesv1 = findPara('F2_D401_REC_SEG_1_TIME_SV')
                    R401.Timesv2 = findPara('F2_D401_REC_SEG_2_TIME_SV')
                    R401.Timesv3 = findPara('F2_D401_REC_SEG_3_TIME_SV')
                    R401.Timesv4 = findPara('F2_D401_REC_SEG_4_TIME_SV')
                    R401.Timesv5 = findPara('F2_D401_REC_SEG_5_TIME_SV')
                    R401.Timesv6 = findPara('F2_D401_REC_SEG_6_TIME_SV')
                    R401.Timesv7 = findPara('F2_D401_REC_SEG_7_TIME_SV')
                    R401.Timesv8 = findPara('F2_D401_REC_SEG_8_TIME_SV')
                    R401.Timesv9 = findPara('F2_D401_REC_SEG_9_TIME_SV')
                    R401.Timesv10 = findPara('F2_D401_REC_SEG_10_TIME_SV')
                    R401.Timepv1 = findPara('F2_D401_DIS_SEG_1_TIME_PV')
                    R401.Timepv2 = findPara('F2_D401_DIS_SEG_2_TIME_PV')
                    R401.Timepv3 = findPara('F2_D401_DIS_SEG_3_TIME_PV')
                    R401.Timepv4 = findPara('F2_D401_DIS_SEG_4_TIME_PV')
                    R401.Timepv5 = findPara('F2_D401_DIS_SEG_5_TIME_PV')
                    R401.Timepv6 = findPara('F2_D401_DIS_SEG_6_TIME_PV')
                    R401.Timepv7 = findPara('F2_D401_DIS_SEG_7_TIME_PV')
                    R401.Timepv8 = findPara('F2_D401_DIS_SEG_8_TIME_PV')
                    R401.Timepv9 = findPara('F2_D401_DIS_SEG_9_TIME_PV')
                    R401.Timepv10 = findPara('F2_D401_DIS_SEG_10_TIME_PV')
                    R401.Time2pv1 = findPara('F2_D401_DIS_STEP1_TIME_PV')
                    R401.Time2pv2 = findPara('F2_D401_DIS_STEP2_TIME_PV')
                    R401.Time2pv3 = findPara('F2_D401_DIS_STEP3_TIME_PV')
                    R401.Time2pv4 = findPara('F2_D401_DIS_STEP4_TIME_PV')
                    R401.TimeSteppv1 = findPara('F2_D401_REC_STEP1_TIME_SV')
                    R401.TimeSteppv2 = findPara('F2_D401_REC_STEP2_TIME_SV')
                    R401.TimeSteppv3 = findPara('F2_D401_REC_STEP3_TIME_SV')
                    R401.TimeSteppv4 = findPara('F2_D401_REC_STEP4_TIME_SV')
                    R401.SpeedStepSv1 = findPara('F2_D401_REC_STEP1_SPEED_SV')
                    R401.SpeedStepSv2 = findPara('F2_D401_REC_STEP2_SPEED_SV')
                    R401.SpeedStepSv3 = findPara('F2_D401_REC_STEP3_SPEED_SV')
                    R401.SpeedStepSv4 = findPara('F2_D401_REC_STEP3_SPEED_SV')

                    set401(R401)
                    //console.log(Recepi401);
                    break;
                case '402':

                    buf2 = data.map(obj => {
                        let cobj = []
                        let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                        cobj = {
                            'time': ar0,
                            'Label': obj.fields.Label,
                            'Value': obj.fields.Value,
                        }
                        return cobj
                    })

                    // R402 = recepimodel
                    //buf2 = recepibuf2

                    R402.step = findPara2('F2_D402_STEP') //first 4 step (0-6)
                    R402.step2 = findPara2('F2_D402_SEG_STEP')  // remaining step (0-10)
                    R402.step2Filling = findPara2('F2_D402_REC_STEP2_FILLING_SV')
                    R402.step3Heating = findPara2('F2_D402_REC_STEP3_HEATING_SV')
                    R402.step4water = findPara2('F2_D402_REC_STEP4_WTR_TEMP_SV')
                    R402.step1time = findPara2('F2_D402_REC_SEG_1_MODE')
                    R402.stepmode1 = controlStateWrapper(findPara2('F2_D402_REC_SEG_1_MODE'))
                    R402.stepmode2 = controlStateWrapper(findPara2('F2_D402_REC_SEG_2_MODE'))
                    R402.stepmode3 = controlStateWrapper(findPara2('F2_D402_REC_SEG_3_MODE'))
                    R402.stepmode4 = controlStateWrapper(findPara2('F2_D402_REC_SEG_4_MODE'))
                    R402.stepmode5 = controlStateWrapper(findPara2('F2_D402_REC_SEG_5_MODE'))
                    R402.stepmode6 = controlStateWrapper(findPara2('F2_D402_REC_SEG_6_MODE'))
                    R402.stepmode7 = controlStateWrapper(findPara2('F2_D402_REC_SEG_7_MODE'))
                    R402.stepmode8 = controlStateWrapper(findPara2('F2_D402_REC_SEG_8_MODE'))
                    R402.stepmode9 = controlStateWrapper(findPara2('F2_D402_REC_SEG_9_MODE'))
                    R402.stepmode10 = controlStateWrapper(findPara2('F2_D402_REC_SEG_10_MODE'))
                    R402.SpeedSv1 = findPara2('F2_D402_REC_SEG_1_SPEED_SV')
                    R402.SpeedSv2 = findPara2('F2_D402_REC_SEG_2_SPEED_SV')
                    R402.SpeedSv3 = findPara2('F2_D402_REC_SEG_3_SPEED_SV')
                    R402.SpeedSv4 = findPara2('F2_D402_REC_SEG_4_SPEED_SV')
                    R402.SpeedSv5 = findPara2('F2_D402_REC_SEG_5_SPEED_SV')
                    R402.SpeedSv6 = findPara2('F2_D402_REC_SEG_6_SPEED_SV')
                    R402.SpeedSv7 = findPara2('F2_D402_REC_SEG_7_SPEED_SV')
                    R402.SpeedSv8 = findPara2('F2_D402_REC_SEG_8_SPEED_SV')
                    R402.SpeedSv9 = findPara2('F2_D402_REC_SEG_9_SPEED_SV')
                    R402.SpeedSv10 = findPara2('F2_D402_REC_SEG_10_SPEED_SV')
                    R402.WaterTempsv1 = findPara2('F2_D402_REC_SEG_1_WTR_TEMP_SV')
                    R402.WaterTempsv2 = findPara2('F2_D402_REC_SEG_2_WTR_TEMP_SV')
                    R402.WaterTempsv3 = findPara2('F2_D402_REC_SEG_3_WTR_TEMP_SV')
                    R402.WaterTempsv4 = findPara2('F2_D402_REC_SEG_4_WTR_TEMP_SV')
                    R402.WaterTempsv5 = findPara2('F2_D402_REC_SEG_5_WTR_TEMP_SV')
                    R402.WaterTempsv6 = findPara2('F2_D402_REC_SEG_6_WTR_TEMP_SV')
                    R402.WaterTempsv7 = findPara2('F2_D402_REC_SEG_7_WTR_TEMP_SV')
                    R402.WaterTempsv8 = findPara2('F2_D402_REC_SEG_8_WTR_TEMP_SV')
                    R402.WaterTempsv9 = findPara2('F2_D402_REC_SEG_9_WTR_TEMP_SV')
                    R402.WaterTempsv10 = findPara2('F2_D402_REC_SEG_10_WTR_TEMP_SV')
                    R402.OilTempsv1 = findPara2('F2_D402_REC_SEG_1_OIL_TEMP_SV')
                    R402.OilTempsv2 = findPara2('F2_D402_REC_SEG_2_OIL_TEMP_SV')
                    R402.OilTempsv3 = findPara2('F2_D402_REC_SEG_3_OIL_TEMP_SV')
                    R402.OilTempsv4 = findPara2('F2_D402_REC_SEG_4_OIL_TEMP_SV')
                    R402.OilTempsv5 = findPara2('F2_D402_REC_SEG_5_OIL_TEMP_SV')
                    R402.OilTempsv6 = findPara2('F2_D402_REC_SEG_6_OIL_TEMP_SV')
                    R402.OilTempsv7 = findPara2('F2_D402_REC_SEG_7_OIL_TEMP_SV')
                    R402.OilTempsv8 = findPara2('F2_D402_REC_SEG_8_OIL_TEMP_SV')
                    R402.OilTempsv9 = findPara2('F2_D402_REC_SEG_9_OIL_TEMP_SV')
                    R402.OilTempsv10 = findPara2('F2_D402_REC_SEG_10_OIL_TEMP_SV')
                    R402.Delta_Tsv1 = findPara2('F2_D402_REC_SEG_1_DELTA_T_SV')
                    R402.Delta_Tsv2 = findPara2('F2_D402_REC_SEG_2_DELTA_T_SV')
                    R402.Delta_Tsv3 = findPara2('F2_D402_REC_SEG_3_DELTA_T_SV')
                    R402.Delta_Tsv4 = findPara2('F2_D402_REC_SEG_4_DELTA_T_SV')
                    R402.Delta_Tsv5 = findPara2('F2_D402_REC_SEG_5_DELTA_T_SV')
                    R402.Delta_Tsv6 = findPara2('F2_D402_REC_SEG_6_DELTA_T_SV')
                    R402.Delta_Tsv7 = findPara2('F2_D402_REC_SEG_7_DELTA_T_SV')
                    R402.Delta_Tsv8 = findPara2('F2_D402_REC_SEG_8_DELTA_T_SV')
                    R402.Delta_Tsv9 = findPara2('F2_D402_REC_SEG_9_DELTA_T_SV')
                    R402.Delta_Tsv10 = findPara2('F2_D402_REC_SEG_10_DELTA_T_SV')
                    R402.Timesv1 = findPara2('F2_D402_REC_SEG_1_TIME_SV')
                    R402.Timesv2 = findPara2('F2_D402_REC_SEG_2_TIME_SV')
                    R402.Timesv3 = findPara2('F2_D402_REC_SEG_3_TIME_SV')
                    R402.Timesv4 = findPara2('F2_D402_REC_SEG_4_TIME_SV')
                    R402.Timesv5 = findPara2('F2_D402_REC_SEG_5_TIME_SV')
                    R402.Timesv6 = findPara2('F2_D402_REC_SEG_6_TIME_SV')
                    R402.Timesv7 = findPara2('F2_D402_REC_SEG_7_TIME_SV')
                    R402.Timesv8 = findPara2('F2_D402_REC_SEG_8_TIME_SV')
                    R402.Timesv9 = findPara2('F2_D402_REC_SEG_9_TIME_SV')
                    R402.Timesv10 = findPara2('F2_D402_REC_SEG_10_TIME_SV')
                    R402.Timepv1 = findPara2('F2_D402_DIS_SEG_1_TIME_PV')
                    R402.Timepv2 = findPara2('F2_D402_DIS_SEG_2_TIME_PV')
                    R402.Timepv3 = findPara2('F2_D402_DIS_SEG_3_TIME_PV')
                    R402.Timepv4 = findPara2('F2_D402_DIS_SEG_4_TIME_PV')
                    R402.Timepv5 = findPara2('F2_D402_DIS_SEG_5_TIME_PV')
                    R402.Timepv6 = findPara2('F2_D402_DIS_SEG_6_TIME_PV')
                    R402.Timepv7 = findPara2('F2_D402_DIS_SEG_7_TIME_PV')
                    R402.Timepv8 = findPara2('F2_D402_DIS_SEG_8_TIME_PV')
                    R402.Timepv9 = findPara2('F2_D402_DIS_SEG_9_TIME_PV')
                    R402.Timepv10 = findPara2('F2_D402_DIS_SEG_10_TIME_PV')
                    R402.Time2pv1 = findPara2('F2_D402_DIS_STEP1_TIME_PV')
                    R402.Time2pv2 = findPara2('F2_D402_DIS_STEP2_TIME_PV')
                    R402.Time2pv3 = findPara2('F2_D402_DIS_STEP3_TIME_PV')
                    R402.Time2pv4 = findPara2('F2_D402_DIS_STEP4_TIME_PV')
                    R402.TimeSteppv1 = findPara2('F2_D402_REC_STEP1_TIME_SV')
                    R402.TimeSteppv2 = findPara2('F2_D402_REC_STEP2_TIME_SV')
                    R402.TimeSteppv3 = findPara2('F2_D402_REC_STEP3_TIME_SV')
                    R402.TimeSteppv4 = findPara2('F2_D402_REC_STEP4_TIME_SV')
                    R402.SpeedStepSv1 = findPara2('F2_D402_REC_STEP1_SPEED_SV')
                    R402.SpeedStepSv2 = findPara2('F2_D402_REC_STEP2_SPEED_SV')
                    R402.SpeedStepSv3 = findPara2('F2_D402_REC_STEP3_SPEED_SV')
                    R402.SpeedStepSv4 = findPara2('F2_D402_REC_STEP3_SPEED_SV')
                    set402(R402)
                    //console.log(Recepi402);
                    break;
                case '403':

                    buf3 = data.map(obj => {
                        let cobj = []
                        let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                        cobj = {
                            'time': ar0,
                            'Label': obj.fields.Label,
                            'Value': obj.fields.Value,
                        }
                        return cobj
                    })
                    //  R403 = recepimodel
                    //buf3 = recepibuf2

                    R403.step = findPara3('F2_D403_STEP') //first 4 step (0-6)
                    R403.step2 = findPara3('F2_D403_SEG_STEP')  // remaining step (0-10)
                    R403.step2Filling = findPara3('F2_D403_REC_STEP2_FILLING_SV')
                    R403.step3Heating = findPara3('F2_D403_REC_STEP3_HEATING_SV')
                    R403.step4water = findPara3('F2_D403_REC_STEP4_WTR_TEMP_SV')
                    R403.step1time = findPara3('F2_D403_REC_SEG_1_MODE')
                    R403.stepmode1 = controlStateWrapper(findPara3('F2_D403_REC_SEG_1_MODE'))
                    R403.stepmode2 = controlStateWrapper(findPara3('F2_D403_REC_SEG_2_MODE'))
                    R403.stepmode3 = controlStateWrapper(findPara3('F2_D403_REC_SEG_3_MODE'))
                    R403.stepmode4 = controlStateWrapper(findPara3('F2_D403_REC_SEG_4_MODE'))
                    R403.stepmode5 = controlStateWrapper(findPara3('F2_D403_REC_SEG_5_MODE'))
                    R403.stepmode6 = controlStateWrapper(findPara3('F2_D403_REC_SEG_6_MODE'))
                    R403.stepmode7 = controlStateWrapper(findPara3('F2_D403_REC_SEG_7_MODE'))
                    R403.stepmode8 = controlStateWrapper(findPara3('F2_D403_REC_SEG_8_MODE'))
                    R403.stepmode9 = controlStateWrapper(findPara3('F2_D403_REC_SEG_9_MODE'))
                    R403.stepmode10 = controlStateWrapper(findPara3('F2_D403_REC_SEG_10_MODE'))
                    R403.SpeedSv1 = findPara3('F2_D403_REC_SEG_1_SPEED_SV')
                    R403.SpeedSv2 = findPara3('F2_D403_REC_SEG_2_SPEED_SV')
                    R403.SpeedSv3 = findPara3('F2_D403_REC_SEG_3_SPEED_SV')
                    R403.SpeedSv4 = findPara3('F2_D403_REC_SEG_4_SPEED_SV')
                    R403.SpeedSv5 = findPara3('F2_D403_REC_SEG_5_SPEED_SV')
                    R403.SpeedSv6 = findPara3('F2_D403_REC_SEG_6_SPEED_SV')
                    R403.SpeedSv7 = findPara3('F2_D403_REC_SEG_7_SPEED_SV')
                    R403.SpeedSv8 = findPara3('F2_D403_REC_SEG_8_SPEED_SV')
                    R403.SpeedSv9 = findPara3('F2_D403_REC_SEG_9_SPEED_SV')
                    R403.SpeedSv10 = findPara3('F2_D403_REC_SEG_10_SPEED_SV')
                    R403.WaterTempsv1 = findPara3('F2_D403_REC_SEG_1_WTR_TEMP_SV')
                    R403.WaterTempsv2 = findPara3('F2_D403_REC_SEG_2_WTR_TEMP_SV')
                    R403.WaterTempsv3 = findPara3('F2_D403_REC_SEG_3_WTR_TEMP_SV')
                    R403.WaterTempsv4 = findPara3('F2_D403_REC_SEG_4_WTR_TEMP_SV')
                    R403.WaterTempsv5 = findPara3('F2_D403_REC_SEG_5_WTR_TEMP_SV')
                    R403.WaterTempsv6 = findPara3('F2_D403_REC_SEG_6_WTR_TEMP_SV')
                    R403.WaterTempsv7 = findPara3('F2_D403_REC_SEG_7_WTR_TEMP_SV')
                    R403.WaterTempsv8 = findPara3('F2_D403_REC_SEG_8_WTR_TEMP_SV')
                    R403.WaterTempsv9 = findPara3('F2_D403_REC_SEG_9_WTR_TEMP_SV')
                    R403.WaterTempsv10 = findPara3('F2_D403_REC_SEG_10_WTR_TEMP_SV')
                    R403.OilTempsv1 = findPara3('F2_D403_REC_SEG_1_OIL_TEMP_SV')
                    R403.OilTempsv2 = findPara3('F2_D403_REC_SEG_2_OIL_TEMP_SV')
                    R403.OilTempsv3 = findPara3('F2_D403_REC_SEG_3_OIL_TEMP_SV')
                    R403.OilTempsv4 = findPara3('F2_D403_REC_SEG_4_OIL_TEMP_SV')
                    R403.OilTempsv5 = findPara3('F2_D403_REC_SEG_5_OIL_TEMP_SV')
                    R403.OilTempsv6 = findPara3('F2_D403_REC_SEG_6_OIL_TEMP_SV')
                    R403.OilTempsv7 = findPara3('F2_D403_REC_SEG_7_OIL_TEMP_SV')
                    R403.OilTempsv8 = findPara3('F2_D403_REC_SEG_8_OIL_TEMP_SV')
                    R403.OilTempsv9 = findPara3('F2_D403_REC_SEG_9_OIL_TEMP_SV')
                    R403.OilTempsv10 = findPara3('F2_D403_REC_SEG_10_OIL_TEMP_SV')
                    R403.Delta_Tsv1 = findPara3('F2_D403_REC_SEG_1_DELTA_T_SV')
                    R403.Delta_Tsv2 = findPara3('F2_D403_REC_SEG_2_DELTA_T_SV')
                    R403.Delta_Tsv3 = findPara3('F2_D403_REC_SEG_3_DELTA_T_SV')
                    R403.Delta_Tsv4 = findPara3('F2_D403_REC_SEG_4_DELTA_T_SV')
                    R403.Delta_Tsv5 = findPara3('F2_D403_REC_SEG_5_DELTA_T_SV')
                    R403.Delta_Tsv6 = findPara3('F2_D403_REC_SEG_6_DELTA_T_SV')
                    R403.Delta_Tsv7 = findPara3('F2_D403_REC_SEG_7_DELTA_T_SV')
                    R403.Delta_Tsv8 = findPara3('F2_D403_REC_SEG_8_DELTA_T_SV')
                    R403.Delta_Tsv9 = findPara3('F2_D403_REC_SEG_9_DELTA_T_SV')
                    R403.Delta_Tsv10 = findPara3('F2_D403_REC_SEG_10_DELTA_T_SV')
                    R403.Timesv1 = findPara3('F2_D403_REC_SEG_1_TIME_SV')
                    R403.Timesv2 = findPara3('F2_D403_REC_SEG_2_TIME_SV')
                    R403.Timesv3 = findPara3('F2_D403_REC_SEG_3_TIME_SV')
                    R403.Timesv4 = findPara3('F2_D403_REC_SEG_4_TIME_SV')
                    R403.Timesv5 = findPara3('F2_D403_REC_SEG_5_TIME_SV')
                    R403.Timesv6 = findPara3('F2_D403_REC_SEG_6_TIME_SV')
                    R403.Timesv7 = findPara3('F2_D403_REC_SEG_7_TIME_SV')
                    R403.Timesv8 = findPara3('F2_D403_REC_SEG_8_TIME_SV')
                    R403.Timesv9 = findPara3('F2_D403_REC_SEG_9_TIME_SV')
                    R403.Timesv10 = findPara3('F2_D403_REC_SEG_10_TIME_SV')
                    R403.Timepv1 = findPara3('F2_D403_DIS_SEG_1_TIME_PV')
                    R403.Timepv2 = findPara3('F2_D403_DIS_SEG_2_TIME_PV')
                    R403.Timepv3 = findPara3('F2_D403_DIS_SEG_3_TIME_PV')
                    R403.Timepv4 = findPara3('F2_D403_DIS_SEG_4_TIME_PV')
                    R403.Timepv5 = findPara3('F2_D403_DIS_SEG_5_TIME_PV')
                    R403.Timepv6 = findPara3('F2_D403_DIS_SEG_6_TIME_PV')
                    R403.Timepv7 = findPara3('F2_D403_DIS_SEG_7_TIME_PV')
                    R403.Timepv8 = findPara3('F2_D403_DIS_SEG_8_TIME_PV')
                    R403.Timepv9 = findPara3('F2_D403_DIS_SEG_9_TIME_PV')
                    R403.Timepv10 = findPara3('F2_D403_DIS_SEG_10_TIME_PV')
                    R403.Time2pv1 = findPara3('F2_D403_DIS_STEP1_TIME_PV')
                    R403.Time2pv2 = findPara3('F2_D403_DIS_STEP2_TIME_PV')
                    R403.Time2pv3 = findPara3('F2_D403_DIS_STEP3_TIME_PV')
                    R403.Time2pv4 = findPara3('F2_D403_DIS_STEP4_TIME_PV')
                    R403.TimeSteppv1 = findPara3('F2_D403_REC_STEP1_TIME_SV')
                    R403.TimeSteppv2 = findPara3('F2_D403_REC_STEP2_TIME_SV')
                    R403.TimeSteppv3 = findPara3('F2_D403_REC_STEP3_TIME_SV')
                    R403.TimeSteppv4 = findPara3('F2_D403_REC_STEP4_TIME_SV')
                    R403.SpeedStepSv1 = findPara3('F2_D403_REC_STEP1_SPEED_SV')
                    R403.SpeedStepSv2 = findPara3('F2_D403_REC_STEP2_SPEED_SV')
                    R403.SpeedStepSv3 = findPara3('F2_D403_REC_STEP3_SPEED_SV')
                    R403.SpeedStepSv4 = findPara3('F2_D403_REC_STEP3_SPEED_SV')
                    set403(R403)
                    //.log(Recepi403);
                    break;
                default:
            }
        }
        else { console.log(Recepi) }

        //console.log(Recepi)
    }



    const GetRealTime_JJLurgiGraph = async (para_request) => {
        const realtimedata = await RealtimeData_Request(para_request, 'FGV', '2', "JJLurgi")
        if (realtimedata.success !== false) {
            //console.log(realtimedata.Data)

            const data = realtimedata.Data

            RT_graphbuf = data.map(obj => {
                let cobj = []
                let ar0 = moment(obj.fields.LastChange).utc(true).format('YYYY-MM-DD HH:mm:ss')

                cobj = {
                    'time': ar0,
                    'Label': obj.fields.Label,
                    'Value': obj.fields.Value,
                }
                return cobj
            })
            // console.log('raw');
            // console.log(RT_graphbuf)
        }
        else {
            console.log('fail')
            //console.log(realtimedata) 
        }
    }

    const tabList = [
        {
            key: 'Recepi D401',
            tab: 'Recepi D401',
        },
        {
            key: 'Recepi D402',
            tab: 'Recepi D402',
        },
        {
            key: 'Recepi D403',
            tab: 'Recepi D403',
        },
    ];

    const contentList = {

        'Recepi D401':
            <div style={{ width: '100%', height: 600, borderRadius: 15, }}>
                <JJLurgiRecepi401
                    R401={R401} />
            </div>,

        'Recepi D402':
            <div style={{ width: '100%', height: 600, borderRadius: 15, }}>
                <JJLurgiRecepi402
                    R402={R402} />
            </div>,

        'Recepi D403':
            <div style={{ width: '100%', height: 600, borderRadius: 15, }}>
                <JJLurgiRecepi403
                    R403={R403} />
            </div>,

    };

    const onTabChange = (key) => {

        switch (key) {
            case 'Recepi D401':
                Get_Recepi([], 'FGV', '401', 'JJLurgi', '401', buf1)
                settabkey(key)
                break;

            case 'Recepi D402':
                Get_Recepi([], 'FGV', '402', 'JJLurgi', '402', buf2)
                settabkey(key)
                break;

            case 'Recepi D403':
                Get_Recepi([], 'FGV', '403', 'JJLurgi', '403', buf3)
                settabkey(key)
                break;

            default:
                console.log('default');
                break;
        }

        //console.log(key);
        settabkey(key)
    }


    return (
        <div>
            {/* <Button onClick={testbuttonhandler}> asdasdasd</Button> */}
            {console.log('render')}
            <Divider orientation="left">Schemetic View</Divider>
            <div style={{ paddingTop: '20px' }}>
                <Card bordered={true}
                    style={{ borderRadius: 15, width: '100%' }} hoverable={false}
                    tabList={tabList}
                    bodyStyle={{ padding: "0" }}
                    headStyle={{ backgroundColor: '#c7bcbb', borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
                    activeTabKey={tabkey}
                    onTabChange={key => {
                        onTabChange(key);
                    }}
                >
                    {contentList[tabkey]}
                </Card>
            </div>


            <Divider orientation="left">TRENDING GRAPH</Divider>
            <Row gutter={[16, 16]}>

                <Col className="gutter-row" span={6} style={{ padding: '8px 8px' }} xs={24} xl={12}>
                    <div>
                        <Card title='Log ' bordered={true}
                            style={{ width: '100%', borderRadius: 15, }} hoverable={true}>
                            <Table bordered columns={columns} dataSource={JJLurgiTable} size="small" pagination={8}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            RowClickhandler(record)
                                        }, // click row
                                    };
                                }} />
                        </Card>
                    </div>
                </Col>

                <Col className="gutter-row" span={6} style={{ padding: '8px 8px' }} xs={24} xl={12}>
                    <div>
                        <Card title={data_option[0]} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#f0f9fa' }} hoverable={true}>
                            <div id="container"></div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default JJLurgiRT

