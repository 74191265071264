import React, { useEffect, useState } from 'react'
import { ReadJob, RealtimeData_Request } from '../../api'
import { FilterOutEndedJob, GetDateTime, ReportSort, TableColumbJob, TableColumbJobHome } from '../../DataRequest/LogDataRequestWrap';
import moment from 'moment';
import { C3Model, G2Model, JobModel } from '../../DataRequest/DataModel';
import { Button, Card, Col, Row, Table, Typography, Tabs, Divider, Statistic } from 'antd';
import RealTimeTableWrapper from '../Realtime/RealTimeTableWrapper';
import { DataSortRT } from '../../DataRequest/RealTimeRequestWrap';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

let LastUpdate = {
    "OTR": '',
    "ALR": '',
    "TXF": '',
    "MarShor": '',
}

let LineStatus = {
    "OTR": true,
    "ALR": true,
    "TXF": true,
    "MarShor": true,
}

let RealTimeValue = {
    "Oiltek1": [],
    "Oiltek2": [],
    "Alfa1": [],
    "Alfa2": [],
    "Tirtiaux1": [],
    "Tirtiaux2": [],
    "Tirtiaux3": [],
    'Margerine': []
}

const Oiltek_list_1 = ["B/E Setting", 'CPO T001 Flow Rate', "PHE CPO OUT HE001 Temperature",
    'H3PO4 Stroke/F', 'F BPO QLTY Color Red', 'F BPO QLTY Black Partical', 'OTR_HeartBeat'
]

const Oiltek_list_2 = ['RBDPO Quality CLR', 'RBDPO Quality FFA', 'RBDPO Quality Black Partical',
    'Vacuum DEO TORR', 'BPO Flow Rate', 'DEOD D702 Temperature'
]

const Alfa_list_1 = ["CPO 26E00 Flow Rate", 'Bleaching Earth Setting',
    'F BPO QLTY Color Red', 'F BPO QLTY Black Partical', 'ALR_HeartBeat'
]

const Alfa_list_2 = ['RPO QUALITY CLR', 'RPO QUALITY FFA', "DEOD 27C03 Temperature", "Flow Rate",
    "Vacuum"
]

const Tirtiaux_list_1 = ["Filtration Load Pressure", 'TXF_HeartBeat']

const Tirtiaux_list_2 = ['OLEIN FFA',
    'OLEIN Col(R)', 'OLEIN I.V.', 'OLEIN C.P.',
    'OLEIN Yield(%)', 'OLEIN Storage'
]

const Tirtiaux_list_3 = ['STEARIN FFA', 'STEARIN Col(R)', 'STEARIN I.V.', 'STEARIN M.P.',
    'STEARIN VM(%)', 'STEARIN Storage',
]

const columns = [
    {
        title: 'Parameter',
        dataIndex: 'Label',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
    },
    {
        title: 'Update Time',
        dataIndex: 'time',
    },
];


const Home = () => {

    const { Title } = Typography

    const [state, setState] = useState({ count: 0 });

    const CheckAlive = () => {

        var CurrentDateTime = new Date();

        RealTimeValue["Oiltek1"].forEach(element => {

            if (element['label'] == 'CPO T001 Flow Rate') {
                LastUpdate['OTR'] = element['time']
                let DateTimeOtr = new Date(LastUpdate['OTR']);

                DateTimeOtr.setHours(DateTimeOtr.getHours() + 1);

                if (DateTimeOtr < CurrentDateTime) {
                    console.log('dis');
                    LineStatus['OTR'] = false
                }
                else {
                    LineStatus['OTR'] = true
                }
            }
        });

        RealTimeValue["Alfa1"].forEach(element => {

            if (element['label'] == 'CPO 26E00 Flow Rate') {
                LastUpdate['ALR'] = element['time']
                LastUpdate['ALR'] = element['time']
                let DateTimeALR = new Date(LastUpdate['ALR']);

                DateTimeALR.setHours(DateTimeALR.getHours() + 1);

                if (DateTimeALR < CurrentDateTime) {
                    // console.log('dis');
                    LineStatus['ALR'] = false
                }
                else {
                    LineStatus['ALR'] = true
                }
            }
        });

        RealTimeValue["Tirtiaux1"].forEach(element => {

            if (element['label'] == 'Filtration Load Pressure') {
                LastUpdate['TXF'] = element['time']
                LastUpdate['TXF'] = element['time']
                let DateTimeTXF = new Date(LastUpdate['TXF']);

                DateTimeTXF.setHours(DateTimeTXF.getHours() + 1);

                if (DateTimeTXF < CurrentDateTime) {
                    console.log('dis');
                    LineStatus['TXF'] = false
                }
                else {
                    LineStatus['TXF'] = true
                }
            }
        });

        RealTimeValue["Margerine"].forEach(element => {

            if (element['label'] == 'Salt Tank AT201 Load Cell LC-201') {
                LastUpdate['MarShor'] = element['time']
                LastUpdate['MarShor'] = element['time']
                let DateTimeMarShor = new Date(LastUpdate['MarShor']);

                DateTimeMarShor.setHours(DateTimeMarShor.getHours() + 1);

                if (DateTimeMarShor < CurrentDateTime) {
                    console.log('dis');
                    LineStatus['MarShor'] = false
                }
                else {
                    LineStatus['MarShor'] = true
                }
            }
        });

    }

    const DataRequest = async (label, site, page, line, TableName) => {
        const response = await RealtimeData_Request(label, site, page, line)

        RealTimeValue[TableName] = DataSortRT(response)

        // console.log(RealTimeValue[TableName]);
    }
    //Salt Tank AT201 Load Cell LC-201
    const RenderNewData = () => {
        DataRequest(Oiltek_list_1, 'FGV', '1', 'OILTEK', "Oiltek1")
        DataRequest(Oiltek_list_2, 'FGV', '2', 'OILTEK', "Oiltek2")
        DataRequest(Alfa_list_1, 'FGV', '1', 'ALPHA LAVAL', "Alfa1")
        DataRequest(Alfa_list_2, 'FGV', '2', 'ALPHA LAVAL', "Alfa2")
        DataRequest(Tirtiaux_list_1, 'FGV', '2', 'Tirtiaux', "Tirtiaux1")
        DataRequest(Tirtiaux_list_2, 'FGV', '3', 'Tirtiaux', "Tirtiaux2")
        DataRequest(Tirtiaux_list_3, 'FGV', '3', 'Tirtiaux', "Tirtiaux3")
        DataRequest([], 'FGV', '1', 'Margarine Aques', "Margerine")


        CheckAlive()

        setState(({ count }) => ({ count: count + 1 }));
    }


    useEffect(() => {
        RenderNewData()
        const interval = setInterval(() => {
            RenderNewData()
        }, 3000)

        return () => {
            clearInterval(interval)
        }

    }, [])

    return (
        <div>

            <div style={{ marginTop: '16px' }}>
                <Card >
                    <h2 style={{ fontSize: '32px' }}>IOT Device Online Status</h2>

                    <Row gutter={'3'} >
                        <Col span={6} xs={24} xl={6}>
                            <Card bordered={true} style={{ backgroundColor: 'ButtonHighlight ', border: '3px outset' }}  >
                                <h2 style={{ alignContent: 'center', fontSize: '32px' }}>
                                    OTR
                                    {
                                        LineStatus['OTR'] ? <CheckCircleFilled
                                            style={{ fontSize: '30px', color: '#04de46', padding: 10 }}
                                        /> : <CloseCircleFilled
                                            style={{ fontSize: '30px', color: '#ed0202', padding: 10 }}
                                        />
                                    }
                                </h2>

                                <Row gutter={'1'} >
                                    <Col span={8} xs={24} xl={24}>
                                        <Row gutter={'1'} >
                                            <Col span={8} xs={24} xl={24}>
                                                <h2>
                                                    Last Update:
                                                </h2>
                                                <h2>
                                                    {LastUpdate['OTR']}
                                                </h2>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={6} xs={24} xl={6}>
                            <Card bordered={true} style={{ backgroundColor: 'ButtonHighlight ', border: '3px outset' }}  >
                                <h2 style={{ alignContent: 'center', fontSize: '32px' }}>
                                    ALR
                                    {
                                        LineStatus['ALR'] ? <CheckCircleFilled
                                            style={{ fontSize: '30px', color: '#04de46', padding: 10 }}
                                        /> : <CloseCircleFilled
                                            style={{ fontSize: '30px', color: '#ed0202', padding: 10 }}
                                        />
                                    }
                                </h2>

                                <Row gutter={'1'} >
                                    <Col span={8} xs={24} xl={24}>
                                        <Row gutter={'1'} >
                                            <Col span={8} xs={24} xl={24}>
                                                <h2>
                                                    Last Update:
                                                </h2>
                                                <h2>
                                                    {LastUpdate['ALR']}
                                                </h2>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={6} xs={24} xl={6}>
                            <Card bordered={true} style={{ backgroundColor: 'ButtonHighlight ', border: '3px outset' }}  >
                                <h2 style={{ alignContent: 'center', fontSize: '32px' }}>
                                    TXF
                                    {
                                        LineStatus['TXF'] ? <CheckCircleFilled
                                            style={{ fontSize: '30px', color: '#04de46', padding: 10 }}
                                        /> : <CloseCircleFilled
                                            style={{ fontSize: '30px', color: '#ed0202', padding: 10 }}
                                        />
                                    }
                                </h2>

                                <Row gutter={'1'} >
                                    <Col span={8} xs={24} xl={24}>
                                        <Row gutter={'1'} >
                                            <Col span={8} xs={24} xl={24}>
                                                <h2>
                                                    Last Update:
                                                </h2>
                                                <h2>
                                                    {LastUpdate['TXF']}
                                                </h2>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>


                        <Col span={6} xs={24} xl={6}>
                            <Card bordered={true} style={{ backgroundColor: 'ButtonHighlight ', border: '3px outset' }}  >
                                <h2 style={{ alignContent: 'center', fontSize: '32px' }}>
                                    MarShor
                                    {
                                        LineStatus['MarShor'] ? <CheckCircleFilled
                                            style={{ fontSize: '30px', color: '#04de46', padding: 10 }}
                                        /> : <CloseCircleFilled
                                            style={{ fontSize: '30px', color: '#ed0202', padding: 10 }}
                                        />
                                    }
                                </h2>

                                <Row gutter={'1'} >
                                    <Col span={8} xs={24} xl={24}>
                                        <Row gutter={'1'} >
                                            <Col span={8} xs={24} xl={24}>
                                                <h2>
                                                    Last Update:
                                                </h2>
                                                <h2>
                                                    {LastUpdate['MarShor']}
                                                </h2>
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </div>







            {/* <div style={{ fontSize: '32px', backgroundColor: '#ffffff' }}>
                <Row gutter={[16, 16]}>
                    <Col span={6} className="gutter-row" xs={24} xl={6}>
                        <Card
                            title={
                                <div>
                                    <h2>
                                        OTR
                                    </h2>
                                    <CloseCircleFilled
                                        style={{ fontSize: '32px', color: '#ed0202', padding: 10 }}
                                    />
                                </div>

                            }
                        >
                            <div>
                                <CloseCircleFilled
                                    style={{ fontSize: '32px', color: '#ed0202', padding: 10 }}
                                />
                                <Paragraph>
                                    Disconnected
                                </Paragraph>
                            </div>
                        </Card>

                    </Col>
                    <Col span={6} className="gutter-row" xs={24} xl={6}>
                        <CloseCircleFilled />
                    </Col>
                    <Col span={6} className="gutter-row" xs={24} xl={6}>
                        <CloseCircleFilled />
                    </Col>
                    <Col span={6} className="gutter-row" xs={24} xl={6}>
                        <CloseCircleFilled />
                    </Col>
                </Row>
            </div> */}


            <div>
                <Divider orientation="left">Category</Divider>

                <div style={{ padding: 10, overflowY: 'hidden' }}>

                    <Tabs type="card" style={{ background: '#ececec', padding: '8px 8px 8px 8px', borderRadius: '15px' }}>
                        <TabPane tab="ALFA LAVA" key="1">
                            <Row gutter={[16, 16]}>

                                <Col span={6} className="gutter-row" xs={24} xl={12}>
                                    <Card title='PRE-TREATMENT & BLEACHING' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Alfa1']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col span={6} className="gutter-row" xs={24} xl={12}>
                                    <Card title='DEACIDIFICATION & DEODORIZATION' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Alfa2']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="OILTEK" key="2">
                            <Row gutter={[16, 16]}>

                                <Col span={6} className="gutter-row" xs={24} xl={12}>
                                    <Card title='PRE-TREATMENT & BLEACHING' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Oiltek1']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col span={6} className="gutter-row" xs={24} xl={12}>
                                    <Card title='DEACIDIFICATION & DEODORIZATION' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Oiltek2']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="TIRTIAUX" key="3">
                            <Row gutter={[16, 16]}>

                                <Col span={6} className="gutter-row" xs={24} xl={8}>
                                    <Card title='FILTERING' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Tirtiaux1']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col span={6} className="gutter-row" xs={24} xl={8}>
                                    <Card title='OILEIN' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Tirtiaux2']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col span={6} className="gutter-row" xs={24} xl={8}>
                                    <Card title='STEARIN' bordered={false}
                                        style={{ width: '100%', borderRadius: 15 }}
                                        bodyStyle={{ paddingTop: "10" }} hoverable={true} >
                                        <Row>
                                            <Col span={6} className="gutter-row" xs={24} xl={24}>
                                                <Table style={{ overflowY: 'hidden' }} scroll bordered columns={columns} dataSource={RealTimeValue['Tirtiaux3']} size="middle" pagination={false} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>
            </div>

        </div>
    )
}

export default Home
