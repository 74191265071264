import React from 'react'
import { DataMapping } from '../../../DataRequest/RealTimeRequestWrap'
import { OTKModelPage1 } from '../../../DataRequest/OiltekModel'

const OiltekBF = (props) => {
  let Para = props.Parameter
  Para = DataMapping(OTKModelPage1, props.Parameter)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1190.55 841.89"
      width='100%'
      height='100%'
    >
      <style>
        {
          ".prefix__st0{fill:#c4c6c8;stroke:#6d6e71;stroke-width:.75;stroke-linecap:round}.prefix__st0,.prefix__st1,.prefix__st2{stroke-linejoin:round;stroke-miterlimit:10}.prefix__st1{fill:#e6e7e8;stroke:#6d6e71;stroke-width:1.5;stroke-linecap:round}.prefix__st2{fill:none;stroke:#58595b;stroke-width:4}.prefix__st4{fill:#58595b}.prefix__st5{fill:#c4c6c8}.prefix__st6{fill:#e0e0e0}.prefix__st7,.prefix__st8{fill:none;stroke:#6d6e71;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st8{stroke-width:4}.prefix__st9{fill:none;stroke-width:2}.prefix__st14,.prefix__st15,.prefix__st9{stroke:#58595b;stroke-linejoin:round;stroke-miterlimit:10}.prefix__st14{fill:#c4c6c8;stroke-width:2}.prefix__st15{fill:none}.prefix__st16{fill:#fff200}.prefix__st17{font-family:&apos;3ds&apos;}.prefix__st18{font-size:20px}.prefix__st19{fill:none}.prefix__st20{font-size:17px}.prefix__st21{font-size:12px}.prefix__st22{font-size:18px}"
        }
      </style>
      <symbol id="prefix__Turbocharger" viewBox="-18.78 -13.41 37.55 23.81">
        <path
          className="prefix__st0"
          d="M10.08-9.69L-.3-7.75l-10.39-1.94v-3.34h20.77zM.3 3.64h13.75v5.1H.3c-1.41 0-2.55-1.14-2.55-2.55S-1.11 3.64.3 3.64z"
        />
        <circle className="prefix__st0" cx={-0.3} cy={-1.65} r={10.38} />
        <path
          className="prefix__st0"
          d="M-.3.9h-13.75v-5.1H-.3c1.41 0 2.55 1.14 2.55 2.55S1.11.9-.3.9z"
        />
        <path className="prefix__st0" d="M-18.4-5.49h4.35V2.2h-4.35z" />
        <path
          transform="rotate(-180 16.226 6.189)"
          className="prefix__st0"
          d="M14.05 2.35h4.35v7.69h-4.35z"
        />
      </symbol>
      <symbol id="prefix__Valve_Auto" viewBox="-19.5 -13.29 32.68 21.98">
        <path
          className="prefix__st1"
          d="M-18.75-12.54l5.56 10.25 5.57-10.25zM-7.62 7.95l-5.56-10.24-5.57 10.24zM2.35-12.37V7.79c5.57 0 10.08-4.51 10.08-10.08S7.91-12.37 2.35-12.37zM-13.19-2.29H2.35"
        />
      </symbol>
      <symbol id="prefix__Valve_small" viewBox="-14.25 -10.57 28.51 21.14">
        <path
          className="prefix__st1"
          d="M13.5 9.82L8.17 0 2.84 9.82zM2.84-9.82L8.17 0l5.33-9.82zM-13.5-9.67h6.78V9.66h-6.78zM8.17 0H-6.72"
        />
      </symbol>
      <g id="prefix__Piping">
        <path
          className="prefix__st2"
          d="M142.22 659.67v-27.73M64.52 576.57h73.7c2.21 0 4 1.79 4 4v31.73"
        />
        <path
          className="prefix__st2"
          d="M88.76 638.58h12.57c2.21 0 4-1.79 4-4v-58.01"
        />
        <path
          d="M64.39 646.42H37.44c-2.21 0-4-1.79-4-4V532.29"
          fill="none"
          stroke="#58595b"
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          className="prefix__st4"
          d="M39.42 534.04l-5.98-10.36-5.99 10.36z"
        />
        <path
          className="prefix__st2"
          d="M64.39 584.26H33.44M267.72 745.23H163.1M267.72 807.24h-43.63c-2.21 0-4-1.79-4-4v-58.01M277.85 737.39H350M277.85 799.41h38.98c2.21 0 4-1.79 4-4V737.4M369.25 737.41l23.11-.02c2.21 0 4-1.79 4-4v-159c0-2.21-1.79-4-4-4H267.72M267.72 671.31h128.64M229.94 613.11h-25.59c-2.21 0-4-1.79-4-4V495.57c0-2.21 1.79-4 4-4h106.74c2.21 0 4-1.79 4-4v-14.12c0-2.21-1.79-4-4-4h-24.26M229.22 512.19h-28.87M287.06 411.26h39.56M231.62 411.26h-96.28c-2.21 0-4-1.79-4-4V283c0-2.21 1.79-4 4-4h263.45M427.89 339.08v217.26c0 2.21 1.79 4 4 4h16.38M496.13 118.11V64.1c0-2.21 1.79-4 4-4h12.59M485.07 552.51h7.06c2.21 0 4-1.79 4-4v-422.4M448.27 498.19h-20.38M485.07 490.5h11.06M582.96 52.26H596c2.21 0 4 1.79 4 4v84.26M547.89 114.28H600M562.02 52.6h-14.13M511.08 122.11H191.71c-2.21 0-4 1.79-4 4V195M600 277.19v40c0 2.21 1.79 4 4 4h86.27c2.21 0 4-1.79 4-4V187.51"
        />
        <path
          className="prefix__st2"
          d="M546.77 416.52v-68.67c0-2.21 1.79-4 4-4h96.37v-22.66M565.96 436.44h12.43"
        />
        <path
          className="prefix__st2"
          d="M625.28 464.18h17.86c2.21 0 4-1.79 4-4V331.5M588.47 409.7l-6.14.09a3.993 3.993 0 00-3.94 4v53.92c0 2.21 1.79 4 4 4h6.08M694.27 111.01v-21c0-2.21 1.79-4 4-4h65.65c2.21 0 4 1.79 4 4V347.2M816.38 248.63V90.01c0-2.21 1.79-4 4-4h312.93M791.74 335.82v50.43c0 2.21 1.79 4 4 4h138.72"
        />
        <path
          className="prefix__st2"
          d="M869.31 390.25V270.08c0-2.21 1.79-4 4-4h61.15M934.46 328.09h-65.15M1063.29 678.99h46.04c2.21 0 4-1.79 4-4V603.5c0-2.21-1.79-4-4-4h-70.04M970.91 678.99h84.38M918.13 678.99h44.78M824.5 678.99h85.63M770.46 678.99h46.04M694.27 449.99v225c0 2.21 1.79 4 4 4h64.2M971.26 382.41h57.07c2.21 0 4 1.79 4 4V421c0 2.21-1.79 4-4 4H698.27c-2.21 0-4 1.79-4 4v12.99"
        />
        <path
          className="prefix__st2"
          d="M698.27 435.99h330.07c7.73 0 14-6.27 14-14V324.4c0-2.21-1.79-4-4-4h-67.07M762.46 688.99h-64.2c-7.73 0-14-6.27-14-14v-235c0-2.21 1.79-4 4-4h2M816.5 688.99h-46.04M910.13 688.99H824.5M898.25 599.49h64.66c2.21 0 4 1.79 4 4v81.5c0 2.21-1.79 4-4 4h-44.78"
        />
        <path
          className="prefix__st2"
          d="M688.27 445.99h340.07c13.25 0 24-10.75 24-24v-159.6c0-2.21-1.79-4-4-4h-87.07M762.46 698.99h-65.2c-12.7 0-23-10.3-23-23v-226c0-2.21 1.79-4 4-4h2M761.84 600.49h54.66c2.21 0 4 1.79 4 4v90.5c0 2.21-1.79 4-4 4h-46.04M914.13 657.67v58.83M1059.29 657.67v54.83c0 2.21-1.79 4-4 4H918.13c-2.21 0-4 1.79-4 4v10.98"
        />
        <path
          className="prefix__st2"
          d="M766.47 656v56.5c0 2.21 1.79 4 4 4h143.67M1044.03 778v-25c0-2.21-1.79-4-4-4h-125.9M388.78 216.5h35.11c2.21 0 4-1.79 4-4v-90.39M388.78 154.49h39.11M351.97 224.19h-24.31c-2.21 0-4-1.79-4-4v-54.02c0-2.21 1.79-4 4-4h24.31M206.9 193.18h116.77M625.28 402.01h21.85"
        />
      </g>
      <g id="prefix__Component">
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_11_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 72.31 582.759)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_10_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 72.31 644.771)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_9_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 260.773 743.583)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_8_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 260.773 805.595)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_6_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 529.607 58.453)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_5_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 529.607 120.466)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_16_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 370.77 160.677)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_15_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 370.77 222.69)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_18_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 465.745 496.69)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_17_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 465.745 558.702)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_4_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 606.88 408.197)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_3_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 606.88 470.21)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_14_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 952.863 264.577)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_13_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 952.863 326.59)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Turbocharger"
          width={37.55}
          height={23.81}
          id="prefix__XMLID_12_"
          x={-18.78}
          y={-13.41}
          transform="matrix(1 0 0 -1 952.863 388.602)"
          overflow="visible"
        />
        <path
          className="prefix__st5"
          d="M164.48 671.48v-3.3h-1.38v-3.11l-5.4-5.4h-30.97l-5.4 5.4v3.11h-1.74v3.3h1.74v80.51h-1.74v3.3h1.74v1.8l-1.38 5.25h44.53l-1.38-5.25v-1.8h1.38v-3.3h-1.38v-80.51z"
        />
        <path
          className="prefix__st6"
          d="M163.1 671.48v80.51h1.38v3.3h-1.38v1.8l1.38 5.25h-10.93l-1.37-5.25v-92.02l-5.4-5.4h10.92l5.4 5.4v3.11h1.38v3.3z"
        />
        <path
          className="prefix__st7"
          d="M164.48 671.48v-3.3h-1.38v-3.11l-5.4-5.4h-30.97l-5.4 5.4v3.11h-1.74v3.3h1.74v80.51h-1.74v3.3h1.74v1.8l-1.38 5.25h44.53l-1.38-5.25v-1.8h1.38v-3.3h-1.38v-80.51zM121.33 668.18h41.77M121.33 671.48h41.77M121.33 751.99h41.77M121.33 755.29h41.77"
        />
        <path
          className="prefix__st5"
          d="M1070.97 765.43v3.96h-1.66v32.56l-50.14 28.94v-61.5h-2.08v-3.96h2.08v-3.73l6.48-6.48h37.18l6.48 6.48v3.73z"
        />
        <path
          className="prefix__st6"
          d="M1070.97 765.43v3.96h-1.66v32.56l-13.12 7.57V761.7l-6.48-6.48h13.12l6.48 6.48v3.73z"
        />
        <path
          className="prefix__st7"
          d="M1070.97 765.43v3.96h-1.66v32.56l-50.14 28.94v-61.5h-2.08v-3.96h2.08v-3.73l6.48-6.48h37.18l6.48 6.48v3.73zM1019.18 765.43h50.13M1019.18 769.39h50.13"
        />
        <use
          xlinkHref="#prefix__Valve_Auto"
          width={32.68}
          height={21.98}
          id="prefix__XMLID_7_"
          x={-19.5}
          y={-13.29}
          transform="matrix(0 -1 -1 0 357.09 724.18)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_Auto"
          width={32.68}
          height={21.98}
          id="prefix__XMLID_1_"
          x={-19.5}
          y={-13.29}
          transform="matrix(0 -1 -1 0 570.152 39.415)"
          overflow="visible"
        />
        <use
          xlinkHref="#prefix__Valve_small"
          width={28.51}
          height={21.14}
          id="prefix__XMLID_2_"
          x={-14.25}
          y={-10.57}
          transform="matrix(1 0 0 -1 152.296 621.874)"
          overflow="visible"
        />
        <path className="prefix__st7" d="M225.63 601.51h65.02" />
        <path className="prefix__st5" d="M229.22 601.51h57.85v82.9h-57.85z" />
        <path className="prefix__st6" d="M272.49 601.51h14.58v82.9h-14.58z" />
        <path className="prefix__st7" d="M229.22 601.51h57.85v82.9h-57.85z" />
        <path className="prefix__st8" d="M225.63 684.42h65.02" />
        <path
          className="prefix__st4"
          d="M229.45 604.26h4.78v4.78h-4.78zM234.24 610.72h4.78v4.78h-4.78zM239.02 617.19h4.78v4.78h-4.78zM243.8 623.66h4.78v4.78h-4.78zM248.58 630.12h4.78v4.78h-4.78zM253.36 636.59h4.78v4.78h-4.78zM258.14 643.05h4.78v4.78h-4.78zM262.92 649.52h4.78v4.78h-4.78zM267.7 655.99h4.78v4.78h-4.78zM272.49 662.45h4.78v4.78h-4.78zM277.27 668.92h4.78v4.78h-4.78zM282.05 675.38h4.78v4.78h-4.78z"
        />
        <path className="prefix__st7" d="M225.63 643.81h65.02" />
        <g>
          <path className="prefix__st7" d="M225.63 500.59h65.02" />
          <path className="prefix__st5" d="M229.22 500.59h57.85v82.9h-57.85z" />
          <path className="prefix__st6" d="M272.49 500.59h14.58v82.9h-14.58z" />
          <path className="prefix__st7" d="M229.22 500.59h57.85v82.9h-57.85z" />
          <path className="prefix__st8" d="M225.63 583.49h65.02" />
          <path
            className="prefix__st4"
            d="M229.45 503.33h4.78v4.78h-4.78zM234.24 509.8h4.78v4.78h-4.78zM239.02 516.26h4.78v4.78h-4.78zM243.8 522.73h4.78v4.78h-4.78zM248.58 529.2h4.78v4.78h-4.78zM253.36 535.66h4.78v4.78h-4.78zM258.14 542.13h4.78v4.78h-4.78zM262.92 548.59h4.78v4.78h-4.78zM267.7 555.06h4.78v4.78h-4.78zM272.49 561.53h4.78v4.78h-4.78zM277.27 567.99h4.78v4.78h-4.78zM282.05 574.46h4.78v4.78h-4.78z"
          />
          <path className="prefix__st7" d="M225.63 542.89h65.02" />
        </g>
        <g>
          <path className="prefix__st7" d="M225.63 399.66h65.02" />
          <path className="prefix__st5" d="M229.22 399.66h57.85v82.9h-57.85z" />
          <path className="prefix__st6" d="M272.49 399.66h14.58v82.9h-14.58z" />
          <path className="prefix__st7" d="M229.22 399.66h57.85v82.9h-57.85z" />
          <path className="prefix__st8" d="M225.63 482.56h65.02" />
          <path
            className="prefix__st4"
            d="M229.45 402.4h4.78v4.78h-4.78zM234.24 408.87h4.78v4.78h-4.78zM239.02 415.34h4.78v4.78h-4.78zM243.8 421.8h4.78v4.78h-4.78zM248.58 428.27h4.78v4.78h-4.78zM253.36 434.74h4.78v4.78h-4.78zM258.14 441.2h4.78v4.78h-4.78zM262.92 447.67h4.78v4.78h-4.78zM267.7 454.13h4.78v4.78h-4.78zM272.49 460.6h4.78v4.78h-4.78zM277.27 467.07h4.78v4.78h-4.78zM282.05 473.53h4.78v4.78h-4.78z"
          />
          <path className="prefix__st7" d="M225.63 441.96h65.02" />
        </g>
        <use
          xlinkHref="#prefix__Valve_Auto"
          width={32.68}
          height={21.98}
          x={-19.5}
          y={-13.29}
          transform="matrix(0 -1 -1 0 334.125 398.099)"
          overflow="visible"
        />
        <g>
          <path
            className="prefix__st5"
            d="M455.3 285.71h3.41c0-17.02-13.8-30.82-30.82-30.82s-30.83 13.8-30.83 30.82h3.41v43.14h-3.41c0 17.02 13.8 30.82 30.83 30.82 17.02 0 30.82-13.8 30.82-30.82h-3.41v-43.14z"
          />
          <path
            className="prefix__st6"
            d="M455.3 328.84h3.41c0 16.46-12.9 29.91-29.14 30.78 9.67-5.92 16.13-16.59 16.13-28.76h-3.73v-47.17h3.73c0-12.18-6.45-22.84-16.13-28.76 16.24.87 29.14 14.32 29.14 30.78h-3.41v43.13z"
          />
          <path
            className="prefix__st9"
            d="M455.3 285.71h3.41c0-17.02-13.8-30.82-30.82-30.82s-30.83 13.8-30.83 30.82h3.41v43.14h-3.41c0 17.02 13.8 30.82 30.83 30.82 17.02 0 30.82-13.8 30.82-30.82h-3.41v-43.14zM400.47 285.71h54.83M400.47 328.84h54.83"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M721.68 132.41h3.41c0-17.02-13.8-30.83-30.83-30.83s-30.83 13.8-30.83 30.83h3.41v43.14h-3.41c0 17.02 13.8 30.83 30.83 30.83s30.83-13.8 30.83-30.83h-3.41v-43.14z"
          />
          <path
            className="prefix__st6"
            d="M721.68 175.54h3.41c0 16.46-12.9 29.91-29.14 30.78 9.67-5.92 16.13-16.59 16.13-28.76h-3.73v-47.17h3.73c0-12.18-6.45-22.84-16.13-28.76 16.24.87 29.14 14.32 29.14 30.78h-3.41v43.13z"
          />
          <path
            className="prefix__st9"
            d="M721.68 132.41h3.41c0-17.02-13.8-30.83-30.83-30.83s-30.83 13.8-30.83 30.83h3.41v43.14h-3.41c0 17.02 13.8 30.83 30.83 30.83s30.83-13.8 30.83-30.83h-3.41v-43.14zM666.85 132.41h54.83M666.85 175.54h54.83"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M568.34 442.62c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38z"
          />
          <path
            className="prefix__st6"
            d="M568.34 442.62c0 11.52-9.03 20.93-20.4 21.55 6.77-4.15 11.29-11.61 11.29-20.14h-2.61v-31.61h9.33v30.2h2.39z"
          />
          <path
            className="prefix__st9"
            d="M568.34 442.62c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38zM527.58 442.62h38.38"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M209.28 214.17c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38z"
          />
          <path
            className="prefix__st6"
            d="M209.28 214.17c0 11.52-9.03 20.93-20.4 21.55 6.77-4.15 11.29-11.61 11.29-20.14h-2.61v-31.61h9.33v30.2h2.39z"
          />
          <path
            className="prefix__st9"
            d="M209.28 214.17c0 11.91-9.66 21.57-21.57 21.57-11.92 0-21.58-9.66-21.58-21.57h2.39v-30.2h38.38v30.2h2.38zM168.52 214.16h38.38"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M628.83 158.06v-5.67l-28.83-23-28.83 23v5.67h4.4v101.55h-4.4v5.67l28.83 23 28.83-23v-5.67h-4.4V158.06z"
          />
          <path
            className="prefix__st6"
            d="M624.42 158.06v101.55h4.41v5.67l-21.96 17.52 4.97-11.16V146.03l-4.97-11.16 21.96 17.52v5.67z"
          />
          <path
            className="prefix__st9"
            d="M628.83 158.06v-5.67l-28.83-23-28.83 23v5.67h4.4v101.55h-4.4v5.67l28.83 23 28.83-23v-5.67h-4.4V158.06zM575.57 158.06h48.86M575.57 259.61h48.86"
          />
        </g>
        <g>
          <path
            className="prefix__st14"
            d="M764.96 173.55h5.92v6.54h-5.92zM813.42 173.55h5.92v6.54h-5.92z"
          />
          <path
            className="prefix__st5"
            d="M833.03 185.26v-4.14l-35.4-9.94h-11.78l-35.4 9.94v4.14h5.64v169.8h-5.64v4.14l35.4 9.94h11.78l35.4-9.94v-4.14h-5.64v-169.8z"
          />
          <path
            className="prefix__st6"
            d="M827.4 185.27v169.79h5.63v4.15l-33.29 9.34 8.63-7.66V179.44l-8.64-7.67 33.3 9.35v4.15z"
          />
          <path
            className="prefix__st9"
            d="M833.03 185.26v-4.14l-35.4-9.94h-11.78l-35.4 9.94v4.14h5.64v169.8h-5.64v4.14l35.4 9.94h11.78l35.4-9.94v-4.14h-5.64v-169.8zM756.09 185.26h71.3M750.45 181.12h82.58M756.09 355.06h71.3M750.45 359.2h82.58"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M1106.09 60.48v-2.22l-18.95-5.32h-6.3l-18.95 5.32v2.22h3.01v90.88h-3.01v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02V60.48z"
          />
          <path
            className="prefix__st6"
            d="M1103.07 60.48v90.88h3.02v2.22l-17.82 5 4.62-4.1V57.36l-4.63-4.1 17.83 5v2.22z"
          />
          <path
            className="prefix__st9"
            d="M1106.09 60.48v-2.22l-18.95-5.32h-6.3l-18.95 5.32v2.22h3.01v90.88h-3.01v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02V60.48z"
          />
          <path
            className="prefix__st15"
            d="M1064.9 60.48h38.17M1061.89 58.26h44.2M1064.9 151.36h38.17M1061.89 153.58h44.2"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M1173.52 60.48v-2.22l-18.95-5.32h-6.3l-18.95 5.32v2.22h3.02v90.88h-3.02v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02V60.48z"
          />
          <path
            className="prefix__st6"
            d="M1170.5 60.48v90.88h3.02v2.22l-17.82 5 4.62-4.1V57.36l-4.62-4.1 17.82 5v2.22z"
          />
          <path
            className="prefix__st9"
            d="M1173.52 60.48v-2.22l-18.95-5.32h-6.3l-18.95 5.32v2.22h3.02v90.88h-3.02v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02V60.48z"
          />
          <path
            className="prefix__st15"
            d="M1132.34 60.48h38.16M1129.32 58.26h44.2M1132.34 151.36h38.16M1129.32 153.58h44.2"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M936.23 739.01v-2.22l-18.95-5.31h-6.3l-18.95 5.31v2.22h3.02v60.5h-3.02v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02v-60.5z"
          />
          <path
            className="prefix__st6"
            d="M933.22 739.01v60.5h3.01v2.22l-17.82 5 4.62-4.1v-66.74l-4.62-4.1 17.82 5v2.22z"
          />
          <path
            className="prefix__st9"
            d="M936.23 739.01v-2.22l-18.95-5.31h-6.3l-18.95 5.31v2.22h3.02v60.5h-3.02v2.22l18.95 5.32h6.3l18.95-5.32v-2.22h-3.02v-60.5z"
          />
          <path
            className="prefix__st15"
            d="M895.05 739.01h38.16M892.03 736.79h44.2M895.05 799.51h38.16M892.03 801.73h44.2"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M766.47 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c-.01-6.38-14.3-11.56-34.38-11.56z"
          />
          <path
            className="prefix__st6"
            d="M800.84 520.28v6.98h-3.14v84.41l-15.98 33.86h2.91v3.5h-4.92V514.85c0-2.09-1.63-4.07-4.52-5.8 15.87 1.25 25.65 5.81 25.65 11.23z"
          />
          <path
            className="prefix__st9"
            d="M766.47 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c-.01-6.38-14.3-11.56-34.38-11.56zM735.23 527.26h62.48M732.09 520.28h68.75M735.23 611.67h62.48M751.21 645.53h30.52M753.22 649.03h26.5M753.22 662.78h26.5"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M914.13 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c0-6.38-14.29-11.56-34.38-11.56z"
          />
          <path
            className="prefix__st6"
            d="M948.51 520.28v6.98h-3.14v84.41l-15.98 33.86h2.91v3.5h-4.92V514.85c0-2.09-1.63-4.07-4.52-5.8 15.87 1.25 25.65 5.81 25.65 11.23z"
          />
          <path
            className="prefix__st9"
            d="M914.13 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c0-6.38-14.29-11.56-34.38-11.56zM882.89 527.26h62.48M879.76 520.28h68.75M882.89 611.67h62.48M898.87 645.53h30.52M900.88 649.03h26.5M900.88 662.78h26.5"
          />
        </g>
        <g>
          <path
            className="prefix__st5"
            d="M1059.29 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c-.01-6.38-14.3-11.56-34.38-11.56z"
          />
          <path
            className="prefix__st6"
            d="M1093.66 520.28v6.98h-3.14v84.41l-15.98 33.86h2.91v3.5h-4.92V514.85c0-2.09-1.63-4.07-4.52-5.8 15.87 1.25 25.65 5.81 25.65 11.23z"
          />
          <path
            className="prefix__st9"
            d="M1059.29 508.72c-20.09 0-34.38 5.17-34.38 11.56v6.98h3.14v84.41l15.98 33.86h-2.91v3.5h4.92v13.75h-1.78v3.5h30.06v-3.5h-1.78v-13.75h4.92v-3.5h-2.91l15.98-33.86v-84.41h3.14v-6.98c-.01-6.38-14.3-11.56-34.38-11.56zM1028.05 527.26h62.48M1024.91 520.28h68.75M1028.05 611.67h62.48M1044.03 645.53h30.52M1046.04 649.03h26.5M1046.04 662.78h26.5"
          />
        </g>
      </g>
      <g id="prefix__Label">
        <path
          className="prefix__st16"
          d="M469.53 797.06H330.78c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h138.75c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
        />
        <text
          transform="translate(331.782 792.633)"
          className="prefix__st17 prefix__st18"
        >
          {"PV:"}
        </text>
        <path
          className="prefix__st19"
          d="M368.91 778.23h104.62v15.75H368.91z"
        />
        <text
          transform="translate(368.907 792.434)"
          className="prefix__st17 prefix__st18"
        >
          {`${Para['CPO T001 Flow Rate'].PV} t/h`}
        </text>
        <path
          className="prefix__st16"
          d="M469.53 822.06H330.78c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h138.75c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
        />
        <text
          transform="translate(329.782 817.703)"
          className="prefix__st17 prefix__st18"
        >
          {"SV:"}
        </text>
        <path
          className="prefix__st19"
          d="M368.91 803.23h104.62v15.75H368.91z"
        />
        <text
          transform="translate(368.907 817.434)"
          className="prefix__st17 prefix__st18"
        >
          {`${Para['CPO T001 Flow Rate'].SV} t/h`}
        </text>
        <path
          className="prefix__st16"
          d="M325.78 361.08H197.06c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
        />
        <text
          transform="translate(198.058 356.654)"
          className="prefix__st17 prefix__st18"
        >
          {"PV:"}
        </text>
        <path className="prefix__st19" d="M235.18 342.25h90.6V358h-90.6z" />
        <text
          transform="translate(235.183 356.455)"
          className="prefix__st17 prefix__st18"
        >
          {`${Para['PHE CPO OUT HE001 Temperature'].PV} \xB0C`}
        </text>
        <path
          className="prefix__st16"
          d="M325.78 386.08H197.06c-2.21 0-4-1.79-4-4v-14c0-2.21 1.79-4 4-4h128.72c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4z"
        />
        <path className="prefix__st19" d="M196.06 365.07h28.46v21.29h-28.46z" />
        <text
          transform="translate(196.058 381.815)"
          className="prefix__st17 prefix__st18"
        >
          {"SV:"}
        </text>
        <path className="prefix__st19" d="M235.18 365.2h90.6v21.29h-90.6z" />
        <text
          transform="translate(235.183 381.945)"
          className="prefix__st17 prefix__st18"
        >
          {`${Para['PHE CPO OUT HE001 Temperature'].SV} \xB0C`}
        </text>
        <path
          className="prefix__st16"
          d="M940.91 132.86H828.93c-2.21 0-4.01-1.8-4.01-4.01v-13.98c0-2.21 1.8-4.01 4.01-4.01h111.98c2.21 0 4.01 1.8 4.01 4.01v13.98c0 2.21-1.8 4.01-4.01 4.01z"
        />
        <path className="prefix__st19" d="M830.76 114.03h113v15.75h-113z" />
        <text transform="translate(830.764 128.23)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st18">
          {`${Para['VAC TORR OILTEK'].PV}`}
          </tspan>
          <tspan x={59.68} y={0} className="prefix__st17 prefix__st20">
            {"mbar"}
          </tspan>
        </text>
        <path
          className="prefix__st16"
          d="M812.75 505.87h-92.56a3.72 3.72 0 01-3.72-3.72v-14.56a3.72 3.72 0 013.72-3.72h92.56a3.72 3.72 0 013.72 3.72v14.56a3.72 3.72 0 01-3.72 3.72z"
        />
        <path className="prefix__st19" d="M738.93 487.04h77.53v15.75h-77.53z" />
        <text transform="translate(738.931 501.24)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st18">
            {`${Para['Niagara Filter 601'].PV}`}
          </tspan>
          <tspan x={39.16} y={0} className="prefix__st17 prefix__st20">
          </tspan>
        </text>
        <path
          className="prefix__st16"
          d="M960.41 505.87h-92.56a3.72 3.72 0 01-3.72-3.72v-14.56a3.72 3.72 0 013.72-3.72h92.56a3.72 3.72 0 013.72 3.72v14.56c0 2.06-1.66 3.72-3.72 3.72z"
        />
        <path className="prefix__st19" d="M886.6 487.04h77.53v15.75H886.6z" />
        <text transform="translate(886.597 501.24)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st18">
          {`${Para['Niagara Filter 602'].PV}`}
          </tspan>
          <tspan x={39.16} y={0} className="prefix__st17 prefix__st20">
            {/* {"bar"} */}
          </tspan>
        </text>
        <path
          className="prefix__st16"
          d="M1105.57 505.87H1013a3.72 3.72 0 01-3.72-3.72v-14.56a3.72 3.72 0 013.72-3.72h92.56a3.72 3.72 0 013.72 3.72v14.56a3.704 3.704 0 01-3.71 3.72z"
        />
        <path
          className="prefix__st19"
          d="M1031.75 487.04h77.53v15.75h-77.53z"
        />
        <text transform="translate(1031.75 501.24)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st18">
          {`${Para['Niagara Filter 603'].PV}`}
          </tspan>
          <tspan x={39.16} y={0} className="prefix__st17 prefix__st20">
          </tspan>
        </text>
        <path
          className="prefix__st16"
          d="M940.91 157.86H828.93c-2.21 0-4.01-1.8-4.01-4.01v-13.98c0-2.21 1.8-4.01 4.01-4.01h111.98c2.21 0 4.01 1.8 4.01 4.01v13.98c0 2.21-1.8 4.01-4.01 4.01z"
        />
        <path className="prefix__st19" d="M830.76 139.03h113v15.75h-113z" />
        <text transform="translate(830.764 153.23)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st18">
          {`${Para['VAC TORR OILTEK'].PV}`}
          </tspan>
          <tspan x={59.68} y={0} className="prefix__st17 prefix__st20">
            {"torr"}
          </tspan>
        </text>
        <text
          transform="translate(50.062 609.038)"
          className="prefix__st17 prefix__st21"
        >
          {"CPO001"}
        </text>
        <text
          transform="translate(46 668.178)"
          className="prefix__st17 prefix__st21"
        >
          {"CPO001A"}
        </text>
        <text
          transform="translate(109.876 625.477)"
          className="prefix__st17 prefix__st21"
        >
          {"V001"}
        </text>
        <text
          transform="translate(127.877 691.503)"
          className="prefix__st17 prefix__st21"
        >
          {"T001"}
        </text>
        <text
          transform="translate(246.11 770.21)"
          className="prefix__st17 prefix__st21"
        >
          {"P001"}
        </text>
        <text
          transform="translate(451.082 522.73)"
          className="prefix__st17 prefix__st21"
        >
          {"P601"}
        </text>
        <text
          transform="translate(592.215 432.155)"
          className="prefix__st17 prefix__st21"
        >
          {"P024"}
        </text>
        <text
          transform="translate(934.136 290.655)"
          className="prefix__st17 prefix__st21"
        >
          {"P602A"}
        </text>
        <text
          transform="translate(934.136 352.19)"
          className="prefix__st17 prefix__st21"
        >
          {"P602B"}
        </text>
        <text
          transform="translate(934.136 412.202)"
          className="prefix__st17 prefix__st21"
        >
          {"P602C"}
        </text>
        <text
          transform="translate(352.043 247.111)"
          className="prefix__st17 prefix__st21"
        >
          {"P023A"}
        </text>
        <text
          transform="translate(356.105 184.861)"
          className="prefix__st17 prefix__st21"
        >
          {"P023"}
        </text>
        <text
          transform="translate(509.172 82.611)"
          className="prefix__st17 prefix__st21"
        >
          {"M001A"}
        </text>
        <text
          transform="translate(509.448 143.111)"
          className="prefix__st17 prefix__st21"
        >
          {"M001B"}
        </text>
        <text
          transform="translate(588.43 493.049)"
          className="prefix__st17 prefix__st21"
        >
          {"P024B"}
        </text>
        <text
          transform="translate(412.91 299.368)"
          className="prefix__st17 prefix__st21"
        >
          {"D601"}
        </text>
        <text
          transform="translate(447.02 583.519)"
          className="prefix__st17 prefix__st21"
        >
          {"P601A"}
        </text>
        <text
          transform="translate(248.579 621.97)"
          className="prefix__st17 prefix__st21"
        >
          {"E001A"}
        </text>
        <text
          transform="translate(248.579 521.044)"
          className="prefix__st17 prefix__st21"
        >
          {"E001B"}
        </text>
        <text
          transform="translate(256.767 420.118)"
          className="prefix__st17 prefix__st21"
        >
          {"E001"}
        </text>
        <text
          transform="translate(315.09 428.868)"
          className="prefix__st17 prefix__st21"
        >
          {"TCV001"}
        </text>
        <text
          transform="translate(338.084 708.21)"
          className="prefix__st17 prefix__st21"
        >
          {"FCV001"}
        </text>
        <text
          transform="translate(242.048 829.71)"
          className="prefix__st17 prefix__st21"
        >
          {"P001A"}
        </text>
        <text transform="translate(131.411 710.67)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st21">
            {"CPO"}
          </tspan>
          <tspan x={-1.21} y={9.4} className="prefix__st17 prefix__st21">
            {"Tank"}
          </tspan>
        </text>
        <text transform="translate(407.342 314.33)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st21">
            {"Vacuum"}
          </tspan>
          <tspan x={6.79} y={9.4} className="prefix__st17 prefix__st21">
            {"Dryer"}
          </tspan>
        </text>
        <text
          transform="translate(532.428 429.68)"
          className="prefix__st17 prefix__st21"
        >
          {"T024"}
        </text>
        <text transform="translate(533.844 474.406)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st21">
            {"Citric"}
          </tspan>
          <tspan x={1.78} y={9.4} className="prefix__st17 prefix__st21">
            {"Acid"}
          </tspan>
        </text>
        <text
          transform="translate(583.627 187.519)"
          className="prefix__st17 prefix__st21"
        >
          {"M001"}
        </text>
        <text
          transform="translate(677.894 152.019)"
          className="prefix__st17 prefix__st21"
        >
          {"M002"}
        </text>
        <text
          transform="translate(1063.497 50.385)"
          className="prefix__st17 prefix__st21"
        >
          {"V602-1"}
        </text>
        <text
          transform="translate(1130.93 50.385)"
          className="prefix__st17 prefix__st21"
        >
          {"V602-2"}
        </text>
        <text
          transform="translate(586.027 202.481)"
          className="prefix__st17 prefix__st21"
        >
          {"Mixer"}
        </text>
        <text
          transform="translate(777.063 241.149)"
          className="prefix__st17 prefix__st21"
        >
          {"B602"}
        </text>
        <text
          transform="translate(770.188 256.111)"
          className="prefix__st17 prefix__st21"
        >
          {"Bleacher"}
        </text>
        <text
          transform="translate(173.909 202.934)"
          className="prefix__st17 prefix__st21"
        >
          {"T023"}
        </text>
        <text
          transform="translate(10.073 516.263)"
          className="prefix__st17 prefix__st21"
        >
          {"Crude Oil"}
        </text>
        <text
          transform="translate(899.78 761.754)"
          className="prefix__st17 prefix__st21"
        >
          {"C601"}
        </text>
        <text
          transform="translate(752.245 546.754)"
          className="prefix__st17 prefix__st21"
        >
          {"F601"}
        </text>
        <text
          transform="translate(899.911 546.754)"
          className="prefix__st17 prefix__st21"
        >
          {"F602"}
        </text>
        <text
          transform="translate(1045.065 546.754)"
          className="prefix__st17 prefix__st21"
        >
          {"F603"}
        </text>
        <text
          transform="translate(1029.686 783.23)"
          className="prefix__st17 prefix__st21"
        >
          {"T741"}
        </text>
        <text transform="translate(1032.943 793.056)">
          <tspan x={0} y={0} className="prefix__st17 prefix__st21">
            {"Slop"}
          </tspan>
          <tspan x={-0.94} y={9.4} className="prefix__st17 prefix__st21">
            {"Tank"}
          </tspan>
        </text>
        <text
          transform="translate(355.383 769.064)"
          className="prefix__st17 prefix__st22"
        >
          {"CPO TOO1"}
        </text>
        <text
          transform="translate(204.352 333.084)"
          className="prefix__st17 prefix__st22"
        >
          {"PHE CPO OUT"}
        </text>
        <text
          transform="translate(868.114 104.859)"
          className="prefix__st17 prefix__st22"
        >
          {"VAC"}
        </text>
        <text
          transform="translate(849.663 475.87)"
          className="prefix__st17 prefix__st22"
        >
          {"NIAGARA FILTER"}
        </text>
      </g>
    </svg>
  )
}

export default OiltekBF
