import { Button, Col, DatePicker, PageHeader, Row, Statistic, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { ConvertMonentToDateTime, ConvertMonentToDateTimeOne, ExportExcelWorkBook, SetDateRange } from '../../DataRequest/LogDataRequestWrap';
import { GeneratePDFG2, NameColumbPDF } from '../../utils/PdfGenerate';
import './ReportTable.css';
import AddRowModel from './AddRowModel';
import { PlusOutlined} from '@ant-design/icons';

const ReportTable = (props) => {
    let Parameter = Object.keys(props.Model)

    const { RangePicker } = DatePicker;
    const [AddrowModal, setAddrowModal] = useState(false);
    

    const SetTimeRange = (value) => {
        // console.log(Parameter);
        var DateRange = []
        DateRange = SetDateRange(value)
        console.log(DateRange);
        props.SetTimeRangeCallBack(Parameter, DateRange[0], DateRange[1], 'FGV', props.site, props.page, props.BufferName, props.Model, props.section)
    }

    // const generatePDF = () => {
    //     let headers = NameColumbPDF(props.Model)
    //     GeneratePDFG2(headers, props.LogValue, props.JobInfo)
    // }

    const AddNewRow = () => {
        setAddrowModal(true)
    }

    const CloseModal = () => {
        setAddrowModal(false)
    }

    const SaveRow = (date, parameter, batch, remark, reportdate) => {
        console.log(date)
        console.log(props.section)
        let ReturnValue = {}
        // if (props.section == 1 || props.section == undefined) {
        //     console.log('here')
        //     ReturnValue = {'Operator Name':'None'}
        // }
        if (props.section == undefined) {
            console.log('here')
            ReturnValue = {'Operator Name':'None'}
        }
        if (props.section == 1) {
            ReturnValue['Batch'] = batch
        }
        if (props.section == 6) {
            if (remark != 'None') {
                ReturnValue[remark] = '0'
                ReturnValue['Reportdate'] = ConvertMonentToDateTimeOne(reportdate).split('T')[0]
                console.log(ReturnValue['Reportdate']);
            }
        }
        if (props.section == 2 || props.section == 4 || props.section == 5 || props.section == 6) {
            ReturnValue['DateTime'] = ConvertMonentToDateTime(date)
        }
        else {
            ReturnValue['DateTime'] = ConvertMonentToDateTimeOne(date)
        }
        if (props.section == 3) {
            ReturnValue[parameter.concat(' Initial')] = '0'
        }
        console.log(ReturnValue)
        // Parameter.forEach(element => {
        //     ReturnValue[element] = 0
        // });
        // console.log(ReturnValue);
        props.AddNewDataRow(ReturnValue, props.section)
        setAddrowModal(false)
    }


    if (props.section == 5 || props.section == 4 || props.section == 3 || props.section == 2 || props.section == 6) {
        return (
            <div style={{ paddingTop: '20px' }}>
                <div>
                    {
                        props.HideDateTime ? <div></div> : <DatePicker onChange={SetTimeRange} />
                    }
                    <Button type="primary" onClick={AddNewRow} shape={props.ButtonType}
                        style={{ marginLeft: '20px' }} icon={<PlusOutlined />}
                    >
                        {props.ButtonName}
                    </Button>

                </div>



                <div style={{ paddingTop: '20px' }}>
                    <Table columns={props.Columb} dataSource={props.LogValue}
                        bordered scroll={{ x: 50, y: 500 }}
                        pagination={false} size="small" />
                </div>

                <AddRowModel Visibility={AddrowModal} CloseModal={CloseModal} SaveRow={SaveRow} Section={props.section} Site={props.site} />

            </div >
        )
    }

    return (
        <div style={{ paddingTop: '20px' }}>
            <div>
                {
                    props.HideDateTime ? <div></div> : <DatePicker onChange={SetTimeRange} />
                }

                <Button type="primary" onClick={AddNewRow} shape={props.ButtonType}
                    style={{ marginLeft: '20px' }} icon={<PlusOutlined />}
                >

                    {props.ButtonName}
                </Button>

            </div>

            <div style={{ paddingTop: '20px' }}>
                <Table columns={props.Columb} dataSource={props.LogValue}
                    bordered scroll={{ x: 2000, y: 500 }}
                    pagination={false} size="small" />
            </div>

            <AddRowModel Visibility={AddrowModal} CloseModal={CloseModal} SaveRow={SaveRow} Section={props.section} Site={props.site} />

        </div >
    )

}

ReportTable.defaultProps = {
    HideDateTime: false,
    ButtonType: 'circle',
    ButtonName: '',
}

export default ReportTable
