import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_logdata, logdata_request, logdata_Write, ReadJob, WriteJob } from '../../api';
import { DataSortTime, TableColumbLabelAsRow, LogDataMappingName1, ReportSort, TableColumbJob, TableColumbLabelAsRowG2, GetDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { AlfaModelPage1 } from '../../DataRequest/AlfaModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogALR1": []
}
let DisableInput = false
let DisableInputOperator = false


let ParaList = Object.keys(AlfaModelPage1)


let columns
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []

setRange = GetDateTime(0, 1)

const AlfaReportPG1 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);


    const user = memoryUtils.user

    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username'] === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        console.log(setRange);
        UpdateTableData([], setRange[0], setRange[1], 'FGV', "ALPHA LAVAL", 1, "LogALR1", AlfaModelPage1)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },

        {
            title: 'CPO 26E00',
            children: [
                {
                    title: 'TEMP IN (C)',
                    width: 60,
                    dataIndex: 'CPO 26E00 Temperature',
                    key: 'CPO 26E00 Temperature',
                    align: 'center'
                },
                {
                    title: 'FLO (m3/Hr)',
                    width: 60,
                    dataIndex: 'CPO 26E00 Flow Rate',
                    key: 'CPO 26E00 Flow Rate',
                    align: 'center'
                },

            ]
        },

        {
            title: 'H3PO4',
            children: [
                {
                    title: '26E00 TEMP',
                    width: 60,
                    dataIndex: 'PHE CPO Out H3PO4 26E00 Temperature',
                    key: 'PHE CPO Out H3PO4 26E00 Temperature',
                    align: 'center'

                },

                {
                    title: '26E01 TEMP',
                    width: 60,
                    dataIndex: 'PHE CPO Out H3PO4 26E01 Temperature',
                    key: 'PHE CPO Out H3PO4 26E01 Temperature',
                    align: 'center'

                },
            ]
        },
        {
            title: 'H3PO4',
            children: [
                {
                    title: 'Stroke/F req (%)',
                    width: 60,
                    dataIndex: 'H3PO4 Stroke/F req (%)',
                    key: 'H3PO4 Stroke/F req (%)',
                    align: 'center'
                },

                {
                    title: 'LEVEL (Kg)',
                    width: 60,
                    dataIndex: 'H3PO4 Level (Kg)',
                    key: 'H3PO4 Level (Kg)',
                    align: 'center'
                },

                {
                    title: 'Usage (Kg/hr)',
                    width: 60,
                    dataIndex: 'H3PO4 Level (Kg/hr)',
                    key: 'H3PO4 Level (Kg/hr)',
                    align: 'center'
                },
            ]
        },

        {
            title: 'VAC (mm/Hg) Torr',
            dataIndex: 'VAC mm/Hg Torr',
            key: 'VAC mm/Hg Torr',
            width: 60,
            align: 'center'
        },
        {
            title: 'Bleaching Earth Setting (%)',
            dataIndex: 'Bleaching Earth Setting',
            key: 'Bleaching Earth Setting',
            width: 60,
            align: 'center'
        },
        {
            title: 'Bleaching Earth Weight',
            dataIndex: 'Bleaching Earth Weight',
            key: 'Bleaching Earth Weight',
            width: 60,
            align: 'center'
        },
        {
            title: 'Bleaching Earth Usage',
            dataIndex: 'Bleaching Earth Usage',
            key: 'Bleaching Earth Usage',
            width: 60,
            align: 'center'
        },
        {
            title: 'Filter No.1 Bar',
            dataIndex: 'Filter No.1',
            key: 'Filter No.1',
            width: 60,
            align: 'center'
        },
        {
            title: 'Filter No.2 Bar',
            dataIndex: 'Filter No.2',
            key: 'Filter No.2',
            width: 60,
            align: 'center'
        },
        {
            title: 'Filter Bag Pressure Bar',
            dataIndex: 'Filter Bag Pressure Bar',
            key: 'Filter Bag Pressure Bar',
            width: 60,
            align: 'center'
        },
        {
            title: 'F BPO QLTY',
            children: [
                {
                    title: 'COLOR RED',
                    width: 60,
                    dataIndex: 'F BPO QLTY Color Red',
                    key: 'F BPO QLTY Color Red',
                    align: 'center'
                },
                {
                    title: 'BLACK PARTICAL',
                    width: 60,
                    dataIndex: 'F BPO QLTY Black Partical',
                    key: 'F BPO QLTY Black Partical',
                    align: 'center'
                },

            ]
        },
        {
            title: 'HP BOILER',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'HP Boiler Temperature',
                    key: 'HP Boiler Temperature',
                    align: 'center'
                },
                {
                    title: 'Barg',
                    width: 60,
                    dataIndex: 'HP Boiler Barg',
                    key: 'HP Boiler Barg',
                    align: 'center'
                },

            ]
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record) }
            } > Click </Button>,
        }
    ]

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancle" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="CPO 26E00 Temperature"
                        label="CPO 26E00 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="CPO 26E00 Flow Rate"
                        label="CPO 26E00 Flow Rate"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE CPO Out H3PO4 26E00 Temperature"
                        label="PHE CPO Out H3PO4 26E00 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE CPO Out H3PO4 26E01 Temperature"
                        label="PHE CPO Out H3PO4 26E01 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="H3PO4 Stroke/F req (%)"
                        label="H3PO4 Stroke/F req (%)"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="H3PO4 Level (Kg)"
                        label="H3PO4 Level (Kg)"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="H3PO4 Level (Kg/hr)"
                        label="H3PO4 Level (Kg/hr)"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VAC mm/Hg Torr"
                        label="VAC mm/Hg Torr"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Bleaching Earth Setting"
                        label="Bleaching Earth Setting"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Bleaching Earth Weight"
                        label="Bleaching Earth Weight"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Bleaching Earth Usage"
                        label="Bleaching Earth Usage"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filter No.1"
                        label="Filter No.1"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filter No.2"
                        label="Filter No.2"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filter Bag Pressure Bar"
                        label="Filter Bag Pressure Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="F BPO QLTY Color Red"
                        label="F BPO QLTY Color Red"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="F BPO QLTY Black Partical"
                        label="F BPO QLTY Black Partical"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInputOperator}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="HP Boiler Temperature"
                        label="HP Boiler Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="HP Boiler Barg"
                        label="HP Boiler Barg"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', "ALPHA LAVAL", 1)
        setVisible(false);
    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 1, "LogALR1", AlfaModelPage1)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }



    const handletable = (record) => {
        prevReading = record
        setVisible(true);
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
        //console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data) => {
        const WriteRow = []
        WriteRow.push(data)
        console.log(WriteRow);
        for (let key in WriteRow[0]){
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }

        const response = await logdata_Write(WriteRow, 'FGV', 'ALPHA LAVAL', 1)

        if (response.success === true) {
            message.success('Successfully Add')
            console.log("Write success")
            //UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 1, "LogALR1", AlfaModelPage1)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        //console.log(values['DateTime']);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values)
        await UpdateTableData([], setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 1, "LogALR1", AlfaModelPage1)
        setLoading(false)
    };

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>

                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogALR1']} Columb={columns}
                    site={'ALPHA LAVAL'} page={'1'} BufferName={"LogALR1"} Model={AlfaModelPage1}
                    AddNewDataRow={AddNewDataRow}
                />
            </Spin>

            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    initialValues={prevReading}
                />
            </div>
        </div>
    )
}

export default AlfaReportPG1
