import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_logdata, logdata_request, logdata_Write, ReadJob, WriteJob } from '../../api';
import { DataSortTime, TableColumbLabelAsRow, LogDataMappingName1, ReportSort, TableColumbJob, TableColumbLabelAsRowG2, GetDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { OTKModelPage3 } from '../../DataRequest/OiltekModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogOtk3": []
}
let DisableInput = false
let DisableInputOperator = false

let ParaList = Object.keys(OTKModelPage3)

let columns
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []
setRange = GetDateTime(0, 1)

const OiltekReportPG3 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);


    const user = memoryUtils.user

    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username'] === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center'
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },
        {
            title: 'MAIN STEAM HEADER PRESS. Kg/cm2',
            width: 100,
            dataIndex: 'MAIN STEAM HEADER PRESS',
            key: 'MAIN STEAM HEADER PRESS',
            align: 'center'
        },
        {
            title: 'VAC. STEAM HEADER PRESS. Kg/cm2',
            width: 100,
            dataIndex: 'VAC. STEAM HEADER DEO PRESS',
            key: 'VAC. STEAM HEADER DEO PRESS',
            align: 'center'
        },
        {
            title: 'SPRG. STEAM HEADER PRESS. Kg/cm2',
            width: 100,
            dataIndex: 'SPRG. STEAM HEADER DEO PRESS',
            key: 'SPRG. STEAM HEADER DEO PRESS',
            align: 'center'
        },
        {
            title: 'B/PLANT STEAM HEADER PRESS. Kg/cm2',
            width: 100,
            dataIndex: 'B/PLANT STEAM HEADER PRESS',
            key: 'B/PLANT STEAM HEADER PRESS',
            align: 'center'
        },
        {
            title: 'NIAGARA STEAM HEADER PRESS. Kg/cm2',
            width: 100,
            dataIndex: 'NIAGARA STEAM HEADER PRESS',
            key: 'NIAGARA STEAM HEADER PRESS',
            align: 'center'
        },

        {
            title: 'C.T. PUMP PRESS. Kg/cm2',
            children: [
                {
                    title: 'No.1',
                    width: 60,
                    dataIndex: 'C.T. PUMP No.1',
                    key: 'C.T. PUMP No.1',
                    align: 'center'
                },
                {
                    title: 'No.2',
                    width: 60,
                    dataIndex: 'C.T. PUMP No.2',
                    key: 'C.T. PUMP No.2',
                    align: 'center'
                },
            ]
        },

        {
            title: 'C.T. FAN IN OPERATION',
            children: [
                {
                    title: 'No.1',
                    width: 60,
                    dataIndex: 'C.T. FAN No.1',
                    key: 'C.T. FAN No.1',
                    align: 'center'
                },
                {
                    title: 'No.2',
                    width: 60,
                    dataIndex: 'C.T. FAN No.2',
                    key: 'C.T. FAN No.2',
                    align: 'center'
                },
            ]
        },

        {
            title: 'COOLING TOWER WATER HEADER',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'Cooling Tower Water Header Temperature',
                    key: 'Cooling Tower Water Header Temperature',
                    align: 'center'
                },
                {
                    title: 'PRESS Kg/cm2',
                    width: 60,
                    dataIndex: 'COOLING TOWER WATER HEADER PRESS',
                    key: 'COOLING TOWER WATER HEADER PRESS',
                    align: 'center'
                },
            ]
        },

        {
            title: 'H.W. PUMP PRESS Kg/cm2',
            children: [
                {
                    title: 'No.1',
                    width: 60,
                    dataIndex: 'H.W. PUMP PRESS No.1',
                    key: 'H.W. PUMP PRESS No.1',
                    align: 'center'
                },
                {
                    title: 'No.2',
                    width: 60,
                    dataIndex: 'H.W. PUMP PRESS No.2',
                    key: 'H.W. PUMP PRESS No.2',
                    align: 'center'
                },
            ]
        },

        {
            title: 'CLEAN COOLING TOWER',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'CLEAN COOLING TOWER No.1',
                    key: 'CLEAN COOLING TOWER No.1',
                    align: 'center'
                },
                {
                    title: 'PRESS',
                    width: 60,
                    dataIndex: 'CLEAN COOLING TOWER No.2',
                    key: 'CLEAN COOLING TOWER No.2',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record) }
            } > Click </Button>,
        }
    ]

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        console.log(initialValues);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancle" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="MAIN STEAM HEADER PRESS"
                        label="MAIN STEAM HEADER PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="VAC. STEAM HEADER DEO PRESS"
                        label="VAC. STEAM HEADER DEO PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="SPRG. STEAM HEADER DEO PRESS"
                        label="SPRG. STEAM HEADER DEO PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="B/PLANT STEAM HEADER PRESS"
                        label="B/PLANT STEAM HEADER PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="NIAGARA STEAM HEADER PRESS"
                        label="NIAGARA STEAM HEADER PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="C.T. PUMP No.1"
                        label="C.T. PUMP No.1"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="C.T. PUMP No.2"
                        label="C.T. PUMP No.2"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="C.T. FAN No.1"
                        label="C.T. FAN No.1"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="C.T. FAN No.2"
                        label="C.T. FAN No.2"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="Cooling Tower Water Header Temperature"
                        label="Cooling Tower Water Header Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="COOLING TOWER WATER HEADER PRESS"
                        label="COOLING TOWER WATER HEADER PRESS"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="H.W. PUMP PRESS No.1"
                        label="H.W. PUMP PRESS No.1"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="H.W. PUMP PRESS No.2"
                        label="H.W. PUMP PRESS No.2"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInput}>
                            <Radio value="ON">ON</Radio>
                            <Radio value="OFF">OFF</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="CLEAN COOLING TOWER No.1"
                        label="CLEAN COOLING TOWER No.1"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="CLEAN COOLING TOWER No.2"
                        label="CLEAN COOLING TOWER No.2"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        console.log('delete');
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', 'OILTEK', 3)
        setVisible(false);
    }

    const handletable = (record) => {
        prevReading = record
        setVisible(true);
    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data) => {
        const WriteRow = []
        WriteRow.push(data)
        for (let key in WriteRow[0]){
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }

        const response = await logdata_Write(WriteRow, 'FGV', 'OILTEK', 3)

        if (response.success === true) {
            message.success('Successfully Add')
            // UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)
            console.log("Write success")
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        console.log(values);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values)
        await UpdateTableData([], setRange[0], setRange[1], 'FGV', 'OILTEK', 3, "LogOtk3", OTKModelPage3)
        setLoading(false)

    };

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>

                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogOtk3']} Columb={columns}
                    site={'OILTEK'} page={'3'} BufferName={"LogOtk3"} Model={OTKModelPage3}
                    AddNewDataRow={AddNewDataRow}
                />
            </Spin>


            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    initialValues={prevReading}
                />
            </div>
        </div>
    )
}

export default OiltekReportPG3
