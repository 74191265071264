import React, { useState, useEffect } from 'react'
import { DeleteJob, delete_logdata, logdata_request, logdata_Write, ReadJob, WriteJob } from '../../api';
import { DataSortTime, TableColumbLabelAsRow, LogDataMappingName1, ReportSort, TableColumbJob, TableColumbLabelAsRowG2, GetDateTime } from '../../DataRequest/LogDataRequestWrap';
import { Button, Modal, Form, Input, Radio, Typography, InputNumber, DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import NameTableWrapper from '../Report/NameTableWrapper';
import ReportTable from '../Report/ReportTable';
import { AlfaModelPage2 } from '../../DataRequest/AlfaModel';
import memoryUtils from '../../utils/memoryUtils';
import { success_state, setA, userinfo, setB } from '../constants'

const { Title } = Typography;

let LogValue = {
    "LogALR2": []
}
let DisableInput = false
let DisableInputOperator = false


let ParaList = Object.keys(AlfaModelPage2)


let columns
let prevReading = { 'DateTime': '2021-04-03 04:03:01' }
let setRange = []

setRange = GetDateTime(0, 1)

const AlfaReportPG2 = () => {

    const [state, setState] = useState({ count: 0 });
    const [visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);


    const user = memoryUtils.user

    if (userinfo['username'] === "Lab") {
        DisableInput = true
    }
    else if (userinfo['username'] === "Operator") {
        DisableInputOperator = true
    }

    useEffect(() => {
        // console.log(setRange);
        UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', "ALPHA LAVAL", 2, "LogALR2", AlfaModelPage2)
        return () => {
        }
    }, [])

    columns = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            fixed: 'left',
            align: 'center',
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'Operator Name',
            key: 'Operator Name',
            align: 'center'
        },
        {
            title: 'Lab Operator Name',
            width: 100,
            dataIndex: 'Lab Operator Name',
            key: 'Lab Operator Name',
            align: 'center'
        },

        {
            title: 'BPO Tank',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'BPO Tank Temperature',
                    align: 'center'
                },
                {
                    title: 'Level(%)',
                    width: 60,
                    dataIndex: 'BPO Tank Level',
                    align: 'center'
                },

            ]
        },

        {
            title: 'BPO FILTER BAG',
            width: 60,
            dataIndex: 'BPO Filter Bag',
            align: 'center'
        },

        {
            title: 'PHE 27E01 BPO TEMP (C)',
            width: 60,
            dataIndex: 'PHE 27E01 BPO Temperature',
            align: 'center'
        },

        {
            title: 'FLOW RATE',
            width: 60,
            dataIndex: 'Flow Rate',
            align: 'center'
        },

        {
            title: 'TEMP (C)',
            children: [
                {
                    title: '27E03 VHE OUT',
                    width: 60,
                    dataIndex: '27E03 VHE Out Temperature',
                    align: 'center'

                },

                {
                    title: '27E04 FINAL HEATER OUT',
                    width: 60,
                    dataIndex: '27E04 Final Heater Out Temperature',
                    align: 'center'

                }]
        },

        {
            title: 'VACUUM TORR',
            width: 60,
            dataIndex: 'Vacuum Torr',
            align: 'center'
        },

        {
            title: 'DEOD 27C03 TEMP(C)',
            width: 60,
            dataIndex: 'DEOD 27C03 Temperature',
            align: 'center'
        },

        {
            title: '26E00 RBD OUT',
            dataIndex: '26E00 RBD Out Temperature',
            width: 60,
            align: 'center'
        },
        {
            title: '27E06 RBD OUT',
            dataIndex: '27E06 RBD Out Temperature',
            width: 60,
            align: 'center'
        },

        {
            title: 'PFAD 27T05',
            children: [
                {
                    title: 'TEMP (C)',
                    width: 60,
                    dataIndex: 'PFAD 27T05 Temperature',
                    align: 'center'
                },
                {
                    title: 'BAR',
                    width: 60,
                    dataIndex: 'PFAD 27T05 Bar',
                    align: 'center'
                },

            ]
        },
        {
            title: 'FILTER BAG BAR',
            width: 60,
            dataIndex: 'Filter Bag Bar',
            align: 'center'
        },

        {
            title: 'TEMP (C)',
            children: [
                {
                    title: 'FRACT',
                    width: 60,
                    dataIndex: 'FRACT Temperature',
                    align: 'center'
                },
                {
                    title: 'STRG',
                    width: 60,
                    dataIndex: 'STRG Temperature',
                    align: 'center'
                },

            ]
        },

        {
            title: 'RPO QUALITY',
            children: [
                {
                    title: 'CLR',
                    width: 60,
                    dataIndex: 'RBDPO Quality CLR',
                    align: 'center'
                },
                {
                    title: 'FFA',
                    width: 60,
                    dataIndex: 'RBDPO Quality FFA',
                    align: 'center'
                },
                {
                    title: 'BLACK PARTICAL',
                    width: 60,
                    dataIndex: 'RBDPO Quality Black Partical',
                    align: 'center'
                },
            ]
        },

        {
            title: 'Action',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 70,
            render: (text, record, index) => <Button onClick={
                (e) => { handletable(record) }
            } > Click </Button>,
        }
    ]

    const CollectionCreateForm = ({ visible, onCreate, onCancel, initialValues }) => {
        // console.log(initialValues['Time']);
        // initialValues['Time'] = moment(initialValues['Time'], "DD-MM-YYYY HH:mm:ss")
        // console.log(initialValues['Time']);
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title="Create a new collection"
                onCancel={onCancel}
                footer={[
                    <Button key="create" type='primary' onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}>
                        Create
                    </Button>,
                    <Button key="cancle" onClick={onCancel}>
                        Cancle
                    </Button>,
                    <Button key="delete" type='primary' danger onClick={() => { onDelete(initialValues['DateTime']) }}>
                        Delete
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="DateTime"
                        label="DateTime"

                        rules={[
                            {
                                required: true,
                                message: `Please Input the value`,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="Operator Name"
                        label="Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Lab Operator Name"
                        label="Lab Operator Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Input disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="BPO Tank Temperature"
                        label="BPO Tank Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="BPO Tank Level"
                        label="BPO Tank Level"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="BPO Filter Bag"
                        label="BPO Filter Bag"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PHE 27E01 BPO Temperature"
                        label="PHE 27E01 BPO Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Flow Rate"
                        label="Flow Rate"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="27E03 VHE Out Temperature"
                        label="27E03 VHE Out Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="27E04 Final Heater Out Temperature"
                        label="27E04 Final Heater Out Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Vacuum Torr"
                        label="Vacuum Torr"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="DEOD 27C03 Temperature"
                        label="DEOD 27C03 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="26E00 RBD Out Temperature"
                        label="26E00 RBD Out Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="27E06 RBD Out Temperature"
                        label="27E06 RBD Out Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD 27T05 Temperature"
                        label="PFAD 27T05 Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="PFAD 27T05 Bar"
                        label="PFAD 27T05 Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="Filter Bag Bar"
                        label="Filter Bag Bar"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="FRACT Temperature"
                        label="FRACT Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="STRG Temperature"
                        label="STRG Temperature"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInput} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality CLR"
                        label="RBDPO Quality CLR"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality FFA"
                        label="RBDPO Quality FFA"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <InputNumber disabled={DisableInputOperator} />
                    </Form.Item>

                    <Form.Item
                        name="RBDPO Quality Black Partical"
                        label="RBDPO Quality Black Partical"
                        rules={[
                            {
                                required: false,
                                message: 'Please input the title of collection!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={DisableInputOperator}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                        </Radio.Group>
                    </Form.Item>

                </Form>
            </Modal>
        );
    };

    const onDelete = (values) => {
        values = moment(values, "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        DeleteLog(ParaList, values, 'FGV', "ALPHA LAVAL", 2)
        setVisible(false);
    }

    const DeleteLog = async (Parameter, DateTime, Site, Line, Page) => {

        const response = await delete_logdata(Parameter, DateTime, Site, Line, Page)

        if (response.success === true) {
            console.log('Deleted');
            UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 2, "LogALR2", AlfaModelPage2)
        }
        else {
            console.log('fail');
            console.log(response);
        }
    }



    const handletable = (record) => {
        prevReading = record
        setVisible(true);
        // console.log(prevReading);
    }

    const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
        setRange[0] = StartTime
        setRange[1] = EndTime

        const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
        let data = DataSortTime(response)

        LogValue[BufferName] = LogDataMappingName1(Model, data)
        // console.log(LogValue[BufferName]);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const AddNewDataRow = async (data) => {
        const WriteRow = []
        WriteRow.push(data)
        for (let key in WriteRow[0]){
            if (WriteRow[0][key] == null) {
                WriteRow[0][key] = ""
            }
        }

        // console.log(WriteRow);
        const response = await logdata_Write(WriteRow, 'FGV', 'ALPHA LAVAL', 2)

        if (response.success === true) {
            message.success('Successfully Add')
            console.log("Write success")
            //UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 2, "LogALR2", AlfaModelPage2)
        }
        else {
            message.warn('Update Fail')
            console.log("fail")
            console.log(response)
        }
    }

    const onCreate = async (values) => {
        // console.log(values['DateTime']);
        values['DateTime'] = moment(values['DateTime'], "DD-MM-YYYY HH:mm:ss").toISOString(false).split('.')[0] + "Z"
        setVisible(false);
        setLoading(true)
        await AddNewDataRow(values)
        await UpdateTableData(ParaList, setRange[0], setRange[1], 'FGV', 'ALPHA LAVAL', 2, "LogALR2", AlfaModelPage2)
        setLoading(false)
    };

    return (
        <div>
            <Spin tip="Uploading" spinning={Loading}>

                <ReportTable SetTimeRangeCallBack={UpdateTableData}
                    LogValue={LogValue['LogALR2']} Columb={columns}
                    site={'ALPHA LAVAL'} page={'2'} BufferName={"LogALR2"} Model={AlfaModelPage2}
                    AddNewDataRow={AddNewDataRow}
                />
            </Spin>

            <div>
                <CollectionCreateForm
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    initialValues={prevReading}
                />
            </div>
        </div>
    )
}

export default AlfaReportPG2
