import React from 'react'
import OiltekPG1Noti from './Notification/OiltekPG1Noti'
import OiltekPG2Noti from './Notification/OiltekPG2Noti'
import OiltekPG3Noti from './Notification/OiltekPG3Noti'

const OiltekNotification = () => {
    return (
        <div>
            <OiltekPG1Noti/>
            <OiltekPG2Noti/>
            <OiltekPG3Noti/>
        </div>
    )
}

export default OiltekNotification
